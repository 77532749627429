
import { useEffect, useState } from "react";
import { db } from "../../firebase/clientFirebaseApps";
import { onSnapshot, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import SiteAlert from "../../components/site_alert";

export default function MaintenancePage() {
  const [message, setMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  // maintinance check
  useEffect(() => {
    const unsub = onSnapshot(doc(db, "settings", "publicSettings"), (doc) => {
      if (doc.data()) {
        const settings = doc.data();
        setMessage(settings?.siteMessage as string || null);
        if (settings && settings.maintenance === false) {
          navigate("/feed");
        }
      }
    });
    return () => unsub();
  
  }, [navigate]);


  return (
    <div className="flex flex-col items-center justify-center h-screen bg-background">
      <img className="w-[200px]" src="/images/logo.png" alt="logo" />
      <h1 className="text-4xl font-bold text-grey mb-8">We&apos;ll be back soon!</h1>
      <p className="text-lg text-gray-600 mb-8 px-3">Sorry for the inconvenience but we&apos;re performing some maintenance at the moment. We&apos;ll be back online shortly!</p>
      <div className="spinner-border text-gray-800" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <SiteAlert siteMessage={message} />
    </div>
  );
};
