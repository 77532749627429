import { P6Slice, P6Event } from "../../../../src/types/event";
import VerifyRandom from "../../components/verify_random/layout";
import { RandomOrgResult } from "../../../../src/types/random";

export default function MiniWinners({
  winners,
  seatList,
  event,
}: {
  winners: number[];
  seatList: P6Slice[] | undefined;
  event: P6Event;
}) {
  return (
    <div className="text-white pt-2.5 relative">
      <div className="bg-green p-2.5 text-[30px] text-center mb-2.5">
        Winner{winners.length > 1 ? "s" : ""}!
        <div className="absolute right-2.5 top-8">
          <VerifyRandom random={event.random as RandomOrgResult} />
        </div>
      </div>
      {winners.map((winner) => (
        <div
          key={winner}
          className="bg-component p-2 rounded-r-xl mb-2.5 flex items-center"
        >
          <div className="bg-green text-[20px] w-20 text-center rounded-lg mr-2.5">
            #{winner}
          </div>
          <div className="pl-5 flex-auto">
            {seatList && seatList[winner - 1].displayName}
          </div>
        </div>
      ))}
    </div>
  );
}
