import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { db } from "../../firebase/clientFirebaseApps";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import Item from "./components/item";
import { type P6DistributionItem } from "../../../../src/types/distribution";

export default function Distribution({
  setCustomEvent,
  setDistributionData,
}: {
  setCustomEvent: Dispatch<SetStateAction<boolean>>;
  setDistributionData: Dispatch<SetStateAction<P6DistributionItem | undefined>>;
}) {
  const [activeList, setActiveList] = useState<P6DistributionItem[]>([]);

  useEffect(() => {
    const q = query(
      collection(db, "distribution"),
      where("status", "==", "active"),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const activeItems: P6DistributionItem[] = [];
      querySnapshot.forEach((doc) => {
        activeItems.push({ id: doc.id, ...doc.data() } as P6DistributionItem);
      });
      if (activeItems.length === 0) {
        setCustomEvent(true);
      } else {
        setActiveList(activeItems);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [setCustomEvent]);
  return (
    <div className="text-white pt-3">
      {activeList.map((item) => (
        <div key={item.id} onClick={() => setDistributionData(item)}>
          <Item item={item} />
        </div>
      ))}
    </div>
  );
}
