      export default function FinancialStatistics({
  purchase,
  payout,
  refund,
  payment,
  trade,
  withdraw,
  groupCreditAdd,
  groupRemoveAdd,
}: {
  purchase: number;
  payout: number;
  refund: number;
  payment: number;
  trade: number;
  withdraw: number;
  groupCreditAdd: number;
  groupRemoveAdd: number;
}) {
  return (
    <div className="text-white mt-4">
      {" "}
      {/* Adjust the margin-top for spacing */}
      <div className="w-[270px] bg-component p-2 rounded-md">
        <div className="bg-shaddow p-2 rounded-md mb-2 text-center">
          <div className="text-2xl">{purchase} $</div>
          <div className="text-sm">Purchase</div>
        </div>
        <div className="bg-shaddow p-2 rounded-md mb-2 text-center">
          <div className="text-2xl">{payout} $</div>
          <div className="text-sm">Payout</div>
        </div>
        <div className="bg-shaddow p-2 rounded-md mb-2 text-center">
          <div className="text-2xl">{refund} $</div>
          <div className="text-sm">Refund</div>
        </div>
        <div className="bg-shaddow p-2 rounded-md mb-2 text-center">
          <div className="text-2xl">{payment} $</div>
          <div className="text-sm">Payment</div>
        </div>
        <div className="bg-shaddow p-2 rounded-md mb-2 text-center">
          <div className="text-2xl">{trade} $</div>
          <div className="text-sm">Trade</div>
        </div>
        <div className="bg-shaddow p-2 rounded-md mb-2 text-center">
          <div className="text-2xl">{withdraw} $</div>
          <div className="text-sm">Withdraw</div>
        </div>
        <div className="bg-shaddow p-2 rounded-md mb-2 text-center">
          <div className="text-2xl">{groupCreditAdd} $</div>
          <div className="text-sm">Add group credit</div>
        </div>
        <div className="bg-shaddow p-2 rounded-md mb-2 text-center">
          <div className="text-2xl">{groupRemoveAdd} $</div>
          <div className="text-sm">Remove group credit</div>
        </div>
      </div>
    </div>
  );
}
