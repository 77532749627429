import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "@firebase/auth";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { clientAuth, analytics } from "../../../firebase/clientFirebaseApps";
import { signIn } from "../../../firebase/clientUserSessionUtils";
import { useForm, FieldValues, SubmitHandler } from "react-hook-form";
import { useState } from "react";
import TermsModal from "../terms_modal";
import { logEvent } from "firebase/analytics";
import { authErrors } from "../../../utils/authErrors";
import { FirebaseError } from "@firebase/util";
import clevertap from "clevertap-web-sdk";
import P6Button from "../../../components/button";

export default function Login() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [authError, setAuthError] = useState<string>();
  const navigate = useNavigate();
  const join = searchParams.get("join");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const appleProvider = new OAuthProvider("apple.com");

  // google login
  async function googleSignIn() {
    try {
      const { user } = await signInWithPopup(clientAuth, googleProvider);
      await signIn(user);
      if (join) {
        clevertap.event.push("Joined Group with Google");
        navigate(`/join/${join}`);
      } else {
        clevertap.event.push("Logged In with Google");
        navigate(`/feed`);
      }
      logEvent(analytics, "login");
    } catch (error) {
      if (error instanceof FirebaseError) {
        console.error();
        setAuthError(authErrors[error.code as keyof typeof authErrors]);
      }
      setLoading(false);
    }
  }

  // facebook login
  async function facebookSignin() {
    try {
      const { user } = await signInWithPopup(clientAuth, facebookProvider);

      await signIn(user);
      if (join) {
        clevertap.event.push("Joined Group with Facebook");
        navigate(`/join/${join}`);
      } else {
        clevertap.event.push("Logged In with Facebook");
        navigate(`/feed`);
      }
      logEvent(analytics, "login");
    } catch (error) {
      if (error instanceof FirebaseError) {
        console.error();
        setAuthError(authErrors[error.code as keyof typeof authErrors]);
      }
      setLoading(false);
    }
  }

  //apple login
  async function appleSignin() {
    try {
      const { user } = await signInWithPopup(clientAuth, appleProvider);

      await signIn(user);
      if (join) {
        clevertap.event.push("Joined Group with Apple");
        navigate(`/join/${join}`);
      } else {
        clevertap.event.push("Logged In with Apple");
        navigate(`/feed`);
      }
      logEvent(analytics, "login");
    } catch (error) {
      if (error instanceof FirebaseError) {
        console.error();
        setAuthError(authErrors[error.code as keyof typeof authErrors]);
      }
      setLoading(false);
    }
  }

  // email login
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const formData = data as { email: string; password: string };
    setLoading(true);
    try {
      const { user } = await signInWithEmailAndPassword(
        clientAuth,
        formData.email,
        formData.password,
      );
      await signIn(user);
      if (join) {
        clevertap.event.push("Joined Group with Email");
        navigate(`/join/${join}`);
      } else {
        clevertap.event.push("Logged In with Email");
        clevertap.onUserLogin.push({
          Site: {
            Identity: user.uid,
          },
        });
        navigate(`/feed`);
      }
      setLoading(false);
    } catch (error) {
      if (error instanceof FirebaseError) {
        console.error();
        setAuthError(authErrors[error.code as keyof typeof authErrors]);
      }
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full flex-col items-center">
      <div className="mt-108 mb-5 text-[44px] font-thin">Login</div>
      <div
        onClick={() => googleSignIn()}
        className="mb-[14px] flex w-[290px] cursor-pointer items-center rounded-full bg-white text-black hover:bg-dark-grey hover:text-white "
      >
        <div className="overflow-hidden rounded-full bg-white">
          <img
            src="/icons/google_icon.svg"
            height="43"
            width="43"
            className="m-[2px]"
            alt="google"
          />
        </div>
        <div className="ml-2.5 text-[21px]">Login with Google</div>
      </div>
      <div
        onClick={() => facebookSignin()}
        className="mb-[14px] flex w-[290px] cursor-pointer items-center rounded-full bg-white text-black hover:bg-dark-grey hover:text-white"
      >
        <div className="overflow-hidden rounded-full bg-white">
          <img
            src="/icons/facebook_icon.svg"
            height="41"
            width="41"
            className="m-[4px]"
            alt="facebook"
          />
        </div>
        <div className="ml-2.5 text-[21px]">Login with Facebook</div>
      </div>
      <div
        onClick={() => appleSignin()}
        className="mb-2.5 flex w-[290px] cursor-pointer items-center rounded-full bg-white text-black hover:bg-dark-grey hover:text-white"
      >
        <div className="overflow-hidden rounded-full bg-white">
          <img
            src="/icons/apple_icon.svg"
            height="41"
            width="33"
            className="my-[3px] ml-[6px] mr-[8px]"
            alt="apple"
          />
        </div>
        <div className="ml-2.5 text-[21px]">Login with Apple</div>
      </div>
      <div className="flex w-full items-center px-14">
        <div className="mt-1 flex w-full flex-auto border-b-[1px] border-dark-grey" />
        <div className="mx-2.5 flex text-[29px] font-light">or</div>
        <div className="mt-1 flex w-full flex-auto border-b-[1px] border-dark-grey" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className={`w-[290px] ${open ? "" : "hidden"}`}>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="email"
                id="email"
                autoComplete="email"
                className="text-field peer pl-9"
                placeholder=" "
                {...register("email", {
                  onChange: () => setAuthError(""),
                  required: true,
                })}
              />
              <div className="absolute top-[0px] flex h-[35px] w-[28px] items-center justify-end">
                <img
                  src="/icons/email_icon.svg"
                  alt="email icon"
                  className="top-[7px] left-[10px] z-10"
                  width={17.49}
                  height={17.49}
                />
              </div>
              <label htmlFor="email" className="form-label ml-7">
                Email
              </label>
            </div>
            {errors.email && <p className="form-error">Email is Required</p>}
          </div>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="password"
                id="password"
                autoComplete="password"
                className="text-field peer pl-9"
                placeholder=" "
                {...register("password", {
                  onChange: () => setAuthError(""),
                  required: true,
                })}
              />
              <div className="absolute top-[0px] flex h-[35px] w-[28px] items-center justify-end">
                <img
                  src="/icons/key_icon.svg"
                  alt="password icon"
                  className="top-[7px] left-[10px] z-10"
                  width={17.49}
                  height={17.49}
                />
              </div>
              <label htmlFor="password" className="form-label ml-7">
                Password
              </label>
            </div>
            {errors.password && (
              <p className="form-error">Password is Required</p>
            )}
            {authError && <p className="form-error">{authError}</p>}
          </div>
        </div>
        <div></div>
        <div
          onClick={() => setOpen(true)}
          data-cy="login-with-email"
          className={`mb-[14px] mt-5 flex w-[290px] cursor-pointer items-center justify-center rounded-full bg-p6blue py-[7px] text-[21px] hover:bg-dark-grey ${
            open ? "hidden" : ""
          }`}
        >
          Login with email
        </div>
        {/* <button
          type="submit"
          className={`relative mb-[14px] mt-5 flex w-[290px] items-center justify-center rounded-full bg-p6blue py-[7px] text-[21px] hover:bg-dark-grey ${
            open ? "" : "hidden"
          }`}
          disabled={loading}
        >
          {loading && (
            <img
              src="/icons/spinner_icon.svg"
              className="absolute left-[4px] top-[4px] animate-spin"
              width="40"
              height="40"
              alt="spinner"
            />
          )}
          Login with email
        </button> */}
        <div className="flex">
          {open && (<P6Button dataCy="login-with-email-button" sm type="submit" text="Login with email" loading={loading} disabled={loading} />)}
        </div>
      </form>
      <div
        className={`w-[290px] text-[15px] font-light ${open ? "hidden" : ""}`}
      >
        <p>By logging in, you agree to the </p>
        <div>
          <span className="text-p6blue">
            <TermsModal />
          </span>{" "}
          of Platform Sixx
        </div>
      </div>
      <div className="mt-2 text-[21px] font-light text-p6blue">
        <Link to="/resetpassword">Reset Password</Link>
      </div>
      <div className="mt-2 text-[21px] font-light">Need an Account?</div>
      <Link to={join ? `/signup?join=${join}` : "/signup"}>
        <div data-cy="sign-up-button" className="mb-5 mt-2 flex w-[290px] items-center justify-center rounded-full bg-white py-[7px] text-[21px] text-black hover:bg-dark-grey hover:text-white">
          Sign Up
        </div>
      </Link>
    </div>
  );
}
