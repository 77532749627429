import { Outlet } from "react-router-dom";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { clientAuth } from "../../firebase/clientFirebaseApps";
import { doc, getDoc } from "firebase/firestore";
import { useOutletContext } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { db } from "../../firebase/clientFirebaseApps";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../firebase/clientUserSessionUtils";
import { type P6CreateUserProfile } from "../../../../src/types/profile";
import * as Sentry from "@sentry/react";
import { P6ClientError } from "../../utils/exeptions";

type ContextType = {
  user: P6CreateUserProfile | null;
  setUser: Dispatch<SetStateAction<P6CreateUserProfile>>;
  socialPic: string;
};

export default function FinishProfileLayout() {
  const [userId, setUserId] = useState<string>();
  // const [profile, setprofile] = useState<boolean>();
  const [user, setUser] = useState<P6CreateUserProfile | null>(null);
  const [socialPic, setSocialPic] = useState<string>();
  const auth = clientAuth;
  const navigate = useNavigate();

  // set Sentry to identify the user
  Sentry.setUser({ email: user?.email, id: user?.userId });

  const logout = async () => {
    await signOut(clientAuth);
    window.dataLayer.push({
      event: "logout",
    } as unknown);
    navigate("/login");
  };

  useEffect(() => {
    if (auth.currentUser && auth.currentUser.photoURL) {
      setSocialPic(auth.currentUser.photoURL);
    }
  }, [auth.currentUser]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        navigate("/login");
      }
    });
  }, [auth, navigate]);

  useEffect(() => {
    (async () => {
      if (userId) {
        const docRef = doc(db, "profiles", userId);
        const docSnap = await getDoc(docRef);
        const userData = docSnap.data();
        if (docSnap.exists()) {
          // setprofile(true);
          if (userData && userData.completeProfile === true) {
            navigate("/feed");
          }
        }
      }
    })().catch((error) => {
      throw new P6ClientError("UnknownError", error as string);
    });
  }, [userId, navigate]);

  const year = new Date().getFullYear();

  if (!userId) {
    return null;
  }
  return (
    <div className="auth-bg flex justify-center bg-background">
      <div className="flex text-white container mx-auto">
        <div className="hidden flex-auto text-[32px] text-white md:flex md:max-w-[50%] md:items-center md:justify-center md:p-12 md:text-center">
          We make it easy for you to engage with the content you love!
        </div>
        <div className="flex h-screen flex-col items-center text-white flex-auto">
          {auth.currentUser && (
            <div
              onClick={() => logout()}
              className="self-end mt-1 text-sm cursor-pointer text-p6blue hover:text-grey"
            >
              Logout ({auth.currentUser.email})
            </div>
          )}
          <div className="mt-6 flex">
            <img src={"/images/logo.png"} width="275" alt="logo" />
          </div>
          <div className="w-full flex-auto">
            <Outlet context={{ user, setUser, userId, socialPic }} />
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-5">
              <img src="/icons/p6_small_logo.svg" alt="small logo" />
            </div>
            <div>© {year} Platform Sixx</div>
            <div className="text-[6px] text-dark-grey text-right">
              Ver. {import.meta.env.VITE_P6_APP_VERSION}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function useUser() {
  return useOutletContext<ContextType>();
}
