import { useState, useEffect } from "react";
import { collection, doc, getDoc, limit, onSnapshot, orderBy, query, startAfter, Timestamp, where } from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";
import Loading from "./loading";
import EventCard from "../event_card/event_card";
import { type P6Event } from "../../../../src/types/event";
import { P6UserProfile } from "../../../../src/types/profile";
import { eventsRollUp } from "../../utils/eventsRollUp";
import EventRollUpComponent from "../../views/shared/eventRollUpComponent";
import { P6Timestamp } from "../../../../src/types/helpers";
import { P6ClientError } from "../../utils/exeptions";

export default function EventList({ user, activeGroupIds }: { user: P6UserProfile, activeGroupIds?: string[] }) {
  const [events, setEvents] = useState<P6Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [firstTime, setFirstTime] = useState(true);
  const [lastDate, setLastDate] = useState<P6Timestamp>({
    nanoseconds: 0,
    seconds: -62135596800
  }); // latest possible date in firestore
  useEffect(() => {
    setLoading(true);
    const handleScroll = () => {
      const innerContainer = document.getElementById("inner-scroll") as HTMLElement;
      const bottom =
        Math.ceil(innerContainer.scrollTop + innerContainer.clientHeight) >=
        innerContainer.scrollHeight - 200;

      if (activeGroupIds && activeGroupIds.length && (bottom || firstTime)) {
        const q = query(collection(db, "events"), where("groupId", "in", activeGroupIds), where("state", "==", "active"), orderBy("createdAt", "desc"), startAfter(lastDate), limit(20),);
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const ar: P6Event[] = [];
          querySnapshot.docs.forEach((doc) => {
            const alreadyExist = events.map(x => x.id).includes(doc.id);
            if (!alreadyExist) {
              ar.push({ id: doc.id, ...doc.data() } as P6Event);
            }
          });
          const mainEventIds = ar.reduce((accumulator: string[], event) => {
            if (event.mainEventIds) {
              const mainEventIdsAsString = event.mainEventIds.map(id => id);
              return accumulator.concat(mainEventIdsAsString);
            }
            return accumulator;
          }, []);
          const missingMainEventIds = mainEventIds.filter(x => !ar.map(x => x.id).includes(x))
          if (missingMainEventIds.length > 0) {
            const getEventData = async (eventId: string) => {
              const eventDocRef = doc(db, "events", eventId);
              const eventDocSnapshot = await getDoc(eventDocRef);
              return { ...eventDocSnapshot.data(), id: eventId } as P6Event;
            };

            const getMissingMainEventsData = async (missingMainEventIds: string[], eventsData: P6Event[]) => {
              const requests = missingMainEventIds.map((eventId) => getEventData(eventId));
              const missingMainEventsData = await Promise.all(requests);
              const updatedEvents = eventsRollUp(eventsData.concat(missingMainEventsData));
              const uniqueEventIds = [...new Set(updatedEvents.concat(eventsData).map(x => x.id))];
              const allEvents = updatedEvents.concat(eventsData);
              const uniqueEvents: P6Event[] = [];
              for (const id of uniqueEventIds) {
                
                const ev = allEvents.filter(x => x.id === id)[0];
                uniqueEvents.push(ev);
              }

              setEvents(uniqueEvents);
            };

            (async () => {
              await getMissingMainEventsData(missingMainEventIds, ar);
            })().catch(err => {
              throw new P6ClientError("UnknownError", err as string);
            });
          }

          const lDate = ar.map(x => x.createdAt)[ar.length - 1];
          const updatedEvents = eventsRollUp(ar)
          const eventsData = updatedEvents.sort((a, b) => {
            // First sort by the featured event
            const aFeaturedCount = a.options.featured;
            const bFeaturedCount = b.options.featured;
            if (bFeaturedCount !== aFeaturedCount) {
              return bFeaturedCount ? 1 : -1;
            }
            // Then sort by the date of the latest event
            const aLatestEventDate = (a.createdAt as Timestamp).toDate().getTime();
            const bLatestEventDate = (b.createdAt as Timestamp).toDate().getTime();

            return bLatestEventDate - aLatestEventDate;
          })

          setEvents(events.concat(eventsData).filter(x => x));
          if (lDate) {
            setLastDate(lDate);
          }
        });

        return () => unsubscribe();
      } else { setLoading(false) }
      setLoading(false);
    };
    const innerContainer = document.getElementById("inner-scroll") as HTMLElement;
    innerContainer.addEventListener("scroll", handleScroll, { passive: true });
    if (firstTime) {
      setFirstTime(false);
      handleScroll();
    }
    setLoading(false);
    return () => {
      innerContainer.removeEventListener("scroll", handleScroll);
    };
  }, [activeGroupIds, events, lastDate, firstTime]);


  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {events.map((event: P6Event) => (
        <div key={event.id}>
          <EventCard key={event.id} data={event} user={user} />
          {/* mini details */}
          {event?.events && <EventRollUpComponent events={event.events} />}
        </div>
      )
      )}
      {events.length === 0 && (
        <div className="mt-2.5 flex h-[87px] w-full flex-col justify-center rounded-lg bg-component text-center">
          <p className="text-[25px]">No Available Events</p>
          <p className="">Check back later.</p>
        </div>
      )}
    </div>
  );
}
