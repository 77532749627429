import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";
import { P6Event } from "../../../../src/types/event";
import Step1 from "./step1";
import Step2 from "./step2";
import P6Button from "../button";

export default function NewMiniSlideout({ groupId }: { groupId: string }) {
  const [open, setOpen] = useState(false);
  const [nextError, setNextError] = useState(false);
  const [step, setStep] = useState(1);
  const [events, setEvents] = useState<P6Event[]>([]);
  const [eventsWithMini, setEventsWithMini] = useState<P6Event[]>([]);

  useEffect(() => {
    const eventsRef = collection(db, "events");
    const eventsQuery = query(
      eventsRef,
      where("groupId", "==", groupId),
      where("state", "==", "active"),
      where("available", "!=", 0),
    );
    const unsubscribe = onSnapshot(eventsQuery, (eventsSnapshot) => {
      if (eventsSnapshot.size > 0) {
        const eventsData: P6Event[] = [];
        eventsSnapshot.forEach((doc) => {
          const eventData = doc.data();
          const eventId = doc.id;
          eventData.id = eventId;
          eventsData.push(eventData as P6Event);
        });
        const eventWithMinisIds = eventsWithMini.map((x) => x.id);
        const filteredEvents = eventsData.filter(
          (x) => !eventWithMinisIds.includes(x.id),
        );
        setEvents(filteredEvents);
        setNextError(false);
      } else {
        setEvents([]);
      }
    });

    return () => unsubscribe();
  }, [groupId, eventsWithMini]);

  const nextStep = () => {
    if (eventsWithMini.length > 0) {
      setStep(2);
    } else {
      setNextError(true);
    }
  };

  return (
    <div>
      <div className="flex px-2">
        <P6Button
          div
          text="MINI"
          icon="add_icon"
          sm
          iconTop="6"
          color="bg-green"
          onClick={() => setOpen(!open)}
        />
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-12">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative z-50 w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4"></div>
                    </Transition.Child>
                    {step === 1 && (
                      <Step1
                        eventsWithMini={eventsWithMini}
                        events={events}
                        setEventsWithMini={setEventsWithMini}
                        nextStep={() => nextStep()}
                        nextError={nextError}
                      />
                    )}
                    {step === 2 && (
                      <Step2
                        eventsWithMini={eventsWithMini}
                        setEventsWithMini={setEventsWithMini}
                        setStep={setStep}
                        setOpen={setOpen}
                        groupId={groupId}
                      />
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
