import { useEffect, useState } from "react";
import { P6Fulfillment } from "../../../../../src/types/fulfillment";
import { db } from "../../../firebase/clientFirebaseApps";
import { doc, onSnapshot } from "firebase/firestore";
import { P6DistributionItem } from "../../../../../src/types/distribution";

export default function OrderItem({ orderData }: { orderData: P6Fulfillment }) {
  const [distributionItem, setDistributionItem] =
    useState<P6DistributionItem>();

  useEffect(() => {
    if (orderData && orderData.distributionItem) {
      const itemId = orderData.distributionItem;
      const unsub = onSnapshot(doc(db, "distribution", itemId), (doc) => {
        setDistributionItem(doc.data() as P6DistributionItem);
      });

      return () => unsub();
    }
  }, [orderData]);

  return (
    <div className="text-white bg-component mt-2 rounded-md p-2 flex items-center">
      {!orderData.complete && (
        <div
          className={`${
            orderData.fulfillmentType ? "bg-green" : " bg-orange-400"
          } w-3 h-3 rounded-full`}
        />
      )}
      {orderData.complete && <div className="bg-p6blue w-3 h-3 rounded-full" />}
      <div className="flex-auto ml-3">
        <div className="text-xs text-grey">
          {distributionItem && distributionItem.upc}
        </div>
        <div>{distributionItem && distributionItem.title}</div>
      </div>
    </div>
  );
}
