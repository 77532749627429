import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { clientAuth } from "../../firebase/clientFirebaseApps";
import ClosedEventIos from "../../components/closed_event_ios/closedEventIos";

export default function EventWinner() {
  const [, setUserId] = useState<string>();
  const auth = clientAuth;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
  }, [auth]);

  return (
    <>
      <ClosedEventIos />
    </>
  );
}
