import { useState, useEffect } from "react";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";
import { Link } from "react-router-dom";
import Loading from "./loading";
import GroupList from "./group_list";
import { useUser } from "../../layouts/page_layout/layout";
import { P6Group } from "../../../../src/types/group";

export default function Groups() {
  const [activeGroups, setActiveGroups] = useState<P6Group[]>([]);
  const [pendingGroups, setPendingGroups] = useState<P6Group[]>([]);
  const [loading, setLoading] = useState(true);
  const { user, activeGroupIds, pendingGroupIds } = useUser();

  useEffect(() => {
    if (user && activeGroupIds.length > 0) {
      setLoading(true);

      // Create an array to store promises for each query
      const queryPromises = activeGroupIds.map((groupId) => {
        const groupsRef = collection(db, "groups");
        const q = query(
          groupsRef,
          where("groupId", "==", groupId),
          orderBy("createdAt", "desc"),
        );

        return new Promise<P6Group[]>((resolve, reject) => {
          onSnapshot(
            q,
            (querySnapshot) => {
              const activeAr: P6Group[] = [];
              querySnapshot.docs.forEach((doc) => {
                activeAr.push({ id: doc.id, ...doc.data() } as P6Group);
              });
              resolve(activeAr);
            },
            reject,
          );
        });
      });

      // Execute all promises concurrently
      Promise.all(queryPromises)
        .then((results) => {
          // Combine results from all queries
          const activeGroups = results.flat();
          setActiveGroups(activeGroups);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // Handle any errors here
          console.error("Error fetching active groups:", error);
        });
    } else {
      setLoading(false);
    }
  }, [activeGroupIds, user]);

  useEffect(() => {
    const unsubscribeFunctions: (() => void)[] = [];

    if (user && pendingGroupIds.length > 0) {
      const groupsRef = collection(db, "groups");

      for (const groupId of pendingGroupIds) {
        const q = query(
          groupsRef,
          where("groupId", "==", groupId),
          orderBy("createdAt", "desc"),
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const pendingAr: P6Group[] = [];
          querySnapshot.forEach((doc) => {
            pendingAr.push({ id: doc.id, ...doc.data() } as P6Group);
          });

          setPendingGroups((prevPendingGroups) => {
            // Merge the new data with the previous state
            const updatedGroups = prevPendingGroups.filter(
              (group) => group.id !== groupId,
            );
            return [...updatedGroups, ...pendingAr];
          });
        });

        unsubscribeFunctions.push(unsubscribe);
      }

      setLoading(false);
    } else {
      setLoading(false);
    }

    // Clean up all listeners when the component unmounts
    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  }, [pendingGroupIds, user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex-auto">
      <div className="mt-2.5 flex items-center p-2.5">
        <Link to="/join" className="flex flex-auto">
          <div className="flex pr-2.5">
            <img
              src="/icons/plus_icon.svg"
              alt="Create Group"
              className="m-auto"
              width={24}
              height={24}
            />
          </div>
          <div className="text-white text-[24px] flex-auto text-left">
            Join Group
          </div>
        </Link>
        <Link to="/groups/create" className="flex justify-end">
          <div className=" text-[24px] text-white">Create Group</div>
          <div className="flex pl-2.5">
            <img
              src="/icons/plus_icon.svg"
              alt="Create Group"
              className="m-auto"
              data-cy="CreateGroup"
              width={24}
              height={24}
            />
          </div>
        </Link>
      </div>
      {activeGroupIds.length > 0 && (
        <div>
          <GroupList status="active" groups={activeGroups.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds )} />
        </div>
      )}
      {pendingGroupIds.length > 0 && (
        <div>
          <GroupList status="pending" groups={pendingGroups.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds )} />
        </div>
      )}
      {activeGroupIds.concat(pendingGroupIds).length === 0 && (
        <div className="mt-2.5 rounded-lg bg-component py-[30px] text-center text-[32px] text-white">
          No Available Groups
        </div>
      )}
    </div>
  );
}
