import { P6Group } from "../../../../src/types/group";
import GroupCard from "./group_card";

export default function GroupList({
  groups,
  status,
}: {
  groups: P6Group[];
  status: string;
}) {
  return (
    <div>
      {groups.map((group: P6Group, index) => (
        <GroupCard key={group.id} group={group} join={false} status={status} index={index + 1}/>
      ))}
    </div>
  );
}
