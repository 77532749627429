import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useUser } from "../../../layouts/page_layout/layout";
import StateSelect from "../../../components/form_fields/select_state";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { P6UserAddress } from "../../../../../src/types/profile";
import { updateFulfillment } from "../../../functions";
import P6Button from "../../button";
import { P6ClientError } from "../../../utils/exeptions";
import { P6Fulfillment } from "../../../../../src/types/fulfillment";

export default function ShipOption({
  setOptionsOpen,
  fulfillment,
  eventId,
}: {
  setOptionsOpen: Dispatch<SetStateAction<boolean>>;
  fulfillment: P6Fulfillment;
  eventId: string;
}) {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState<P6UserAddress>();
  const { user } = useUser();
  const {
    register,
    setError,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<FieldValues>();

  useEffect(() => {
    if (user) {
      setAddress(user.address);
    }
  }, [user]);

  const onSubmit: SubmitHandler<FieldValues> = (params) => {
    if (!params.state) {
      setError("state", { type: "custom", message: "custom message" });
    } else {
      setAddress(params as P6UserAddress);
      setEdit(false);
    }
  };

  const onError = () => {
    if (!getValues("state")) {
      setError("state", { type: "custom", message: "custom message" });
    }
  };

  const ship = async () => {
    setLoading(true);
    try {
      const request = {
        id: fulfillment.id,
        eventId: eventId,
        fulfillmentType: "ship",
        address: address,
        optionSelectedDate: new Date(),
        payout: true,
      };
      if (fulfillment.distribution && fulfillment.distributionItem) {
        request.distribution = fulfillment.distribution;
        request.distributionItem = fulfillment.distributionItem;
      }
      await updateFulfillment(request);
      window.dataLayer.push({
        event: "request_shipping",
        eventProps: {
          id: fulfillment.id,
          event_id: eventId
        },
      } as unknown);
      setLoading(false);
      setOptionsOpen(false);
    } catch (error) {
      throw new P6ClientError("UnknownError", error as string);
    }
  };
  return (
    <>
      {edit ? (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="p-2.5">
            <div className="text-center text-lg font-light leading-4">
              Address
            </div>
            <div className="text-sm text-white text-opacity-50 text-center mb-3">
              Update your shipping address
            </div>
            <div className="mb-2.5">
              <div className="relative mb-2.5">
                <input
                  type="text"
                  id="address"
                  autoComplete="address-line1"
                  className="text-field peer"
                  placeholder=" "
                  {...register("address", {
                    required: true,
                  })}
                />
                <label htmlFor="address" className="form-label">
                  Address
                </label>
              </div>
              {errors.address && (
                <p className="form-error">Address is Required</p>
              )}
            </div>
            <div className="mb-2.5">
              <div className="relative mb-2.5">
                <input
                  type="text"
                  id="address2"
                  autoComplete="address-line2"
                  className="text-field peer"
                  placeholder=" "
                  {...register("address2", {
                    required: false,
                  })}
                />
                <label htmlFor="address2" className="form-label">
                  Address 2
                </label>
              </div>
              {errors.address2 && (
                <p className="form-error">Address 2 is Required</p>
              )}
            </div>
            <div className="mb-2.5">
              <div className="relative mb-2.5">
                <input
                  type="text"
                  id="city"
                  autoComplete="address-level2"
                  className="text-field peer"
                  placeholder=" "
                  {...register("city", {
                    required: true,
                  })}
                />
                <label htmlFor="city" className="form-label">
                  City
                </label>
              </div>
              {errors.city && <p className="form-error">City is Required</p>}
            </div>
            <div className="mb-2.5">
              <div className="relative mb-2.5">
                <input
                  type="text"
                  id="zip"
                  autoComplete="postal-code"
                  className="text-field peer"
                  placeholder=" "
                  {...register("zip", {
                    required: true,
                  })}
                />
                <label htmlFor="zip" className="form-label">
                  Zip Code
                </label>
              </div>
              {errors.zip && <p className="form-error">Zip Code is Required</p>}
            </div>
            <div data-cy="ship-select-state">
              <StateSelect
                errors={errors}
                setValue={setValue}
                clearErrors={clearErrors}
                defaultValue={getValues("state") as string}
              />
            </div>
            <div className="flex p-2.5 gap-2">
              <div className="flex items-center justify-center text-center">
                <P6Button
                  sm
                  text="Cancel"
                  onClick={() => setEdit(false)}
                  color="bg-dark-grey"
                />
              </div>
              <div className="flex flex-grow items-center justify-center text-center">
                <P6Button
                  type="submit"
                  sm
                  icon="address_icon"
                  iconLeft
                  text="Save Address"
                />
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="relative">
          <div className="text-2xl text-center mb-2">
            <div className="text-lg">Ship To</div>
          </div>
          <div className="text-s text-right mr-5">
            <div
              data-cy="shipment-edit"
              onClick={() => setEdit(true)}
              className="text-p6blue cursor-pointer text-sm absolute top-0 right-2.5"
            >
              edit
            </div>
          </div>
          <div className="flex">
            <div className="flex w-[100px] justify-center">
              <img
                src="/icons/address_icon.svg"
                alt="Location"
                className="w-10 h-10"
              />
            </div>
            <div className="text-sm text-left pl-2.5 mb-2 pr-10">
              <h2>{address && address.address}</h2>
              <h2>
                {address && address.city}, {address && address.state}{" "}
                {address && address.zip}
              </h2>
            </div>
          </div>
          <div className="flex flex-grow items-center justify-center text-center p-2.5 w-52 m-auto" data-cy="ship-to-button">
            {loading ? (
              <P6Button sm text="Sent" color={"bg-grey"} loading={loading} />
            ) : (
              <P6Button
                sm
                text="Ship"
                iconLeft
                icon="ship_white_icon"
                onClick={() => ship()}
                color="bg-p6blue"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
