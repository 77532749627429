import { percent, isFull } from "../../utils/functions";

export default function Progress({
  seats,
  available,
}: {
  seats: number;
  available: number;
}) {
  return (
    <div className="relative mt-2.5 h-[19px] w-full bg-black">
      <div className="absolute m-auto flex h-[16px] w-full items-center justify-center text-[18px] text-white">
        {seats - (available < 0 ? 0 : available) }/{seats}
      </div>
      <div
        className={`${isFull(
          (available < 0 ? 0 : available),
        )} flex h-[19px] items-center justify-center`}
        style={{ width: `${percent(seats, (available < 0 ? 0 : available))}%` }}
      ></div>
    </div>
  );
}
