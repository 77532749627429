import { useState, useEffect, Dispatch, SetStateAction } from "react";

export default function Tabs({
  admin,
  setTab,
}: {
  admin: boolean;
  setTab: Dispatch<SetStateAction<string>>;
}) {
  const [tabs, setTabs] = useState<
    { name: string; href: string; current: boolean }[]
  >([]);

  useEffect(() => {
    if (admin) {
      setTabs([
        { name: "Add Credits", href: "#", current: true },
        { name: "Payout", href: "#", current: false },
      ]);
    } else {
      setTabs([{ name: "Add Credits", href: "#", current: true }]);
    }
  }, [admin]);

  const selectTab = (tabName: string) => {
    const newTabs = tabs.map((tab) => {
      if (tab.name === tabName) {
        setTab(tab.name);
        tab.current = true;
      } else {
        tab.current = false;
      }
      return tab;
    });
    setTabs(newTabs);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div>
      <div>
        <div className="border-b border-gray-200">
          <nav
            className={`-mb-px grid ${admin ? "grid-cols-2" : "grid-cols-1"}`}
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? "border-p6blue text-p6blue"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium",
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                <div
                  className=" text-center"
                  onClick={() => selectTab(tab.name)}
                >
                  {tab.name}
                </div>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
