import { useEffect, useState } from "react";
import IOSPaymentForm from "../../components/payment_modal/iOSPaymentForm";
import { onAuthStateChanged } from "firebase/auth";
import { clientAuth } from "../../firebase/clientFirebaseApps";

export default function IOSPayment() {
  const [, setUserId] = useState<string>();
  const auth = clientAuth;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
  }, [auth]);

  return (
    <>
      <IOSPaymentForm />
    </>
  );
}
