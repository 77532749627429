import { Link, useLocation, useParams } from "react-router-dom";
import PaymentModal from "../../components/payment_modal/payment_modal";
import MenuSlideout from "../../components/menu_slideout/menu_slideout";
import { useEffect, useState, Dispatch, type SetStateAction } from "react";
import { db } from "../../firebase/clientFirebaseApps";
import { doc, onSnapshot } from "firebase/firestore";
import { P6UserProfile } from "../../../../src/types/profile";

export default function LayoutSubHeader({
  paymentOpen,
  setPaymentOpen,
  creditAmount,
  user,
}: {
  paymentOpen: boolean;
  setPaymentOpen: Dispatch<SetStateAction<boolean>>;
  creditAmount?: number;
  displayName: string;
  profileImageUrl: string;
  user: P6UserProfile;
}) {
  const { pathname } = useLocation();
  const { eventId, groupId } = useParams();
  const [credits, setCredits] = useState<number>(0);
  const [group, setGroup] = useState<string>("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [groupCredits, setGroupCredits] = useState<number>(0);

  useEffect(() => {
    let groupCredits = 0;
    if (group) {
      if (user.groupCredits) {
        groupCredits = user.groupCredits[group];
      }
      setCredits(user.credits | 0);
      setGroupCredits(groupCredits);
    } else {
      setCredits(user.credits);
    }
  }, [groupId, group, user.credits, pathname, user.groupCredits]);

  useEffect(() => {
    if (eventId) {
      const unsub = onSnapshot(doc(db, "events", eventId), (doc) => {
        setGroup(doc?.data()?.groupId as string);
      });
      return () => unsub();
    }
    if (groupId) {
      setGroup(groupId);
    }
    if (!eventId && !groupId) {
      setGroup("");
    }
  }, [eventId, groupId]);

  const active = (path: string) => {
    if (pathname === path) {
      return "head-active";
    }
  };

  return (
    <div className="sticky top-[55px] z-50 h-[55px] w-full border-b border-border bg-component">
      <div className="flex items-center">
        <div
          className={`flex h-14 w-16 items-center justify-center ${
            active("/feed") as string
          }`}
        >
          <Link to="/feed">
            <img
              src={
                pathname === "/feed"
                  ? "/icons/feed_icon_on.svg"
                  : "/icons/feed_icon.svg"
              }
              alt="Feed"
              className="m-auto"
              width={37}
              height={30}
            />
          </Link>
        </div>
        <div
          className={`flex h-14 w-16 items-center justify-center ${
            active("/groups") as string
          }`}
        >
          <Link to="/groups">
            <img
              src={
                pathname === "/groups"
                  ? "/icons/groups_icon_on.svg"
                  : "/icons/groups_icon.svg"
              }
              alt="Groups"
              data-cy="GroupsNav"
              className="m-auto"
              width={47}
              height={30}
            />
          </Link>
        </div>

        <div
          className={`flex h-14 w-16 items-center justify-center ${
            active("/history") as string
          }`}
        >
          <Link to="/history">
            <img
              src={
                pathname === "/history"
                  ? "/icons/user_history_icon_on.svg"
                  : "/icons/user_history_icon.svg"
              }
              alt="History"
              className="m-auto"
              width={30}
              height={30}
            />
          </Link>
        </div>

        <div className="flex grow items-center justify-end">
          <PaymentModal
            credits={credits}
            paymentOpen={paymentOpen}
            setPaymentOpen={setPaymentOpen}
            creditAmount={creditAmount}
            groupCreditAmount={groupCredits}
          />
        </div>
        <div className="flex h-14 w-16 items-center justify-center">
          <img
            src="/icons/menu_icon.svg"
            alt="Menu"
            className="m-auto cursor-pointer"
            width={36.14}
            height={24.09}
            onClick={() => {
              setMenuOpen(true);
            }}
          />
          <MenuSlideout
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            user={user}
          />
        </div>
      </div>
    </div>
  );
}
