import { Link } from "react-router-dom";
import { shortDate } from "../../utils/dateFormat";
import { Timestamp } from "firebase/firestore";

export default function EventTitle({
  date,
  title,
  logo,
  groupId,
  autoRoll,
}: {
  date: Timestamp;
  title: string;
  logo: string;
  groupId: string;
  autoRoll: boolean;
}) {
  return (
    <div className="flex text-white">
      <Link to={`/group/${groupId}/active`}>
        <div className="relative ml-2.5 flex h-[55px] w-[55px] overflow-hidden rounded-full">
          {logo && (
            <img
              src={logo}
              alt="Event"
              className="rounded-full w-[55px] h-[55px] object-cover"
            />
          )}
        </div>
      </Link>
      <div className="flex flex-auto flex-col justify-center pl-2.5">
        <p className="text-base">{title}</p>
        <p className="text-sm text-grey">{shortDate(date)} date</p>
      </div>
      <div className="relative mr-2.5 flex text-white">
        {autoRoll && (
          <img
            src="/icons/autoroll_icon.svg"
            alt="Autoroll"
            className={autoRoll ? "" : "hidden"}
            width={24}
            height={25}
          />
        )}
      </div>
    </div>
  );
}
