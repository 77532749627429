import { useState, useEffect } from "react";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase/clientFirebaseApps";
import { type P6GroupMembership } from "../../../../../../src/types/groupMembership";
import User from "./user";
import { P6Timestamp } from "../../../../../../src/types/helpers";
import UserSlidout from "../../../../components/user_managment_slidout/user_modal_layout";

export default function MemberList({
  groupId,
  userIds,
}: {
  groupId: string;
  userIds?: string[];
}) {
  // const [users, setUsers] = useState<P6GroupMembership[]>([]);
  const [list, setList] = useState<P6GroupMembership[]>([]);
  const [profileIds, setProfileIds] = useState<string[]>([]);
  const [firstTime, setFirstTime] = useState(true);
  const [selectedUser, setSelectedUser] = useState<string | undefined>();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [lastDate, setLastDate] = useState<P6Timestamp>({
    nanoseconds: 0,
    seconds: -62135596800,
  }); // latest possible date in firestore

  useEffect(() => {
    if (userIds && userIds?.length > 0) {
      setProfileIds(userIds);
    }
  }, [userIds]);

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight - 200;
      if (bottom && groupId) {
        const q = query(
          collection(db, "groupMemberships"),
          where("groupId", "==", groupId),
          where("status", "==", "active"),
          orderBy("createdAt", "desc"),
          startAfter(lastDate),
          limit(30),
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const ar: Array<P6GroupMembership> = [];
          const memberIds = list.map((x) => x.id);
          querySnapshot.docs.forEach((doc) => {
            setLoadingData(true);
            if (!memberIds.includes(doc.id)) {
              ar.push({ id: doc.id, ...doc.data() } as P6GroupMembership);
            }
          });
          if (ar.length > 0) {
            setLastDate(ar[ar.length - 1].createdAt);
            setList(list.concat(ar));
            setLoadingData(false);
          }
        });

        return () => unsubscribe();
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    if (firstTime) {
      setFirstTime(false);
      handleScroll();
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [groupId, list, lastDate, firstTime]);

  if (userIds && profileIds) {
    return (
      <div>
        <UserSlidout
          groupId={groupId}
          userId={selectedUser as string}
          setSelectedUser={setSelectedUser}
        />
        {userIds.map((id) => (
          <User
            key={id}
            userId={id}
            groupId={groupId}
            list="active"
            setSelectedUser={setSelectedUser}
            userIds={profileIds}
            setUserIds={setProfileIds}
          />
        ))}
      </div>
    );
  }

  return (
    <div>
      <UserSlidout
        groupId={groupId}
        userId={selectedUser as string}
        setSelectedUser={setSelectedUser}
      />
      {list.length > 0 && (
        <div data-cy="users-loaded">
          {list.map((doc: P6GroupMembership) => (
            <User
              key={doc.id}
              userId={doc.userId}
              groupId={groupId}
              list="active"
              setSelectedUser={setSelectedUser}
            />
          ))}
        </div>
      )}
      {loadingData && (
        <div className="flex justify-center items-center mt-4">
          <div className="text-center">
            <div className="text-2xl font-bold">Loading...</div>
          </div>
        </div>
      )}
      {!list.length && !loadingData && (
        <div className="flex justify-center items-center mt-4">
          <div className="text-center">
            <div className="text-2xl font-bold">No Members</div>
            <div className="text-sm text-gray-500">Check back soon!</div>
          </div>
        </div>
      )}
    </div>
  );
}
