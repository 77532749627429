import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { db } from "../../firebase/clientFirebaseApps";
import { onSnapshot, doc } from "firebase/firestore";
import { useUser } from "../../layouts/page_layout/layout";
import { P6Seat } from "../../../../src/types/event";
import { purchaseSeat } from "../../functions";
import { type P6ApiResponse } from "../../../../src/types";
import { P6ClientError } from "../../utils/exeptions";
import UserSlidout from "../../components/user_managment_slidout/user_modal_layout";

export default function Seat({
  seatId,
  cost,
  eventId,
  flash,
  limited,
  groupId,
  alreadyPurchasedSeat,
  admin,
  distribution,
  setLimitAlert,
  noGroupCredits,
}: {
  seatId: string;
  cost: number;
  eventId: string;
  flash: number[];
  limited: boolean;
  groupId: string;
  alreadyPurchasedSeat: boolean;
  admin: boolean;
  distribution: boolean;
  setLimitAlert: Dispatch<SetStateAction<boolean>>;
  noGroupCredits: boolean;
}) {
  const [data, setData] = useState<P6Seat>();
  const [buying, setBuying] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [sold, setSold] = useState(false);
  const [cancelTimer, setCancelTimer] = useState(3);
  const [loading, setLoading] = useState<boolean>(false);
  const { user, setPaymentOpen, setCreditAmount } = useUser();
  const [selectedUser, setSelectedUser] = useState<string | undefined>();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, `events/${eventId}/seats/${seatId}`),
      (doc) => {
        setData(doc.data() as P6Seat);
      },
    );

    return () => unsubscribe();
  }, [eventId, seatId]);

  useEffect(() => {
    let secondCounterId: ReturnType<typeof setTimeout> | null = null;
    if (cancelTimer === 0) {
      if (buying && user) {
        setBuying(false);
        setCancel(false);
        setSold(true);
        if (data?.status === "open") {
          (async () => {
            setLoading(true);
            const seat: { eventId: string; seatId: string; quantity: number } =
              {
                eventId: eventId,
                seatId: seatId,
                quantity: 1,
              };
            try {
              if (!seat.eventId || typeof seat.eventId !== "string") {
                throw new P6ClientError(
                  "SeatPurchaseError",
                  "Invalid or no user id",
                );
              }
              if (!seat.seatId || typeof seat.seatId !== "string") {
                throw new P6ClientError(
                  "SeatPurchaseError",
                  "Invalid or no seat id",
                );
              }
              if (!seat.quantity || typeof seat.quantity !== "number") {
                throw new P6ClientError(
                  "SeatPurchaseError",
                  "Invalid or no quantity",
                );
              }
              if (limited && alreadyPurchasedSeat) {
                setLimitAlert(true);
                throw new P6ClientError(
                  "SeatPurchaseError",
                  "Your have already bought a seat.",
                );
              }
              const purchaseInfo = {
                eventId: seat.eventId,
                seatId: seat.seatId,
                quantity: seat.quantity,
                distribution: distribution,
                noGroupCredits: noGroupCredits,
              };
              const result = await purchaseSeat(purchaseInfo);
              const resultData = result.data as P6ApiResponse;
              if (!resultData.success) {
                setSold(false);
                throw new P6ClientError(
                  "SeatPurchaseError",
                  "Unable to purchase seat",
                );
              } else {
                setLoading(false);
                setBuying(false);
              }
              if (resultData.message === "Insufficient Credits") {
                setPaymentOpen(true);
              }
            } catch (error) {
              setLoading(false);
              setSold(false);
              throw new P6ClientError("UnknownError", error as string);
            }
          })().catch((error) => {
            throw new P6ClientError("UnknownError", error as string);
          });
        }
      }
    }
    if (buying) {
      if (limited && alreadyPurchasedSeat) {
        setLimitAlert(true);
        setBuying(false);
      } else {
        let groupCredits =
          user.groupCredits &&
          user.groupCredits[groupId as keyof typeof user.groupCredits];
        if (distribution || noGroupCredits) {
          groupCredits = 0;
        }
        const credits: number =
          user.credits + (groupCredits ? groupCredits : 0) || 0;
        if (cost > credits) {
          setBuying(false);
          setCreditAmount(cost - credits);
          setPaymentOpen(true);
        } else {
          secondCounterId = setTimeout(() => {
            setCancelTimer(cancelTimer - 1);
          }, 1000);
        }
      }
    }

    if (cancel) {
      setCancelTimer(3);
      clearTimeout(secondCounterId as ReturnType<typeof setTimeout>);
      setBuying(false);
      setCancel(false);
    }

    return () => {
      clearTimeout(secondCounterId as ReturnType<typeof setTimeout>);
    };
  }, [
    buying,
    cancel,
    cancelTimer,
    seatId,
    eventId,
    user,
    data?.status,
    cost,
    setCreditAmount,
    setPaymentOpen,
    limited,
    groupId,
    alreadyPurchasedSeat,
    setLimitAlert,
    distribution,
    noGroupCredits,
  ]);

  return data ? (
    <div className="relative mb-2.5 cursor-pointer text-white"  data-cy={`seat-${data.number}`}>
      {admin && (
        <UserSlidout
          userId={selectedUser as string}
          groupId={groupId}
          setSelectedUser={setSelectedUser}
        />
      )}

      <div
        className={` flex h-[38px] overflow-hidden rounded-tl-full rounded-bl-full ${
          flash.includes(data.number) ? "border-green border-[2px]" : ""
        }`}
      >
        {data.status === "open" && !buying && !sold && (
          <div
            onClick={() => setBuying(true)}
            className={`flex w-full bg-green`}
          >
            <div
              className={`relative flex w-[53px] flex-shrink-0 items-center justify-center bg-shaddow text-[20px]`}
            >
              {data.number}
            </div>
            <div className="mx-1 flex flex-auto items-center justify-center">
              <div className={`text-[20px] leading-6 line-clamp-1`}>
                {cost > 0 ? `${cost} ₢` : "Free"}{" "}
              </div>
            </div>
          </div>
        )}
        {data.status === "reserved" && !buying && !sold && (
          <div className="flex w-full bg-p6blue">
            <div
              className={`relative flex w-[53px] flex-shrink-0 items-center justify-center bg-shaddow text-[20px]`}
            >
              {data.number}
            </div>
            <div className="mx-1 flex flex-auto items-center justify-center">
              <div className={`text-[18px] leading-6 line-clamp-1`}>MINI</div>
            </div>
          </div>
        )}
        {buying && (
          <div onClick={() => setCancel(true)} className="flex w-full bg-red">
            <div
              className={`relative flex w-[53px] flex-shrink-0 items-center justify-center bg-shaddow text-[20px]`}
            >
              {data.number}
            </div>
            <div className="mx-1 flex flex-auto items-center justify-center">
              <div
                className={`flex w-full items-center justify-center text-[24px] leading-6`}
              >
                <div className="flex flex-auto justify-center text-[16px]">
                  Cancel
                </div>
                <div className="mx-1.5 flex h-[22px] w-[22px] items-center justify-center rounded-full bg-white text-[20px] text-red">
                  {cancelTimer}
                </div>
              </div>
            </div>
          </div>
        )}
        {data.status === "sold" && !loading && (
          <div
            className="flex flex-auto"
            onClick={() => {
              if (admin) {
                setSelectedUser(data.boughtBy);
              }
            }}
          >
            <div className={`relative flex w-[53px] flex-shrink-0 bg-shaddow`}>
              <img
                src={
                  data.profileImage ? data.profileImage : "/icons/user_icon.svg"
                }
                alt="author"
                className="rounded-full"
                width={38}
                height={38}
              />
              <div className="absolute bottom-0 right-0 rounded-tl-full rounded-bl-full bg-shaddow/50 px-1.5 leading-5">
                {data.number}
              </div>
            </div>
            <div className="flex flex-auto items-center justify-center bg-dark">
              <div className={`text-[16px] font-light leading-6 line-clamp-1`}>
                {data.displayName}
              </div>
            </div>
          </div>
        )}
        {loading && (
          <div className="flex w-full bg-shaddow">
            <div
              className={`relative flex w-[53px] flex-shrink-0 items-center justify-center bg-shaddow text-[20px]`}
            >
              {data.number}
            </div>
            <div className="mx-1 flex flex-auto items-center justify-center">
              <div
                className={`flex w-full items-center justify-center text-[28px] leading-6`}
              >
                <div className="flex flex-auto justify-center text-[25px]">
                  <img
                    width={30}
                    height={30}
                    src="/icons/spinner_icon.svg"
                    alt="Event"
                    className={`animate-spin`}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
}
