import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { FieldValues, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { createMini } from "../../functions";
import { type P6ApiError, type P6ApiResponse } from "../../../../src/types";
import P6Button from "../button";
import { P6Event } from "../../../../src/types/event";
import { P6ClientError } from "../../utils/exeptions";
import { Switch } from "@headlessui/react";

type formReturn = {
  rounding: string;
  title: string;
  description: string;
  seats: number;
  winners: number;
  autoroll: boolean;
  featured: boolean;
  limited: boolean;
};

export default function NewMiniFrom({
  groupId,
  setStep,
  eventsWithMini,
  setOpen,
  setEventsWithMini,
}: {
  eventsWithMini: P6Event[];
  groupId: string;
  setStep: Dispatch<SetStateAction<number>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setEventsWithMini: Dispatch<SetStateAction<P6Event[]>>;
}) {
  const {
    register,
    setError,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      rounding: "roundUp",
      title: "",
      description: "",
      seats: null,
      winners: 1,
      eventValue: null,
      autoroll: false,
      featured: false,
      limited: false,
      noGroupCredits: false,
    },
  });
  const [loading, setLoading] = useState(false);
  const seats = useWatch({ control, name: "seats" }) as number;
  const [eventValue, setEventValue] = useState<number>(0);
  // const [roundDown, setRoundDown] = useState(false);
  const [autoRoll, setAutoRoll] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [limited, setLimited] = useState(false);
  const [noGroupCredits, setNoGroupCredits] = useState(false);

  useEffect(() => {
    const val = eventsWithMini.reduce((accumulator, currentValue) => {
      if (currentValue.options.miniSeats && currentValue.options) {
        return (
          accumulator +
          currentValue.options.seatPrice * currentValue.options.miniSeats
        );
      } else {
        return accumulator;
      }
    }, 0);

    setEventValue(val * 1.06);
  }, [eventsWithMini]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setLoading(true);
    const formData = data as formReturn;

    if (formData.seats < formData.winners) {
      setLoading(false);
      setError("winners", {
        type: "custom",
        message: "Winners must be equal or less than number of seats",
      });
      return;
    }
    const request = {
      mainEventIdsAndSeats: eventsWithMini.map((x) => ({
        id: x.id,
        seats: x.options.miniSeats,
      })),
      winners: formData.winners,
      title: formData.title,
      description: formData.description,
      groupId: groupId,
      available: formData.seats,
      options: {
        rounding: "roundUp",
        seats: formData.seats,
        eventValue: eventValue,
        autoRoll: autoRoll,
        featured: featured,
        limited: limited,
        type: "mini",
        noGroupCredits: noGroupCredits,
      },
      state: "active",
    };

    createMini(request)
      .then((result) => {
        const resultData = result.data as P6ApiResponse;
        // Read result of the Cloud Function.
        if (resultData.success) {
          setLoading(false);
          setOpen(false);
          setStep(1);
          reset();
          setEventsWithMini([]);
        } else {
          throw new P6ClientError(
            "FunctionError",
            "Unable to create mini event.",
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        const errorData = error as P6ApiError;
        throw new P6ClientError("UnknownError", errorData.message);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-2.5">
        <div className="relative mb-2.5">
          <input
            type="text"
            id="title"
            autoComplete="off"
            className="text-field peer"
            placeholder=" "
            {...register("title", {
              required: true,
            })}
          />
          <label htmlFor="title" className="form-label">
            Title
          </label>
        </div>
        {errors.title && <p className="form-error">Title is Required</p>}
      </div>
      <div className="mb-2.5">
        <div className="relative mb-2.5">
          <textarea
            id="description"
            rows={4}
            className="peer block w-full appearance-none rounded-lg border-[1px] border-grey bg-component px-2.5 pb-1 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
            placeholder=" "
            {...register("description", {
              required: true,
            })}
          ></textarea>
          <label
            htmlFor="description"
            className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-5 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
          >
            Description
          </label>
        </div>
        {errors.description && (
          <p className="form-error">Description is Required</p>
        )}
      </div>
      <div className="grid grid-cols-2 gap-2.5">
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="number"
              id="seats"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("seats", {
                required: true,
                valueAsNumber: true,
              })}
            />
            <label htmlFor="seats" className="form-label">
              Seats
            </label>
          </div>
          {errors.seats && <p className="form-error">Seats is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              min={1}
              type="number"
              id="winners"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("winners", {
                required: true,
                valueAsNumber: true,
              })}
            />
            <label htmlFor="winners" className="form-label">
              Winners
            </label>
          </div>
          {errors.winners && <p className="form-error">Winners is Required</p>}
        </div>
      </div>

      <div className="flex text-white items-center mb-2.5">
        <Switch
          data-cy="auto-roll-mini"
          checked={autoRoll}
          onChange={setAutoRoll}
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
        >
          <span
            aria-hidden="true"
            className="pointer-events-none absolute h-full w-full rounded-md"
          />
          <span
            aria-hidden="true"
            className={`${autoRoll ? "bg-green" : "bg-grey"} 
                pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
          />
          <span
            aria-hidden="true"
            className={`${autoRoll ? "translate-x-5" : "translate-x-0"} 
                pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
          />
        </Switch>
        <div className="ml-2 flex flex-auto">
          <div>Auto Roll</div>
          <div className="">
            <div className="group relative w-max">
              <img
                src="/icons/info_icon.svg"
                className="ml-1.5 h-3 w-3"
                alt="tt"
              />
              <div className="w-[120px] pointer-events-none absolute -top-7 left-8 opacity-0 bg-shaddow text-sm p-2 rounded-lg z-40 transition-opacity group-hover:opacity-100 text-white">
                Wheel will automatically roll when all seats are filled.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex text-white items-center mb-2.5">
        <Switch
          data-cy="feature-mini"
          checked={featured}
          onChange={setFeatured}
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
        >
          <span
            aria-hidden="true"
            className="pointer-events-none absolute h-full w-full rounded-md"
          />
          <span
            aria-hidden="true"
            className={`${featured ? "bg-green" : "bg-grey"} 
                pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
          />
          <span
            aria-hidden="true"
            className={`${featured ? "translate-x-5" : "translate-x-0"} 
                pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
          />
        </Switch>
        <div className="ml-2 flex flex-auto">
          <div>Featured</div>
          <div className="">
            <div className="group relative w-max">
              <img
                src="/icons/info_icon.svg"
                className="ml-1.5 h-3 w-3"
                alt="tt"
              />
              <div className="w-[120px] pointer-events-none absolute -top-7 left-8 opacity-0 bg-shaddow text-sm p-2 rounded-lg z-40 transition-opacity group-hover:opacity-100 text-white">
                Put this event on the top of the list.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex text-white items-center mb-2.5">
        <Switch
          data-cy="limited-mini"
          checked={limited}
          onChange={setLimited}
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
        >
          <span
            aria-hidden="true"
            className="pointer-events-none absolute h-full w-full rounded-md"
          />
          <span
            aria-hidden="true"
            className={`${limited ? "bg-green" : "bg-grey"} 
                pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
          />
          <span
            aria-hidden="true"
            className={`${limited ? "translate-x-5" : "translate-x-0"} 
                pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
          />
        </Switch>
        <div className="ml-2 flex flex-auto">
          <div>Limited</div>
          <div className="">
            <div className="group relative w-max">
              <img
                src="/icons/info_icon.svg"
                className="ml-1.5 h-3 w-3"
                alt="tt"
              />
              <div className="w-[120px] pointer-events-none absolute -top-7 left-8 opacity-0 bg-shaddow text-sm p-2 rounded-lg z-40 transition-opacity group-hover:opacity-100 text-white">
                Only allow users to buy one seat in this event.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex text-white items-center mb-2.5">
        <Switch
          data-cy="group-credits-event"
          checked={noGroupCredits}
          onChange={setNoGroupCredits}
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
        >
          <span
            aria-hidden="true"
            className="pointer-events-none absolute h-full w-full rounded-md"
          />
          <span
            aria-hidden="true"
            className={`${noGroupCredits ? "bg-green" : "bg-grey"} 
                pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
          />
          <span
            aria-hidden="true"
            className={`${noGroupCredits ? "translate-x-5" : "translate-x-0"} 
                pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
          />
        </Switch>
        <div className="ml-2 flex flex-auto">
          <div className="text-sm">No Group Credits</div>
          <div className="">
            <div className="group relative w-max">
              <img
                src="/icons/info_icon.svg"
                className="ml-1.5 h-3 w-3"
                alt="tt"
              />
              <div className="w-[120px] pointer-events-none absolute -top-7 left-8 opacity-0 bg-shaddow text-sm p-2 rounded-lg z-40 transition-opacity group-hover:opacity-100 text-white">
                Do not allow group credits on this event.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex text-white items-center mb-2.5 mt-2.5">
        {/* <Switch
          checked={roundDown}
          onChange={setRoundDown}
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
        >
          <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md" />
          <span
            aria-hidden="true"
            className={`${roundDown ? "bg-green" : "bg-grey"} 
                  pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
          />
          <span
            aria-hidden="true"
            className={`${roundDown ? "translate-x-5" : "translate-x-0"} 
                  pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
          />
        </Switch> */}
        {/* <div className="ml-2 flex flex-auto">
          <div>Round Down</div>
          <div className="">
            <div className="group relative w-max">
              <img src="/icons/info_icon.svg" className="ml-1.5 h-3 w-3" alt="tt" />
              <div
                className="w-[120px] pointer-events-none absolute -top-7 left-8 opacity-0 bg-shaddow text-sm p-2 rounded-lg z-40 transition-opacity group-hover:opacity-100 text-white"
              >
                Round down allows you to set the value of each seat to be rounded down to the nearest whole number.
              </div>
            </div>
          </div>
        </div> */}
        {eventValue && seats && (
          <div>{eventValue && seats && Math.ceil(eventValue / seats)} ₢</div>
        )}
      </div>

      <div className="grid grid-cols-2 gap-2.5 mt-4">
        <div data-cy="mini-back">
          <P6Button
            sm
            div={true}
            onClick={() => setStep(1)}
            text="Back"
            color="bg-p6blue"
            textColor="text-white"
          />
        </div>
      
        <P6Button
          sm
          type="submit"
          loading={loading}
          text="Start Mini"
          color="bg-green"
          textColor="text-white"
        />
      </div>
    </form>
  );
}
