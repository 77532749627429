import { useState, useEffect } from "react";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase/clientFirebaseApps";
import { type P6GroupMembership } from "../../../../../../src/types/groupMembership";
import User from "./user";
import { P6Timestamp } from "../../../../../../src/types/helpers";
import { P6ClientError } from "../../../../utils/exeptions";

export default function PendingList({
  groupId,
  userIds,
}: {
  groupId: string;
  userIds?: string[];
}) {
  const [list, setList] = useState<P6GroupMembership[]>([]);
  const [profileIds, setProfileIds] = useState<string[]>([]);
  const [, setSelectedUser] = useState<string | undefined>();
  const [lastDate, setLastDate] = useState<P6Timestamp>({
    nanoseconds: 0,
    seconds: -62135596800,
  }); // latest possible date in firestore

  useEffect(() => {
    if (userIds && userIds?.length > 0) {
      setProfileIds(userIds);
    }
  }, [userIds]);

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight - 200;

      if (groupId && bottom) {
        const queryRef = query(
          collection(db, "groupMemberships"),
          where("groupId", "==", groupId),
          where("status", "==", "pending"),
          orderBy("createdAt", "desc"),
          startAfter(lastDate),
          limit(25),
        );

        const unsubscribe = onSnapshot(
          queryRef,
          (querySnapshot) => {
            const ar: P6GroupMembership[] = [];
            querySnapshot.forEach((doc) => {
              ar.push({ id: doc.id, ...doc.data() } as P6GroupMembership);
            });
            setList(list.concat(ar));

            if (querySnapshot.size > 0) {
              const lastDocument = querySnapshot.docs[querySnapshot.size - 1];
              const lastMembership = lastDocument.data() as P6GroupMembership;
              setLastDate(lastMembership.createdAt as P6Timestamp);
            }
          },
          (error: Error) => {
            throw new P6ClientError("UnknownError", error.message);
          },
        );

        return () => {
          unsubscribe();
        };
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [groupId, lastDate, list]);

  if (list.length === 0) {
    return null;
  }

  if (userIds && profileIds) {
    return (
      <div>
        {profileIds.map((id) => (
          <User
            key={id}
            userId={id}
            groupId={groupId}
            list="pending"
            setSelectedUser={setSelectedUser}
            userIds={profileIds}
            setUserIds={setProfileIds}
          />
        ))}
      </div>
    );
  }

  return (
    <div>
      {list.map((doc: P6GroupMembership) => (
        <User
          key={doc.id}
          userId={doc.userId}
          groupId={groupId}
          list="pending"
          setSelectedUser={setSelectedUser}
        />
      ))}
      {!list.length && (
        <div className="flex justify-center items-center mt-4">
          <div className="text-center">
            <div className="text-2xl font-bold">No Pending Members</div>
            <div className="text-sm text-gray-500">Check back soon!</div>
          </div>
        </div>
      )}
    </div>
  );
}
