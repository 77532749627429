import { useEffect, useState } from "react";
import { useUser } from "../../layouts/page_layout/layout";
import Item from "./item";
import { P6Notification } from "../../../../src/types/notification";
import {
  collection,
  doc,
  onSnapshot,
  query,
  where,
  writeBatch,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";

export default function Notifications() {
  const { activeGroupIds } = useUser();
  const [notifications, setNotifications] = useState<P6Notification[]>([]);

  useEffect(() => {
    if (activeGroupIds && activeGroupIds.length > 0) {
      const q = query(
        collection(db, "notifications"),
        where("groupId", "in", activeGroupIds),
        orderBy("createdAt", "desc"),
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const ar: P6Notification[] = [];
        querySnapshot.docs.forEach((doc) => {
          ar.push({ id: doc.id, ...doc.data() } as P6Notification);
        });
        setNotifications(ar);
      });

      return () => unsubscribe();
    } else {
      setNotifications([]);
    }
  }, [activeGroupIds]);

  const handleMarkAllRead = async () => {
    // Remove the userId from the userIds array of all notifications
    const batch = writeBatch(db);

    notifications.forEach((notification) => {
      const notificationRef = doc(db, "notifications", notification.id);
      batch.delete(notificationRef);
    });

    await batch.commit();

    // Update the state to remove the notifications that no longer have the userId
    setNotifications([]);
  };

  return (
    <div className="flex-auto">
      <div className="text-white">
        <div className="mt-5 bg-component text-white">
          <div className="flex items-end px-2.5 pt-5">
            <div className="flex flex-auto text-[24px] leading-7">
              Notifications
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="text-[15px] leading-7 text-p6blue"
              onClick={handleMarkAllRead}
            >
              Mark All Read
            </div>
          </div>
          {notifications.map((notification) => (
            <Item key={notification.id} data={notification} />
          ))}
        </div>
      </div>
    </div>
  );
}
