import { P6Comment } from "../../../../../src/types/comment";
import { fromNow } from "../../../utils/dateFormat";
import { Timestamp } from "firebase/firestore";

export default function ReplyList({ comments }: { comments: P6Comment[] }) {
  return (
    <div className="px-2.5 pt-8">
      {comments.map((comment: P6Comment) => (
        <div className="flex pb-1.5 text-white" key={comment.id}>
          <div className="flex flex-shrink-0">
            <img
              src={comment.image}
              alt="Author"
              className="h-[39px] rounded-full"
              width={39}
              height={39}
            />
          </div>
          <div className="flex-auto">
            <div className="ml-2.5 rounded-lg bg-shaddow px-[8px] py-[6px]">
              <div className="flex">
                <div className="flex flex-auto text-base">
                  {comment.username}
                </div>
                <div className="flex text-base text-grey">
                  {fromNow(comment.createdAt as Timestamp)}
                </div>
              </div>
              <div className="pt-1.5 text-base leading-4 text-grey break-words max-w-[27rem]">
                {comment.content}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
