import { useEffect, useState } from "react";
import { P6Transaction } from "../../../../src/types/transaction";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  startAfter,
  limit,
} from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";
import { useUser } from "../../layouts/page_layout/layout";
import HistoryItem from "./components/historyItem";
import { P6Timestamp } from "../../../../src/types/helpers";

const History = () => {
  const [transactions, setTransactions] = useState<P6Transaction[]>([]);
  const { user, userId } = useUser();
  const [firstTime, setFirstTime] = useState(true);
  const [lastDate, setLastDate] = useState<P6Timestamp>({
    nanoseconds: 0,
    seconds: -62135596800,
  }); // latest possible date in firestore

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight - 200;
      if (bottom || firstTime) {
        const txRef = collection(db, "transactions");
        const txQuery = query(
          txRef,
          where("userId", "==", user.userId),
          orderBy("createdAt", "desc"),
          startAfter(lastDate),
          limit(20),
        );
        const unsubscribe = onSnapshot(txQuery, (txSnapshot) => {
          if (txSnapshot.size > 0) {
            const txData: P6Transaction[] = [];
            txSnapshot.forEach((doc) => {
              txData.push({ id: doc.id, ...doc.data() } as P6Transaction);
            });
            const lDate = txData.map((x) => x.createdAt)[txData.length - 1];
            if (lDate) {
              setLastDate(lDate);
              setTransactions(transactions.concat(txData));
            }
          }
        });

        return () => unsubscribe();
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    if (firstTime) {
      setFirstTime(false);
      handleScroll();
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [transactions, firstTime, lastDate, user.userId]);

  return (
    <div className="flex-auto text-white mt-2.5">
      {transactions.length > 0 &&
        transactions.map((transaction) => (
          <div
            key={transaction.id as string}
            className="flex flex-row justify-between"
          >
            <HistoryItem transaction={transaction} userId={userId} />
          </div>
        ))}
    </div>
  );
};
export default History;
