import { useState, useEffect } from "react";
import { removeEvent } from "../../functions";
import P6Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import { type P6ApiResponse } from "../../../../src/types";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";
import { type P6Seat } from "../../../../src/types/event";
import { P6ClientError } from "../../utils/exeptions";

export default function AdminAction({
  eventId,
  groupId,
  groupCreditsUsed,
  eventValue,
  eventState,
  distroCost,
}: {
  eventId: string;
  groupId: string;
  groupCreditsUsed?: number;
  eventValue?: number;
  eventState?: string;
  distroCost?: number | null;
}) {
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [miniAlert, setMiniAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [seats, setSeats] = useState<P6Seat[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const q = query(
      collection(db, `events/${eventId}`, "seats"),
      where("status", "==", "reserved"),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const seats: Array<P6Seat> = [];
      querySnapshot.forEach((doc) => {
        seats.push({ id: doc.id, ...doc.data() } as P6Seat);
      });
      setSeats(seats);
    });
    return () => unsubscribe();
  }, [eventId]);

  const handleRemoveEvent = async (eventId: string) => {
    setLoading(true);
    setDeleteAlert(false);
    try {
      if (!eventId) {
        throw new P6ClientError(
          "InvalidArgument",
          "Event id is required to remove event.",
        );
      }
      const result = await removeEvent({ eventId: eventId });
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        navigate(`/group/${groupId}/active`);
      }
      setLoading(false);
    } catch (error) {
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  const handleAlert = () => {
    if (seats.length > 0) {
      setMiniAlert(true);
    } else {
      setDeleteAlert(true);
    }
  };

  return (
    <div className="bg-component mt-2.5 p-1.5">
      <div className="flex items-center justify-center">
        {loading && (
          <div className="text-red text-lg">Removal in progress.</div>
        )}
        {!loading && (
          <div className="flex w-full">
            <div className="text-white text-xs w-[150px]">
              <div className="flex">
                Event Value:{" "}
                <span className="flex-auto text-right">{eventValue}</span>
              </div>
              {distroCost && (
                <div className="flex">
                  Distribution Cost:{" "}
                  <span className="flex-auto text-right">{distroCost}</span>
                </div>
              )}
              {groupCreditsUsed && (
                <div className="flex">
                  Group Credits:{" "}
                  <span className="text-red flex-auto text-right">
                    -{groupCreditsUsed}
                  </span>
                </div>
              )}
              <div className="border-t flex">
                {eventState === "closed" ? "" : "Estimated"} Payout:
                <span
                  className={`text-${
                    (eventValue ? Math.ceil(eventValue) * 0.9 : 0) -
                      (groupCreditsUsed || 0) -
                      (distroCost || 0) <
                    0
                      ? "red"
                      : "green"
                  } flex-auto text-right`}
                >
                  {(eventValue ? Math.ceil(eventValue * 0.9) : 0) -
                    (groupCreditsUsed || 0) -
                    (distroCost || 0)}
                </span>
              </div>
            </div>

            {eventState !== "removed" && <div className="flex-auto p-3">
              <div className="float-right">
                <P6Button
                  loading={loading}
                  div
                  text="Remove Event"
                  sm
                  color="bg-red"
                  textColor="text-white"
                  onClick={() => handleAlert()}
                />
              </div>
            </div>}
          </div>
        )}
      </div>
      {deleteAlert && (
        <div
          id="alert-additional-content-1"
          className="fixed mr-2.5 max-w-[540px] bottom-2.5 mr-10px max-md: z-50 p-4 mb-4 text-white border border-red rounded-lg bg-[#422c27] dark:text-white dark:border-red"
          role="alert"
        >
          <div className="flex items-center ">
            <svg
              className="flex-shrink-0 w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <h3 className="text-lg font-medium ">Remove Event?</h3>
          </div>
          <div className="mt-2 mb-4 text-sm text-grey">
            This action can not be undone, please make sure you wanted to remove
            this event or click cancel to go back. Any user who has purchased a
            seat will be refunded.
          </div>
          <div className="flex">
            <button
              type="button"
              className="text-white bg-dark-grey hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-dark-grey dark:hover:bg-grey"
              onClick={() => setDeleteAlert(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="text-white bg-transparent border border-red hover:bg-highlight hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red dark:border-red dark:text-white dark:hover:text-white dark:focus:ring-red"
              data-dismiss-target="#alert-additional-content-1"
              aria-label="Close"
              onClick={() => handleRemoveEvent(eventId)}
            >
              Remove Event
            </button>
          </div>
        </div>
      )}

      {miniAlert && (
        <div
          id="alert-additional-content-1"
          className="fixed mr-2.5 max-w-[540px] bottom-2.5 mr-10px max-md: z-50 p-4 mb-4 text-white border border-[#483f11] rounded-lg bg-[#a18c22] dark:text-white dark:border-[#483f11]"
          role="alert"
        >
          <div className="flex items-center ">
            <svg
              className="flex-shrink-0 w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <h3 className="text-lg font-medium ">Mini attached to event</h3>
          </div>
          <div className="mt-2 mb-4 text-sm text-white">
            This event can not be removed. Please remove all attached events to
            proceed with removel.
          </div>
          <div className="flex">
            <button
              type="button"
              className="text-white bg-dark-grey hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-dark-grey dark:hover:bg-grey"
              onClick={() => setMiniAlert(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
