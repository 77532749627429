import { useEffect, useState } from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../../firebase/clientFirebaseApps";
import { fromNow } from "../../../utils/dateFormat";
import Comment from "./comment";
import ReplyList from "./reply_list";
import { P6Comment } from "../../../../../src/types/comment";

export default function CommentList({
  eventId,
  groupId,
}: {
  eventId: string;
  groupId: string;
}) {
  const [comments, setComments] = useState<P6Comment[]>([]);
  const [replyOpen, setReplyOpen] = useState<boolean[]>(
    Array(comments.length).fill(false),
  );

  // Fetch the comments data for the current event, and update the state
  // with the new comments data
  useEffect(() => {
    if (eventId && groupId) {
      const commentsRef = collection(db, "comments");
      const commentsQuery = query(
        commentsRef,
        where("eventId", "==", eventId),
        where("groupId", "==", groupId),
        orderBy("createdAt", "desc"),
      );
      const unsubscribe = onSnapshot(commentsQuery, (commentsSnapshot) => {
        const commentsData: P6Comment[] = commentsSnapshot.docs.map(
          (commentDoc) => {
            const commentData = commentDoc.data();
            const commentId = commentDoc.id;
            return { id: commentId, ...commentData } as P6Comment;
          },
        );

        // Filter the commentsData array to get only the top-level comments
        const topLevelComments: P6Comment[] = commentsData.filter(
          (comment: P6Comment) => !comment.commentId,
        );
        // For each top-level comment, filter the commentsData array again to get its replies
        const updatedComments: P6Comment[] = topLevelComments.map(
          (comment: P6Comment) => {
            const replies: P6Comment[] = commentsData.filter(
              (reply: P6Comment) => reply.commentId === comment.id,
            );
            return { ...comment, comments: replies };
          },
        );

        setComments(updatedComments);
        setReplyOpen(Array(updatedComments.length).fill(false));
      });

      return () => unsubscribe();
    }
  }, [eventId, groupId]);

  return (
    <div className="px-2.5 pt-8">
      {comments.map((comment: P6Comment, index: number) => (
        <div className="flex text-white" key={comment.id}>
          <div className="flex flex-shrink-0">
            <img
              src={comment.image}
              alt="Author"
              className="h-[39px] rounded-full"
              width={39}
              height={39}
            />
          </div>
          <div className="flex-auto">
            <div className="bg-shaddow ml-2.5 rounded-lg px-[8px] py-[6px]">
              <div className="flex">
                <div className="flex flex-auto text-base">
                  {comment.username}
                </div>
                <div className="flex text-base text-grey">
                  {fromNow(comment.createdAt as Timestamp)}
                </div>
              </div>
              <div className="pt-1.5 text-base leading-4 text-grey whitespace-wrap break-words max-w-[30rem]">
                {comment.content}
              </div>
            </div>
            {comment.comments && comment.comments.length > 0 && (
              <ReplyList comments={comment.comments} />
            )}
            <div
              data-cy={`reply-${index + 1}-button`}
              style={{ cursor: "pointer" }}
              className="lin ml-6 mt-1 flex text-xs text-grey"
              onClick={() => {
                const newReplyOpen = [...replyOpen];
                newReplyOpen[index] = !newReplyOpen[index];
                setReplyOpen(newReplyOpen);
              }}
            >
              Reply
            </div>
            <div className="mt-2.5">
              <div className={replyOpen[index] ? "" : "hidden"}>
                <Comment
                  label="Reply"
                  button="Reply"
                  eventId={eventId}
                  commentId={comment.id}
                  groupId={groupId}
                  index={index + 1}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
