export default function TermsText() {
  return (
    <div className="px-2.5 text-grey">
      <div className="mt-8 mb-4 text-center text-[36px] text-white">
        Terms of Use
      </div>

      <div className="mb-1.5 text-[20px] text-white">1) Welcome</div>
      <div className="">
        <p className="mb-2.5 text-[12px]">
          Welcome to Platform Sixx (the “Site“). The Site and any related
          services, modules, functions, software or platforms (collectively, the
          “Services”) were created by us in order to provide you a much easier
          way to run events. These Platform Sixx Terms & Conditions (the
          “Terms”) constitute a set of rules by which we operate such Services.
        </p>
        <p className="mb-2.5 text-[12px]">
          These Terms of Service become a legally binding contract (the
          “Agreement”) when you access our websites or when you install,
          sign-up, order, or otherwise use one or more of our applications
          (“Apps”). Your access to and use of our websites, our Apps (including
          software as well as templates, graphics, and other content and
          information they include and produce), along with related services
          including installation, configuration, consulting, maintenance, and
          other support (collectively our “Services”) are subject to the terms
          and conditions of this Agreement, as well as our Privacy Policy. This
          Agreement also includes any additional or modified terms and
          conditions that apply to particular Apps as shown in the Product
          Schedules included with this Agreement.
        </p>
        <p className="mb-2.5 text-[12px]">
          By using or accessing the Services, you acknowledge that you agree to
          the Terms, are subject to them and to our Privacy Policy. IF YOU DO
          NOT AGREE TO BE FULLY BOUND BY ALL OF THESE TERMS, PLEASE DO NOT
          ACCESS THE SITE AND DO NOT USE THE SERVICES. You should read through
          all the Terms carefully as they constitute a legally binding agreement
          between you and Platform Sixx. Please note that we reserve the right,
          at our sole discretion, to revise, modify or change or remove portions
          or all of these, at any time. The date when these Terms were last
          updated is indicated at the end of the Terms.
        </p>
      </div>

      <div className="mb-1.5 text-[20px] text-white">
        2) Registration and Access
      </div>
      <div>
        <p className="mb-2.5 text-[12px]">
          In order to access the Services, you must first subscribe to use them
          by providing complete information requested and setting up a username
          and password. The information provided can be updated at any time by
          accessing your Platform Sixx Profile page.
        </p>
        <p className="mb-2.5 text-[12px]">
          Based on the specific package you choose, you may grant account access
          to additional users in your organization. and specify the type of
          access each user shall have as part of the Services. Upon signing into
          their account and using the Service, all such users shall also become
          subject to these Terms and each such account user will be responsible
          for the activity that occurs through their account and information
          shared.
        </p>
        <p className="mb-2.5 text-[12px]">
          We urge you to keep your account password secure and to always log-off
          from the Services when leaving your computer unattended. We strongly
          recommend notifying us immediately of unauthorized use of your account
          or of any related security breach by writing to us at
          info@platformsixx.com.
        </p>
      </div>

      <div className="mb-1.5 text-[20px] text-white">3) Fees and Billing</div>
      <div>
        <p className="mb-2.5 text-[12px]">
          By opening an account with Platform Sixx, you agree to timely pay all
          charges incurred by your account, including applicable taxes, in
          accordance with billing terms in effect at the time the charge becomes
          payable. If payment cannot be charged to your credit card or the
          charge is returned for any reason, Platform Sixx may suspend or
          terminate your account.
        </p>
        <p className="mb-2.5 text-[12px]">
          We reserve the right to change the amount of, or basis for
          determining, any fees or charges for services we provide, and to
          institute new fees, charges or terms effective upon prior notice to
          customers (which may be sent by email to the address you have most
          recently provided us).
        </p>
        <p className="mb-2.5 text-[12px]">
          You are responsible for managing your plan, if you wish to cancel your
          account at any time for any reason, you may do so by contacting us at
          info@platformsixx.com. WE DO NOT PROVIDE REFUNDS OR CREDITS. All
          provisions of these Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          warranty disclaimers, indemnity and limitations of liability.
        </p>
      </div>

      <div className="mb-1.5 text-[20px] text-white">
        4) Platform Sixx BETA Limitations
      </div>
      <div>
        <p className="mb-2.5 text-[12px]">
          PLEASE NOTE SOME THE SERVICES ARE CURRENTLY BEING PROVIDED IN THEIR
          BETA VERSION, THE FEATURES OF WHICH HAVE NOT BEEN FULLY IMPLEMENTED OR
          REFINED. AS WITH ANY BETA VERSION, THE SERVICES CURRENTLY CONSTITUTE A
          WORK IN PROGRESS AND AS SUCH, THERE MAY BE UNRESOLVED ISSUES. WHILE WE
          APPRECIATE YOUR PATIENCE DURING THIS FINALIZATION STAGE, WE ENCOURAGE
          YOU NOT TO USE THIS BETA VERSION OF THE SERVICES UNLESS YOU ARE
          COMFORTABLE USING BETA SOFTWARE AND UNDERSTAND THE IMPLICATIONS
          THEREOF.
        </p>
        <p className="mb-2.5 text-[12px]">
          Furthermore, please note that currently the Services were created with
          the intent to be used by users in North America. You may use the
          Services outside of such territories, but bear in mind that Platform
          Sixx makes no representations that the Service is appropriate or
          available for such use.
        </p>
      </div>

      <div className="mb-1.5 text-[20px] text-white">
        5) Ownership of the Services Provided to You
      </div>
      <div>
        <p className="mb-2.5 text-[12px]">
          The underlying platforms of the Services are either the proprietary
          property of Platform Sixx or the proprietary property of our licensors
          or licensees. By subscribing to our Services, Platform Sixx grants you
          a personal, revocable, non-assignable, and non-exclusive license to
          use the Services for the term of the subscription in accordance with
          the Terms, unless terminated earlier.
        </p>
        <p className="mb-2.5 text-[12px]">
          “Platform Sixx” and other trademarks, slogans, service marks and trade
          names which appear via the Service are proprietary to Platform Sixx.
          You shall not remove or alter any copyright notice, trademark or other
          proprietary or restrictive notice or legend affixed to any material
          provided by Platform Sixx. Except as expressly set forth herein, no
          right, license, or interest to such trademarks are generated or
          granted hereunder.
        </p>
        <p className="mb-2.5 text-[12px]">
          Please note that Platform Sixx facilitates companies running events
          which as a user you can enter. As an entrant you acknowledge that
          &quot;event Owner&quot; is fully responsible for ensuring that their
          event complies with all applicable rules & regulations in the
          countries for which it is operating. Platform Sixx is not responsible
          for; or liable for the failure of any event Owner to comply with these
          rules.
        </p>
        <p className="mb-2.5 text-[12px]">
          &quot;event Owners&quot; must also be responsible for ensuring that
          their event fully complies with all rules & regulations of any social
          network or Third Party on which it is run. Platform Sixx will not be
          held responsible for the loss of any data or accounts due to actions
          from Third Parties that relate to activity that is against their
          guidelines.
        </p>
        <p className="mb-2.5 text-[12px]">
          You acknowledge and agree that Platform Sixx is not responsible or
          liable for event Owners use or distribution of information you
          provide. Please see each event Owners promotion rules, terms and
          privacy policy for details on how they use your personal information.
        </p>
        <p className="mb-2.5 text-[12px]">
          As Platform Sixx can be used on any website in any country, by using
          Platform Sixx you acknowledge that you are responsible for compliance
          with any local laws.
        </p>
        <p className="mb-2.5 text-[12px]">
          PLATFORM SIXX DOES NOT SPONSOR, ADMINISTER OR ENDORSE THE CONTENT OF
          ANY PROMOTION OR GIVEAWAY DISTRIBUTED THROUGH THE SITE UNLESS
          OTHERWISE STATED AND IS THEREFORE NOT LIABLE.
        </p>
        <p className="mb-2.5 text-[12px]">
          Platform Sixx may require you to provide personally identifiable
          information in order to participate in certain promotions. In the
          event that you choose to provide personally identifiable information,
          you acknowledge and agree that Platform Sixx may use any information
          you provide consistent with Platform Sixx and these Terms of Service.
        </p>
      </div>

      <div className="mb-1.5 text-[20px] text-white">6) Technical Support</div>
      <div>
        <p className="mb-2.5 text-[12px]">
          Technical support is provided to you by Platform Sixx via email and
          live chat during regular business hours (i.e. Monday to Friday from
          9AM CST to 6PM CST) by sending your technical inquiries to
          support@platformsixx.com. Platform Sixx shall respond via email to
          your support request within a reasonable time-period.
        </p>
      </div>

      <div className="mb-1.5 text-[20px] text-white">
        7) Account Content & Use Of Services
      </div>
      <div>
        <p className="mb-2.5 text-[12px]">
          You shall be solely responsible for the information you or any of your
          authorized users transmit or submit through the Site or the Services.
          You expressly agree that your use of the Services and your account
          content will not:
        </p>
        <ul className="mb-2.5 list-disc pl-6 text-[12px]">
          <li>
            <div>Run a event without the intention of awarding a prize;</div>
          </li>
          <li>
            <div>
              Run a event to refer users via a referral link into another event;
            </div>
          </li>
          <li>
            <div>
              Ignore Platform Sixx generated entries by picking an external
              winner - unless specifically mentioned in your T&C. For example
              running a Platform Sixx event and picking winners via Retweets
              only, this defrauds users of their entries generated via Platform
              Sixx.
            </div>
          </li>
          <li>
            <div>
              Set up a fake giveaway with the sole intention of driving entries
              to a real giveaway (without the intention of providing a prize for
              your campaign);
            </div>
          </li>
          <li>
            <div>
              Run a campaign promoting Cryptocurrencies, ICOs or Airdrops unless
              you are the developer or an approved marketing partner;
            </div>
          </li>
          <li>
            <div>
              Run a campaign promoting Cryptocurrencies, ICOs or Airdrops using
              an email address that does not match the domain offering the
              service;
            </div>
          </li>
          <li>
            <div>
              Run a campaign promoting Cryptocurrencies, ICOs or Airdrops with a
              Team or Employees that do not exist;
            </div>
          </li>
          <li>
            <div>
              Giveaway game keys purely for the purpose of farming followers for
              various groups or other social accounts;
            </div>
          </li>
          <li>
            <div>
              Run a event that is funded by donations or mostly contains
              referral, affiliate links or CPA offers;
            </div>
          </li>
          <li>
            <div>
              Continually extend the end date of a campaign without users
              agreeing to terms that allow it;
            </div>
          </li>
          <li>
            <div>
              Run a campaign that is purely designed to exchange followers (or
              other actions) and not for business purposes;
            </div>
          </li>
          <li>
            <div>
              Encourage users to break the TOS of an external site (i.e.
              clicking Adsense ads);
            </div>
          </li>
          <li>
            <div>
              Use or copy prize images that you do not own the copyright to;
            </div>
          </li>
          <li>
            <div>
              Create multiple or false contestant accounts for the purpose of
              increasing campaign entries or redeeming multiple rewards;
            </div>
          </li>
          <li>
            <div>
              Impersonate any person or entity or falsely state or otherwise
              misrepresent your affiliation with a person or entity;
            </div>
          </li>
          <li>
            <div>
              Sublicense to, transfer, network, transmit, distribute, or permit
              use of the Service by, any third party without permission;
            </div>
          </li>
          <li>
            <div>
              Reverse engineer, decompile, or disassemble any aspect of the
              Service;
            </div>
          </li>
          <li>
            <div>
              Attempt to automate entries with the intent of defrauding the
              event Owner;
            </div>
          </li>
          <li>
            <div>
              Forge headers or otherwise manipulate identifiers in order to
              disguise the origin of any data transmitted to other users;
            </div>
          </li>
          <li>
            <div>
              Interfere with or disrupt the Service or other user’s use of the
              Service;
            </div>
          </li>
          <li>
            <div>
              Reproduce, duplicate or copy or exploit any other portion of the
              Platform Sixx Site, without the express written permission of
              Platform Sixx;
            </div>
          </li>
          <li>
            <div>
              Modify, adapt or hack the Platform Sixx Site or falsely imply that
              some other site is associated with Platform Sixx Site;
            </div>
          </li>
          <li>
            <div>
              Create multiple accounts after your account has been disabled;
            </div>
          </li>
          <li>
            <div>
              Knowingly run campaigns on behalf of users that are already banned
              for previous TOS violations;
            </div>
          </li>
        </ul>
      </div>

      <div>
        <p className="mb-2.5 text-[12px]">
          Breach of any of the TOS may result in a permanent account ban, or
          initiate an investigation whereby Platform Sixx may ask for more
          information from you (such as communication with winners). During this
          time any live giveaways inside the account may suffer interruption,
          until such information is presented.
        </p>
      </div>

      <div className="mb-1.5 text-[20px] text-white">
        8) Third Party Applications & Reliability
      </div>
      <div>
        <p className="mb-2.5 text-[12px]">
          While the primary purpose of the Services is to aggregate third party
          websites, applications, interfaces and other materials (“Third Party
          Applications“), it should be made clear that Platform Sixx does not
          own or control any of these. As Platform Sixx serves only as collector
          of such Third Party Applications for your convenience, it does not in
          any way endorse, recommend or make any recommendations in relation to
          any such Third Party Applications. Similarly, while Platform Sixx
          monitors the working condition of the connectors to such Third Party
          Applications and is committed to resolving any issues that may arise
          from a third party changing the login procedure of its application, it
          is not liable, nor can it be held responsible, for any changes or the
          inoperability of such Third Party Applications, whether temporary or
          permanent.
        </p>
        <p className="mb-2.5 text-[12px]">
          Platform Sixx also assumes no responsibility for the content,
          guidelines, privacy policies, or practices of any third party and by
          using the Services you expressly relieve Platform Sixx from any and
          all liability arising from your use of any Third Party Applications.
        </p>
        <p className="mb-2.5 text-[12px]">
          You further acknowledge that such Third Party Applications may be
          protected by copyrights, patents, trademarks, trade secrets and/or
          other proprietary rights, and therefore you undertake not to modify,
          publish, transmit, distribute, perform, participate in the transfer or
          sale, create derivative works of, or in any way exploit, any of the
          Third Party Applications, in whole or in part, and undertake to abide
          by the relevant terms of use of such Third Party Applications.
        </p>
      </div>

      <div className="mb-1.5 text-[20px] text-white">
        9) Right to Shut Down, Deny or Limit Access, Remove Content
      </div>
      <div>
        <p className="mb-2.5 text-[12px]">
          Platform Sixx reserves the right to suspend or terminate your account
          at any time and for any or no reason at all, at our sole discretion.
          Accordingly, Platform Sixx reserves the right to remove any user
          content, which is abusive, illegal, disruptive, or that otherwise
          fails to conform to our standards, and to limit or revoke your access
          in full or in part to the Services, in its sole discretion, at any
          time, and for any reason, including, but not limited to technical
          difficulties or violation of these Terms.
        </p>
      </div>

      <div className="mb-1.5 text-[20px] text-white">10) Your Feedback</div>
      <div>
        <p className="mb-2.5 text-[12px]">
          While we welcome any feedback or comments from you, please note that
          any unsolicited ideas, feedback or suggestions pertaining to our
          business, such as ideas for new or improved products or technologies,
          website or tool enhancements, processes, materials, marketing plans or
          new product names, will automatically upon submission become the
          property of Platform Sixx, without any compensation to you. While
          under no obligation to review such submissions or to keep such
          submissions confidential, Platform Sixx may use or redistribute any
          such submission and its contents for any purpose and in any way it
          deems.
        </p>
        <p className="mb-2.5 text-[12px]">
          To provide such submissions or feedback, please email
          info@platformsixx.com
        </p>
      </div>

      <div className="mb-1.5 text-[20px] text-white">11) No Warranty</div>
      <div>
        <p className="mb-2.5 text-[12px]">
          YOU ACKNOWLEDGE, THAT TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
          LAW, THAT THE SERVICES, THE SITE AND OTHER MATERIALS PROVIDED TO YOU
          UNDER THIS AGREEMENT ARE PROVIDED ON AN “AS IS” BASIS. USE OF THE
          SERVICES AND RELIANCE ON THE SITE CONTENT IS DONE SOLELY AT YOUR OWN
          RISK. Platform Sixx DISCLAIMS ANY AND ALL WARRANTIES RELATING TO THE
          SERVICE, THE SITE, THEIR CONTENT AND OTHER MATERIALS, EXPRESS OR
          IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
          NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY AND FITNESS
          FOR A PARTICULAR PURPOSE.
        </p>
        <p className="mb-2.5 text-[12px]">
          WE DO NOT WARRANT THAT THE SITE OR THE SERVICES PROVIDED WILL BE
          ERROR-FREE, THAT ACCESS THERETO WILL BE UNINTERRUPTED, THAT DEFECTS
          WILL BE CORRECTED, OR THAT THE SITE OR THE SERVERS THAT MAKE OUR
          SERVICES AVAILABLE ARE OR WILL BE FREE OF VIRUSES OR OTHER HARMFUL
          COMPONENTS.
        </p>
      </div>

      <div className="mb-1.5 text-[20px] text-white">
        12) Liabilities and Indemnities
      </div>
      <div>
        <p className="mb-2.5 text-[12px]">
          You shall indemnify and save harmless Platform Sixx and its directors,
          officers, employees, representatives and agents (“Related Parties”)
          against all demands, claims, actions, liabilities, losses, costs,
          damages or expenses whatsoever (including reasonable attorneys’ fees)
          (“Damages”) asserted against, imposed upon or incurred by Platform
          Sixx and/or any of its Related Parties resulting from or arising out
          of any violation of these Terms by you or arising and related to your
          use of the Services.
        </p>
        <p className="mb-2.5 text-[12px]">
          THE CUMULATIVE LIABILITY OF PLATFORM SIXX FOR ALL CLAIMS RELATING TO
          THE SITE OR SERVICES, REGARDLESS OF THE FORM OF ACTION, WHETHER IN
          CONTRACT OR TORT, INCLUDING NEGLIGENCE, INTELLECTUAL PROPERTY
          INFRINGEMENT, STRICT LIABILITY, OR OTHERWISE, SHALL NOT EXCEED THE
          TOTAL FEES ACTUALLY PAID BY YOU FOR THE SERIVICES OR USE OF THE SITE.
        </p>
        <p className="mb-2.5 text-[12px]">
          PLATFORM SIXX WILL NOT BE LIABLE FOR DAMAGES OF ANY KIND INCLUDING,
          WITHOUT LIMITATION, COMPENSATORY, CONSEQUENTIAL, INCIDENTAL, INDIRECT,
          SPECIAL OR SIMILAR DAMAGES, THAT MAY RESULT FROM THE USE OF, OR THE
          INABILITY TO USE, THE SITE OR THE SERVICES, WHETHER THE MATERIAL IS
          PROVIDED OR OTHERWISE SUPPLIED BY PLATFORM SIXX OR ANY THIRD PARTY.
        </p>
        <p className="mb-2.5 text-[12px]">
          THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION AND
          ELSEWHERE IN THESE TERMS OF USE APPLY TO THE MAXIMUM EXTENT PERMITTED
          BY APPLICABLE LAW.
        </p>
      </div>

      <div className="mb-1.5 text-[20px] text-white">
        13) Confidentiality and Privacy
      </div>
      <div>
        <p className="mb-2.5 text-[12px]">
          We are committed to safeguarding any personal information that may be
          collected through our Services and to ensuring that you are fully
          informed as to how your personal information will be used and stored.
          To learn more about how Platform Sixx protects your personal
          information, please refer to our Privacy Policy .
        </p>
      </div>

      <div className="mb-1.5 text-[20px] text-white">14) Miscellaneous</div>
      <div>
        <p className="mb-2.5 text-[12px]">
          (a) These Terms constitute the entire agreement between the parties
          concerning the subject matter hereof, replacing any prior agreements;
          (b) You affirm that you are fully able and competent to enter into the
          terms, conditions, obligations, affirmations, representations, and
          warranties set forth in these Terms, and to abide by and comply with
          these Terms; (c) Failure to enforce any provision will not constitute
          a waiver of that provision. If any provision is found unenforceable,
          it and any related provisions will be interpreted to best accomplish
          the unenforceable provision’s essential purpose. (d) These Terms shall
          be governed by the laws of the United States of America and the Great
          state of Texas, without giving effect to any principles of conflicts
          of laws thereof, and the eligible courts, shall have exclusive
          jurisdiction over all disputes between the parties. Nothing in this
          agreement limits a party’s ability to seek equitable relief.
        </p>
      </div>

      <div>Product Schedules</div>
      <div>
        <p className="mb-2.5 text-[12px]">Platform Sixx App</p>
        <p className="mb-2.5 text-[12px]">
          The following additional or modified terms and conditions apply under
          this Agreement to your use of Platform Sixx App.
        </p>
        <p className="mb-2.5 text-[12px]">
          Our Relationship With You as a Member of Platform Sixx App. Platform
          Sixx Apps and Services enable you as a registered user (“Member”) to
          easily create, manage, and administer sweepstakes, contests, and
          similar promotions (collectively, “Promotions”), and to configure,
          collect, and manage data using the tools we provide. In some cases, we
          may host a Member’s Promotion on our servers, or you may elect to host
          a Promotion on your own website(s). Our relationship with you is
          solely as a provider of online tools and hosting services, and nothing
          more. While we make available various tools and generic templates for
          your use in creating online Promotions, we do not provide legal
          advice. You are solely responsible for ensuring that you operate your
          Promotion in full compliance with applicable laws, including but not
          limited to: (i) the design, advertising, and administration of your
          Promotion; (ii) prize selection, fulfillment, and tax reporting; (ii)
          the accuracy of any descriptions of the Promotion and applicable
          rules; (iii) compliance with applicable laws regulating your
          Promotion, such as state lottery prohibitions, sweepstakes
          registration requirements, bonding, or other requirements that may
          apply depending on the jurisdiction; and (iv) your strict compliance
          with applicable data protection laws.
        </p>
        <p className="mb-2.5 text-[12px]">
          You May Not Allow Participation by Under-Age Participants. We are
          unable to host any Promotion directed to children under the age of 13,
          or in which such children may otherwise participate, and you are
          prohibited from designing such a Promotion to be hosted on our
          website. The collection of personal information from children under 13
          is governed in the U.S. by the Children’s Online Privacy Protection
          Act, and your failure to comply with that statute can subject you to
          severe penalties. Similar state laws can further regulate data
          collection from minors under 16, and some states have an age of
          majority higher than 18 years old. You are solely responsible for
          restricting data collection from, and participation by, any under-age
          participants.
        </p>
        <p className="mb-2.5 text-[12px]">
          Prohibited. We do not permit our Services to be used with affiliate
          offers, referral links, CPA offers, cryptocurrency promotions, sneaker
          raffles, gambling, or illegal lotteries. Violation of this prohibition
          may result in immediate suspension and termination of a Promotion and
          Member account.
        </p>
        <p className="mb-2.5 text-[12px]">
          Legal Disclaimer. WE MAY MAKE GENERIC SWEEPSTAKES OR CONTEST TEMPLATES
          AVAILABLE FOR YOU TO MODIFY TO SUIT YOUR PARTICULAR PROMOTION, BUT WE
          MAKE NO REPRESENTATIONS OR WARRANTIES WHATSOEVER AS TO WHETHER THEY
          ARE LEGAL OR APPROPRIATE, AND HEREBY DISCLAIM ANY SUCH REPRESENTATIONS
          AND WARRANTIES IN FULL. YOUR USE OF ANY INFORMATION WE MAY PROVIDE IS
          AT YOUR SOLE RISK. YOU SHOULD ALWAYS SEEK ADVICE AND INFORMATION FROM
          A QUALIFIED LEGAL PROFESSIONAL BEFORE OPERATING A PROMOTION.
        </p>
      </div>
    </div>
  );
}
