import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Dispatch, SetStateAction, useState } from "react";
import { updateFulfillment } from "../../../functions";
import P6Button from "../../button";
import { P6ClientError } from "../../../utils/exeptions";

export default function PickupOption({
  setOptionsOpen,
  fulfillmentId,
  eventId,
}: {
  setOptionsOpen: Dispatch<SetStateAction<boolean>>;
  fulfillmentId: string;
  eventId: string;
}) {
  const {
    register,
    setError,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();
  const [loading, setLoading] = useState(false);
  const onSubmit: SubmitHandler<FieldValues> = async (params) => {
    if (!params.pickedUpBy) {
      setError("pickedUpBy", { type: "custom", message: "custom message" });
    } else {
      try {
        setLoading(true);
        const request = {
          id: fulfillmentId,
          eventId: eventId,
          fulfillmentType: "pickup",
          pickedUpBy: params.pickedUpBy as string,
          optionSelectedDate: new Date(),
        };
        await updateFulfillment(request);
        window.dataLayer.push({
          event: "confirm_pickup_request",
          eventProps: {
            id: fulfillmentId,
            event_id: eventId,
            picked_up_by: params.pickedUpBy as string,
          },
        } as unknown);
        setLoading(false);
        setOptionsOpen(false);
      } catch (error) {
        throw new P6ClientError("UnknownError", error as string);
      }
    }
  };

  const onError = () => {
    if (!getValues("state")) {
      setError("state", { type: "custom", message: "custom message" });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="p-2">
          <div className="text-center text-lg">Pick Up</div>
          <div className="text-white text-opacity-50 text-sm text-center mb-3">
            Let us know who will be picking up your item.
          </div>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="pickedUpBy"
                autoComplete="pickedUpBy"
                className="text-field peer"
                placeholder=" "
                {...register("pickedUpBy", {
                  required: true,
                })}
              />
              <label htmlFor="pickedUpBy" className="form-label">
                Who is Picking Up...
              </label>
            </div>
            {errors.trackingNumber && (
              <p className="form-error"> Who is Picking Up is Required</p>
            )}
          </div>
          <div className="flex flex-grow items-center justify-center text-center p-2.5 gap-2">
            <P6Button
              sm
              text="Cancel"
              type="button"
              color="bg-dark-grey"
              onClick={() => setOptionsOpen(false)}
            />
            <P6Button
              sm
              icon="pickup_white_icon"
              iconLeft
              text="Pick Up"
              type="submit"
              color={"bg-green"}
              loading={loading}
            />
          </div>
        </div>
      </form>
    </>
  );
}
