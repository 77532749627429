export default function Loading() {
  return (
    <div className="flex-auto">
      {[...Array<undefined>(3)].map((x: undefined, i: number) => (
        <div
          key={i}
          className="mt-2.5 h-[455px] flex-auto bg-component text-white"
        >
          <div className="rounded-lg">
            <div className="">
              <div className="p-2.5">
                <div className="flex animate-pulse space-x-4">
                  <div className="bg-shadow h-[57px] w-[57px] rounded-full"></div>
                  <div className="flex-1 space-y-6 py-1">
                    <div className="bg-shadow h-2 rounded"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="bg-shadow col-span-2 h-2 rounded"></div>
                        <div className="bg-shadow col-span-1 h-2 rounded"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-2.5 space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-2 h-2 rounded bg-shaddow"></div>
                    <div className="col-span-1 h-2 rounded bg-shaddow"></div>
                  </div>
                </div>
              </div>
              <div className=" bg-shadow col-span-2 h-[280px] rounded"></div>
              <div className="flex px-2.5 pb-2.5">
                <div className="bg-shadow mr-2.5 mt-4 h-[42px] w-[52px] rounded-full "></div>
                <div className="bg-shadow mt-4 h-[42px] w-full rounded-full"></div>
                <div className="bg-shadow mt-4 ml-2.5 h-[42px] w-[92px]"></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
