import { useEffect, useState } from "react";
import { db } from "../../../../firebase/clientFirebaseApps";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { P6Fulfillment } from "../../../../../../src/types/fulfillment";
import OrderItem from "../../components/order_item";
import { Link } from "react-router-dom";

export default function Orders() {
  const [orders, setOrders] = useState<P6Fulfillment[]>([]);

  useEffect(() => {
    const q = query(
      collection(db, "fulfillments"),
      where("distribution", "!=", null),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const openOrders: P6Fulfillment[] = [];
      querySnapshot.forEach((doc) => {
        openOrders.push({ id: doc.id, ...doc.data() } as P6Fulfillment);
      });

      setOrders(openOrders);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="text-white">
      {orders.map((order) => (
        <Link key={order.id} to={`/distribution/order/${order.id}`}>
          <OrderItem orderData={order} />
        </Link>
      ))}
    </div>
  );
}
