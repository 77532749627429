import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import P6Button from "../button";
import VerifyDisplay from "./verify";
import { RandomOrgResult } from "../../../../src/types/random";

export default function VerifyRandom({ random }: { random: RandomOrgResult }) {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  return (
    <div>
      <div
        className="cursor-pointer"
        onClick={() => {
          setOpen(true);
        }}
      >
        <P6Button sm text="Verify" color="bg-black" />
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-2.5 text-center sm:items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative max-w-screen-xs transform overflow-hidden rounded-lg bg-component text-left shadow-xl transition-all ">
                  <div>
                    <img
                      src="/icons/X_icon.svg"
                      alt="close"
                      className="absolute top-4 right-4 cursor-pointer"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                  <VerifyDisplay random={random} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
