import { Fragment, Dispatch, SetStateAction } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { P6UserProfile } from "../../../../src/types/profile";
import { clientAuth } from "../../firebase/clientFirebaseApps";
import { signOut } from "../../firebase/clientUserSessionUtils";
import { useNavigate } from "react-router-dom";
import Menu from "./menu";

export default function MainMenu({
  menuOpen,
  setMenuOpen,
  user,
}: {
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  user: P6UserProfile;
}) {
  const navigate = useNavigate();

  const logout = async () => {
    await signOut(clientAuth);
    navigate("/login");
  };

  return (
    <Transition.Root show={menuOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setMenuOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-[200px]">
                  <div className="flex h-full flex-col overflow-y-scroll bg-component pt-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-sm leading-6 text-red">
                          <span
                            onClick={() => logout()}
                            className="hover:text-p6blue cursor-pointer"
                          >
                            Logout
                          </span>
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative text-grey hover:text-p6blue"
                            onClick={() => setMenuOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <Menu user={user} setMenuOpen={setMenuOpen} />
                    </div>
                    {/* Foother */}
                    <div className="p-2.5">
                      <div className="flex flex-col justify-end text-[10px] text-grey">
                        <div>
                          &copy; {new Date().getFullYear()} Platform Sixx Inc.
                        </div>
                        <div>All rights reserved.</div>
                        <div className="text-[6px] text-dark-grey text-right">
                          Ver. {import.meta.env.VITE_P6_APP_VERSION}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
