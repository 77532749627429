import { Dispatch, SetStateAction } from "react";
import Shipment from "./shipment";

export default function Layout({
  setOptionsOpen,
  fulfillmentId,
  eventId,
}: {
  setOptionsOpen: Dispatch<SetStateAction<boolean>>;
  fulfillmentId: string;
  eventId: string;
}) {
  return (
    <div className="relative text-white">
      <Shipment
        setOptionsOpen={setOptionsOpen}
        fulfillmentId={fulfillmentId}
        eventId={eventId}
      />
    </div>
  );
}
