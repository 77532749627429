import { useState } from "react";
import { P6Event } from "../../../../src/types/event";
import OptionsModal from "./options_modal/options_modal";
import { P6Fulfillment } from "../../../../src/types/fulfillment";
import ShipmentModal from "./shipment_modal/shipment_modal";
import ReceiveModal from "./receive_modal/receive_modal";
import WonIcons from "./won_icons";
import WonText from "./won_text";
import { useLocation } from "react-router-dom";

export default function Won({
  data,
  fulfillment,
  admin,
}: {
  data: P6Event;
  fulfillment: P6Fulfillment;
  admin?: boolean;
}) {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [shipmentOpen, setShipmentOpen] = useState(false);
  const [receiveOpen, setReceiveOpen] = useState(false);
  const { pathname } = useLocation();

  const openOptionsModal = () => {
    setOptionsOpen(true);
  };

  const openShipmentModal = () => {
    setShipmentOpen(true);
  };

  const openReceiveModal = () => {
    setReceiveOpen(true);
  };

  const buttonType = (fulfillment: P6Fulfillment) => {
    if (fulfillment.receivedDate) {
      return;
    }

    if (
      fulfillment.fulfillmentType === "ship" &&
      !fulfillment.shipDate &&
      admin &&
      !fulfillment.distribution
    ) {
      return (
        <div
          data-cy="ship-button"
          onClick={() => void openShipmentModal()}
          className="rounded-full bg-p6blue px-2.5 cursor-pointer"
        >
          Ship
        </div>
      );
    }
    if (
      fulfillment.fulfillmentType === "ship" &&
      !fulfillment.shipDate &&
      pathname.slice(1).split("/")[0] === "distribution"
    ) {
      return (
        <div
          onClick={() => void openShipmentModal()}
          className="rounded-full bg-p6blue px-2.5 cursor-pointer"
        >
          Ship
        </div>
      );
    }

    switch (fulfillment.fulfillmentType) {
      case "pickup":
        return fulfillment.pickedUpBy ? (
          <div
            onClick={() => void openReceiveModal()}
            className="rounded-full bg-green px-2.5 cursor-pointer whitespace-nowrap"
          >
            Pick Up
          </div>
        ) : (
          ""
        );
      case "ship":
        return fulfillment.shipDate ? (
          <div
            data-cy="receive-button"
            onClick={() => void openReceiveModal()}
            className="rounded-full bg-green px-2.5 cursor-pointer"
          >
            Receive
          </div>
        ) : (
          <div className="rounded-full bg-grey px-2.5">Receive</div>
        );
      case "trade":
        return;
      default:
        return (
          <div
            data-cy="open-options"
            onClick={() => void openOptionsModal()}
            className="rounded-full bg-green px-2.5 cursor-pointer"
          >
            Option
          </div>
        );
    }
  };

  return (
    <div className="text white h-full">
      <div className="mt-[3px] flex items-center justify-center">
        <div className="flex flex-auto flex-col items-center">
          <WonText
            fulfillment={fulfillment}
            creditsToReturn={data.options.creditsToReturn}
          />
          <WonIcons fulfillment={fulfillment} closedAt={data.closedAt} />
        </div>

        <div className="mb-2.5 flex auto-cols-auto items-start justify-end">
          {fulfillment && buttonType(fulfillment)}
        </div>
      </div>
      <OptionsModal
        optionsOpen={optionsOpen}
        setOptionsOpen={setOptionsOpen}
        setReceiveOpen={setReceiveOpen}
        fulfillment={fulfillment}
        eventId={data.id}
        createdAt={data.createdAt}
      />
      <ShipmentModal
        optionsOpen={shipmentOpen}
        setOptionsOpen={setShipmentOpen}
        fulfillmentId={fulfillment.id}
        eventId={data.id}
      />
      <ReceiveModal
        optionsOpen={receiveOpen}
        setOptionsOpen={setReceiveOpen}
        fulfillment={fulfillment}
        creditsToReturn={data.options.creditsToReturn}
      />
    </div>
  );
}
