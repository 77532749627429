import { useState, useEffect, SetStateAction, Dispatch } from "react";
import Select from "react-select";
import {
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormSetValue,
} from "react-hook-form";

type FormValues = {
  carrier: string;
};

const carrierArray = [
  { label: "DHL", value: "DHL" },
  { label: "FEDEX", value: "FEDEX" },
  { label: "UPS", value: "UPS" },
  { label: "Other", value: "Other" },
];

export default function CarrierSelect({
  errors,
  setValue,
  clearErrors,
  defaultValue,
  setOther,
}: {
  errors: FieldErrors<FormValues>;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  defaultValue?: string;
  setOther: Dispatch<SetStateAction<boolean>>;
}) {
  const [label, setLabel] = useState<string | undefined>();
  const setCarrierValue = (value: string | undefined) => {
    setValue("carrier", value);
    clearErrors("carrier");
    setOther(value?.toLowerCase() === "other");
  };
  useEffect(() => {
    if (defaultValue) {
      const selected = carrierArray.find((x) => x.value === defaultValue);
      setLabel(selected?.label);
    }
  }, [defaultValue]);

  const autoCompleteCarrier = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const label = carrierArray.find((x) => x.label === target.value);
    const value = carrierArray.find((x) => x.value === target.value);

    if (label) {
      setValue("carrier", label.value);
      setLabel(label.label);
    }
    if (value) {
      setValue("carrier", value.value);
      setLabel(value.label);
      setOther(value.value === "other");
    }
  };

  return (
    <div className="relative mb-2.5">
      <input
        className="absolute h-0 w-0 text-background"
        type="text"
        autoComplete="address-level1"
        onChange={(event) => autoCompleteCarrier(event)}
      />
      <Select
        unstyled
        classNames={{
          control: (state) =>
            state.isFocused
              ? "flex block h-8 px-2.5 w-full text-sm text-grey bg-component rounded-full border-[1px] appearance-none text-white border-green outline-none ring-0 border-green peer pl-4"
              : "flex block h-8 px-2.5 w-full text-sm text-grey bg-component rounded-full border-[1px] border-grey appearance-none dark:text-white dark:border-grey dark:focus:border-green focus:outline-none focus:ring-0 focus:border-green peer pl-4",
          menu: (state) =>
            state.options
              ? "overflow-hidden w-[140px] bg-component rounded-lg border-[1px] border-grey mt-1.5"
              : "",
          option: (state) =>
            state.data
              ? "overflow-hidden w-[140px] hover:bg-green px-2.5 mx-2.5 first:mt-2 rounded-full last:mb-2"
              : "",
        }}
        classNamePrefix="select"
        placeholder={label}
        isLoading={false}
        isSearchable={true}
        name="carrier-select"
        options={carrierArray}
        onChange={(value) => setCarrierValue(value?.value)}
      />
      <label
        htmlFor="carrier-select"
        className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
      >
        Carrier
      </label>
      {errors.carrier && <p className="form-error mt-0">Carrier is Required</p>}
    </div>
  );
}
