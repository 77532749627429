import { P6Post } from "../../../src/types/post";

export const postsRollUp = (eventsData: P6Post[]): P6Post[] => {
  // Filter the eventsData array to get only the top-level events
  const topLevelEvents = eventsData.filter(
    (event: P6Post) => !event.mainEventIds,
  );

  // For each top-level event, filter the eventsData array again to get its minis
  const updatedEvents = topLevelEvents.map((event) => {
    let minis = eventsData.filter(
      (mini: P6Post) =>
        mini.mainEventIds && event.eventId && mini?.mainEventIds.map((x) => x).includes(event.eventId),
    );
    minis = minis.map((mini: P6Post) => {
      let micros = eventsData.filter(
        (micro) =>
          micro.mainEventIds &&
          mini.eventId &&
          micro.mainEventIds.map((x) => x).includes(mini.eventId),
      );
      micros = micros.map((micro: P6Post) => {
        let nanos = eventsData.filter(
          (nano) =>
            nano.mainEventIds &&
            micro.eventId &&
            nano.mainEventIds.map((x) => x).includes(micro.eventId),
        );
        nanos = nanos.map((nano: P6Post) => {
          let macros = eventsData.filter(
            (macro) =>
              macro.mainEventIds &&
              nano.eventId &&
              macro.mainEventIds.map((x) => x).includes(nano.eventId),
          );
          macros = macros.map((macro: P6Post) => {
            const picos = eventsData.filter(
              (pico) =>
                pico.mainEventIds &&
                macro.eventId &&
                pico.mainEventIds.map((x) => x).includes(macro.eventId),
            );
            return { ...macro, events: picos };
          });
          return { ...nano, events: macros };
        });
        return { ...micro, events: nanos };
      });
      return { ...mini, events: micros };
    });
    return { ...event, events: minis };
  });
  return updatedEvents;
};
