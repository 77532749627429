import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
// import reportWebVitals from "./reportWebVitals";

import routesConfig from "./routes";

// Initialize Sentry
import * as Sentry from "@sentry/react";

// Initialize Clevertap or CleverCrap or CleverTrap
import clevertap from "clevertap-web-sdk";

function disableSentry() {
  if (import.meta.env.MODE !== "production") {
    return "";
  }
  return "https://2a3c235de7c8b4100a1e1e43a3c6c9c8@o4504979054395392.ingest.sentry.io/4505676114362368";
}

Sentry.init({
  dsn: disableSentry(),
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "https://app.platformsixx.com/",
        "https://platformsixx-next.web.app/",
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
});

const router = createBrowserRouter(routesConfig);

// if (import.meta.env.MODE === "production") {
  clevertap.init(import.meta.env.VITE_CLEVER_TAP_APP_ID as string);
  clevertap.spa = true;
// }

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
