import { P6Post } from "../../../../src/types/post";
import EventMinis from "../../components/event_card/event_minis";

const PostRollUpComponent = ({ events }: { events: P6Post[] }) => {
  return (
    <>
      {/* mini details */}
      {events?.filter(x => x.state).map((mini) => (
        <div key={mini.eventId as string}>
          <EventMinis
            id={mini.eventId as string}
            micro={false}
            state={mini.state}
            seats={mini.seats as number}
            seatPrice={mini.seatPrice as number}
            available={mini.available as number}
            title={mini.title} />
          {mini?.events?.map((micro) => (
            <div key={micro.eventId as string} className="">
              <EventMinis
                id={micro.eventId as string}
                micro={true}
                state={micro.state}
                seats={micro.seats as number}
                seatPrice={micro.seatPrice as number}
                available={micro.available as number}
                title={micro.title} />
              {micro?.events?.map((nano: P6Post) => (
                <div key={nano.eventId as string} className="ml-4">
                  <EventMinis
                    id={nano.eventId as string}
                    micro={true}
                    state={nano.state}
                    seats={nano.seats as number}
                    seatPrice={nano.seatPrice as number}
                    available={nano.available as number}
                    title={nano.title} />
                  {nano?.events?.map((macro: P6Post) => (
                    <div key={macro.eventId as string} className="ml-4">
                      <EventMinis
                        id={macro.eventId as string}
                        micro={true}
                        state={macro.state}
                        seats={macro.seats as number}
                        seatPrice={macro.seatPrice as number}
                        available={macro.available as number}
                        title={macro.title} />
                      {macro?.events?.map((pico: P6Post) => (
                        <div key={pico.eventId as string} className="ml-4">
                          <EventMinis
                            id={pico.eventId as string}
                            micro={true}
                            state={pico.state}
                            seats={pico.seats as number}
                            seatPrice={pico.seatPrice as number}
                            available={pico.available as number}
                            title={pico.title} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
export default PostRollUpComponent;
