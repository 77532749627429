import { useState, useEffect, ChangeEvent } from "react";
import MemberList from "./components/member_list";
import PendingList from "./components/pending_list";
import MemberTools from "./components/member_tools";
import { collection, query, where, onSnapshot, getDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase/clientFirebaseApps";
import algoliasearch from "algoliasearch/lite";
import { ProfileIndex } from "../../../../../src/types/algolia/indexes";
import { P6ClientError } from "../../../utils/exeptions";
import { P6GroupMembership } from "../../../../../src/types/groupMembership";

const appId = import.meta.env.VITE_ANGOLIA_APP_ID as string;
const apiKey = import.meta.env.VITE_ANGOLIA_API_KEY as string;
const searchClient = algoliasearch(appId, apiKey);
let indexName = "Profiles";

if (
  !import.meta.env.MODE ||
  import.meta.env.MODE === "development" ||
  import.meta.env.MODE === "test"
) {
  indexName = "dev_" + indexName;
}
const index = searchClient.initIndex(indexName);

export default function Members({
  groupId,
  groupCode,
}: {
  groupId: string;
  groupCode: string;
}) {
  const [pending, setPending] = useState<boolean>(false);
  const [activeCount, setActiveCount] = useState<number>(0);
  const [pendingCount, setPendingCount] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<ProfileIndex[] | null>();
  const [profileIds, setProfileIds] = useState<string[]>();

  useEffect(() => {
    if (searchQuery.trim() !== "" && searchQuery.length > 2) {
      if (pending) {
        index
          .search(searchQuery, {
            filters: `pendingGroupIds:${groupId}`,
          })
          .then(({ hits }) => {
            setSearchResults(hits as ProfileIndex[]);
          })
          .catch((error) => {
            throw new P6ClientError("UnknownError", error as string);
          });
      } else {
        index
          .search(searchQuery, {
            filters: `activeGroupIds:${groupId}`,
          })
          .then(({ hits }) => {
            setSearchResults(hits as ProfileIndex[]);
          })
          .catch((error) => {
            throw new P6ClientError("UnknownError", error as string);
          });
      }
    } else {
      setSearchResults(null);
    }
  }, [searchQuery, groupId, pending]);

  useEffect(() => {
    const activeQuery = query(
      collection(db, "groupMemberships"),
      where("status", "==", "active"),
      where("groupId", "==", groupId),
    );

    const activeUnsubscribe = onSnapshot(activeQuery, (snapshot) => {
      setActiveCount(snapshot.size);
    });

    return () => {
      activeUnsubscribe();
    };
  }, [groupId]);

  useEffect(() => {
    const pendingQuery = query(
      collection(db, "groupMemberships"),
      where("status", "==", "pending"),
      where("groupId", "==", groupId),
    );

    const pendingUnsubscribe = onSnapshot(pendingQuery, (snapshot) => {
      const profileIdsData: string[] = [];
      snapshot.forEach((groupMembership) => {
        const groupM = groupMembership.data() as P6GroupMembership;
        profileIdsData.push(groupM.userId);
      })
      // setPendingCount(snapshot.size);
      setProfileIds(profileIdsData);
    });

    return () => {
      pendingUnsubscribe();
    };
  }, [groupId]);

  useEffect(() => {
    if (profileIds && profileIds.length > 0) {
      const profileExistencePromises = profileIds.map(async (profileId) => {
        const profileDocRef = doc(db, "profiles", profileId);
        const profileSnapshot = await getDoc(profileDocRef);
        return profileSnapshot.exists();
      });
  
      Promise.all(profileExistencePromises)
      .then((results) => {
        const existsCount = results.filter((x) => x);
        setPendingCount(existsCount.length);
      })
      .catch((error) => {
        console.error('Error checking profile existence:', error);
      });
    }
  }, [profileIds])
  

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  return (
    <div>
      <div className="flex h-[55px] w-full bg-component px-2.5">
        <div className="relative flex flex-auto items-center">
          <input
            data-cy="search-members"
            type="search"
            id="user-input"
            className="peer block h-[35px] w-full appearance-none rounded-full border-[1px] border-grey bg-component px-2.5 pl-10 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
            placeholder="Search a user"
            onChange={handleInputChange}
            value={searchQuery}
          />
          <img
            src="/icons/magnify_glass.svg"
            alt="Email Icon"
            className="absolute top-[20px] left-[10px] z-10"
            width={17.49}
            height={17.49}
          />
          <label
            htmlFor="user-input"
            className="z-9 absolute top-[10px] left-7 flex origin-[50] -translate-y-3 scale-75 transform items-center rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-[10px] peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
          >
            Search Members
          </label>
        </div>
        <div
          onClick={() => setPending(!pending)}
          className="mt-2.5 ml-2.5 flex h-[35px] items-center px-2.5 font-light text-white cursor-pointer bg-component border-[1px] border-grey hover:bg-p6blue hover:border-p6blue"
        >
          {pending ? "Active" : "Pending"}
          <div className="ml-2 rounded-full bg-red px-2 text-white">
            {pending ? activeCount : pendingCount}
          </div>
        </div>
      </div>
      <MemberTools
        groupId={groupId}
        groupCode={groupCode}
        count={pending ? pendingCount : activeCount}
      />
      {!pending && (
        <div className="text-white">
          {searchResults ? (
            <MemberList
              groupId={groupId}
              userIds={searchResults.map((x) => x.objectID)}
            />
          ) : (
            <MemberList groupId={groupId} />
          )}
        </div>
      )}
      {pending && (
        <div className="text-white">
          {searchResults ? (
            <PendingList
              groupId={groupId}
              userIds={searchResults.map((x) => x.objectID)}
            />
          ) : (
            <PendingList groupId={groupId} />
          )}
        </div>
      )}
    </div>
  );
}
