import EventDetails from "./event_details";
import EventImage from "./event_image";
import CommentList from "./comment_list";
import Comment from "./comment";
import { Timestamp } from "firebase/firestore";
import { P6Post } from "../../../../../src/types/post";
import PostRollUpComponent from "../../shared/postRollUpComponent";

export default function Post({
  event,
  groupName,
  logo,
  index,
}: {
  event: P6Post;
  groupName: string;
  logo: string;
  index?: number;
}) {
  const { events, images, eventId, id } = event;
  return (
    <div className="mt-2.5 w-full bg-component ">
      {/* event details */}
      <EventDetails
        groupName={groupName}
        title={event.title}
        description={event.description}
        date={event.createdAt as Timestamp}
        image={logo}
        groupId={event.groupId}
      />
      {/* event image */}
      {images && (
        <EventImage state={event.state} eventId={eventId} images={images} />
      )}
      {/* mini details */}
      {events && <PostRollUpComponent events={events} />}
      {/* comments */}
      {<CommentList eventId={eventId || id as string} groupId={event.groupId} />}
      <div className="border-t-[1px] border-dark-grey pt-[18px]">
        <Comment
          label="Comment"
          button="Post"
          eventId={eventId || id as string}
          groupId={event.groupId}
          index={index}
        />
      </div>
    </div>
  );
}
