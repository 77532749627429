import { useState, useEffect, Dispatch, SetStateAction } from "react";
import FormAddCredits from "./add_credits_form";
import AddPaymentMethod from "./add_payment_method_form";
import PaymnetMethods from "./add_payment_methods";
import AddBillingAddress from "./add_billing_address";
import { db } from "../../../firebase/clientFirebaseApps";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { type P6PaymentMethod } from "../../../../../src/types/payment/payment_method";
import { type authnetAddress } from "../../../../../src/types/payment/provider/authnet";

export default function AddCredits({
  userId,
  setPaymentOpen,
  creditAmount,
}: {
  userId?: string;
  setPaymentOpen: Dispatch<SetStateAction<boolean>>;
  creditAmount?: number;
}) {
  const [addOpen, setAddOpen] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);
  const [address, setAddress] = useState<authnetAddress | undefined>();
  const [payMethods, setPayMethods] = useState<P6PaymentMethod[]>([]);
  const [chargeMethod, setChargeMethod] = useState<string | undefined>();
  const [hasCard, setHasCard] = useState<boolean>(false);

  const backState = () => {
    if (addOpen) {
      setAddOpen(false);
    }
    if (addressOpen) {
      setAddressOpen(false);
      setAddOpen(true);
    }
  };

  useEffect(() => {
    if (userId) {
      const paymentMethodRef = collection(db, "paymentMethods");
      const paymentMethodQuery = query(
        paymentMethodRef,
        where("userId", "==", userId),
      );
      const unsubscribe = onSnapshot(paymentMethodQuery, (querySnapshot) => {
        const paymentMethodsAr: P6PaymentMethod[] = [];
        querySnapshot.forEach((doc) => {
          const pm = doc.data();
          pm.id = doc.id;
          if (doc.data().defaultCard) {
            setChargeMethod(doc.id);
          }
          paymentMethodsAr.push(pm as P6PaymentMethod);
        });
        setPayMethods(paymentMethodsAr);
      });
      return () => unsubscribe();
    } else {
      setPayMethods([]);
    }
  }, [userId]);

  useEffect(() => {
    if (payMethods) setHasCard(true);
  }, [payMethods]);

  return (
    <div className="relative text-white">
      <div className="flex w-full mt-2">
        {addOpen && (
          <div className="float-left">
            <img
              src="/icons/back_icon.svg"
              alt="Close"
              className="ml-2.5 mt-2.5 h-auto w-auto cursor-pointer"
              width={17.93}
              height={17.93}
              onClick={() => backState()}
            />
          </div>
        )}
      </div>
      {addOpen && !addressOpen && (
        <AddPaymentMethod
          address={address}
          addressOpen={addressOpen}
          setAddressOpen={setAddressOpen}
          setAddOpen={setAddOpen}
          hasCard={hasCard}
        />
      )}
      {payMethods.length > 0 && (
        <div className="">
          {!addOpen && !addressOpen && (
            <FormAddCredits
              creditAmount={creditAmount}
              setPaymentOpen={setPaymentOpen}
              chargeMethod={chargeMethod as string}
            />
          )}
        </div>
      )}
      <div className="m-2.5">
        {!addressOpen && !addressOpen && (
          <PaymnetMethods
            addOpen={addOpen}
            setAddOpen={setAddOpen}
            payMethods={payMethods}
            setChargeMethod={setChargeMethod}
            chargeMethod={chargeMethod as string}
          />
        )}
      </div>

      {addressOpen && (
        <AddBillingAddress
          setAddress={setAddress}
          setAddressOpen={setAddressOpen}
        />
      )}
    </div>
  );
}
