import { RandomOrgResult } from "../../../../src/types/random";
import { Link } from "react-router-dom";
import moment from "moment";

export default function VerifyDisplay({ random }: { random: RandomOrgResult }) {
  return (
    <div className="text-white p-2.5">
      <div className="mb-3">
        <div className="text-center">
          {random.signature && (
            <div>
              <div className="flex justify-center py-3">
                <img
                  src="/icons/check_icon_green.svg"
                  alt="verified"
                  className="w-16 h-16"
                />
              </div>
              <div>{JSON.stringify(random.random.data)}</div>
              <div className="text-lg">Authenticity Check Succeeded</div>
            </div>
          )}
          {!random.signature && (
            <div>
              <div className="flex justify-center py-3">
                <img
                  src="/icons/failed_icon.svg"
                  alt="verified"
                  className="w-16 h-16"
                />
              </div>
              <div>{JSON.stringify(random.random.data)}</div>
              <div className="text-lg">Authenticity Check Failed</div>
            </div>
          )}
          <div>
            True random data generated for{" "}
            <span className="text-p6blue">Platform Sixx</span>
          </div>
          <div className="mb-3">
            {moment(random.random.completionTime).format(
              "MMMM Do YYYY, h:mm:ss a",
            )}
          </div>
          <Link to="https://www.random.org/" className="text-p6blue">
            RANDOM.ORG
          </Link>
        </div>
      </div>
      <div className="text-sm">
        <span>Verify manualy with </span>
        <Link
          to="https://api.random.org/signatures/form"
          target="_blank"
          rel="noopener noreferrer"
          className="text-p6blue"
        >
          random.org
        </Link>
      </div>
      <div className="text-[10px] leading-3 my-2">
        To verify the signature, you need to copy the signature and the random
        number into the form from the link above.
      </div>
      <div>
        <div className="text-sm">Random:</div>
        <div className="break-all text-[10px] text-grey">
          {JSON.stringify(random.random)}
        </div>
      </div>
      <div className="mt-2">
        <div className="text-sm">Signature:</div>
        <div className="flex break-all text-[10px] text-grey">
          {random.signature}
        </div>
      </div>
    </div>
  );
}
