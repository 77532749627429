import { useState } from "react";
import { useUser } from "../../../layouts/page_layout/layout";
import { Timestamp } from "@firebase/firestore";
import { P6Comment } from "../../../../../src/types/comment";
import { P6ClientError } from "../../../utils/exeptions";
import { createComment } from '../../../functions/index';

export default function Comment({
  label,
  button,
  eventId,
  commentId,
  groupId,
  index
}: {
  label: string;
  button: string;
  eventId: string;
  commentId?: string;
  groupId: string;
  index?: number;
}) {
  const { user } = useUser();
  const [content, setContent] = useState("");
  const [contentLength, setContentLength] = useState(0);
  const handleCommentSubmit = async () => {
    try {
      if (
        !content ||
        content.length > 255 ||
        !eventId ||
        !user.displayName ||
        !user.profileImageUrl ||
        !groupId
      ) {
        setContent("");
        return;
      }

      const commentData: P6Comment = {
        content,
        username: user.displayName,
        image: user.profileImageUrl,
        eventId,
        groupId,
        createdAt: Timestamp.now(),
      };

      if (commentId) {
        commentData.commentId = commentId;
      }
      await createComment(commentData);

      setContent(""); // Clear the content field after successful submission
      setContentLength(0);
    } catch (error) {
      throw new P6ClientError("UnknownError", error as string);
      // Handle the error if needed
    }
  };

  // eslint-disable-next-line no-undef
  const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cont = event.target.value;
    if (cont && cont.length < 256) {
      setContent(cont);
      setContentLength(cont.length);
    } else if (cont.length === 0) {
      setContent("");
      setContentLength(0);
    }
  };

  return (
    <div className="mb-2.5 px-2.5 pb-[18px]">
      <div className="flex text-white">
        <div className="flex">
          <div>
            <img
              src={user.profileImageUrl}
              alt="Author"
              className="rounded-full h-[35px] w-[35px]"
              width={35}
              height={35}
            />
          </div>
        </div>
        <div className="flex-auto pl-2.5">
          <div className="relative">
            <input
              data-cy={`${label.toLocaleLowerCase() === "reply" ? "reply-" : ""}comment-input-${index}`}
              className="text-field peer"
              placeholder=" "
              id={`comment-${eventId}`}
              value={content}
              onChange={handleContentChange}
            />
            <label
              htmlFor={`comment-${eventId}`}
              className="form-label w-full bg-transparent"
            >
              <div className="flex pr-4 bg-transparent">
                <div className="flex flex-auto">
                  <div className="bg-component flex-none px-2">{label}</div>
                </div>
                <div className="bg-component px-2">{contentLength}/255</div>
              </div>
            </label>
          </div>
        </div>
        <div className="flex pl-2.5">
          <div
            data-cy={`${label.toLocaleLowerCase() === "reply" ? "reply-" : ""}comment-button-${index}`}
            style={{ cursor: "pointer" }}
            className="flex items-center justify-center border-[1px] border-grey px-4 text-grey"
            onClick={() => void handleCommentSubmit()}
          >
            {button}
          </div>
        </div>
      </div>
    </div>
  );
}
