import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";
import { type P6UserProfile } from "../../../../src/types/profile";
import { FieldValues, useForm, SubmitHandler } from "react-hook-form";
import P6Button from "../button";
import { updateUserGroupCredits } from "../../functions";
import { P6ApiResponse } from "../../../../src/types";
import { P6ClientError } from "../../utils/exeptions";
import { P6UpdateGroupCreditsRequest } from "../../../../src/types/profile";
import Notes from "../../views/profile/notes";

export default function UserModal({
  userId,
  groupId,
}: {
  userId: string;
  groupId: string;
}) {
  const [user, setUser] = useState<P6UserProfile>();
  const [buttonText, setButtonText] = useState<string>("Add");
  const [groupCredits, setGroupCredits] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setLoading(true);
    try {
      if (!userId) {
        throw new P6ClientError("InvalidArgument", "User ID is required.");
      }
      if (!groupId) {
        throw new P6ClientError("InvalidArgument", "Group ID is required.");
      }
      if (!data.amount) {
        throw new P6ClientError("InvalidArgument", "message");
      }
      const request: P6UpdateGroupCreditsRequest = {
        groupId: groupId,
        userId: userId,
        amount: data.amount as number,
      };
      const result = (await updateUserGroupCredits(request)) as P6ApiResponse;
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        setLoading(false);
        reset();
      }
    } catch (error) {
      setLoading(false);
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  useEffect(() => {
    if (userId) {
      const unsub = onSnapshot(doc(db, "profiles", userId), (doc) => {
        setUser(doc.data() as P6UserProfile);
      });
      return () => unsub();
    }
  }, [userId]);

  useEffect(() => {
    if (user && groupId) {
      if (user.groupCredits) {
        setGroupCredits(user.groupCredits[groupId] || 0);
      }
    }
  }, [user, groupId]);

  const userAddress = (
    address: string,
    city: string,
    state: string,
    zip: string,
  ) => {
    return address + " " + city + ", " + state + " " + zip;
  };

  if (!user) {
    return null;
  }
  return (
    <div className="text-white">
      {/* profile header */}
      <div className="flex items-center flex-col pt-3">
        <div className="text-lg leading-none">{user.fullName}</div>
        <div className="text-sm text-grey mb-2.5">{user.displayName}</div>
        <div>
          <img
            src={user?.profileImageUrl}
            alt="User Profile"
            className="rounded-full w-[100px] h-[100px] object-cover"
          />
        </div>
      </div>
      {/* Group credits */}
      <div className="flex items-center justify-center">
        <div className="flex items-center justify-center text-[25px] mt-5 bg-green rounded-full px-3">
          <img
            src="/icons/credit_icon.svg"
            alt="Credit Icon"
            className="inline-block h-[25px] mr-2"
          />
          {groupCredits}
        </div>
      </div>
      <div className="underline text-lg ml-4 mb-2 mt-4">Manage Credits</div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex gap-2 mb-6">
        <div className="">
          <div className="relative">
            <input
              type="number"
              id="amount"
              autoComplete=""
              className="text-field peer pl-9"
              placeholder=" "
              {...register("amount", {
                required: true,
                valueAsNumber: true,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  parseInt(e.target.value) < 0
                    ? setButtonText("Remove")
                    : setButtonText("Add");
                },
              })}
            />
            <div className="absolute top-0 flex h-[35px] w-[28px] justify-end">
              <img
                src="/icons/credit_icon.svg"
                alt="Credit Icon"
                className="top-[7px] left-[10px] z-10"
                width={17.49}
                height={17.49}
              />
            </div>
            <label htmlFor="amount" className="form-label ml-7">
              Credits
            </label>
          </div>
          {errors.amount && <p className="form-error mt-0">Amount Required</p>}
        </div>
        <div className="flex w-[60%] h-[33px]">
          <P6Button
            color={buttonText === "Add" ? "bg-green" : "bg-red"}
            type="submit"
            text={buttonText}
            loading={loading}
            sm
            icon={buttonText === "Add" ? "add_icon" : "minus_icon"}
          />
        </div>
      </form>

      {/* Contac info */}
      <div className="underline text-lg ml-4 mb-2">Contact Info</div>
      <div className="bg-background rounded-lg p-2.5 mb-4">
        <div className="flex items-center mb-1 text-sm">
          <img
            src="/icons/user_email_icon.svg"
            className="w-[12px] mr-2"
            alt="email"
          />
          <a href={`mailto:${user.email}`} className="hover:text-p6blue">
            {user.email}
          </a>
        </div>
        <div className="flex items-center text-sm">
          <img
            src="/icons/phone_icon.svg"
            className="w-[12px] mr-2"
            alt="email"
          />
          <a
            href={`tel:${user.phoneNumber as string}`}
            className="hover:text-p6blue"
          >
            {user.phoneNumber}
          </a>
        </div>
      </div>

      <div className="bg-background rounded-lg p-2.5 mb-4">
        <a
          className="flex items-center"
          target="_blank"
          rel="noreferrer noopener"
          href={`http://maps.google.com/?q=${userAddress(
            user && user.address && user.address.address,
            user && user.address && user.address.city,
            user && user.address && user.address.state,
            user && user.address && user.address.zip,
          )}`}
        >
          <img
            src="/icons/address_icon.svg"
            className="w-[12px] mr-2"
            alt="email"
          />
          <div>
            <div className="text-sm">
              {user.address && user.address.address}
            </div>
            {user.address && user.address.address2 && (
              <div className="text-sm">
                {user.address && user.address.address2}
              </div>
            )}
            <div className="flex">
              <div className="mr-1 text-sm">
                {user.address && user.address.city},
              </div>
              <div className="mr-1 text-sm">
                {user.address && user.address.state}
              </div>
              <div className="text-sm">{user.address && user.address.zip}</div>
            </div>
          </div>
        </a>
      </div>
      <div className="relative">
        <div className="text-xs text-grey font-thin text-right pb-1 pr-2.5">
          Notes can be viewd by users
        </div>
        <Notes note={user.notes as string} />
      </div>
    </div>
  );
}
