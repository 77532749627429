import { P6DistributionItem } from "../../../../../src/types/distribution";

export default function Item({ item }: { item: P6DistributionItem }) {
  return (
    <div className="mb-2 flex bg-shaddow rounded-md overflow-hidden items-center cursor-pointer">
      <img
        src={`${item.images[0]}`}
        className="w-[35px] h-[35px] object-cover"
        alt="title"
      />
      <div className="flex-auto ml-2 py-1">
        <div className="text-[10px] text-grey">sku: {item.upc}</div>
        <div className="text-sm leading-3 line-clamp-1">{item.title}</div>
      </div>
      <div className="mr-3 bg-green px-2 rounded-full">{item.cost}</div>
    </div>
  );
}
