import { Dispatch, SetStateAction } from "react";
import Redirect from "./redirect";

export default function Layout({
  setOptionsOpen,
}: {
  setOptionsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="relative text-white">
      <Redirect setOptionsOpen={setOptionsOpen} />
    </div>
  );
}
