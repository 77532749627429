import { Link } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { fromNow } from "../../../utils/dateFormat";

export default function EventDetails({
  groupName,
  title,
  description,
  date,
  image,
  groupId,
}: {
  groupName: string;
  title: string;
  description: string;
  date: Timestamp;
  image: string;
  groupId: string;
}) {
  return (
    <div className="p-2.5">
      <div className="flex text-white">
        <div className="flex">
          <Link to={`/group/${groupId}/active`}>
            <img
              src={image}
              alt="Group logo"
              className="rounded-full w-[55px] h-[55px] object-cover"
              width={55}
              height={55}
            />
          </Link>
        </div>
        <div className="flex flex-auto flex-col justify-center pl-2.5">
          <p className="text-base">{title}</p>
          <Link to={`/group/${groupId}/active`}>
            <p className="text-sm text-grey">{groupName}</p>
          </Link>
        </div>
        <div className="flex">
          <p className="text-sm text-grey">{fromNow(date)}</p>
        </div>
      </div>
      <div>
        <p className="pt-2.5 text-sm text-grey">{description} </p>
      </div>
    </div>
  );
}
