import { useEffect, useState } from "react";
import { P6Transaction } from "../../../../../../src/types/transaction";
import { getTransactionsByUserId } from "../../../../functions";
import { P6ApiResponse } from "../../../../../../src/types";
import { longDate } from "../../../../utils/dateFormat";
import FinancialStatistics from "../financial/components/financialStatistics";

export default function FinancialByUser() {
  const [transactions, setTransactions] = useState<P6Transaction[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [payout, setPayout] = useState(0);
  const [purchase, setPurchase] = useState(0);
  const [refund, setRefund] = useState(0);
  const [payment, setPayment] = useState(0);
  const [trade, setTrade] = useState(0);
  const [withdraw, setWithdraw] = useState(0);
  const [groupCreditAdd, setGroupCreditAdd] = useState(0);
  const [groupRemoveAdd, setGroupRemoveAdd] = useState(0);

  useEffect(() => {
    if (transactions.length > 0) {
      const purchaseSum = parseFloat(
        transactions
          .filter((x) => x.type.toLowerCase() === "purchase")
          .reduce(
            (sum, transaction) =>
              sum + Math.abs(parseInt(transaction.amount.toString())),
            0,
          )
          .toFixed(2),
      );
      const refundSum = parseFloat(
        transactions
          .filter(
            (x) =>
              x.type.toLowerCase() === "refund" &&
              x.creditType &&
              x.creditType.toLowerCase() === "global",
          )
          .reduce(
            (sum, transaction) => sum + parseInt(transaction.amount.toString()),
            0,
          )
          .toFixed(2),
      );
      const paymentSum = parseFloat(
        transactions
          .filter((x) => x.type.toLowerCase() === "payment")
          .reduce(
            (sum, transaction) => sum + parseInt(transaction.amount.toString()),
            0,
          )
          .toFixed(2),
      );
      const payoutSum = parseFloat(
        transactions
          .filter((x) => x.type.toLowerCase() === "payout")
          .reduce(
            (sum, transaction) => sum + parseInt(transaction.amount.toString()),
            0,
          )
          .toFixed(2),
      );
      const withdrawSum = parseFloat(
        transactions
          .filter((x) => x.type.toLowerCase() === "withdraw")
          .reduce(
            (sum, transaction) => sum + parseInt(transaction.amount.toString()),
            0,
          )
          .toFixed(2),
      );
      const tradeSum = parseFloat(
        transactions
          .filter((x) => x.type.toLowerCase() === "trade")
          .reduce(
            (sum, transaction) => sum + parseInt(transaction.amount.toString()),
            0,
          )
          .toFixed(2),
      );
      const groupCreditAddSum = parseFloat(
        transactions
          .filter((x) => x.type.toLowerCase() === "group_credit_add")
          .reduce(
            (sum, transaction) => sum + parseInt(transaction.amount.toString()),
            0,
          )
          .toFixed(2),
      );
      const groupRemoveAddSum = parseFloat(
        transactions
          .filter((x) => x.type.toLowerCase() === "group_remove_add")
          .reduce(
            (sum, transaction) => sum + parseInt(transaction.amount.toString()),
            0,
          )
          .toFixed(2),
      );

      setPurchase(purchaseSum);
      setPayout(payoutSum);
      setRefund(refundSum);
      setPayment(paymentSum);
      setTrade(tradeSum);
      setWithdraw(withdrawSum);
      setGroupCreditAdd(groupCreditAddSum);
      setGroupRemoveAdd(groupRemoveAddSum);
    }
  }, [transactions]);

  const handleSearch = async () => {
    if (searchValue && searchValue.length > 8) {
      const response = await getTransactionsByUserId(searchValue);
      const result = response.data as P6ApiResponse;
      if (result.success) {
        setTransactions(result.data as P6Transaction[]);
      }
    }
  };

  return (
    <div className="flex text-white ">
      <div className="flex-auto pr-8 relative overflow-x-auto shadow-md sm:rounded-lg w-[50%]">
        <div className="flex items-center justify-between pb-4">
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <button
              onClick={async () => handleSearch()}
              className="absolute inset-y-0 left-0 flex items-center pl-3 pointer"
            >
              <svg
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <input
              type="text"
              id="table-search"
              className="block p-2 pl-10 text-sm border  rounded-lg w-80 bg-gray-700 border-gray-600 placeholder-gray-400 text-white"
              placeholder="User id"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
        <table className="w-full text-sm text-left text-gray-400">
          <thead className="text-xs uppercase bg-gray-700 text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Type
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Amount
              </th>
              <th scope="col" className="px-6 py-3">
                Created At
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions &&
              transactions.length > 0 &&
              transactions.map((transaction) => (
                <tr
                  key={transaction.id}
                  className="border-b bg-gray-800 border-gray-700 hover:bg-gray-600"
                >
                  <td className="px-6 py-4 font-medium whitespace-nowrap text-white">
                    {transaction.type}
                  </td>
                  <td className="px-6 py-4">{transaction.status}</td>
                  <td className="px-6 py-4">{transaction.amount}</td>
                  <td className="px-6 py-4">
                    {longDate(transaction.createdAt)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <FinancialStatistics
        payout={payout}
        purchase={purchase}
        refund={refund}
        payment={payment}
        trade={trade}
        withdraw={withdraw}
        groupCreditAdd={groupCreditAdd}
        groupRemoveAdd={groupRemoveAdd}
      />
    </div>
  );
}
