// import { collection, getDocs, query, where } from "firebase/firestore";
import Post from "./post";
// import { useEffect, useState } from 'react';
// import { db } from "../../../firebase/clientFirebaseApps";
// import { P6Group } from "../../../../../src/types/group";
// import { P6ClientError } from "../../../utils/exeptions";
import { P6Post } from "../../../../../src/types/post";
export default function PostList({
  events
}: {
  events: P6Post[];
}) {
  // const [groups, setGroups] = useState<P6Group[]>([]);

  // useEffect(() => {
  //   const groupIds = [...new Set(events.map((x) => x.groupId))]
  //   if (groupIds.length > 0) {
  //     (async () => {
  //       const groupsRef = collection(db, "groups");
  //       const groupsQuery = query(groupsRef, where("groupId", "in", groupIds));
  //       const querySnapshot = await getDocs(groupsQuery);
  //       const groupsData = querySnapshot.docs.map(doc => doc.data()) as P6Group[];

  //       setGroups(groupsData);
  //     })().catch((error) => {
  //       throw new P6ClientError("UnknownError", error as string);
  //     });
  //   }
  // }, [events])

  return (
    <>
      {events.map((event: P6Post, index) => {
        // const group = groups.find(x => x.groupId === event.groupId);
        // if (group) {
          return <Post key={event.id} event={event} groupName={event.groupName} logo={event.groupLogo } index={index + 1} />;
        // }
        return null;
      })}
    </>
  );
}
