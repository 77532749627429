import { Timestamp } from "firebase/firestore";
import { Link } from "react-router-dom";
import { P6Notification } from "../../../../src/types/notification";
import { fromNow } from "../../utils/dateFormat";

export default function Item({ data }: { data: P6Notification }) {
  return (
    <div className="border-t-[1px] text-white">
      <Link to={`/event/${data.eventId}`}>
        <div className="flex items-center px-2.5 py-3">
          <div>
            <img
              src={data.groupImage}
              alt="author"
              className="rounded-full"
              width={35}
              height={35}
            />
          </div>
          <div className="flex flex-auto flex-col px-2.5">
            <div className="text-xs text-grey">
              {fromNow(data.createdAt as Timestamp)}
            </div>
            <div className="text-[15px] leading-4">
              {data.groupName} - {data.action}
            </div>
          </div>
          <div className="h-[35px] w-[35px]">
            <img src={data.eventImage} alt="author" />
          </div>
        </div>
      </Link>
    </div>
  );
}
