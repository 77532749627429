import { useState, useEffect } from "react";
import { collection, onSnapshot, query, Timestamp, where, orderBy, limit } from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";
import WidgetItem from "./item";
import { type P6Event } from "../../../../src/types/event";

export default function EventWidget({ activeGroupIds }: { activeGroupIds?: string[] }) {
  const [events, setEvents] = useState<P6Event[]>([]);

  useEffect(() => {
    if (activeGroupIds && activeGroupIds.length) {
      const q = query(collection(db, "events"), where("groupId", "in", activeGroupIds), where("state", "==", "active"), orderBy("createdAt", "desc"), limit(60),);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const ar: Array<P6Event> = [];
        querySnapshot.docs.forEach((doc) => {
          ar.push({ id: doc.id, ...doc.data() } as P6Event);
        });
        setEvents(
          ar.sort((a, b) => {
            // First sort by the featured event
            const aFeaturedCount = a.options.featured;
            const bFeaturedCount = b.options.featured;

            if (bFeaturedCount !== aFeaturedCount) {
              return bFeaturedCount ? 1 : -1;
            }

            // Then sort by the date of the latest event
            const aLatestEventDate = (a.createdAt as Timestamp).toDate().getTime();
            const bLatestEventDate = (b.createdAt as Timestamp).toDate().getTime();

            return bLatestEventDate - aLatestEventDate;
          })
        );
      });

      return () => unsubscribe();
    }
  }, [activeGroupIds]);

  if (!events.length) {
    return null;
  }
  return (
    <>
      <div className="mt-2.5 h-[166px] max-w-screen-md bg-component">
        <div className="flex w-full items-center text-white">
          <span className="ml-2.5 mt-2 flex-auto text-xl">Events</span>
          <span className="float-right mr-2.5 text-base text-grey">Top {events.length}</span>
        </div>
        {/* Image Scroller */}
        <div className="flex overflow-x-auto">
          {events.map((event: P6Event) => (
            <WidgetItem key={event.id} data={event} />
          ))}
          {events.length === 0 && (
            <div className="mt-2.5 flex h-[87px] w-full flex-col justify-center rounded-lg bg-component text-center">
              <p className="text-[25px]">No Available Events</p>
              <p className="">Check back later.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
