import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { updateGroupMembership } from "../../functions";
import { type P6ApiResponse } from "../../../../src/types";
import { P6ClientError } from "../../utils/exeptions";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../layouts/page_layout/layout";

export default function LeaveGroup({
  admin,
  userId,
  groupId,
}: {
  admin: boolean;
  userId: string;
  groupId: string;
}) {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const { activeGroupIds, setActiveGroupIds } = useUser();
  const navigate = useNavigate();

  const leaveGroup = async () => {
    try {
      if (!userId) {
        throw new P6ClientError("InvalidArgument", "UserId is required.");
      }
      if (!groupId) {
        throw new P6ClientError("InvalidArgument", "Group ID is required.");
      }
      if (admin) {
        throw new P6ClientError(
          "InvalidArgument",
          "Admin can not leave group.",
        );
      }

      const request = {
        groupId: groupId,
        level: "user",
        status: "active",
        userId: userId,
        delete: true,
      };

      const result = await updateGroupMembership(request);
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        // TODO: test put a notification in here for the user to let them know it has been removed
        const index = activeGroupIds.indexOf(groupId);
        if (index > -1) {
          activeGroupIds.splice(index, 1);
        }
        setActiveGroupIds(activeGroupIds);
        navigate("/groups");
        setOpen(false);
      } else {
        throw new P6ClientError("FunctionError", "Unable to remove event.");
      }
    } catch (error) {
      setOpen(false);
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  return (
    <div>
      <div className="h-30 absolute -right-[94px] top-2.5 transform cursor-pointer rounded-l-full bg-red pl-3 pr-2 text-[14px] transition-all duration-500 ease-in-out hover:right-0">
        <div className="flex items-center">
          <img
            src="/icons/leave_icon.svg"
            className="mr-2.5 h-[16px]"
            alt="leave group"
          />
          <span onClick={() => setOpen(true)}>Leave Group</span>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-2.5 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative max-w-screen-xs transform overflow-hidden rounded-lg bg-component text-left shadow-xl transition-all sm:my-2.5">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="bg-red-100 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Leave Group
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to leave this group? All of
                            your data will be permanently removed. This action
                            cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="hover:bg-red-500 inline-flex w-full justify-center rounded-md bg-red px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      onClick={() => leaveGroup()}
                    >
                      Leave Group
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
