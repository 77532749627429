import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, FieldValues } from "react-hook-form";
import { useUser } from "../../../../layouts/finish_profile_layout/layout";
import Progress from "../../../../components/progress/progress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import type { SubmitHandler } from "react-hook-form";
import { type P6CreateUserProfile } from "../../../../../../src/types/profile";
import P6Button from "../../../../components/button";
import { createUserProfile } from "../../../../functions";
import { P6ClientError } from "../../../../utils/exeptions";
import { type P6ApiResponse } from "../../../../../../src/types";
import clevertap from "clevertap-web-sdk";

export default function Step3() {
  const { user } = useUser();
  const [phoneFocus, setPhoneFocus] = useState<string>();
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    // reset form with user data
    reset({ notificationEmail: user?.email as string });
  }, [user, reset]);

  async function fbCreateProfile(user: P6CreateUserProfile) {
    try {
      if (!user) {
        throw new P6ClientError(
          "InvalidArgument",
          "User is required to create a profile.",
        );
      }
      const result = await createUserProfile(user);
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        setLoading(false);
        navigate("/feed");
      } else {
        throw new P6ClientError("FunctionError", "Unable to add user profile.");
      }
    } catch (error) {
      throw new P6ClientError("UnknownError", error as string);
    }
  }

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
    if (user) {
      user.completeProfile = true as boolean;
      user.phoneNumber = data.phoneNumber as string;
      user.notificationEmail = data.notificationEmail as string;
    }
    clevertap.onUserLogin.push({
      Site: {
        Email: data.notificationEmail as string,
        Phone: "+1" + (data.phoneNumber as string),
      },
    });
    setLoading(true);
    await fbCreateProfile(user as P6CreateUserProfile);
  };

  const focusPhone = (focus: boolean) => {
    if (focus) {
      setPhoneFocus("phoneFocus");
    } else {
      setPhoneFocus("");
    }
  };

  return (
    <div>
      <Progress step={3} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-5">
          <div className="text-center text-[34px] font-light text-white">
            Finish your profile
          </div>
          <div className="mb-8 text-center text-[25px] font-light">
            Notification Contacts
          </div>
          <div className="relative mb-2.5">
            <Controller
              name="phoneNumber"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <div className="tacos">
                  <PhoneInput
                    value={value as string}
                    inputClass=""
                    country="us"
                    onChange={onChange}
                    onFocus={() => focusPhone(true)}
                    onBlur={() => focusPhone(false)}
                    disableDropdown={true}
                    countryCodeEditable={false}
                    inputProps={{
                      autoFocus: true,
                      name: "phoneNumber",
                      id: "phoneNumber",
                    }}
                  />
                  <label
                    htmlFor="phoneNumber"
                    className={`absolute ${
                      phoneFocus as string
                    } z-9 top-0 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red `}
                  >
                    Phone Number
                  </label>
                </div>
              )}
            />
            {errors.phoneNumber && (
              <p className="form-error mt-0">Phone # is Required</p>
            )}
          </div>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="notificationEmail"
                autoComplete="email"
                className="text-field peer"
                placeholder=" "
                {...register("notificationEmail", {
                  required: true,
                })}
              />
              <label htmlFor="notificationEmail" className="form-label">
                Notification Email
              </label>
            </div>
            {errors.notificationEmail && (
              <p className="form-error">Notification Email is Required</p>
            )}
          </div>
          <div className="mt-5 grid grid-cols-2 gap-2.5">
            <P6Button dataCy="back-button" text="Back" link="/finishprofile/step2" />
            <P6Button
              type="submit"
              text="Finish"
              loading={loading}
              color="bg-green"
              dataCy="finish-button"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
