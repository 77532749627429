import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import P6Button from "../../button";
import { P6Fulfillment } from "../../../../../src/types/fulfillment";
import { updateFulfillment } from "../../../functions";
import { P6ClientError } from "../../../utils/exeptions";

type P6FulfillmentTradeRequest = {
  id: string;
  eventId: string;
  receivedDate: Date;
  tradedDate: Date;
  optionSelectedDate: Date;
  fulfillmentType: string;
  complete: boolean;
  fulfilled: boolean;
  distribution?: string;
  distributionItem?: string;
};

export default function Receive({
  setOptionsOpen,
  fulfillment,
  creditsToReturn,
}: {
  setOptionsOpen: Dispatch<SetStateAction<boolean>>;
  fulfillment: P6Fulfillment;
  creditsToReturn: number;
}) {
  const { setError, getValues, handleSubmit } = useForm<FieldValues>();
  const [type, setType] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (fulfillment) {
      setType(
        fulfillment.fulfillmentType === "pickup" ? "Picked Up" : "Received",
      );
    }
  }, [fulfillment]);

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    try {
      setLoading(true);
      if (!fulfillment?.fulfillmentType) {
        const request = {
          id: fulfillment.id,
          eventId: fulfillment.eventId,
          receivedDate: new Date(),
          tradedDate: new Date(),
          optionSelectedDate: new Date(),
          fulfillmentType: "trade",
          complete: true,
          fulfilled: true,
        } as P6FulfillmentTradeRequest;

        if (fulfillment.distribution && fulfillment.distributionItem) {
          request.distribution = fulfillment.distribution;
          request.distributionItem = fulfillment.distributionItem;
        }
        await updateFulfillment(request);
        window.dataLayer.push({
          event: "confirm_trade_request",
          eventProps: {
            id: fulfillment.id,
            event_id: fulfillment.eventId,
          },
        } as unknown);
      } else {
        const request = {
          id: fulfillment.id,
          eventId: fulfillment.eventId,
          receivedDate: new Date(),
          complete: true,
          fulfilled: true,
        };
        await updateFulfillment(request);
        window.dataLayer.push({
          event: "confirm_receive_request",
          eventProps: {
            id: fulfillment.id,
            event_id: fulfillment.eventId,
          },
        } as unknown);
      }
      setLoading(true);
      setOptionsOpen(false);
    } catch (error) {
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  const onError = () => {
    if (!getValues("comment")) {
      setError("comment", { type: "custom", message: "custom message" });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="p-5">
          {fulfillment && !fulfillment.fulfillmentType ? (
            <>
              <div className="mb-2 text-center text-[25px] font-bold">
                Trade for{" "}
                {fulfillment.distribution
                  ? Math.ceil(creditsToReturn * 0.9)
                  : creditsToReturn}{" "}
                Credits
              </div>
              <h6 className="mb-8 text-center text-[15px] font-light">
                This action can not be undone or changed, please make sure you
                want to trade for credits before you submit.
              </h6>
            </>
          ) : (
            <div>
              <div className="text-center text-lg">Mark {type}</div>
              <div className="text-white text-opacity-50 text-center">
                Mark item as received
              </div>
            </div>
          )}
          <div className="flex flex-grow items-center justify-center text-center p-2.5 gap-2">
            <P6Button
              sm
              text="Cancel"
              color="bg-dark-grey"
              onClick={() => setOptionsOpen(false)}
            />
            <P6Button
              sm
              icon="complete_icon"
              iconLeft
              text={
                fulfillment && !fulfillment.fulfillmentType
                  ? "Trade"
                  : (type as string)
              }
              type="submit"
              color={"bg-green"}
              loading={loading}
            />
          </div>
        </div>
      </form>
    </>
  );
}
