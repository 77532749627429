export default function Loading() {
  return (
    <div>
      {[...Array<undefined>(10)].map((x: undefined, i: number) => (
        <div
          key={i}
          className="mt-2.5 h-[87px] flex-auto overflow-hidden rounded-lg bg-component text-white"
        >
          <div className="rounded-lg ">
            <div className="flex animate-pulse">
              <div className=" h-[87px] w-[87px] bg-shaddow"></div>
              <div className="flex-1 space-y-6 p-2">
                <div className="h-2 rounded bg-shaddow"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-2 h-2 rounded bg-shaddow"></div>
                    <div className="col-span-1 h-2 rounded bg-shaddow"></div>
                  </div>
                  <div className="mt-4 h-[16px] rounded-full bg-p6blue"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
