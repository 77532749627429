import { Link } from "react-router-dom";
import { P6Group } from "../../../../src/types/group";

export default function GroupCard({
  group,
  join,
  status,
  index,
}: {
  group: P6Group;
  join: boolean;
  status: string;
  index: number;
}) {
  return (
    <div className="p-2.5">
      <div className="relative h-[205px]  overflow-hidden rounded-lg bg-component">
        {group && (
          <img
            src={group?.cover}
            className={`cover over ${status === "pending" ? "opacity-30" : ""}`}
            alt="Group"
          />
        )}
        <div
          className={`h-[19]px absolute top-2.5 left-2.5 rounded-full ${
            status === "active" ? "bg-green" : "bg-p6blue"
          } px-4 text-sm capitalize text-white`}
        >
          {status}
        </div>
        <div
          className={`absolute bottom-0 flex h-[55px] w-full bg-black/80 ${
            status === "pending" ? "opacity-50" : ""
          }`}
        >
          <div className="flex flex-auto flex-col">
            <div className="ml-2.5 text-[22px] text-white line-clamp-1">
              {group?.name}
            </div>
            <div className="ml-2.5 text-xs leading-none text-grey line-clamp-1">
              {group?.businessInfo.description}
            </div>
          </div>
          <div className="flex items-center">
            {!join && status === "active" && (
              <Link to={`/group/${group?.id}/active`}>
                <div data-cy={group?.name === "No events group" ? `group-no-events-view` : `group-${index}-view`} className="mr-2.5 flex h-[30px] items-center rounded-full bg-p6blue px-4 text-[22px] text-white">
                  View
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
