import { P6Event } from "../../../src/types/event";

export const eventsRollUp = (eventsData: P6Event[]): P6Event[] => {
  // Filter the eventsData array to get only the top-level events
  const topLevelEvents = eventsData.filter(
    (event: P6Event) => !event.mainEventIds,
  );
  // For each top-level event, filter the eventsData array again to get its minis
  const updatedEvents = topLevelEvents.map((event) => {
    let minis = eventsData.filter(
      (mini: P6Event) =>
        mini.mainEventIds && mini.mainEventIds.map((x) => x).includes(event.id),
    );

    minis = minis.map((mini: P6Event) => {
      let micros = eventsData.filter(
        (micro) =>
          micro.mainEventIds &&
          micro.mainEventIds.map((x) => x).includes(mini.id),
      );
      micros = micros.map((micro: P6Event) => {
        let nanos = eventsData.filter(
          (nano) =>
            nano.mainEventIds &&
            nano.mainEventIds.map((x) => x).includes(micro.id),
        );
        nanos = nanos.map((nano: P6Event) => {
          let macros = eventsData.filter(
            (macro) =>
              macro.mainEventIds &&
              macro.mainEventIds.map((x) => x).includes(nano.id),
          );
          macros = macros.map((macro: P6Event) => {
            const picos = eventsData.filter(
              (pico) =>
                pico.mainEventIds &&
                pico.mainEventIds.map((x) => x).includes(macro.id),
            );
            return { ...macro, events: picos };
          });
          return { ...nano, events: macros };
        });
        return { ...micro, events: nanos };
      });
      return { ...mini, events: micros };
    });
    return { ...event, events: minis };
  });
  return updatedEvents;
};
