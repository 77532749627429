import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase/clientFirebaseApps";
import { doc, onSnapshot } from "firebase/firestore";
import { type RSRItem } from "../../../../src/types/distribution";
import { createDistributionItem } from "../../functions";
import { type P6ApiResponse } from "../../../../src/types";
import { P6ClientError } from "../../utils/exeptions";
import P6Button from "../../components/button";
import { type P6DistributionItem } from "../../../../src/types/distribution";
import { currency } from "../../utils/currency";
import { FieldValues, useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { imageUrl } from '../../utils/cdnUrl';


export default function DistributionRSRItemDetails() {
  const [item, setItem] = useState<RSRItem>();
  const [loading, setLoading] = useState(false);
  const [stateRestrictions, setStateRestrictions] = useState<string[]>();
  const { itemId } = useParams();
  const [markup, setMarkup] = useState<number>(0);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setMarkup(data.customMargin as number);
  };

  useEffect(() => {
    if (!item) {
      return;
    }
    const restrictions = [] as string[];
    if (item.AK === "Y") {
      restrictions.push("AK");
    }
    if (item.AL === "Y") {
      restrictions.push("AL");
    }
    if (item.AR === "Y") {
      restrictions.push("AR");
    }
    if (item.AZ === "Y") {
      restrictions.push("AZ");
    }
    ``;
    if (item.CA === "Y") {
      restrictions.push("CA");
    }
    if (item.CO === "Y") {
      restrictions.push("CO");
    }
    if (item.CT === "Y") {
      restrictions.push("CT");
    }
    if (item.DC === "Y") {
      restrictions.push("DC");
    }
    if (item.DE === "Y") {
      restrictions.push("DE");
    }
    if (item.FL === "Y") {
      restrictions.push("FL");
    }
    if (item.GA === "Y") {
      restrictions.push("GA");
    }
    if (item.HI === "Y") {
      restrictions.push("HI");
    }
    if (item.IA === "Y") {
      restrictions.push("IA");
    }
    if (item.ID === "Y") {
      restrictions.push("ID");
    }
    if (item.IL === "Y") {
      restrictions.push("IL");
    }
    if (item.IN === "Y") {
      restrictions.push("IN");
    }
    if (item.KS === "Y") {
      restrictions.push("KS");
    }
    if (item.KY === "Y") {
      restrictions.push("KY");
    }
    if (item.LA === "Y") {
      restrictions.push("LA");
    }
    if (item.MA === "Y") {
      restrictions.push("MA");
    }
    if (item.MD === "Y") {
      restrictions.push("MD");
    }
    if (item.ME === "Y") {
      restrictions.push("ME");
    }
    if (item.MI === "Y") {
      restrictions.push("MI");
    }
    if (item.MN === "Y") {
      restrictions.push("MN");
    }
    if (item.MO === "Y") {
      restrictions.push("MO");
    }
    if (item.MS === "Y") {
      restrictions.push("MS");
    }
    if (item.MT === "Y") {
      restrictions.push("MT");
    }
    if (item.NC === "Y") {
      restrictions.push("NC");
    }
    if (item.ND === "Y") {
      restrictions.push("ND");
    }
    if (item.NE === "Y") {
      restrictions.push("NE");
    }
    if (item.NH === "Y") {
      restrictions.push("NH");
    }
    if (item.NV === "Y") {
      restrictions.push("NV");
    }
    if (item.NY === "Y") {
      restrictions.push("NY");
    }
    if (item.OH === "Y") {
      restrictions.push("OH");
    }
    if (item.OK === "Y") {
      restrictions.push("OK");
    }
    if (item.OR === "Y") {
      restrictions.push("OR");
    }
    if (item.PA === "Y") {
      restrictions.push("PA");
    }
    if (item.RI === "Y") {
      restrictions.push("RI");
    }
    if (item.SC === "Y") {
      restrictions.push("SC");
    }
    if (item.SD === "Y") {
      restrictions.push("SD");
    }
    if (item.TN === "Y") {
      restrictions.push("TN");
    }
    if (item.TX === "Y") {
      restrictions.push("TX");
    }
    if (item.UT === "Y") {
      restrictions.push("UT");
    }
    if (item.VA === "Y") {
      restrictions.push("VA");
    }
    if (item.VT === "Y") {
      restrictions.push("VT");
    }
    if (item.WA === "Y") {
      restrictions.push("WA");
    }
    if (item.WI === "Y") {
      restrictions.push("WI");
    }
    if (item.WV === "Y") {
      restrictions.push("WV");
    }
    if (item.WY === "Y") restrictions.push("WY");

    setStateRestrictions(restrictions);
  }, [item]);

  const activate = async () => {
    setLoading(true);
    try {
      if (!item) {
        throw new P6ClientError("FunctionError", "Unable to create new event.");
      }

      const request = {
        msrp: item.RetailPrice,
        title: item.ProductDescription,
        available: item.InventoryQuantity,
        status: "available",
        images: [imageUrl(item.Imagename)],
        manufacturer: item.FullManufacturerName,
        mfgModel: item.ManufacturerPartNumber,
        model: item.Model,
        shippingWeight: item.ProductWeight,
        upc: item.UPCCode,
        cost: item.RSRPricing + markup,
        restrictions: stateRestrictions,
        source: {
          id: item.id,
          type: "rsr",
        },
      } as P6DistributionItem;

      await createDistributionItem(request)
        .then((result) => {
          const resultData = result.data as P6ApiResponse;
          // Read result of the Cloud Function.
          if (resultData.success) {
            setLoading(false);
          } else {
            throw new P6ClientError(
              "FunctionError",
              "Unable to create new event."
            );
          }
        })
        .catch((error: { code: string; message: string; details: string }) => {
          setLoading(false);
          return Promise.reject(
            new P6ClientError("UnknownError", error.message)
          );
        });
    } catch (error) {
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  // Get the event data
  useEffect(() => {
    if (itemId) {
      const unsubscribe = onSnapshot(
        doc(db, "distribution/_distributors/rsr", itemId),
        (doc) => {
          const currentItem = { id: doc.id, ...doc.data() } as RSRItem;
          if (currentItem) {
            setMarkup(currentItem.RSRPricing * 0.1);
            setItem(currentItem);
          }
        }
      );
      return () => {
        unsubscribe();
      };
    }
  }, [itemId]);

  if (!item) {
    return <div>Loading...</div>;
  }
  return (
    <div className="text-white pt-3">
      <div className="flex-auto text-grey">upc: {item.UPCCode}</div>
      <div className="mb-3">
        <div>{item.ProductDescription}</div>
      </div>

      <div className="flex space-x-2">
        <div className="text-center">
          <div className="text-[10px] text-grey">Dealer Price</div>
          <div className="bg-dark-grey rounded-full px-2">
            ${item.RSRPricing}
          </div>
        </div>
        <div className="text-center">
          <div className="text-[10px] text-grey">Retail Price</div>
          <div className="bg-dark-grey rounded-full px-2">
            ${item.RetailPrice}
          </div>
        </div>
        <div className="flex-auto" />
        <div className="text-center">
          <div className="text-[10px] text-grey">Weight</div>
          <div className="bg-dark-grey rounded-full px-2">
            {item.ProductWeight}
          </div>
        </div>
        <div className="text-center">
          <div className="text-[10px] text-grey">Quantity</div>
          <div className="bg-green rounded-full px-2">
            {item.InventoryQuantity}
          </div>
        </div>
      </div>
      
      <div className="mt-2 mb-1 border-b-[1px]">Markup</div>
      <div className="bg-component p-2 rounded-lg">
        <div className="flex mb-3">
          <div className="text-center">
            <div className="text-sm">Markup</div>
            <div className="bg-green rounded-full px-2 ">{currency(markup)}</div>
          </div>
          <div className="flex-auto" />
          <div className="text-center">
            <div className="text-sm">Admin Cost</div>
            <div className="bg-green rounded-full px-2">{currency(item.RSRPricing + markup)}</div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center space-x-2">
            <div>
              <div className="">
                <div className="relative">
                  <input
                    type="number"
                    id="customMargin"
                    autoComplete=""
                    className="text-field peer"
                    placeholder=" "
                    {...register("customMargin", {
                      required: true,
                      valueAsNumber: true,
                      min: item.RSRPricing * 0.75 - item.RSRPricing,
                    })}
                  />
                  <label htmlFor="" className="form-label"></label>
                </div>
                {errors.customMargin && (
                  <p className="form-error mt-[1px]">
                    {(errors.customMargin.type === "required" &&
                      "Event Value is Required") ||
                      (errors.customMargin.type === "min" &&
                        `Event Value must be greater than, ${
                          item.RSRPricing * 0.75 - item.RSRPricing
                        }`)}
                  </p>
                )}
              </div>
            </div>
            <div>
              <P6Button sm text="Submit" type="submit" />
            </div>
            <div>
              <P6Button
                sm
                text="Cancel"
                type="button"
                onClick={() => reset()}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="flex items-center mb-3 justify-end mt-2">
        {item.status === "available" && (
          <div>
            <P6Button loading={loading} sm text="Activate" onClick={activate} />
          </div>
        )}
      </div>

      <div className="mb-2">
        <div className="text-[10px] text-grey">Manufacture</div>
        <div className="text-sm ml-2 leading-3 line-clamp-1">
          {item.FullManufacturerName}
        </div>
      </div>
      <div className="mb-2">
        <div className="text-[10px] text-grey">Part#</div>
        <div className="text-sm ml-2 leading-3 line-clamp-1">
          {item.ManufacturerPartNumber}
        </div>
      </div>
      <div className="mb-2">
        <div className="text-[10px] text-grey">Model</div>
        <div className="text-sm ml-2 leading-3 line-clamp-1">{item.Model}</div>
      </div>
      <div className="">
        {stateRestrictions && stateRestrictions.length > 0 && (
          <div className="items-center mb-3">
            <div className="text-[10px] text-grey mb-1">Restrictions: </div>
            <div className="ml-2 flex space-x-2">
              {stateRestrictions.map((item, index) => (
                <span
                  key={index}
                  className="inline-flex items-center gap-x-1.5 rounded-full bg-component border border-dark-grey px-1.5 py-0.5 text-xs font-medium text-red-700"
                >
                  <svg
                    className="h-1.5 w-1.5 fill-red"
                    viewBox="0 0 6 6"
                    aria-hidden="true"
                  >
                    <circle cx={3} cy={3} r={3} />
                  </svg>
                  {item}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="text-[10px] text-grey">Product Image</div>
      <div>
        <img
          src={imageUrl(item.Imagename)}
          alt="title"
          className="mr-2.5 flex-shrink-0 rounded-lg object-cover"
        />
      </div>
    </div>
  );
}
