import { Dispatch, SetStateAction } from "react";
import P6Button from "../../button";
import { useParams } from "react-router-dom";

export default function Redirect({
  setOptionsOpen,
}: {
  setOptionsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  
  const { eventId } = useParams();

  const redirectToApp = () => {
    if (!eventId) {
      window.location.href = "https://apps.apple.com/us/app/pf6/id6473810833";
      return;
    }
    const appURL = `pf6://open-event?name=${eventId}`;
    // const url = window.location.href;
    try {
      window.location.href = appURL;
    } catch (error) {
      window.location.href = "https://apps.apple.com/us/app/pf6/id6473810833";
    }
    // const userAgent = navigator.userAgent;

    // const isSafari = userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Chrome") === -1;
    // if (isSafari) {
    //   setTimeout(() => {
    //     if (window.location.href === url) {
    //       window.location.href = "https://apps.apple.com/us/app/pf6/id6473810833";
    //     }
    //   }, 2000);
    // }
  };

  return (
    <>
        <div className="p-5">
        <div className="mb-2 text-center text-[25px] font-bold">
          Do you want to check the mobile APP?
        </div>
          <div className="flex flex-grow items-center justify-center text-center p-2.5 gap-2">
            <P6Button
              sm
              text="Stay on Web"
              color="bg-dark-grey"
              onClick={() => setOptionsOpen(false)}
            />
            <P6Button
              sm
              icon="complete_icon"
              iconLeft
              text="Check the APP"
              color={"bg-green"}
              onClick={() => redirectToApp()} 
            />
          </div>
        </div>
    </>
  );
}
