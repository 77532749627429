import { InputMask } from "primereact/inputmask";
import { useState, useRef, useEffect } from "react";
import { type P6UserProfile } from "../../../../../src/types/profile";
import { type authnetAddress } from "../../../../../src/types/payment/provider/authnet";
import { clientAuth } from "../../../firebase/clientFirebaseApps";
import { onAuthStateChanged } from "firebase/auth";
import { db } from "../../../firebase/clientFirebaseApps";
import { doc, getDoc } from "firebase/firestore";
import { Dispatch, SetStateAction } from "react";
import { useAcceptJs } from "react-acceptjs";
import { authData } from "../../../firebase/clientFirebaseApps";
import P6Button from "../../../components/button";
import { createauthnetpaymentmethod } from "../../../functions";
import { P6ClientError } from "../../../utils/exeptions";
import { P6ApiResponse, P6ApiError } from "../../../../../src/types";

export default function AddPaymentMethod({
  address,
  addressOpen,
  setAddressOpen,
  setAddOpen,
  hasCard,
}: {
  address: authnetAddress | undefined;
  addressOpen: boolean;
  setAddressOpen: Dispatch<SetStateAction<boolean>>;
  setAddOpen: Dispatch<SetStateAction<boolean>>;
  hasCard: boolean;
}) {
  const [userId, setUserId] = useState<string>();
  const [user, setUser] = useState<P6UserProfile | null>(null);
  const [mask, setMask] = useState("9999 9999 9999 9999");
  const [ccvMask, setCcvMask] = useState("999");
  const [loading, setloading] = useState(false);
  const [ccImage, setCcImage] = useState<string>("/icons/genericcc_icon.svg");
  const [cardNumber, setCardNumber] = useState<string | undefined>();
  const [cardType, setCardType] = useState<string>();
  const [exp, setExp] = useState<string | undefined>();
  const [cvc, setCvc] = useState<string | undefined>();
  const expRef = useRef<InputMask>(null);
  const cvcRef = useRef<InputMask>(null);
  const auth = clientAuth;
  const [error, setError] = useState<string | undefined>();
  const { dispatchData } = useAcceptJs({
    environment: import.meta.env.MODE === "development" ? "SANDBOX" : "PRODUCTION",
    authData,
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
  }, [auth]);

  useEffect(() => {
    (async () => {
      if (userId) {
        const docRef = doc(db, "profiles", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          setUser({ ...(userData as P6UserProfile), userId: userId });
        }
      }
    })().catch((error) => {
      throw new P6ClientError("UnknownError", error as string);
    });
  }, [userId, setUser]);

  const cardFinish = () => {
    if (expRef && expRef.current) {
      expRef.current.focus();
    }
  };
  const expFinish = () => {
    if (cvcRef && cvcRef.current) {
      cvcRef.current.focus();
    }
  };
  const addCard = async () => {
    setloading(true);
    if (loading) return;
    try {
      if (!cardNumber) {
        throw new P6ClientError("PaymentError", "Card number is required");
      }
      if (!exp) {
        throw new P6ClientError("PaymentError", "Expiration date is required");
      }
      if (!cvc) {
        throw new P6ClientError("PaymentError", "CVC is required");
      }

      let addressObject = {};
      const cardData = {
        cardNumber: cardNumber?.replace(/\s/g, ""),
        month: exp?.split(" / ")[0],
        year: exp?.split(" / ")[1],
        cardCode: cvc,
      };

      if (address) {
        address.phoneNumber = user?.phoneNumber;
        addressObject = address;
      } else if (user && user.fullName) {
        const defaultAddress = {
          firstName: user.fullName.substring(0, user.fullName.indexOf(" ")),
          lastName: user.fullName.substring(user.fullName.indexOf(" ") + 1),
          phoneNumber: user.phoneNumber,
          ...user.address,
        };
        delete defaultAddress.address2;
        addressObject = defaultAddress as authnetAddress;
      }
      if (cardData && addressObject) {
        const response = await dispatchData({ cardData });
        const tacoSause = {
          opaqueData: response.opaqueData,
          userBillTo: addressObject,
          amount: 1,
          cardExpDate: cardData.month + " / " + cardData.year,
          defaultCard: !hasCard,
          type: cardType,
          last4: cardNumber ? "XXXX" + cardNumber.slice(-4) : "",
        };
        const result = await createauthnetpaymentmethod(tacoSause);
        const resultData = result.data as P6ApiResponse;
        if (resultData.success) {
          setloading(false);
          setAddOpen(false);
        } else {
          throw new P6ClientError(
            "PaymentError",
            "Unable to add payment method.",
          );
        }
      }
    } catch (error) {
      setloading(false);
      const errorData = error as P6ApiError;
      setError(`Unable to add payment method. ${errorData.message}`);
      throw new P6ClientError("PaymentError", "Unable to add payment method.");
    }
  };

  useEffect(() => {
    //const matches = String(cardNumber).match(/\d/)
    if (cardNumber) {
      const first = cardNumber[0];
      if (first === "3") {
        setMask("9999 999999 99999");
        setCcImage("/icons/amex_icon.svg");
        setCardType("amex");
        setCcvMask("9999");
      } else if (first === "4") {
        setMask("9999 9999 9999 9999");
        setCcImage("/icons/visa_icon.svg");
        setCardType("visa");
        setCcvMask("999");
      } else if (first === "5") {
        setMask("9999 9999 9999 9999");
        setCcImage("/icons/mastercard_icon.svg");
        setCardType("mastercard");
        setCcvMask("999");
      } else if (first === "6") {
        setMask("9999 9999 9999 9999");
        setCcImage("/icons/discover_icon.svg");
        setCardType("discover");
        setCcvMask("999");
      }
    } else {
      setCcImage("/icons/genericcc_icon.svg");
    }
  }, [cardNumber]);

  return (
    <div>
      <div className="mt-2.5 flex w-full p-2.5 text-white">
        <div className="flex w-full rounded-full border-[1px] border-grey p-2">
          <img className="ml-2" width="25" src={ccImage} alt="Card Logo" />
          <div className="flex-auto">
            <InputMask
              className="cc-field w-full bg-component"
              //autocomplete="cc-number"
              slotChar=""
              id="cardNumber"
              autoComplete="cc-number"
              placeholder="Card Number"
              mask={mask}
              value={cardNumber}
              onComplete={() => cardFinish()}
              onChange={(e) => setCardNumber(e.value as string)}
            />
          </div>
          <div>
            <InputMask
              className="cc-field w-[60px] bg-component text-center"
              slotChar="MM / YY"
              autoComplete="cc-exp"
              id="exp"
              mask="99 / 99"
              value={exp}
              placeholder="MM / YY"
              onComplete={() => expFinish()}
              type="text"
              onChange={(e) => setExp(e.value as string)}
              ref={expRef}
            />
          </div>
          <div>
            <InputMask
              className="cc-field w-[60px] bg-component"
              slotChar=""
              autoComplete="cc-csc"
              id="cvc"
              placeholder="CVC"
              mask={ccvMask}
              value={cvc}
              onChange={(e) => setCvc(e.value as string)}
              ref={cvcRef}
            />
          </div>
        </div>
      </div>
      <div className="text-red text-center mb-2.5">{error}</div>
      <div className="mb-2.5 flex px-4 text-[12px] text-grey">
        {!address && user && (
          <div>
            <div>{user.fullName}</div>
            <div>{user.address?.address}</div>
            <div>{user.address?.address2}</div>
            <div>
              {user.address?.city}, {user.address?.state}, {user.address?.zip}
            </div>
          </div>
        )}
        {address && (
          <div>
            <div>
              {address?.firstName} {address?.lastName}
            </div>
            <div>{address?.address}</div>
            <div>
              {address?.city}, {address?.state}, {address?.zip}
            </div>
          </div>
        )}
        <div className="ml-3 flex-auto">
          <span
            className="cursor-pointer text-p6blue"
            onClick={() => setAddressOpen(!addressOpen)}
          >
            change
          </span>
        </div>
        {/* <div
          onClick={() => addCard()}
          className="flex h-7 items-center rounded-full bg-p6blue px-5 text-[20px] text-white cursor-pointer hover:bg-grey hover:text-black"
        >
          Add
        </div> */}
        <div
          className={`h-[20px] ${loading ? "w-[100px]" : "w-[80px]"} relative`}
        >
          <P6Button
            div
            text="Add"
            onClick={() => addCard()}
            textColor="text-white"
            loading={loading}
            justify={loading ? "end" : "center"}
          />
        </div>
      </div>
    </div>
  );
}
