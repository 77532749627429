import { useRef, useEffect } from "react";
import * as d3 from "d3";
import { Arc, DefaultArcObject } from "d3-shape";
import { P6Slice } from "../../../../src/types/event";

export default function Wheel({
  seatList,
  random,
  autoRoll,
  wheelSpun,
  spinIt,
}: {
  seatList: P6Slice[] | undefined | null;
  random: number;
  autoRoll: boolean;
  wheelSpun: boolean;
  spinIt: boolean;
}) {
  const d3Container = useRef(null);
  const wheelFlair = useRef<
    string | number | undefined | ReturnType<typeof setTimeout>
  >();
  const flairTimeout = useRef<
    string | number | undefined | ReturnType<typeof setTimeout>
  >();
  const spunTimeout = useRef<
    string | number | undefined | ReturnType<typeof setTimeout>
  >();

  // Start wheel logic
  useEffect(() => {
    if (seatList && seatList.length > 0 && d3Container.current) {
      const colors = ["#323636", "#000944", "#ffffff"];
      const fullRotation = 360;
      const offSet = fullRotation / seatList.length;
      const randomNumber = random;
      let initialRotation = 45;
      const pickedNumber =
        randomNumber * -offSet + initialRotation + offSet / 2;

      // Find out if the wheel has spun and set it to the correct rotation
      if (wheelSpun) {
        initialRotation = randomNumber * -offSet + 45 + offSet / 2;
      }

      //const height = Math.min(600, 400);
      const pie = d3
        .pie()
        .sort(null)
        .value(() => {
          return 1;
        })
        .padAngle(0.01);

      // Set up the wheel
      const wheel = d3
        .select(d3Container.current)
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", "-110 -110 220 220");
      //.attr("viewBox", "0 0 300 300");

      const arc1: Arc<any, DefaultArcObject> = d3
        .arc()
        .innerRadius(105)
        .outerRadius(110)
        .padRadius(80);

      // background arc
      const slices1 = pie(
        Array.from(
          { length: 54 },
          () =>
            ({ number: 1, color: "#000944" }) as unknown as
              | number
              | { valueOf(): number },
        ),
      );

      const g1 = wheel
        .append("g")
        .attr("stroke", "#000000")
        .attr("class", "outer-slices");
      g1.selectAll("path")
        .data(slices1)
        .enter()
        .append("path")
        .attr("class", "outer-slice")
        .attr("d", arc1 as any)
        .attr("fill", () => "#000944");

      const arc2 = d3.arc().innerRadius(10).outerRadius(105);
      // List of seats.
      const slices2 = pie(seatList as any);

      const g2 = wheel.append("g");
      g2.attr("class", "inner-slices").attr(
        "transform",
        `rotate(${initialRotation})`,
      );
      g2.selectAll("path")
        .data(slices2)
        .enter()
        .append("g")
        .attr("stroke", "#121212")
        .attr("class", "inner-slice")
        .style("stroke-width", "1")
        .insert("path")
        .attr("d", arc2 as any)
        .attr("fill", (d) => (d as any).data.color)
        .on("mouseover", function (event, d: any) {
          const currentNode = d3.select(this);
          // setWheelSpun(true);
          currentNode
            .transition()
            .duration(200)
            .ease(d3.easeCubicOut)
            .attr(
              "d",
              arc2
                .innerRadius(d.innerRadius - 2)
                .outerRadius(d.outerRadius + 2.5) as any,
            )
            .transition()
            .duration(200)
            .ease(d3.easeCubicOut)
            .attr(
              "d",
              arc2.innerRadius(d.innerRadius).outerRadius(d.outerRadius) as any,
            )
            .transition()
            .duration(200)
            .ease(d3.easeCubicOut)
            .attr(
              "d",
              arc2
                .innerRadius(d.innerRadius - 2)
                .outerRadius(d.outerRadius + 2.5) as any,
            )
            .transition()
            .duration(200)
            .ease(d3.easeCubicOut)
            .attr(
              "d",
              arc2.innerRadius(d.innerRadius).outerRadius(d.outerRadius) as any,
            )
            .transition()
            .duration(200)
            .ease(d3.easeCubicOut)
            .attr(
              "d",
              arc2
                .innerRadius(d.innerRadius - 2)
                .outerRadius(d.outerRadius + 2.5) as any,
            )
            .transition()
            .duration(200)
            .ease(d3.easeCubicOut)
            .attr(
              "d",
              arc2.innerRadius(d.innerRadius).outerRadius(d.outerRadius) as any,
            );

          // Add additional logic here if needed
        });

      // Set up the slice and add name and number
      g2.selectAll(".inner-slice")
        .data(slices2)
        .insert("text")
        .attr("transform", (d) => {
          (d as any).innerRadius = 10;
          (d as any).outerRadius = 110;
          (d as any).angle = (d.startAngle + d.endAngle) / 2;
          return `rotate(${
            ((d as any).angle * 180) / Math.PI - 88
          }) translate(${(d as any).outerRadius - 7})`;
        })
        .style("stroke-width", "0")
        .attr("text-anchor", "end")
        .text((d) => {
          const name = (d as any).data.displayName;
          return name + " " + (d as any).data.number;
        })
        .style("font-family", "Helvetica, Arial, sans-serif")
        .style("font-size", "8")
        .style("font-weight", "100")
        .style("user-select", "none")
        //.style("stroke", (d) => d.data.textColor)
        .style("fill", (d) => (d as any).data.textColor);

      // g2.selectAll('.inner-slice')
      //     .append('circle')
      //       .attr('r', 5)
      //       .attr('fill', 'black')
      // .centroid

      const g3 = wheel.append("g");
      g3.selectAll("circle")
        .data([0])
        .enter()
        .append("circle")
        .attr("r", 120)
        .attr("fill", "transparent");

      // Add the P6 logo
      g3.append("svg:image")
        .attr("x", -8)
        .attr("y", -8)
        .attr("width", 16)
        .attr("height", 16)
        .attr("xlink:href", "/icons/p6_small_logo.svg");

      // Set up the flar animation
      const randomFlash = (
        target: any,
        colors: string[],
        duration: number,
        iterations: number,
        initialTime: number,
        iteration = 0,
      ) => {
        const endTime = initialTime + duration - 200;
        const nextIteration = iteration + 1;

        wheelFlair.current = setTimeout(
          () => {
            target
              .selectAll("path")
              .transition()
              .duration(duration / iterations)
              .ease(d3.easeCubicOut)
              .attr(
                "fill",
                () => colors[Math.floor(Math.random() * colors.length)],
              );
            // .attrTween('fill', (d, i, nodes) => (
            //   d3.interpolate(d3.select(nodes[i]).attr('color'), colors[Math.floor(Math.random() * colors.length)])
            // ))

            if (Date.now() < endTime) {
              randomFlash(
                target,
                colors,
                duration,
                iterations,
                initialTime,
                nextIteration,
              );
            } else {
              g2.selectAll(".inner-slice path")
                .transition()
                .duration(200)
                .ease(d3.easeCubicOut)
                .attr("d", (arc2 as any).innerRadius(8).outerRadius(107.5))
                .transition()
                .duration(200)
                .ease(d3.easeCubicOut)
                .attr("d", (arc2 as any).innerRadius(10).outerRadius(105))
                .transition()
                .duration(200)
                .ease(d3.easeCubicOut)
                .attr("d", (arc2 as any).innerRadius(8).outerRadius(107.5))
                .transition()
                .duration(200)
                .ease(d3.easeCubicOut)
                .attr("d", (arc2 as any).innerRadius(10).outerRadius(105))
                .transition()
                .duration(200)
                .ease(d3.easeCubicOut)
                .attr("d", (arc2 as any).innerRadius(8).outerRadius(107.5))
                .transition()
                .duration(200)
                .ease(d3.easeCubicOut)
                .attr("d", (arc2 as any).innerRadius(10).outerRadius(105));
            }
          },
          ((duration / iterations) * iteration) / 10,
        );
      };

      // Start the wheel spin
      if (spinIt && !wheelSpun) {
        g2.transition()
          .duration(10000)
          .ease(d3.easeCubicOut)
          .attrTween("transform", () =>
            d3.interpolateString(
              g2.attr("transform"),
              `rotate(${fullRotation * 4 + pickedNumber})`,
            ),
          );
        const duration = 10000;
        const iterations = 300;
        const initialTime = Date.now();

        flairTimeout.current = setTimeout(
          () => randomFlash(g1, colors, duration, iterations, initialTime),
          100,
        );
      }

      // Set up the arrow
      const arrowArc = d3
        .arc()
        .innerRadius(0)
        .outerRadius(25)
        .startAngle(20 * (Math.PI / 180)) //converting from degs to radians
        .endAngle(60 * (Math.PI / 180));

      const arrow = wheel.append("g");
      arrow
        .selectAll("path")
        .data([0])
        .enter()
        .append("path")
        .attr("width", 50)
        .attr("height", 50)
        .attr("d", arrowArc as any)
        .attr("fill", "#8AAD3B")
        .attr("transform", "translate(73, -73)");
    }

    // Clean up
    return () => {
      clearTimeout(wheelFlair.current);
      clearTimeout(flairTimeout.current);
      clearTimeout(spunTimeout.current);
    };
  }, [seatList, random, autoRoll, spinIt]);

  if (!seatList || seatList.length === 0) {
    return null;
  }

  return (
    <div className=" aspect-square relative z-10 flex justify-center">
      <svg
        id="chart"
        className="p-2.5 flex flex-auto justify-center relative d3-component"
        ref={d3Container}
      />
    </div>
  );
}
