import { useNavigate } from "react-router-dom";
import {
  P6GroupBranding,
  P6GroupBusinessInfo,
  P6GroupPersonalInfo,
} from "../../../../../../src/types/group";
import { useState, Dispatch, SetStateAction } from "react";
import { createGroup } from "../../../../functions";
import P6Button from "../../../../components/button";
import { P6ClientError } from "../../../../utils/exeptions";
import { P6ApiResponse } from "../../../../../../src/types";

export default function Confirm({
  setStep,
  branding,
  businessInfo,
  personalInfo,
}: {
  setStep: Dispatch<SetStateAction<number>>;
  branding: P6GroupBranding;
  businessInfo: P6GroupBusinessInfo;
  personalInfo: P6GroupPersonalInfo;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  async function addGroup() {
    setLoading(true);
    try {
      const request = {
        name: branding.name,
        category: branding.category,
        logo: branding.logo,
        cover: branding.cover,
        businessInfo,
        personalInfo,
      };
      const result = await createGroup(request);
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        window.dataLayer.push({
          event: "create_group",
          eventProps: {
            group_id: resultData.data,
            group_name: branding.name
          },
        } as unknown);
        navigate(`/groups`);
      } else {
        throw new P6ClientError("FunctionError", "Unable to create group.");
      }
    } catch (error) {
      setLoading(false);
      throw new P6ClientError("UnknownError", error as string);
    }
  }

  return (
    <div className="mt-8 p-2.5">
      <div className="mb-2.5 rounded-lg bg-component p-2.5">
        <span
          onClick={() => setStep(1)}
          className="float-right text-p6blue cursor-pointer"
          id="branding"
          data-cy="editBranding"
        >
          Edit
        </span>
        <div className="text-grey">Branding</div>
        <div className="text-[34px]">{branding.name}</div>
        <div className="text-grey">Category: {branding.category}</div>
      </div>
      <div className="mb-2.5 rounded-lg bg-component p-2.5">
        <span
          onClick={() => setStep(2)}
          className="float-right text-p6blue cursor-pointer"
          id="business"
          data-cy="editBusiness"
        >
          Edit
        </span>
        <div className="text-grey">Business Info</div>
        <div className="pt-2.5 text-sm text-grey">Description</div>
        <div className="">{businessInfo.description}</div>
        <div className="grid grid-cols-2">
          <div>
            <div className="pt-2.5 text-sm text-grey">Address</div>
            <div className="">{businessInfo.address}</div>
            <div className="">{businessInfo.address2}</div>
            <div className="">
              {businessInfo.city}, {businessInfo.state} {businessInfo.zip}
            </div>
          </div>
          <div className="text-right">
            <div className="pt-2.5 text-sm text-grey">Contact</div>
            <div className="">{businessInfo.businessEmail}</div>
            <div className="">{businessInfo.businessPhone}</div>
          </div>
        </div>
      </div>
      <div className="mb-2.5 rounded-lg bg-component p-2.5">
        <span
          onClick={() => setStep(3)}
          className="float-right text-p6blue cursor-pointer"
          id="personal"
          data-cy="editPersonal"
        >
          Edit
        </span>
        <div className="text-grey">Personal Info</div>
        <div className="">
          {personalInfo.firstName} {personalInfo.lastName}
        </div>
        <div className="grid grid-cols-2">
          <div>
            <div className="pt-2.5 text-sm text-grey">Address</div>
            <div className="">{personalInfo.address}</div>
            <div className="">{personalInfo.address2}</div>
            <div className="">
              {personalInfo.city}, {personalInfo.state} {personalInfo.zip}
            </div>
          </div>
          <div className="text-right">
            <div className="pt-2.5 text-sm text-grey">Contact</div>
            <div className="">{personalInfo.personalEmail}</div>
            <div className="">{personalInfo.personalPhone}</div>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <P6Button
          div
          onClick={() => addGroup()}
          text="Create Group"
          color="bg-green"
          loading={loading}
          dataCy="finishCreateGroup"
        />
      </div>
    </div>
  );
}
