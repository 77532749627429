import { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";

export default function ItemImages({ images }: { images: string[] }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <div className="mt-2.5 max-w-xl flex overflow-x-auto pl-2.5">
      {images.map((src, index) => (
        <img
          src={src}
          onClick={() => openImageViewer(index)}
          width={200}
          className="mr-2.5 h-[111px] flex-shrink-0 rounded-lg object-cover"
          key={index}
          style={{ margin: "2px" }}
          alt=""
        />
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </div>
  );
}
