import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState, Dispatch, SetStateAction } from "react";
import CarrierSelect from "../../form_fields/select_carrier";
import { updateFulfillment } from "../../../functions";
import P6Button from "../../button";
import { db } from "../../../firebase/clientFirebaseApps";
import { doc, onSnapshot } from "firebase/firestore";
import { P6Fulfillment } from "../../../../../src/types/fulfillment";
import { P6Event } from "../../../../../src/types/event";
import { P6UserProfile } from "../../../../../src/types/profile";
import { P6ClientError } from "../../../utils/exeptions";

export default function Shipment({
  setOptionsOpen,
  fulfillmentId,
  eventId,
}: {
  setOptionsOpen: Dispatch<SetStateAction<boolean>>;
  fulfillmentId: string;
  eventId: string;
}) {
  const [other, setOther] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fulfillment, setFulfillment] = useState<P6Fulfillment>();
  const [event, setEvent] = useState<P6Event>();
  const [user, setUser] = useState<P6UserProfile>();

  const {
    register,
    setError,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<FieldValues>();

  useEffect(() => {
    if (fulfillmentId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const unsub = onSnapshot(
        doc(db, "fulfillments", fulfillmentId),
        (doc) => {
          setFulfillment({ id: doc.id, ...doc.data() } as P6Fulfillment);
        },
      );
      return () => unsub();
    }
  }, [fulfillmentId]);

  useEffect(() => {
    if (eventId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const unsub = onSnapshot(doc(db, "events", eventId), (doc) => {
        setEvent({ id: doc.id, ...doc.data() } as P6Event);
      });
      return () => unsub();
    }
  }, [eventId]);

  useEffect(() => {
    if (event && event.postEvent && event.postEvent.winnerId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const unsub = onSnapshot(
        doc(db, "profiles", event.postEvent.winnerId),
        (doc) => {
          setUser(doc.data() as P6UserProfile);
        },
      );
      return () => unsub();
    }
  }, [event]);

  useEffect(() => {
    if (fulfillmentId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const unsub = onSnapshot(
        doc(db, "fulfillments", fulfillmentId),
        (doc) => {
          setFulfillment({ id: doc.id, ...doc.data() } as P6Fulfillment);
        },
      );
      return () => unsub();
    }
  }, [fulfillmentId]);

  const onSubmit: SubmitHandler<FieldValues> = async (params) => {
    if (!params.carrier) {
      setError("carrier", { type: "custom", message: "custom message" });
    } else {
      params.carrier = other
        ? (params.other as string)
        : (params.carrier as string);
      try {
        setLoading(true);
        const request = {
          id: fulfillmentId,
          eventId: eventId,
          carrier: params.carrier as string,
          trackingNumber: params.trackingNumber as string,
          shipDate: new Date(),
        };
        await updateFulfillment(request);
        window.dataLayer.push({
          event: "confirm_shipping_request",
          eventProps: {
            id: fulfillmentId,
            event_id: eventId,
            carrier: params.carrier as string,
            tracking_number: params.trackingNumber as string,
            shipDate: new Date(),
          },
        } as unknown);
        setLoading(false);
        setOptionsOpen(false);
      } catch (error) {
        throw new P6ClientError("UnknownError", error as string);
      }
    }
  };

  const onError = () => {
    if (!getValues("carrier")) {
      setError("carrier", { type: "custom", message: "custom message" });
    }
  };

  if (!fulfillment) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="p-2">
          <div className="text-center text-xl mb-2">Shipment Details</div>
          <div className="mb-8 flex">
            <div className="w-[80px] flex items-center">
              <img
                src="/icons/address_icon.svg"
                alt="shipment icon"
                className="w-10 h-10 m-auto"
              />
            </div>
            <div>
              <div className="text-lg font-bold">{user && user.fullName}</div>
              <div className="text-sm">
                <div>
                  {fulfillment &&
                    fulfillment.address &&
                    fulfillment.address.address}
                </div>
                {fulfillment &&
                  fulfillment.address &&
                  fulfillment.address.address2 && (
                    <div>{fulfillment.address.address2}</div>
                  )}
                <div className="flex">
                  <div className="mr-1.5">
                    {fulfillment &&
                      fulfillment.address &&
                      fulfillment.address.city}
                    ,
                  </div>
                  <div className="mr-1.5">
                    {fulfillment &&
                      fulfillment.address &&
                      fulfillment.address.state}
                  </div>
                  <div>
                    {fulfillment &&
                      fulfillment.address &&
                      fulfillment.address.zip}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="relative z-10" data-cy="carrier-select">
              <CarrierSelect
                errors={errors}
                setValue={setValue}
                clearErrors={clearErrors}
                defaultValue={getValues("carrier") as string}
                setOther={setOther}
              />
            </div>
            <div className="mb-2.5">
              <div className="relative mb-2.5">
                <input
                  data-cy="tracking-number"
                  type="text"
                  id="trackingNumber"
                  autoComplete="trackingNumber"
                  className="text-field peer"
                  placeholder=" "
                  {...register("trackingNumber", {
                    required: true,
                  })}
                />
                <label htmlFor="trackingNumber" className="form-label z-0">
                  Tracking Number
                </label>
              </div>
              {errors.trackingNumber && (
                <p className="form-error">Tracking Number is Required</p>
              )}
            </div>
          </div>
          {other && (
            <div className="mb-2.5">
              <div className="relative mb-2.5 z-0">
                <input
                  data-cy="other-carrier"
                  type="text"
                  id="other"
                  autoComplete="other"
                  className="text-field peer"
                  placeholder=" "
                  {...register("other", {
                    required: true,
                  })}
                />
                <label htmlFor="other" className="form-label">
                  Other Carrier
                </label>
              </div>
              {errors.other && (
                <p className="form-error"> Other Carrier is Required</p>
              )}
            </div>
          )}
          <div className="flex flex-grow items-center justify-center text-center p-2.5">
            <div className="flex w-full gap-x-3">
              <P6Button
                sm
                text="Cancel"
                type="button"
                color={"bg-dark-grey"}
                onClick={() => setOptionsOpen(false)}
              />
              <P6Button
                sm
                text="Send"
                type="submit"
                color={"bg-p6blue"}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
