import { Link } from "react-router-dom";
import { type P6DistributionItem } from "../../../../../src/types/distribution";

export default function ListItem({ item }: { item: P6DistributionItem }) {
  return (
    <Link to={`/distribution/item/${item.id}`} className="text-white">
      <div className="mb-2 flex bg-component rounded-md overflow-hidden items-center">
        <img
          src={`${item.images[0]}`}
          className="w-[35px] h-[35px] object-cover"
          alt="title"
        />
        <div className="flex-auto ml-2 py-1">
          <div className="text-[10px] text-grey">sku: {item.upc}</div>
          <div className="text-sm leading-3 line-clamp-1">{item.title}</div>
        </div>
        <div className="mr-3 bg-green px-2 rounded-full">{item.available}</div>
      </div>
    </Link>
  );
}
