import { Link } from "react-router-dom";
import { percent, isFull } from "../../utils/functions";

export default function EventMinis({
  micro,
  state,
  id,
  seatPrice,
  seats,
  available,
  title,
}: {
  micro: boolean;
  state: string;
  id: string;
  seatPrice: number;
  seats: number;
  available: number;
  title: string;
}) {
  const isMicro = micro ? "ml-7" : "ml-2.5";
  const stateStyle = (stateStyle: string) => {
    return stateStyle === "closed" ? "flex" : "hidden";
  };

  return (
    <div className="relative w-full">
      <Link to={`/event/${id}`}>
        {/* <div
          className={`${micro ? "" : "hidden"
            } absolute top-[-10px] left-6 h-[25px] w-5 rounded-bl-lg border-l border-b`}
        ></div> */}
        <div className={`${isMicro} mt-2.5 mr-2.5 h-[35px] rounded-lg bg-shaddow p-[5px] relative overflow-hidden`}>
          {/* info box */}
          <div className="flex h-full flex-row overflow-hidden">
            <div className="w-full">
              <div className="flex items-center">
                <div className="flex items-center flex-shrink-0 bg-green rounded-full px-2 h-[20px]">
                  {seatPrice}
                  <img src="/icons/credit_icon.svg" alt="eth" className="inline h-4 w-4" />
                </div>
                <div className="line-clamp-1 text-base mx-2 text-white flex-auto">{title}</div>
                <div className="bg-background px-2 text-sm flex-shrink-0 rounded-full flex-initial">{seats - available} / {seats}</div>
              </div>

              <div className="flex">
                <div className={`${stateStyle(state)} mt-1 ml-2.5 h-6  rounded-full bg-red capitalize`}>
                  <span className="pl-2.5 pr-2.5 text-base text-white">{state}</span>
                </div>
              </div>
              <div
                className={`${isFull(available)} absolute flex h-[3px] items-center justify-center bottom-0 left-0 right-0`}
                style={{ width: `${percent(seats, available)}%` }}
              ></div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
