import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldValues,
} from "react-hook-form";
import { useUser } from "../../layouts/page_layout/layout";
import SelectState from "../../components/form_fields/select_state";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  type P6UserProfile,
  type P6UserAddress,
} from "../../../../src/types/profile";
import { P6ClientError } from "../../utils/exeptions";
import { P6ApiResponse } from "../../../../src/types";
import { updateUserProfile } from "../../functions";
import P6Button from "../../components/button";

type FormValues = {
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;
  notificationEmail: string;
};

export default function DetailsEdit() {
  const { user, setUser } = useUser();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneFocus, setPhoneFocus] = useState<string>();
  const cancelButtonRef = useRef(null);
  const {
    register,
    setError,
    setValue,
    reset,
    control,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<FieldValues>();

  useEffect(() => {
    // reset form with user data
    const notificationEmail = user?.notificationEmail;
    const phoneNumber = user?.phoneNumber;
    reset({
      ...user?.address,
      notificationEmail: notificationEmail,
      phoneNumber: phoneNumber,
    } as FormValues);
  }, [reset, user]);

  const focusPhone = (focus: boolean) => {
    if (focus) {
      setPhoneFocus("phoneFocus");
    } else {
      setPhoneFocus("");
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setLoading(true);
    try {
      const formData = data;
      const address: P6UserAddress = {
        address: formData.address as string,
        address2: formData.address2 as string,
        city: formData.city as string,
        state: formData.state as string,
        zip: formData.zip as string,
      };
      const userData = {
        notificationEmail: formData.notificationEmail as string,
        phoneNumber: formData.phoneNumber as string,
      };

      const updatedUser = {
        ...user,
        ...userData,
        address: address as {
          address: string;
          address2: string;
          city: string;
          state: string;
          zip: string;
        },
      };
      setUser({ ...user, ...data } as P6UserProfile);
      const result = await updateUserProfile(updatedUser);
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        setLoading(false);
        setOpen(false);
      } else {
        throw new P6ClientError("FunctionError", "Unable to update profile.");
      }
    } catch (error) {
      setLoading(false);
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  const onError = () => {
    if (!getValues("state")) {
      setError("state", { type: "custom", message: "custom message" });
    }
  };

  return (
    <div>
      <div
        id="edit-details"
        onClick={() => setOpen(true)}
        className="absolute top-1 right-2.5 cursor-pointer text-p6blue"
      >
        edit
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-2.5 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative max-w-screen-xs transform overflow-hidden rounded-lg bg-component text-left shadow-xl transition-all sm:my-2.5">
                  <div className="min-w-[300px] text-white">
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                      <div className="p-5">
                        <div className="mb-5 text-center text-[30px] font-light text-white">
                          Edit Your Details
                        </div>
                        <div className="mb-2.5 font-thin">Address</div>
                        <div className="mb-2.5">
                          <div className="relative mb-2.5">
                            <input
                              type="text"
                              id="address"
                              autoComplete="address-line1"
                              className="text-field peer"
                              placeholder=" "
                              {...register("address", {
                                required: true,
                              })}
                            />
                            <label htmlFor="address" className="form-label">
                              Address
                            </label>
                          </div>
                          {errors.address && (
                            <p className="form-error">Address is Required</p>
                          )}
                        </div>
                        <div className="mb-2.5">
                          <div className="relative mb-2.5">
                            <input
                              type="text"
                              id="address2"
                              autoComplete="address-line2"
                              className="text-field peer"
                              placeholder=" "
                              {...register("address2", {})}
                            />
                            <label htmlFor="address2" className="form-label">
                              Address 2
                            </label>
                          </div>
                          {errors.address2 && (
                            <p className="form-error">Address 2 is Required</p>
                          )}
                        </div>
                        <div className="mb-2.5">
                          <div className="relative mb-2.5">
                            <input
                              type="text"
                              id="city"
                              autoComplete="address-level2"
                              className="text-field peer"
                              placeholder=" "
                              {...register("city", {
                                required: true,
                              })}
                            />
                            <label htmlFor="city" className="form-label">
                              City
                            </label>
                          </div>
                          {errors.city && (
                            <p className="form-error">City is Required</p>
                          )}
                        </div>
                        <div className="relative z-50">
                          <SelectState
                            errors={errors}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            defaultValue={getValues("state") as string}
                          />
                        </div>
                        <div className="mb-2.5">
                          <div className="relative mb-2.5">
                            <input
                              type="text"
                              id="zip"
                              autoComplete="postal-code"
                              className="text-field peer"
                              placeholder=" "
                              {...register("zip", {
                                required: true,
                              })}
                            />
                            <label htmlFor="zip" className="form-label">
                              Zip Code
                            </label>
                          </div>
                          {errors.zip && (
                            <p className="form-error">Zip Code is Required</p>
                          )}
                        </div>
                        <div className="mb-2.5 font-thin">Contact</div>
                        <div className="mb-2.5">
                          <div className="relative mb-2.5">
                            <input
                              type="text"
                              id="notificationEmail"
                              autoComplete="email"
                              className="text-field peer"
                              placeholder=" "
                              {...register("notificationEmail", {
                                required: true,
                              })}
                            />
                            <label
                              htmlFor="notificationEmail"
                              className="form-label"
                            >
                              Notification Email
                            </label>
                          </div>
                          {errors.notificationEmail && (
                            <p className="form-error">
                              Notification Email is Required
                            </p>
                          )}
                        </div>
                        <div className="relative mb-3">
                          <Controller
                            name="phoneNumber"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <div className="tacos">
                                <PhoneInput
                                  value={value as string}
                                  inputClass=""
                                  country="us"
                                  onChange={onChange}
                                  onFocus={() => focusPhone(true as boolean)}
                                  onBlur={() => focusPhone(false as boolean)}
                                  disableDropdown={true}
                                  countryCodeEditable={false}
                                  inputProps={{
                                    autoFocus: true,
                                  }}
                                />
                                <label
                                  htmlFor="phoneNumber"
                                  className={`absolute ${
                                    phoneFocus as string
                                  } z-9 top-0 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red `}
                                >
                                  Phone Number
                                </label>
                              </div>
                            )}
                          />
                          {errors.phoneNumber && (
                            <p className="mt-[0px] ml-10 mb-2.5 text-red">
                              Phone # is Required
                            </p>
                          )}
                        </div>
                        <div className="mt-8 flex flex-col space-y-2.5">
                          <P6Button
                            sm
                            type="submit"
                            text="Update Details"
                            color="bg-green"
                            loading={loading}
                          />
                          <P6Button
                            sm
                            div
                            onClick={() => setOpen(false)}
                            text="Cancel"
                            color="bg-dark-grey"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
