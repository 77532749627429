import { useState, useEffect, type Dispatch, SetStateAction } from "react";
import { clientAuth } from "../../../../firebase/clientFirebaseApps";
import { onAuthStateChanged } from "firebase/auth";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { stateList } from "../../../../utils/state_array";
import Select from "react-select";
import { P6GroupBusinessInfo } from "../../../../../../src/types/group";
import P6Button from "../../../../components/button";
import clevertap from "clevertap-web-sdk";

export default function Step2({
  setStep,
  businessInfo,
  setBusinessInfo,
}: {
  setStep: Dispatch<SetStateAction<number>>;
  businessInfo: P6GroupBusinessInfo;
  setBusinessInfo: Dispatch<SetStateAction<P6GroupBusinessInfo | undefined>>;
}) {
  const [userId, setUserId] = useState("");
  const [isLoading] = useState(false);
  const [loading] = useState<boolean>(false);
  const [loadingBack] = useState<boolean>(false);
  const {
    register,
    setError,
    setValue,
    reset,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();
  const auth = clientAuth;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUserId(uid);
      }
    });
  }, [userId, auth]);

  useEffect(() => {
    // reset form with user data
    reset(businessInfo);
  }, [businessInfo, reset]);

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    const formData = data as P6GroupBusinessInfo;
    if (!formData.state) {
      setError("state", { type: "custom", message: "custom message" });
    } else {
      clevertap.event.push("P6 - Group Step 2");
      setBusinessInfo(formData);
      setStep(3);
    }
  };

  const onError = () => {
    if (!getValues("state")) {
      setError("state", { type: "custom", message: "custom message" });
    }
  };

  const setStateValue = (value: string) => {
    setValue("state", value);
    clearErrors("state");
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="p-5">
          <div className="text-center text-[34px] font-light text-white">
            Group Info
          </div>
          <div className="mb-8 text-center text-[25px] font-light">
            Business Info
          </div>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <textarea
                id="description"
                rows={4}
                className="peer block w-full appearance-none rounded-lg border-[1px] border-grey bg-component px-2.5 pb-1 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
                placeholder=" "
                {...register("description", {
                  required: true,
                })}
              ></textarea>
              <label
                htmlFor="description"
                className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-5 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
              >
                Description
              </label>
            </div>
            {errors.description && (
              <p className="mt-[-10px] mb-2.5 pl-2.5 text-red">
                Description is Required
              </p>
            )}
          </div>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="address"
                className="peer block w-full appearance-none rounded-full border-[1px] border-grey bg-component px-2.5 pb-1.5 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
                placeholder=" "
                {...register("address", {
                  required: true,
                })}
              />
              <label
                htmlFor="address"
                className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
              >
                Address
              </label>
            </div>
            {errors.address && (
              <p className="mt-[-10px] mb-2.5 pl-2.5 text-red">
                Address is Required
              </p>
            )}
          </div>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="address2"
                className="peer block w-full appearance-none rounded-full border-[1px] border-grey bg-component px-2.5 pb-1.5 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
                placeholder=" "
                {...register("address2")}
              />
              <label
                htmlFor="address2"
                className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
              >
                Address 2
              </label>
            </div>
          </div>
          <div className="mb- grid grid-cols-5">
            <div className="col-span-2 mb-2.5">
              <div className=" relative">
                <input
                  type="text"
                  id="city"
                  className="peer block w-full appearance-none rounded-full border-[1px] border-grey bg-component px-2.5 pb-1.5 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
                  placeholder=" "
                  {...register("city", {
                    required: true,
                  })}
                />
                <label
                  htmlFor="city"
                  className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
                >
                  City
                </label>
              </div>
              {errors.city && (
                <p className="mt-[-0px] mb-2.5 pl-2.5 text-red">
                  City Required
                </p>
              )}
            </div>
            <div className="col-span-3 flex">
              <div className="relative ml-2.5 mr-2.5 w-40">
                <Select
                  unstyled
                  instanceId="state"
                  aria-label="stateSelect"
                  classNames={{
                    control: (state) =>
                      state.isFocused
                        ? "flex block px-2.5 w-30 text-sm text-grey bg-component rounded-full border-[1px] appearance-none text-white border-green outline-none ring-0 border-green peer pl-4"
                        : "flex block px-2.5 w-30 text-sm text-grey bg-component rounded-full border-[1px] border-grey appearance-none dark:text-white dark:border-grey dark:focus:border-green focus:outline-none focus:ring-0 focus:border-green peer pl-4",
                    menu: (state) =>
                      state.options
                        ? "bg-component rounded-lg border-[1px] border-grey mt-1.5 absolute w-[150px]"
                        : "",
                    option: (state) =>
                      state.data
                        ? "hover:bg-green px-2.5 first:mt-2 last:mb-2"
                        : "",
                  }}
                  isLoading={isLoading}
                  isSearchable={true}
                  name="state"
                  options={stateList}
                  onChange={(value) => setStateValue(value?.value as string)}
                />
                <label
                  htmlFor="state"
                  className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
                >
                  State
                </label>
                {errors.state && (
                  <p className="mt-[0px] mb-2.5 pl-2.5 text-red">Required</p>
                )}
              </div>

              <div className="flex-auto">
                <div className="relative mb-2.5">
                  <input
                    type="text"
                    id="zip"
                    maxLength={5}
                    className="peer block w-full appearance-none rounded-full border-[1px] border-grey bg-component px-2.5 pb-1.5 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
                    placeholder=" "
                    {...register("zip", {
                      required: true,
                    })}
                  />
                  <label
                    htmlFor="zip"
                    className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
                  >
                    Zip Code
                  </label>
                </div>
                {errors.zip && (
                  <p className="mt-[-10px] mb-2.5 pl-2.5 text-red">Required</p>
                )}
              </div>
            </div>
          </div>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="businessPhone"
                className="peer block w-full appearance-none rounded-full border-[1px] border-grey bg-component px-2.5 pb-1.5 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
                placeholder=" "
                {...register("businessPhone", {
                  required: true,
                })}
              />
              <label
                htmlFor="businessPhone"
                className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
              >
                Business Phone Number
              </label>
            </div>
            {errors.businessPhone && (
              <p className="mt-[-10px] mb-2.5 pl-2.5 text-red">
                Phone # Required
              </p>
            )}
          </div>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="businessEmail"
                className="peer block w-full appearance-none rounded-full border-[1px] border-grey bg-component px-2.5 pb-1.5 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
                placeholder=" "
                {...register("businessEmail", {
                  required: true,
                })}
              />
              <label
                htmlFor="businessEmail"
                className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
              >
                Business Email
              </label>
            </div>
            {errors.businessEmail && (
              <p className="mt-[-10px] mb-2.5 pl-2.5 text-red">
                Email Required
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 mt-8 space-x-2.5">
            <P6Button
              div
              loading={loadingBack}
              onClick={() => setStep(1)}
              text="Back"
              dataCy="back"
            />
            <P6Button
              loading={loading}
              type="submit"
              text="Next"
              color="bg-green"
              dataCy="next"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
