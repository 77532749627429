import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/clientFirebaseApps";
import { doc, onSnapshot } from "firebase/firestore";
import { P6DistributionItem } from "../../../../../../src/types/distribution";
import { P6Fulfillment } from "../../../../../../src/types/fulfillment";
import { P6UserProfile } from "../../../../../../src/types/profile";
import { P6Event } from "../../../../../../src/types/event";
import Won from "../../../../components/event_card/won";

export default function DistributionOrder() {
  const [distributionItem, setDistributionItem] =
    useState<P6DistributionItem>();
  const [orderData, setOrderData] = useState<P6Fulfillment>();
  const [user, setUser] = useState<P6UserProfile>();
  const [event, setEvent] = useState<P6Event>();
  const { orderId } = useParams();

  useEffect(() => {
    if (orderId) {
      const unsub = onSnapshot(doc(db, "fulfillments", orderId), (doc) => {
        setOrderData(doc.data() as P6Fulfillment);
      });

      return () => unsub();
    }
  }, [orderId]);

  useEffect(() => {
    if (orderData && orderData.distributionItem) {
      const itemId = orderData.distributionItem;
      const unsub = onSnapshot(doc(db, "distribution", itemId), (doc) => {
        setDistributionItem(doc.data() as P6DistributionItem);
      });

      return () => unsub();
    }
  }, [orderData]);

  useEffect(() => {
    if (orderData && orderData.winnerId) {
      const userId = orderData.winnerId;
      const unsub = onSnapshot(doc(db, "profiles", userId), (doc) => {
        setUser(doc.data() as P6UserProfile);
      });

      return () => unsub();
    }
  }, [orderData]);

  useEffect(() => {
    if (orderData && orderData.eventId) {
      const eventId = orderData.eventId;
      const unsub = onSnapshot(doc(db, "events", eventId), (doc) => {
        setEvent({ id: doc.id, ...doc.data() } as P6Event);
      });

      return () => unsub();
    }
  }, [orderData]);

  if (!orderData || !distributionItem || !event) return null;

  return (
    <div className="text-white pt-3">
      <Won data={event} fulfillment={orderData} />
      <div className="flex space-x-2 mt-3">
        <div className="bg-component p-2 rounded-md flex-auto">
          <div className="flex">
            <img
              src="/icons/address_icon.svg"
              alt="address icon"
              className="inline-block mx-3"
            />
            <div className="text-xs">
              <div className="text-lg">{user && user.fullName}</div>
              <div>
                {orderData && orderData.address && orderData.address.address}
              </div>
              <div>
                {orderData && orderData.address && orderData.address.address2}
              </div>
              <div className="flex">
                <div className="mr-1">
                  {orderData && orderData.address && orderData.address.city},
                </div>
                <div className="mr-1">
                  {orderData && orderData.address && orderData.address.state}
                </div>
                <div>
                  {orderData && orderData.address && orderData.address.zip}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!orderData.complete && (
          <div
            className={`${
              orderData && orderData.fulfillmentType
                ? "bg-green"
                : " bg-orange-400"
            } bg-green rounded-md p-2 flex items-center`}
          >
            {orderData && orderData.fulfillmentType
              ? "Ready To Ship"
              : "Awaiting Close"}
          </div>
        )}
        {orderData.complete && (
          <div className="bg-p6blue rounded-md p-2 flex items-center">
            Shipped
          </div>
        )}
      </div>
      <div className="bg-component mt-4 rounded-md p-2">
        <div className="text-xs text-grey">
          {distributionItem && distributionItem.upc}
        </div>
        <div>{distributionItem && distributionItem.title}</div>
      </div>
    </div>
  );
}
