export function percent(seats: number, available: number) {
  const taken = seats - available;
  const seatPercent = (taken / seats) * 100;
  return seatPercent;
}

export function isFull(seatsLeft: number) {
  if (seatsLeft === 0) {
    return "bg-green";
  } else {
    return "bg-p6blue";
  }
}
