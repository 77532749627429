import { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import clevertap from "clevertap-web-sdk";
import { useUser } from "../../layouts/page_layout/layout";
import { updateUserProfile } from "../../functions";
import { P6ApiResponse } from "../../../../src/types";
import { P6ClientError } from "../../utils/exeptions";

export default function Settings() {
  const { user, setUser } = useUser();
  const [email, setEmail] = useState<boolean>(
    user && user.notifications && user.notifications.email === false ? user.notifications.email : true
  );
  const [text, setText] = useState<boolean>(
    user && user.notifications && user.notifications.text === false ? user.notifications.text : true
  );
  const [push, setPush] = useState<boolean>(false);

  useEffect(() => {
    clevertap.profile.push({
      Site: {
        // optional fields. controls whether the user will be sent email, push etc.
        "MSG-email": email, // Disable email notifications
        "MSG-sms": text, // Enable sms notifications
      },
    });
  }, [email, text]);

  useEffect(() => {
    (async () => {
      try {
        const notificationData = {
          notifications: {
            email: email,
            text: text,
          },
        };

        const updatedUser = {
          ...user,
          ...notificationData,
        };

        const result = await updateUserProfile(updatedUser);
        const resultData = result.data as P6ApiResponse;

        if (resultData.success) {
        } else {
          throw new P6ClientError("FunctionError", "Unable to update profile.");
        }
      } catch (error) {
        throw new P6ClientError("UnknownError", error as string);
      }
    })().catch((error) => {
      throw new P6ClientError("UnknownError", error as string);
    });
  }, [email, setUser, text, user]);

  return (
    <div className="text-white p-6">
      <div className="text-2xl font-bold">Settings</div>
      <div className="text-sm text-grey">
        Manage your Platform Sixx settings and preferences.
      </div>
      <div className="text-lg leading-4 mt-4">Notifications</div>
      <div className="text-sm text-grey mb-2">
        Let us know you want to receive notifications.
      </div>

      <div className="flex items-center mb-2">
        <Switch
          checked={email}
          onChange={setEmail}
          data-cy="email-switch"
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
        >
          <span
            aria-hidden="true"
            className="pointer-events-none absolute h-full w-full rounded-md"
          />
          <span
            aria-hidden="true"
            className={`${email ? "bg-green" : "bg-grey"} 
                pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
          />
          <span
            aria-hidden="true"
            className={`${email ? "translate-x-5" : "translate-x-0"} 
                pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
          />
        </Switch>
        <div className="ml-2">Email</div>
      </div>

      <div className="flex items-center mb-2">
        <Switch
          checked={text}
          onChange={setText}
          data-cy="text-switch"
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
        >
          <span
            aria-hidden="true"
            className="pointer-events-none absolute h-full w-full rounded-md"
          />
          <span
            aria-hidden="true"
            className={`${text ? "bg-green" : "bg-grey"} 
                pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
          />
          <span
            aria-hidden="true"
            className={`${text ? "translate-x-5" : "translate-x-0"} 
                pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
          />
        </Switch>
        <div className="ml-2">Text</div>
      </div>

      <div className="text-sm">Push notifications coming soon!</div>
      <div className="flex items-center mb-2">
        <Switch
          checked={push}
          disabled
          onChange={setPush}
          data-cy="push-switch"
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full disabled:"
        >
          <span
            aria-hidden="true"
            className="pointer-events-none absolute h-full w-full rounded-md"
          />
          <span
            aria-hidden="true"
            className={`${push ? "bg-green" : "bg-grey"} 
                pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
          />
          <span
            aria-hidden="true"
            className={`${push ? "translate-x-5" : "translate-x-0"} 
                pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
          />
        </Switch>
        <div className="ml-2">Push</div>
      </div>
    </div>
  );
}
