import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { clientAuth } from "../../firebase/clientFirebaseApps";
import { onAuthStateChanged } from "firebase/auth";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

export default function JoinLayout() {
  const navigate = useNavigate();
  const auth = clientAuth;
  const { groupId } = useParams();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        if (!groupId) {
          navigate(`/login`);
        } else {
          navigate(`/login?join=${groupId}`);
        }
      } else {
        // set Sentry to identify the user
        Sentry.setUser({ id: user?.uid });
      }
    });
  }, [auth, groupId, navigate]);

  const year = new Date().getFullYear();

  return (
    <div className="auth-bg flex justify-center bg-background">
      <div className="flex text-white md:container md:mx-auto">
        <div className="hidden flex-auto text-[32px] text-white md:flex md:max-w-[50%] md:items-center md:justify-center md:p-12 md:text-center">
          Welcome to the community.
        </div>
        <div className="flex h-screen flex-col items-center text-white md:flex-auto">
          <div className="mt-8 flex">
            <img src={"/images/p6_logo.svg"} width="275" alt="logo" />
          </div>
          <div className="w-full flex-auto">
            <Outlet />
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-5">
              <img src="/icons/p6_small_logo.svg" alt="small logo" />
            </div>
            <div>© {year} Platform Sixx</div>
            <div className="text-[6px] text-dark-grey text-right">
              Ver. {import.meta.env.VITE_P6_APP_VERSION}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
