import { useState, useEffect } from "react";
import { db } from "../../firebase/clientFirebaseApps";
import { doc, onSnapshot } from "firebase/firestore";
import { useParams } from "react-router-dom";
import GroupImage from "./group_image";
import GroupActions from "./group_actions";
import AdminActions from "./admin_actions";
import Active from "./page/active";
import History from "./page/history";
import Action from "./page/action";
import Members from "./page/members";
import { P6Group } from "../../../../src/types/group";
import { useUser } from "../../layouts/page_layout/layout";
import { type P6GroupMembership } from "../../../../src/types/groupMembership";
import * as Sentry from "@sentry/react";
import Loading from "./loading";
import clevertap from "clevertap-web-sdk";

export default function Group() {
  const [group, setGroup] = useState<P6Group>();
  const [admin, setAdmin] = useState<boolean>(false);
  const { groupId, action } = useParams();
  const { user, userId } = useUser();

  // Add group name to Sentry
  Sentry.setTag("group", group?.name);

  useEffect(() => {
    if (groupId) {
      const unsub = onSnapshot(doc(db, "groups", groupId), (doc) => {
        const group = doc.data() as P6Group;
        setGroup(group);
        clevertap.event.push("Group Viewed", {
          Name: group.name,
          Category: group.category,
        });

        window.dataLayer.push({
          event: "group_data",
          eventProps: {
            group_id: groupId,
            group_name: group.name,
            id: group.analyticsId as number || groupId,
          },
        } as unknown);
      });

      return () => unsub();
    }
  }, [groupId]);

  useEffect(() => {
    if (user && groupId) {
      const unsub = onSnapshot(
        doc(db, "groupMemberships", groupId + (user.userId as string)),
        (doc) => {
          const gMember = doc.data() as P6GroupMembership;
          if (gMember.level === "admin") {
            setAdmin(true);
          } else {
            setAdmin(false);
          }
        },
      );

      return () => unsub();
    }
  }, [user, groupId]);

  const tab = () => {
    if (action === "active" && groupId) {
      return <Active groupId={groupId} />;
    } else if (action === "history") {
      return <History groupId={groupId as string} />;
    } else if (action === "action") {
      return <Action admin={admin} groupId={groupId as string} />;
    } else if (action === "members") {
      return (
        <Members
          groupId={groupId as string}
          groupCode={
            group && group.groupCode ? group.groupCode : (groupId as string)
          }
        />
      );
    } else {
      return <Active groupId={groupId as string} />;
    }
  };

  if (!group) {
    return <Loading />;
  }

  return (
    <div className="flex-auto md:w-full">
      <div className="text-white">
        <GroupImage
          groupId={groupId as string}
          userId={userId}
          cover={group.cover}
          logo={group.logo}
          name={group.name}
          admin={admin}
        />
        {groupId && action && (
          <GroupActions
            active={action}
            groupId={groupId}
            admin={admin}
            userId={userId}
            groupName={group.name || ""}
            fullName={user.fullName || ""}
          />
        )}
        {admin && groupId && action && (
          <AdminActions groupId={groupId} page={action} />
        )}
        {tab()}
      </div>
    </div>
  );
}
