import { useState, Dispatch, SetStateAction } from "react";
import { FieldValues, useForm, SubmitHandler } from "react-hook-form";
import SelectState from "../../form_fields/select_state";
import P6Button from "../../button/";
import { createPayoutRecipient } from "../../../functions";
import { type P6PayoutRecipientRequest } from "../../../../../src/types/payout/recipient";
import { type P6ApiResponse } from "../../../../../src/types";

export default function PayoutMethodForm({
  groupId,
  setFormOpen,
}: {
  groupId: string | undefined;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<FieldValues>();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (groupId) {
      setLoading(true);
      data.groupId = groupId;
      const request = data as P6PayoutRecipientRequest;

      request.details.address.state = data.state as string;
      const result = await createPayoutRecipient(
        data as P6PayoutRecipientRequest,
      );
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        setLoading(false);
        setFormOpen(false);
        reset();
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <div className="text-white">
      <div className="text-lg text-center mb-2.5">Add a Payout Method.</div>
      <div className="mb-2.5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="accountHolderName"
                autoComplete="name"
                className="text-field peer"
                placeholder=" "
                {...register("accountHolderName", {
                  required: true,
                })}
              />
              <label htmlFor="accountHolderName" className="form-label">
                Account Holder Name
              </label>
            </div>
            {errors.accountHolderName && (
              <p className="form-error">Account Holder Name is Required</p>
            )}
          </div>

          <div className="grid grid-cols-2 gap-2">
            <div className="mb-2.5">
              <div className="relative mb-2.5">
                <input
                  type="number"
                  id="accountNumber"
                  autoComplete="off"
                  className="text-field peer"
                  placeholder=" "
                  {...register("details.accountNumber", {
                    required: true,
                  })}
                />
                <label htmlFor="accountNumber" className="form-label">
                  Account Number
                </label>
              </div>
              {errors.accountNumber && (
                <p className="form-error">Account Number is Required</p>
              )}
            </div>

            <div className="mb-2.5">
              <div className="relative mb-2.5">
                <input
                  type="number"
                  id="abartn"
                  autoComplete="off"
                  className="text-field peer"
                  placeholder=" "
                  {...register("details.abartn", {
                    required: true,
                  })}
                />
                <label htmlFor="" className="form-label">
                  Routing Number
                </label>
              </div>
              {errors.abartn && <p className="form-error"> is Required</p>}
            </div>
          </div>

          {/* Address setion */}
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="firstLine"
                autoComplete="address-line1"
                className="text-field peer"
                placeholder=" "
                {...register("details.address.firstLine", {
                  required: true,
                })}
              />
              <label htmlFor="firstLine" className="form-label">
                Address
              </label>
            </div>
            {errors.firstLine && <p className="form-error"> is Required</p>}
          </div>

          <div className="grid grid-cols-3 gap-2">
            <div className="mb-2.5">
              <div className="relative mb-2.5">
                <input
                  type="text"
                  id="city"
                  autoComplete="address-level2"
                  className="text-field peer"
                  placeholder=" "
                  {...register("details.address.city", {
                    required: true,
                  })}
                />
                <label htmlFor="" className="form-label">
                  City
                </label>
              </div>
              {errors.city && <p className="form-error"> is Required</p>}
            </div>

            <SelectState
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              defaultValue={getValues("state") as string}
            />

            <div className="mb-2.5">
              <div className="relative mb-2.5">
                <input
                  type="text"
                  id="postCode"
                  autoComplete="postal-code"
                  className="text-field peer"
                  placeholder=" "
                  {...register("details.address.postCode", {
                    required: true,
                    maxLength: 5,
                    minLength: 5,
                  })}
                />
                <label htmlFor="postCode" className="form-label">
                  Zip Code
                </label>
              </div>
              {errors.postCode && <p className="form-error"> is Required</p>}
            </div>
          </div>
          <div className="flex">
            <P6Button
              type="submit"
              loading={loading}
              text="Add Payout Method"
              textColor="text-white"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
