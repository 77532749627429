import { P6Event } from "../../../../src/types/event";
import EventMinis from "../../components/event_card/event_minis";

const EventRollUpComponent = ({ events }: { events: P6Event[] }) => {
  return (
    <>
      {/* mini details */}
      {events?.map((mini) => (
        <div key={mini.id}>
          <EventMinis
            id={mini.id}
            micro={false}
            state={mini.state}
            seats={mini.options.seats}
            seatPrice={mini.options.seatPrice}
            available={mini.available}
            title={mini.title} />
          {mini?.events?.map((micro) => (
            <div key={micro.id} className="">
              <EventMinis
                id={micro.id}
                micro={true}
                state={micro.state}
                seats={micro.options.seats}
                seatPrice={micro.options.seatPrice}
                available={micro.available}
                title={micro.title} />
              {micro?.events?.map((nano: P6Event) => (
                <div key={nano.id} className="ml-4">
                  <EventMinis
                    id={nano.id}
                    micro={true}
                    state={nano.state}
                    seats={nano.options.seats}
                    seatPrice={nano.options.seatPrice}
                    available={nano.available}
                    title={nano.title} />
                  {nano?.events?.map((macro: P6Event) => (
                    <div key={macro.id} className="ml-4">
                      <EventMinis
                        id={macro.id}
                        micro={true}
                        state={macro.state}
                        seats={macro.options.seats}
                        seatPrice={macro.options.seatPrice}
                        available={macro.available}
                        title={macro.title} />
                      {macro?.events?.map((pico: P6Event) => (
                        <div key={pico.id} className="ml-4">
                          <EventMinis
                            id={pico.id}
                            micro={true}
                            state={pico.state}
                            seats={pico.options.seats}
                            seatPrice={pico.options.seatPrice}
                            available={pico.available}
                            title={pico.title} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
export default EventRollUpComponent;
