export default function Progress(params: { step: number }) {
  const { step } = params;

  return (
    <div className="flex items-center px-8">
      <div
        className={`flex h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-white text-[21px] ${
          step === 1 ? "animate-pulse" : ""
        }`}
      >
        <div className={`rounded-full bg-white ${step > 1 ? "" : "hidden"} `}>
          <img
            src="/icons/check_icon_green.svg"
            alt="Google"
            className=""
            width={26}
            height={26}
          />
        </div>
        <div
          className={`h-full w-full bg-p6blue text-center ${
            step > 1 ? "hidden" : ""
          }`}
        >
          1
        </div>
      </div>
      <div className="flex-auto border-b-[1px]" />
      <div
        className={`flex h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-white text-[21px] ${
          step === 2 ? "animate-pulse" : ""
        }`}
      >
        <div className={`rounded-full bg-white ${step > 2 ? "" : "hidden"}`}>
          <img
            src="/icons/check_icon_green.svg"
            alt="Google"
            className=""
            width={26}
            height={26}
          />
        </div>
        <div
          className={`h-full w-full bg-p6blue text-center ${
            step > 2 ? "hidden" : ""
          }`}
        >
          2
        </div>
      </div>
      <div className="flex-auto border-b-[1px]" />
      <div
        className={`flex h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-white text-[21px] ${
          step === 3 ? "animate-pulse" : ""
        }`}
      >
        <div className={`rounded-full bg-white ${step > 3 ? "" : "hidden"}`}>
          <img
            src="/icons/check_icon_green.svg"
            alt="Google"
            className=""
            width={26}
            height={26}
          />
        </div>
        <div
          className={`h-full w-full bg-p6blue text-center ${
            step > 3 ? "hidden" : ""
          }`}
        >
          3
        </div>
      </div>
    </div>
  );
}
