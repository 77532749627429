import { useState, useEffect } from "react";
import { clientAuth } from "../../firebase/clientFirebaseApps";
import Tabs from "./components/tabs";
import { onAuthStateChanged, signInWithCustomToken} from "firebase/auth";
import AddCredits from "./components/add";
import Payouts from "./components/payout";
import { useParams } from "react-router-dom";
import { db } from "../../firebase/clientFirebaseApps";
import { doc, onSnapshot } from "firebase/firestore";
import { P6GroupMembership } from "../../../../src/types/groupMembership";
import { P6ClientError } from "../../utils/exeptions";

export default function IOSPaymentForm({
}) {
  const [tab, setTab] = useState<string>("Add Credits");
  const [admin, setAdmin] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>();
  const [groupId, ] = useState<string>();
  const auth = clientAuth;
  const { token } = useParams();
  const [, setPaymentOpen] = useState<boolean>(true);
  const creditAmount = 0;

  useEffect(() => {
    if (userId && groupId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const unsub = onSnapshot(
        doc(db, "groupMemberships", groupId + userId),
        (doc) => {
          const gMember = doc.data() as P6GroupMembership;
          if (gMember.level === "admin") {
            setAdmin(true);
          } else {
            setAdmin(false);
          }
        },
      );
      return () => unsub();
    }
  }, [userId, groupId]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
  }, [auth]);

  useEffect(() => {
    (async () => {
      try {
        if (token) {
          const encodedToken = atob(token);
          await signInWithCustomToken(auth, encodedToken);
        }
      } catch (error) {
        console.error('Error signing in with ID token:', error);
      }
    })().catch((error) => {
      throw new P6ClientError("UnknownError", error as string);
    });
  }, [token, auth]);
  

  return (
    <div className="relative text-white">
      <Tabs admin={admin} setTab={setTab} />
      {tab === "Add Credits" && (
        <AddCredits
          userId={userId}
          creditAmount={creditAmount}
          setPaymentOpen={setPaymentOpen}
        />
      )}
      {tab === "Payout" && userId && admin && (
        <Payouts userId={userId} groupId={groupId} />
      )}
    </div>
  );
}
