import { P6Timestamp } from "../../../src/types/helpers";

export const eventTime = (
  filledDate: P6Timestamp,
  status: string,
  wheelTimer: number,
  autoRoll: boolean,
) => {
  const countDownDate = new Date(filledDate.seconds * 1000).getTime();
  if (!autoRoll) {
    wheelTimer = 0;
  }

  const now = new Date().getTime();
  // Find the distance between now and the count down date
  const distance = countDownDate + wheelTimer * 1000 - now;
  // Time calculations for days, hours, minutes and seconds
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return {
    minutes: minutes,
    seconds: seconds,
    status: status,
    distance: Math.floor(distance / 1000),
  };
};
