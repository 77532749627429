import { type Dispatch, type SetStateAction, useState, useEffect } from "react";
import { FieldValues, useForm, SubmitHandler } from "react-hook-form";
import P6Button from "../../button";
import { chargepaymentmethod } from "../../../functions";
// import { analytics } from "../../../firebase/clientFirebaseApps";
// import { logEvent } from "firebase/analytics";
import { P6ClientError } from "../../../utils/exeptions";
import { paymentMessages } from "../../../utils/paymentMessages";
import { type P6ApiResponse, type P6ApiError } from "../../../../../src/types";

export default function FormAddCredits({
  setPaymentOpen,
  chargeMethod,
  creditAmount,
}: {
  setPaymentOpen: Dispatch<SetStateAction<boolean>>;
  chargeMethod: string;
  creditAmount?: number;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<{status: boolean; amount: number;}>({status: false, amount: 0});
  const [paymentMessage, setPaymentMessage] = useState<
    { message: string; success: boolean } | null | undefined
  >();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FieldValues>();

  useEffect(() => {
    if (creditAmount) {
      setValue("credits", creditAmount);
    }
  }, [creditAmount, setValue]);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    await handlePayment(data as {credits: number})
  };

  const handlePayment = async (data: {credits: number;}) => {
    setLoading(true);
    try {
      if (data.credits < 10) {
        setPaymentMessage({
          message: "Minimum purchase is 10 credits.",
          success: false,
        });
        throw new P6ClientError(
          "PaymentError",
          "Minimum purchase is 10 credits.",
        );
      }
      const result = (await chargepaymentmethod({
        amount: data.credits,
        paymentMethodId: chargeMethod,
      })) as P6ApiResponse;

      const resultData = result.data as P6ApiResponse;
      setPaymentMessage({
        message:
          paymentMessages[resultData.data as keyof typeof paymentMessages],
        success: resultData.data === "1" ? true : false,
      });
      if (resultData.data === "1") {
        setPaymentOpen(false);
        // Log payment in analytics
        // logEvent(analytics, "purchase" as never, {
        //   currency: "USD",
        //   value: data.credits,
        //   transaction_id: resultData.message,
        //   items: [
        //     {
        //       item_id: "CREDITS",
        //       item_name: "Platform Sixx Credits",
        //       price: 1.0,
        //     },
        //   ],
        // });
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            currency: "USD",
            value: data.credits,
            transaction_id: resultData.message,
            items: [
              {
                item_id: "CREDITS",
                item_name: "Platform Sixx Credits",
                price: data.credits,
              },
            ],
          }
        } as unknown);
        setLoading(false);
      } else {
        throw new P6ClientError(
          "PaymentError",
          paymentMessages[resultData.data as keyof typeof paymentMessages],
        );
      }
      if (!resultData) {
        // Catch error is no result data
        throw new P6ClientError(
          "PaymentError",
          "No result data: Payment did not process" as string,
        );
      }
    } catch (error) {
      setLoading(false);
      const errorData = error as P6ApiError;
      throw new P6ClientError("UnknownError", errorData.message);
    }
  }

  return (
    <div className="text-white">
      <div className="text-center text-sm border-b-[1px] mb-3 mx-2.5">Quick Credits</div>
      {confirmation.status ? (
        <div className="flex space-x-2 px-6">
          <div>
            <P6Button sm color="bg-grey" textColor="text-black" text="Cancel" onClick={() => setConfirmation({status: false, amount: 0})} />
          </div>
          <div className="flex flex-auto">
            <P6Button sm text={`Confirm ${confirmation.amount} ₢`} onClick={() => handlePayment({credits: confirmation.amount})} loading={loading} />
          </div>
        </div>
      ) :(
        <div className="flex space-x-2 p-2.5">
          <P6Button sm text="50 ₢" onClick={() => setConfirmation({status: true, amount: 50})} />
          <P6Button sm text="100 ₢" onClick={() => setConfirmation({status: true, amount: 100})}/>
          <P6Button sm text="200 ₢" onClick={() => setConfirmation({status: true, amount: 200})}/>
          <P6Button sm text="500 ₢" onClick={() => setConfirmation({status: true, amount: 500})}/>
        </div>
    )}
      
      <div className="w-full bg-component pl-2.5">
        <div className="relative flex flex-col items-center">
          <div className="mt-3 w-full text-center sm:text-left">
            <div className="text-white">
              <div className="mb-2.5">
                {!confirmation.status && (
                  <div>
                    <div className="text-center mb-3 mr-2.5 text-sm border-b-[1px]">Custom Amount</div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="flex items-center gap-2.5"
                    >
                      <div className="relative flex-auto">
                        <input
                          type="number"
                          id="credits"
                          autoComplete="off"
                          className="text-field peer pl-9"
                          placeholder=" "
                          {...register("credits", {
                            required: true,
                            valueAsNumber: true,
                          })}
                        />
                        <div className="absolute top-1.5 flex h-[px] w-[28px] justify-end">
                          <img
                            src="/icons/credit_icon.svg"
                            alt="Credit Icon"
                            className="top-[8px] left-[10px] z-10"
                            width={17.49}
                            height={17.49}
                          />
                        </div>
                        <label htmlFor="credits" className="form-label ml-7">
                          Credits
                        </label>
                      </div>
                      <div className="flex flex-auto min-w-[200px] mr-2.5">
                        <P6Button
                          type="submit"
                          loading={loading}
                          text="Add Credits"
                        />
                      </div>
                    </form>
                  </div>
                )}
                {errors.credits && (
                  <p className="form-error mt-2.5 text-center mr-2.5">
                    You must add a credit amount.
                  </p>
                )}
                {paymentMessage && (
                  <div
                    className={`px-2.5 text-center ${
                      paymentMessage && paymentMessage.success
                        ? "text-green"
                        : "text-red"
                    }`}
                  >
                    {paymentMessage.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
