import { Link } from "react-router-dom";

export default function Loading() {
  return (
    <div className="w-full">
      <div className="mt-2.5 flex items-center p-2.5">
        <Link to="/groups/create" className="flex flex-auto justify-end">
          <div className=" text-[24px] text-white">Create Group</div>
          <div className="flex pl-2.5">
            <img
              src="/icons/plus_icon.svg"
              alt="Create Group"
              className="m-auto"
              width={24}
              height={24}
            />
          </div>
        </Link>
      </div>
    </div>
  );
}
