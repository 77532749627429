import { useState, Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { P6UserProfile } from "../../../../src/types/profile";

export default function Menu({
  user,
  setMenuOpen,
}: {
  user: P6UserProfile;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [feedActive, setFeedActive] = useState(false);
  const [groupsActive, setGroupsActive] = useState(false);
  const [historyActive, setHistoryActive] = useState(false);
  const [settingsActive, setSettingsActive] = useState(false);

  return (
    <div className="text-white">
      <Link to="/profile" onClick={() => setMenuOpen(false)}>
        <div className="flex flex-col justify-center items-center mb-4">
          <img
            src={user.profileImageUrl}
            alt="User Profile"
            className="rounded-full w-[100px] h-[100px] object-cover"
          />
          <div className="mt-2.5">{user.fullName}</div>
          <div className="text-sm text-grey">{user.displayName}</div>
        </div>
      </Link>
      <Link to="/feed" onClick={() => setMenuOpen(false)}>
        <div
          onMouseEnter={() => setFeedActive(true)}
          onMouseLeave={() => setFeedActive(false)}
          className="flex mb-3 mt-5 text-lg text-white hover:text-p6blue"
        >
          <img
            src={`/icons/feed_icon${feedActive ? "_on" : ""}.svg`}
            className="inline-block w-6 mr-2"
            alt="Feed"
          />
          <div>Feed</div>
        </div>
      </Link>
      <Link to="/groups" onClick={() => setMenuOpen(false)}>
        <div
          onMouseEnter={() => setGroupsActive(true)}
          onMouseLeave={() => setGroupsActive(false)}
          className="flex mb-3 mt-5 text-lg text-white hover:text-p6blue"
        >
          <img
            src={`/icons/groups_icon${groupsActive ? "_on" : ""}.svg`}
            className="inline-block w-6 mr-2"
            alt="Groups"
          />
          <div>Groups</div>
        </div>
      </Link>
      <Link to="/history" onClick={() => setMenuOpen(false)}>
        <div
          onMouseEnter={() => setHistoryActive(true)}
          onMouseLeave={() => setHistoryActive(false)}
          className="flex mb-3 mt-5 text-lg text-white hover:text-p6blue"
        >
          <img
            src={`/icons/user_history_icon${historyActive ? "_on" : ""}.svg`}
            className="inline-block w-6 mr-2"
            alt="My History"
          />
          <div>My History</div>
        </div>
      </Link>
      <Link to="/settings" onClick={() => setMenuOpen(false)}>
        <div
          onMouseEnter={() => setSettingsActive(true)}
          onMouseLeave={() => setSettingsActive(false)}
          className="flex mb-3 mt-5 text-lg text-white hover:text-p6blue"
        >
          <img
            src={`/icons/settings_icon${settingsActive ? "_on" : ""}.svg`}
            className="inline-block w-6 h-6 mr-2"
            alt="Notifications"
          />
          <div>Settings</div>
        </div>
      </Link>
    </div>
  );
}
