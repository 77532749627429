import type { Auth, User } from "firebase/auth";
import { signOut as logOut } from "firebase/auth";
import Cookies from "universal-cookie";
import clevertap from "clevertap-web-sdk";

const cookies = new Cookies();
const tokenName = "authentication" as string;

export const signIn = async (user: User | null): Promise<string | null> => {
  if (!user) {
    return null;
  }
  const token = await user.getIdToken();
  clevertap.event.push("User Signed In");
  window.dataLayer.push({
    event: "login",
  } as unknown);
  cookies.set(tokenName, token);
  return token;
};

export const signOut = async (auth: Auth): Promise<void> => {
  await logOut(auth);
  window.dataLayer.push({
    event: "logout",
  } as unknown);
  clevertap.event.push("User Logged Out");
  cookies.remove(tokenName);
};

