import { P6Fulfillment } from "../../../../src/types/fulfillment";

export default function WonText({
  fulfillment,
  creditsToReturn,
}: {
  fulfillment: P6Fulfillment;
  creditsToReturn: number;
}) {
  const textOption = (fulfillment: P6Fulfillment) => {
    if (fulfillment) {
      if (fulfillment.fulfillmentType === "ship" && fulfillment.receivedDate) {
        return "Delivered";
      }

      if (
        fulfillment.fulfillmentType === "pickup" &&
        fulfillment.receivedDate
      ) {
        return "Picked Up";
      }

      switch (fulfillment.fulfillmentType) {
        case "pickup":
          return fulfillment.pickedUpDate
            ? "Please select a receiving option"
            : `Awaiting Pickup by ${fulfillment.pickedUpBy as string}`;
        case "ship":
          return fulfillment.shipDate
            ? `Shipped via ${fulfillment.carrier as string} ${
                fulfillment.trackingNumber as string
              }`
            : "Awaiting Shipment";
        case "trade":
          return `Traded for ${creditsToReturn} ₢`;
        default:
          return "Please select a receiving option";
      }
    }
  };

  return (
    <div className="col-span-2 mb-[3px] text-center text-[12px] line-clamp-1">
      {fulfillment && textOption(fulfillment)}
    </div>
  );
}
