import { useEffect, useState } from "react";
import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";
import { Link } from "react-router-dom";
import ReferModal from "../../components/refer_modal/refer_modal";
import { P6ClientError } from "../../utils/exeptions";

export default function GroupActions({
  active,
  groupId,
  admin,
  userId,
  fullName,
  groupName,
}: {
  active: string;
  groupId: string;
  admin: boolean;
  userId: string;
  fullName: string;
  groupName: string;
}) {
  const [actionCount, setActionCount] = useState<number>(0);
  const [activeCount, setActiveCount] = useState<number>(0);

  const activePath = (path: string) => {
    if (active === path) {
      return "text-p6blue";
    }
  };

  useEffect(() => {
    (async () => {
      const coll = collection(db, "events");
      if (admin) {
        const q = query(
          coll,
          where("fulfilled", "==", false),
          where("groupId", "==", groupId),
        );
        const qRemovedEvents = query(
          coll,
          where("state", "==", "removed"),
          where("groupId", "==", groupId),
        );
        const snapshot = await getCountFromServer(q);
        const snapshotRemoved = await getCountFromServer(qRemovedEvents);
        setActionCount(snapshot.data().count - activeCount - snapshotRemoved.data().count);
      } else {
        const q = query(
          coll,
          where("postEvent.winnerId", "==", userId),
          where("fulfilled", "==", false),
          where("groupId", "==", groupId),
        );
        const snapshot = await getCountFromServer(q);
        setActionCount(snapshot.data().count);
      }
    })().catch((error) => {
      throw new P6ClientError("UnknownError", error as string);
    });
  }, [groupId, userId, admin, activeCount]);

  useEffect(() => {
    (async () => {
      const coll = collection(db, "events");
      const q = query(
        coll,
        where("state", "==", "active"),
        where("groupId", "==", groupId),
      );
      const snapshot = await getCountFromServer(q);
      setActiveCount(snapshot.data().count);
    })().catch((error) => {
      throw new P6ClientError("UnknownError", error as string);
    });
  }, [groupId, userId]);

  const isAdmin = admin;

  return (
    <div className="w-full bg-component pt-1.5">
      <div className="grid grid-cols-4">
        <div className="col-span-1 border-r-[1px] border-grey text-center text-xs">
          <Link to={`/group/${groupId}/active`} data-cy="active">
            <div className="flex justify-center">
              <div className="relative">
                <img
                  src={
                    active === "active"
                      ? "/icons/active_icon_on.svg"
                      : "/icons/active_icon.svg"
                  }
                  alt="Group Events"
                  className="m-auto pt-2"
                  width={24}
                  height={30}
                />
                {activeCount > 0 && (
                  <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red border-2 border-white rounded-full -top-0 -right-3 dark:border-gray-900">
                    {activeCount}
                  </div>
                )}
              </div>
            </div>
            <div className={`mt-1 ${activePath("active") as string}`}>
              Active
            </div>
          </Link>
        </div>
        <div className="col-span-1	border-r-[1px] border-grey text-center text-xs">
          <Link to={`/group/${groupId}/history`} data-cy="history">
            <img
              src={
                active === "history"
                  ? "/icons/history_icon_on.svg"
                  : "/icons/history_icon.svg"
              }
              alt="Group History"
              className="m-auto pt-2"
              width={30}
              height={30}
            />
            <div className={`mt-1 ${activePath("history") as string}`}>
              History
            </div>
          </Link>
        </div>
        <div className="col-span-1	border-r-[1px] border-grey text-center text-xs">
          <Link to={`/group/${groupId}/action`} data-cy="action">
            <div className="flex justify-center">
              <div className="relative">
                <img
                  src={
                    active === "action"
                      ? "/icons/bag_icon_on.svg"
                      : "/icons/bag_icon.svg"
                  }
                  alt="Action Needed"
                  className="m-auto pt-2"
                  width={24}
                  height={30}
                />
                {actionCount > 0 && (
                  <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red border-2 border-white rounded-full -top-0 -right-3 dark:border-gray-900">
                    {actionCount}
                  </div>
                )}
              </div>
            </div>
            <div className={`mt-1 ${activePath("action") as string}`}>
              Action
            </div>
          </Link>
        </div>
        <div className="col-span-1 text-center text-xs">
          {!isAdmin && (
            <ReferModal
              groupName={groupName}
              fullName={fullName}
              groupId={groupId}
            />
          )}
          {isAdmin && (
            <div className="col-span-1	text-center text-xs">
              <Link to={`/group/${groupId}/members`} data-cy="members">
                <div className="flex justify-center">
                  <div className="relative">
                    <img
                      src={
                        active === "members"
                          ? "/icons/groups_icon_on.svg"
                          : "/icons/groups_icon.svg"
                      }
                      alt="Action Needed"
                      className="m-auto pt-2"
                      width={50}
                      height={30}
                    />
                  </div>
                </div>
                <div className={`mt-1 ${activePath("members") as string}`}>
                  Members
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
