import { Link } from "react-router-dom";
import { GroupIndex } from "../../../../src/types/algolia/indexes";

export default function ItemGroup({
  noBorder,
  group,
}: {
  noBorder?: boolean;
  group: GroupIndex;
}) {
  const profilePic = "/images/test/profile.jpg";

  return (
    <div className={`text-white ${noBorder ? "" : "border-t-[1px]"}`}>
      <Link to={`/group/${group.objectID}/active`}>
        <div className="flex items-center px-2.5 py-3">
          <div>
            <img
              src={group.logo ? group.logo : profilePic}
              alt="group"
              className="rounded-full"
              width={35}
              height={35}
            />
          </div>
          <div className="flex flex-auto flex-col px-2.5">
            <div className="text-xs text-grey">Group</div>
            <div className="text-[15px] leading-4">{group.name}</div>
          </div>
        </div>
      </Link>
    </div>
  );
}
