import { httpsCallableFromURL } from "firebase/functions";
import { functions } from "../firebase/clientFirebaseApps";

const functionURL = (functionName: string) => {
  if (import.meta.env.MODE !== "production") {
    return `http://localhost:5001/platformsixx-next/us-central1/${functionName}`;
  } else {
    // This returns the right URL based on staging or production
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `https://${functionName}-${
      import.meta.env.VITE_FUNCTION_LOCATION
    }/${functionName}`;
  }
};

// Create user profile  
export const updateMaintenanceStatus = httpsCallableFromURL(
  functions,
  functionURL("updatemaintenancestatus"),
);

// Create user profile
export const createUserProfile = httpsCallableFromURL(
  functions,
  functionURL("createuserprofile"),
);

// Update user profile
export const updateUserProfile = httpsCallableFromURL(
  functions,
  functionURL("updateuserprofile"),
);

// Update user group credits
export const updateUserGroupCredits = httpsCallableFromURL(
  functions,
  functionURL("updateusergroupcredits"),
);

// Create user payment method
export const createauthnetpaymentmethod = httpsCallableFromURL(
  functions,
  functionURL("createauthnetpaymentmethod"),
);

// Update user payment method
export const updatePaymentMethod = httpsCallableFromURL(
  functions,
  functionURL("updatepaymentmethod"),
);

// Remove user payment method
export const deletepaymentmethod = httpsCallableFromURL(
  functions,
  functionURL("deletepaymentmethod"),
);

// Charge user and update credits
export const chargepaymentmethod = httpsCallableFromURL(
  functions,
  functionURL("chargepaymentmethod"),
);

// Purchase seat
export const purchaseSeat = httpsCallableFromURL(
  functions,
  functionURL("purchaseseat"),
);

// Add event
export const createEvent = httpsCallableFromURL(
  functions,
  functionURL("createevent"),
);

// Add group
export const createGroup = httpsCallableFromURL(
  functions,
  functionURL("creategroup"),
);

// Update group
export const updateGroup = httpsCallableFromURL(
  functions,
  functionURL("updategroup"),
);

// Add mini
export const createMini = httpsCallableFromURL(
  functions,
  functionURL("createmini"),
);

// Update fulfillment
export const updateFulfillment = httpsCallableFromURL(
  functions,
  functionURL("updatefulfillment"),
);

// Add user to group
export const createGroupMembership = httpsCallableFromURL(
  functions,
  functionURL("creategroupmembership"),
);

// Update user to group
export const updateGroupMembership = httpsCallableFromURL(
  functions,
  functionURL("updategroupmembership"),
);

// Update event
export const updateEvent = httpsCallableFromURL(
  functions,
  functionURL("updateevent"),
);

// transfer money
export const createPayoutTransfer = httpsCallableFromURL(
  functions,
  functionURL("createpayouttransfer"),
);

// create wise recipient
export const createPayoutRecipient = httpsCallableFromURL(
  functions,
  functionURL("createpayoutrecipient"),
);

// Remove event
export const removeEvent = httpsCallableFromURL(
  functions,
  functionURL("removeevent"),
);

// Refer a friend
export const memberRefer = httpsCallableFromURL(
  functions,
  functionURL("memberrefer"),
);

// Get transactions
export const getTransactions = httpsCallableFromURL(
  functions,
  functionURL("gettransactions"),
);

// Get transactions by user id
export const getTransactionsByUserId = httpsCallableFromURL(
  functions,
  functionURL("gettransactionsbyuser"),
);

// Create distribution item
export const createDistributionItem = httpsCallableFromURL(
  functions,
  functionURL("createdistributionitem"),
);

// Update distrubution item status
export const updateDistributionStatus = httpsCallableFromURL(
  functions,
  functionURL("updatedistributionstatus"),
);

// Create post
export const createPost = httpsCallableFromURL(
  functions,
  functionURL("createpost"),
);

// Create comment
export const createComment = httpsCallableFromURL(
  functions,
  functionURL("createcomment"),
);
