import { useState, useEffect } from "react";
import { db } from "../../../../../src/firebase/clientFirebaseApps";
import { onSnapshot, doc } from "firebase/firestore";
import { Switch } from "@headlessui/react";
import { type P6ApiResponse } from "../../../../../../src/types";
import { P6ClientError } from "../../../../../src/utils/exeptions";
import { updateMaintenanceStatus } from "../../../../functions";
import { P6Settings } from "../../../../../../src/types/admin";
import P6Button from "../../../../../src/components/button";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function MaintenanceAdmin() {
  const [, setSettings] = useState<P6Settings>();
  const [active, setActive] = useState<boolean>(false);
  const [value, setValue] = useState('');

  // Get the event data
  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "settings", "publicSettings"), (doc) => {
      if (doc) {
        console.log(doc.data());
        setActive(doc.data()?.maintenance as boolean);
        setValue(doc.data()?.siteMessage as string);
        setSettings(doc.data() as P6Settings);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const changeStatus = async () => {
    try {
      const result = await updateMaintenanceStatus({
        maintenance: !active as boolean,
      });
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        setActive(!active);
      } else {
        throw new P6ClientError("FunctionError", "Unable to remove event.");
      }
    } catch (error) {
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  const setMessage = async (message: string | null) => {
    try {
      const result = await updateMaintenanceStatus({
        siteMessage: message,
      });
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        if (message === null){
          setValue('');
        }
      } else {
        throw new P6ClientError("FunctionError", "Unable to remove event.");
      }
    } catch (error) {
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  return (
    <div className="text-white">
      <div className="flex items-center mb-3">
        <Switch
          checked={active}
          onChange={() => changeStatus()}
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
        >
          <span
            aria-hidden="true"
            className="pointer-events-none absolute h-full w-full rounded-md"
          />
          <span
            aria-hidden="true"
            className={`${active ? "bg-green" : "bg-grey"} 
            pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
          />
          <span
            aria-hidden="true"
            className={`${active ? "translate-x-5" : "translate-x-0"} 
            pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
          />
        </Switch>
        <span className="ml-3">Maintenance Mode</span>
      </div>
      <div>Sitewide Message</div>
      <ReactQuill theme="snow" value={value} onChange={setValue} />
      <div className="flex mt-3">
        <div className="flex-auto" />
        <div className="flex space-x-2">
          <P6Button div sm text="Clear Message" color="bg-grey" onClick={() => setMessage(null)} />
          <P6Button div sm text="Set Message" onClick={() => setMessage(value)} />
        </div>
      </div>
    </div>
  );
}
