import { useState } from "react";
import { type P6PaymentMethod } from "../../../../../src/types/payment/payment_method";
import { deletepaymentmethod } from "../../../functions";
import { updatePaymentMethod } from "../../../functions";
import { P6ClientError } from "../../../utils/exeptions";
import { P6ApiResponse } from "../../../../../src/types";
import P6Button from "../../button";

export default function PaymentMethod({
  data,
  chargeMethod,
}: {
  data: P6PaymentMethod;
  chargeMethod: string;
}) {
  const { cardExpDate, token, tokenType, id, defaultCard } = data;
  const [loading, setloading] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [defaultAction, setDefaultAction] = useState<boolean>(false);
  const [defaultLoding, setDefaultLoding] = useState<boolean>(false);

  const handleDelete = async (paymentId: string) => {
    setloading(true);
    try {
      if (!paymentId) {
        throw new P6ClientError("InvalidArgument", "No payment method id");
      }
      const result = await deletepaymentmethod(paymentId);
      setloading(false);
      const resultData = result.data as P6ApiResponse;
      if (!resultData.success) {
        throw new P6ClientError(
          "InvalidArgument",
          "Unable to delete payment method",
        );
      }
    } catch (error) {
      setloading(false);
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  const startRemove = () => {
    setConfirm(true);
    if (defaultCard) {
      setTimeout(() => {
        setConfirm(false);
      }, 2500);
    }
  };

  const defaultActions = () => {
    if (!defaultCard) {
      setDefaultAction(true);
    }
  };

  const handleDefault = async (id: string | undefined) => {
    setDefaultLoding(true);
    try {
      if (!id) {
        throw new P6ClientError("InvalidArgument", "No payment method id");
      }
      const reslut = (await updatePaymentMethod({
        id,
        changeDefault: true,
      })) as P6ApiResponse;
      const reslutData = reslut.data as P6ApiResponse;
      if (reslutData.success) {
        setDefaultAction(false);
        setDefaultLoding(false);
      } else {
        throw new P6ClientError(
          "InvalidArgument",
          "Unable to set default payment method",
        );
      }
    } catch (error) {
      setDefaultLoding(false);
      return Promise.reject(
        new P6ClientError("UnknownError", "Default card was not updated!"),
      );
    }
  };

  return (
    <div>
      {!confirm && !defaultAction && (
        <div
          className={`h-[40px] mt-2.5 flex items-center justify-center rounded-lg bg-background px-4 py-1.5 ${
            chargeMethod === data.id ? "border-[1px] border-green" : ""
          }`}
        >
          <div onClick={() => defaultActions()} className="flex">
            <img
              width={18}
              height={18}
              src={`${
                defaultCard
                  ? "/icons/star_icon_on.svg"
                  : "/icons/star_icon_off.svg"
              }`}
              alt="default on"
              className=" mr-6"
            />
          </div>

          <div className="flex flex-auto cursor-pointer">
            <div className="flex">
              { tokenType && <img
                src={`/icons/${tokenType.toLowerCase()}_icon.svg`}
                alt="Credit Card"
                className=""
                width={32.64}
                height={20.5}
              />}
            </div>
            <div className="flex flex-auto justify-center">
              •••• •••• •••• {token.substr(token.length - 4)}
            </div>
            <div className="flex flex-auto justify-center">{cardExpDate}</div>
          </div>
          <div>
            <img
              onClick={() => startRemove()}
              src="/icons/delete_icon.svg"
              alt="Delete"
              className="cursor-pointer"
              width={14}
              height={18}
            />
          </div>
        </div>
      )}
      {defaultAction && !confirm && (
        <div
          className={`h-[40px] mt-2.5 flex items-center justify-center rounded-lg ${
            confirm && defaultCard ? "bg-red" : "bg-background"
          } px-5 py-1.5`}
        >
          <div className="grid grid-cols-2 w-full text-center gap-2.5">
            <div>
              <P6Button
                sm
                text="Set Default"
                div
                loading={defaultLoding}
                onClick={() => handleDefault(id)}
                color="bg-green"
              />
            </div>
            <div>
              <P6Button
                sm
                text="Cancel"
                div
                onClick={() => setDefaultAction(false)}
                color="bg-shaddow"
              />
            </div>
          </div>
        </div>
      )}
      {confirm && !defaultAction && (
        <div
          className={`h-[40px] mt-2.5 flex items-center justify-center rounded-lg ${
            confirm && defaultCard ? "bg-red" : "bg-background"
          } px-5 py-1.5`}
        >
          {!defaultCard && (
            <div className="grid grid-cols-2 w-full text-center gap-2.5">
              <div
                onClick={() => handleDelete(id as string)}
                className="bg-red rounded-full cursor-pointer relative"
              >
                {loading && (
                  <img
                    width={18}
                    height={18}
                    src="/icons/spinner_icon.svg"
                    alt="Event"
                    className="absolute left-[4px] top-[3px] animate-spin"
                  />
                )}
                Remove
              </div>
              <div
                onClick={() => setConfirm(false)}
                className="bg-shaddow rounded-full cursor-pointer relative"
              >
                Cancel
              </div>
            </div>
          )}
          {defaultCard && (
            <div className="flex w-full">
              <p className="flex-auto text-center">
                You can not remove default card
              </p>
              <div className="flex">
                <img
                  src="/icons/X_icon.svg"
                  alt="Credit Card"
                  className="flex cursor-pointer"
                  width={16}
                  height={16}
                  onClick={() => setConfirm(false)}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
