import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";

import { Navigation } from "swiper/modules";

export default function EventImage({
  state,
  eventId,
  images,
}: {
  state: string;
  eventId?: string;
  images: string[];
}) {
  const post = (state: string) => {
    return state === "active" ? "bg-green" : "bg-red";
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return (
        '<span class="' + className + ' top-0 !opacity-80 !h-5 !w-5"> </span>'
      );
    },
  };

  return (
    <div className="relative overflow-visible" id="post-images">
      {eventId ? (
        <Link to={`/event/${eventId}`}>
          <div className="relative max-w-full lg:max-w-xl">
            <Swiper
              modules={[Pagination, Navigation]}
              navigation={true}
              spaceBetween={10}
              slidesPerView={1}
              autoHeight={true}
              pagination={pagination}
            >
              {images.map((image, index) => (
                <SwiperSlide key={index} className="">
                  <img
                    src={image}
                    className="w-full"
                    alt={`Event ${eventId} - Slide ${index + 1}`}
                    sizes="(max-width: 768px) 100vw,
                  (max-width: 1200px) 50vw,
                  33vw"
                    style={{ width: "100%" }}
                  />
                  <div
                    className={`${post(
                      state
                    )} absolute top-2.5 right-2.5 rounded-full`}
                  >
                    <span className="pl-2.5 pr-2.5 text-xl capitalize text-white">
                      {state}
                    </span>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Link>
      ) : (
        <div className="relative max-w-full lg:max-w-xl">
          <Swiper
            modules={[Pagination, Navigation]}
            navigation={true}
            spaceBetween={10}
            slidesPerView={1}
            autoHeight={true}
            pagination={pagination}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className={`w-full`}>
                  <img
                    id={image}
                    src={image}
                    className="w-full"
                    alt={`Event ${eventId} - Slide ${index + 1}`}
                  />
                </div>
                <div
                  className={`${post(
                    state
                  )} absolute top-2.5 right-2.5 rounded-full`}
                >
                  <span className="pl-2.5 pr-2.5 text-xl capitalize text-white">
                    {state}
                  </span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
}
