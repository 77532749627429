import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { db } from "../../../firebase/clientFirebaseApps";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { type P6PayoutMethod } from "../../../../../src/types/payment/payout_method";
import PayoutMethod from "./payout_method";

export default function PayoutMethodsList({
  userId,
  groupId,
  setAccount,
}: {
  userId: string;
  groupId: string | undefined;
  setAccount: Dispatch<SetStateAction<P6PayoutMethod | undefined>>;
}) {
  const [payoutMethods, setPayoutMethods] = useState<P6PayoutMethod[]>([]);

  useEffect(() => {
    if (userId && groupId) {
      const payoutMethodRef = collection(db, "payoutMethods");
      const payoutMethodQuery = query(
        payoutMethodRef,
        where("userId", "==", userId),
        where("groupId", "==", groupId),
      );
      const unsubscribe = onSnapshot(payoutMethodQuery, (querySnapshot) => {
        const payoutMethodsAr: P6PayoutMethod[] = [];
        querySnapshot.forEach((doc) => {
          const pm = doc.data();
          payoutMethodsAr.push(pm as P6PayoutMethod);
        });
        setPayoutMethods(payoutMethodsAr);
      });
      return () => unsubscribe();
    } else {
      setPayoutMethods([]);
    }
  }, [groupId, userId]);

  return (
    <div className="">
      {payoutMethods.map((payoutMethod: P6PayoutMethod) => (
        <div key={payoutMethod.id}>
          {/* <PaymentMethod data={payMethod} setChargeMethod={setChargeMethod} chargeMethod={chargeMethod} defaultId={defaultId} /> */}
          <PayoutMethod data={payoutMethod} setAccount={setAccount} />
        </div>
      ))}
      {payoutMethods?.length === 0 && (
        <div className="text-center mt-4 mb-2">
          <p className="text-[16px] font-light">No Available Payout Methods</p>
          <p className="text-[16px] font-light">
            You must add a payout method to continue.
          </p>
        </div>
      )}
    </div>
  );
}
