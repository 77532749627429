import { Dispatch, SetStateAction } from "react";
import { type P6PayoutMethod } from "../../../../../src/types/payment/payout_method";

export default function PayoutMethod({
  data,
  setAccount,
}: {
  data: P6PayoutMethod;
  setAccount: Dispatch<SetStateAction<P6PayoutMethod | undefined>>;
}) {
  return (
    <div className="text-white">
      <div
        className="flex bg-component hover:bg-shaddow border-[1px] rounded-lg border-white mt-2.5 p-2.5 cursor-pointer"
        onClick={() => setAccount(data)}
      >
        <div className="flex-auto text-center">
          <div className="underline">Account:</div>
          <div className="text-sm text-grey">{data.accountNumber}</div>
        </div>
        <div className="flex-auto text-center">
          <div className="underline">Routing #</div>
          <div className="text-sm text-grey">{data.abartn}</div>
        </div>
      </div>
    </div>
  );
}
