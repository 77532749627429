import { Dispatch, SetStateAction } from "react";
import PostForm from "./post_form";

export default function Layout({
  setOptionsOpen,
}: {
  setOptionsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="relative text-white">
      <PostForm
        setOptionsOpen={setOptionsOpen}
      />
    </div>
  );
}
