import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Bars3Icon, BellIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { clientAuth } from "../../firebase/clientFirebaseApps";
import { signOut } from "../../firebase/clientUserSessionUtils";
import { useNavigate } from "react-router-dom";

// const userNavigation = [
//   { name: "Your profile", href: "#" },
//   { name: "Sign out", href: "#" },
// ]

export default function MenuTop({
  setSidebarOpen,
  user,
}: {
  setSidebarOpen: (open: boolean) => void;
  user: { email: string | null; photoURL: string | null };
}) {
  const navigate = useNavigate();
  const logout = async () => {
    await signOut(clientAuth);
    window.dataLayer.push({
      event: "logout",
    } as unknown);
    navigate("/login");
  };

  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-component px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-grey lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span data-cy="admin-sidebar" className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      {/* Separator */}
      <div className="h-6 w-px bg-component lg:hidden" aria-hidden="true" />

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <form
          className="relative flex flex-1 bg-component"
          action="#"
          method="GET"
        >
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-grey"
            aria-hidden="true"
          />
          <input
            id="search-field"
            className="block bg-component h-full w-full border-0 py-0 pl-8 pr-0 text-grey placeholder:text-gray-400 sm:text-sm"
            placeholder="Search..."
            type="search"
            name="search"
          />
        </form>
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-grey hover:text-p6blue"
          >
            <span className="sr-only">View notifications</span>
            <BellIcon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          <div
            className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
            aria-hidden="true"
          />

          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              <img
                className="h-8 w-8 rounded-full"
                src={user.photoURL ? user.photoURL : "/icons/user_icon.svg"}
                alt=""
              />
              <span className="hidden lg:flex lg:items-center">
                <span
                  className="ml-4 text-sm font-semibold leading-6 text-grey"
                  aria-hidden="true"
                >
                  {user.email}
                </span>
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute flex flex-col right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-shaddow py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item>
                  <Link
                    to={"#"}
                    className="block w-full px-3 py-1 text-sm leading-6 text-grey"
                  >
                    Profile
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <div
                    onClick={() => logout()}
                    className="block w-full px-3 py-1 text-sm leading-6 text-grey"
                  >
                    Logout
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}
