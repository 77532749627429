import PaymentMethod from "./add_payment_method";
import { Dispatch, SetStateAction } from "react";
import { type P6PaymentMethod } from "../../../../../src/types/payment/payment_method";

export default function PaymnetMethods({
  addOpen,
  setAddOpen,
  payMethods,
  chargeMethod,
  setChargeMethod,
}: {
  addOpen: boolean;
  setAddOpen: Dispatch<SetStateAction<boolean>>;
  payMethods: P6PaymentMethod[];
  chargeMethod: string;
  setChargeMethod: Dispatch<SetStateAction<string | undefined>>;
}) {
  return (
    <div className="text-white">
      <div className="text-center text-sm mb-3 border-b-[1px]">Payment Methods</div>
      <div>
        {!addOpen && (
          <div
            onClick={() => setAddOpen(!addOpen)}
            className="flex justify-center items-center border-[1px] rounded-lg px-5 py-1.5 cursor-pointer hover:bg-green"
          >
            <img
              src="/icons/add_icon.svg"
              alt="Add Payment"
              className="mr-4 right-[10px] cursor-pointer"
              width={18}
              height={18}
            />
            Add Payment Method
          </div>
        )}
      </div>

      <div className="">
        {payMethods.map((payMethod) => (
          <div
            key={payMethod.id as string}
            onClick={() => setChargeMethod(payMethod.id)}
          >
            <PaymentMethod data={payMethod} chargeMethod={chargeMethod} />
          </div>
        ))}
        {payMethods?.length === 0 && (
          <div className="mt-2.5 flex h-[60px] w-full flex-col justify-center rounded-lg bg-component text-center">
            <p className="text-[16px] font-light">
              No Available Payment Methods
            </p>
            <p className="text-[16px] font-light">
              You must add a payment method to continue.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
