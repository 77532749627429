import { Dispatch, SetStateAction } from "react";
import { P6Fulfillment } from "../../../../../src/types/fulfillment";
import Receive from "./receive";

export default function Layout({
  setOptionsOpen,
  fulfillment,
  creditsToReturn,
}: {
  setOptionsOpen: Dispatch<SetStateAction<boolean>>;
  fulfillment: P6Fulfillment;
  creditsToReturn: number;
}) {
  return (
    <div className="relative text-white">
      <Receive
        setOptionsOpen={setOptionsOpen}
        fulfillment={fulfillment}
        creditsToReturn={creditsToReturn}
      />
    </div>
  );
}
