import EventCard from "../../../components/event_card/event_card";
import { useEffect, useState } from "react";
import {
  DocumentData,
  Query,
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase/clientFirebaseApps";
import { P6Event } from "../../../../../src/types/event";
import { useUser } from "../../../layouts/page_layout/layout";
import { P6Fulfillment } from "../../../../../src/types/fulfillment";

const Action = ({ groupId, admin }: { groupId: string; admin: boolean }) => {
  const [events, setEvents] = useState<P6Event[]>([]);
  const [fulfillments, setFulfillments] = useState<P6Fulfillment[]>([]);
  const { user } = useUser();

  useEffect(() => {
    const eventsRef = collection(db, "events");
    if (admin) {
      const eventsQuery = query(
        eventsRef,
        where("fulfilled", "==", false),
        where("state", "==", "closed"),
        where("groupId", "==", groupId),
        orderBy("closedAt", "desc"),
      );
      const unsubscribe = onSnapshot(eventsQuery, (eventsSnapshot) => {
        const eventsData: P6Event[] = [];
        eventsSnapshot.forEach((doc) => {
          const eventData = doc.data();
          const eventId = doc.id;
          eventData.id = eventId;
          eventsData.push(eventData as P6Event);
        });

        setEvents(eventsData);
      });
      return () => unsubscribe();
    } else {
      const eventsQuery = query(
        eventsRef,
        where("groupId", "==", groupId),
        where("state", "==", "closed"),
        where("postEvent.winnerId", "==", user.userId),
        where("fulfilled", "==", false),
      );
      const unsubscribe = onSnapshot(eventsQuery, (eventsSnapshot) => {
        const eventsData: P6Event[] = [];
        eventsSnapshot.forEach((doc) => {
          const eventData = doc.data();
          const eventId = doc.id;
          eventData.id = eventId;
          eventsData.push(eventData as P6Event);
        });

        setEvents(eventsData);
      });
      return () => unsubscribe();
    }
  }, [admin, groupId, user.userId]);

  useEffect(() => {
    if (events.length > 0 && groupId && user.userId) {
      const fulfillmentsRef = collection(db, "fulfillments");
      let fulfillmentsQuery: Query<DocumentData>;
      if (admin) {
        fulfillmentsQuery = query(
          fulfillmentsRef,
          where("groupId", "==", groupId),
          where("adminId", "==", user.userId),
        );
      } else {
        fulfillmentsQuery = query(
          fulfillmentsRef,
          where("groupId", "==", groupId),
          where("winnerId", "==", user.userId),
        );
      }
      const unsubscribe = onSnapshot(
        fulfillmentsQuery,
        (fulfillmentsSnapshot) => {
          if (fulfillmentsSnapshot.size > 0) {
            const fulfillmentsData: P6Fulfillment[] = [];
            fulfillmentsSnapshot.forEach((doc) => {
              const fulfillmentData = {
                id: doc.id,
                ...doc.data(),
              } as P6Fulfillment;
              fulfillmentsData.push(fulfillmentData);
            });
            setFulfillments(fulfillmentsData);
          } else {
            setFulfillments([]);
          }
        },
      );
      return () => unsubscribe();
    } else {
      setFulfillments([]);
    }
  }, [events, groupId, user.userId, admin]);

  return (
    <div>
      <div className="h-[55px] w-full bg-component px-2.5 pt-2.5">
        <div className="relative">
          <input
            type="text"
            id="email"
            className="peer block w-full appearance-none rounded-full border-[1px] border-grey bg-component px-2.5 pb-1.5 pt-2 pl-10 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
            placeholder=" "
          />
          <img
            src="/icons/magnify_glass.svg"
            alt="Email Icon"
            className="absolute top-[11px] left-[10px] z-10"
            width={17.49}
            height={17.49}
          />
          <label
            htmlFor="email"
            className="z-9 absolute top-0 left-7 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
          >
            Search Action Needed
          </label>
        </div>
      </div>
      {fulfillments.length > 0 &&
        events.map(
          (event) =>
            fulfillments.find((x) => x.eventId === event.id) && (
              <EventCard
                key={event.id}
                data={event}
                user={user}
                fulfillment={fulfillments.find((x) => x.eventId === event.id)}
                admin={admin}
              />
            ),
        )}
      {!fulfillments.length && (
        <div className="flex justify-center items-center mt-4">
          <div className="text-center">
            <div className="text-2xl font-bold">No Actions Needed</div>
            <div className="text-sm text-gray-500">Check back soon!</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Action;
