import { percent, isFull } from "../../utils/functions";

export default function Active({
  description,
  autoRoll,
  seats,
  available,
}: {
  description: string;
  autoRoll: boolean;
  seats: number;
  available: number;
}) {
  return (
    <div>
      <div className="flex">
        <div className="mt-1 mr-2.5 flex h-8 flex-auto text-[13px] leading-4 text-grey line-clamp-2">
          {description}
        </div>
        <div className="mt-1 flex flex-shrink-0 items-center pr-2.5">
          <div className="w-[60]">
            <img
              src="/icons/autoroll_icon.svg"
              alt="Autoroll"
              className={autoRoll ? "" : "hidden"}
              width={24}
              height={25}
            />
          </div>
        </div>
      </div>
      <div className="relative mt-1.5 h-[16px] w-full rounded-full bg-black">
        <div className="absolute m-auto flex h-[16px] w-full items-center justify-center text-[14px]">
          {seats - (available < 0 ? 0 : available)}/{seats}
        </div>
        <div
          className={`${isFull(
            (available < 0 ? 0 : available),
          )} flex h-[16px] items-center justify-center rounded-full`}
          style={{ width: `${percent(seats, (available < 0 ? 0 : available))}%` }}
        ></div>
      </div>
    </div>
  );
}
