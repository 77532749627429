type ErrorName =
  | "InternalError"
  | "UnknownError"
  | "SecurityError"
  | "PaymentError"
  | "InvalidArgument"
  | "SeatPurchaseError"
  | "FunctionError"
  | "UIElementError";

export class P6ClientError extends Error {
  name: ErrorName;
  message: string;
  cause?: Error;
  success?: boolean;
  constructor(name: ErrorName, message: string, cause?: Error) {
    super(message);
    this.name = name;
    this.message = message;
    this.success = false;
    this.cause = cause;
  }
}

// TODO - Talk to Alex about how to do this on client

// Sentry error reporting based on the environment we are running at
// const projectId = process.env.GCP_PROJECT || process.env.GCLOUD_PROJECT;
// if (process.env.NODE_ENV === "production") {
//   let runEnv = "staging";
//   if (projectId === "platform-sixx-prod") {
//     runEnv = "production";
//   }
//   Sentry.init({
//     dsn: "https://29b3ea42bd0d4d79830c61b7e2af4317@o4504979054395392.ingest.sentry.io/4504979056492544",
//     // release: "p6-functions@" + process.env.npm_package_version,
//     tracesSampleRate: 0.8, // This has to be changed to 0.5 probably in production
//     environment: runEnv,
//   });
// }

// function callSentry(error: P6FunctionError) {
//   process.env.NODE_ENV === "production" && Sentry.captureException(error);
// }
