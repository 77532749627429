import { useEffect, useState, Dispatch, type SetStateAction } from "react";
import { clientAuth } from "../../firebase/clientFirebaseApps";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase/clientFirebaseApps";
import {
  collection,
  onSnapshot,
  query,
  where,
  limit,
  doc,
} from "firebase/firestore";
import { useOutletContext } from "react-router-dom";
import { Outlet } from "react-router-dom";
import ActiveList from "../../views/distribution/components/active_list";
import AvalableList from "../../views/distribution/components/available_list";
import LayoutHeader from "./layout_header";
import LayoutSubHeader from "./layout_subheader";
import { type P6UserProfile } from "../../../../src/types/profile";
import { type P6GroupMembership } from "../../../../src/types/groupMembership";
import * as Sentry from "@sentry/react";

type ContextType = {
  user: P6UserProfile;
  setUser: Dispatch<SetStateAction<P6UserProfile>>;
  userId: string;
  activeGroupIds: string[];
  setActiveGroupIds: Dispatch<SetStateAction<string[]>>;
  pendingGroupIds: string[];
  suspendedGroupIds: string[];
  setPaymentOpen: Dispatch<SetStateAction<boolean>>;
  setCreditAmount: Dispatch<SetStateAction<number>>;
};

export default function PageLayout() {
  const [userId, setUserId] = useState<string>();
  const [user, setUser] = useState<P6UserProfile | null | undefined>();
  const [activeGroupIds, setActiveGroupIds] = useState<string[]>([]);
  const [pendingGroupIds, setPendingGroupIds] = useState<string[]>([]);
  const [suspendedGroupIds, setSuspendedGroupIds] = useState<string[]>([]);
  const [paymentOpen, setPaymentOpen] = useState<boolean>(false);
  const [creditAmount, setCreditAmount] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<string>("active");
  const [siteDistributor, setSiteDistributor] = useState(false);
  const auth = clientAuth;
  const navigate = useNavigate();

  // set Sentry to identify the user
  Sentry.setUser({ email: user?.email, id: user?.userId });

  useEffect(() => {
    if (userId) {
      const unsub = onSnapshot(doc(db, "profiles", userId), (doc) => {
        if (doc.data()) {
          const userData = doc.data();
          setUser({ ...(userData as P6UserProfile), userId: doc.id });
          if (userData && userData.completeProfile === false) {
            navigate("/finishprofile/step1");
          }
        } else {
          navigate("/finishprofile/step1");
        }
      });

      return () => unsub();
    }
  }, [userId, navigate]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUser(null);
        navigate("/login");
      }
    });
  }, [auth, navigate]);

  useEffect(() => {
    if (userId) {
      const siteDistributorsRef = collection(db, "distributors");
      const q = query(
        siteDistributorsRef,
        where("userId", "==", userId),
        limit(1),
      );

      const unsubscribeAdmin = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.size === 0) {
          navigate("/feed");
        }
        querySnapshot.docs.forEach((doc) => {
          if (!doc) {
            navigate("/feed");
          }
          if (doc.data().userId !== userId) {
            navigate("/feed");
          } else {
            setCreditAmount(doc.data().credits as number);
            setSiteDistributor(true);
          }
        });
        return () => unsubscribeAdmin();
      });
    }
  }, [navigate, userId]);

  useEffect(() => {
    if (userId) {
      const groupsRef = collection(db, "groupMemberships");
      const groupsQuery = query(groupsRef, where("userId", "==", userId));
      const activeGroupIdsAr: string[] = [];
      const pendingGroupIdsAr: string[] = [];
      const suspendedGroupIdsAr: string[] = [];
      const unsubscribe = onSnapshot(groupsQuery, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const groupM = doc.data() as P6GroupMembership;
          if (groupM.status === "active") {
            activeGroupIdsAr.push(groupM.groupId);
          } else if (groupM.status === "pending") {
            pendingGroupIdsAr.push(groupM.groupId);
          } else if (groupM.status === "suspended") {
            suspendedGroupIdsAr.push(groupM.groupId);
          }
        });
        setActiveGroupIds(activeGroupIdsAr);
        setPendingGroupIds(pendingGroupIdsAr);
        setSuspendedGroupIds(suspendedGroupIdsAr);
      });
      return () => unsubscribe();
    } else {
      setActiveGroupIds([]);
      setPendingGroupIds([]);
      setSuspendedGroupIds([]);
    }
  }, [userId]);

  if (!siteDistributor || !userId || !user) {
    return null;
  }

  return (
    <div className="bg-background">
      <LayoutHeader />
      <LayoutSubHeader
        paymentOpen={paymentOpen}
        setPaymentOpen={setPaymentOpen}
        creditAmount={creditAmount}
        displayName={user.displayName}
        profileImageUrl={user.profileImageUrl}
        user={user}
      />
      <div className="md:container md:mx-auto md:flex">
        <div className="w-full relative">
          <Outlet
            context={{
              user,
              setUser,
              userId,
              activeGroupIds,
              setActiveGroupIds,
              pendingGroupIds,
              suspendedGroupIds,
              setPaymentOpen,
              setCreditAmount,
            }}
          />
        </div>
        <div
          className="ml-2.5 hidden w-[443px] text-white sm:hidden md:sticky md:top-[110px] md:mb-[-110px] md:h-full md:max-h-screen md:max-w-screen-xs  md:shrink-0 md:flex-grow md:flex-col md:overflow-y-auto md:pb-[110px] lg:flex"
          id="inner-scroll"
        >
          <div className="text-sm font-medium text-center border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <ul className="grid grid-cols-2 -mb-px">
              <li
                className={`mr-2 ${
                  activeTab === "active"
                    ? "border-b-2 border-p6blue text-p6blue"
                    : ""
                } cursor-pointer`}
              >
                <div
                  onClick={() => setActiveTab("active")}
                  className="inline-block p-4 rounded-t-lg hover:text-p6blue hover:border-gray-300 dark:hover:text-gray-300"
                >
                  Active
                </div>
              </li>
              <li
                className={`mr-2 ${
                  activeTab === "available"
                    ? "border-b-2 border-p6blue text-p6blue"
                    : ""
                } cursor-pointer`}
              >
                <div
                  onClick={() => setActiveTab("available")}
                  className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                >
                  Available
                </div>
              </li>
            </ul>
          </div>
          {activeTab === "active" && <ActiveList />}
          {activeTab === "available" && <AvalableList />}
        </div>
      </div>
    </div>
  );
}

export function useUser() {
  return useOutletContext<ContextType>();
}
