import { useEffect, useState } from "react";
import { db } from "../../firebase/clientFirebaseApps";
import { collection, query, where, getDocs } from "firebase/firestore";
import { P6Event } from "../../../../src/types/event";
import Item from "../../components/event_widget/item";
import { P6ClientError } from "../../utils/exeptions";

export default function EventsInMini({
  mainEventIds,
}: {
  mainEventIds: string[];
}) {
  const [events, setEvents] = useState<P6Event[]>([]);
  useEffect(() => {
    if (mainEventIds) {
      const eventsRef = collection(db, "events");
      const eventsQuery = query(
        eventsRef,
        where("__name__", "in", mainEventIds),
      );

      (async () => {
        try {
          const eventsSnapshot = await getDocs(eventsQuery);
          const eventsData = eventsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as P6Event[];
          setEvents(eventsData);
        } catch (error) {
          console.error("Error fetching events:", error);
        }
      })().catch((error) => {
        throw new P6ClientError("UnknownError", error as string);
      });
    }
  }, [mainEventIds]);

  return (
    <div className="text-white">
      <div className="p-2.5 text-[22px]">Events included in MINI</div>
      <div className="flex overflow-x-auto max-w-[550px] m-auto">
        {events.map((event) => (
          <div key={event.id}>
            <Item data={event} />
          </div>
        ))}
      </div>
    </div>
  );
}
