import {
  useState,
  useEffect,
  type Dispatch,
  SetStateAction,
  ChangeEvent,
} from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { clientStorage } from "../../../../firebase/clientFirebaseApps";
import { P6GroupBranding } from "../../../../../../src/types/group";
import P6Button from "../../../../components/button";
import { P6ClientError } from "../../../../utils/exeptions";
import clevertap from "clevertap-web-sdk";

export default function Step1({
  setStep,
  branding,
  setBranding,
}: {
  setStep: Dispatch<SetStateAction<number>>;
  branding: P6GroupBranding;
  setBranding: Dispatch<SetStateAction<P6GroupBranding | undefined>>;
}) {
  // this needs to be updated with dynamic values
  const profilePic = "/images/blank_profile.svg";
  const coverPic = "/images/cover-placeholder.svg";

  const [loading] = useState<boolean>(false);
  const [profileImageUrl, setProfileImageUrl] = useState(profilePic);
  const [coverImageUrl, setCoverImageUrl] = useState(coverPic);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedCover, setSelectedCover] = useState<File>();
  const [preview, setPreview] = useState(profilePic);
  const [previewCover, setPreviewCover] = useState(coverPic);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const storage = clientStorage;

  useEffect(() => {
    if (!selectedFile) {
      setPreview(profilePic);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, profilePic]);

  useEffect(() => {
    if (!selectedCover) {
      setPreviewCover(coverPic);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedCover);
    setPreviewCover(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedCover, coverPic]);

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined);
        throw new P6ClientError("UIElementError", "No file was selected.");
      }

      const file = e.target.files[0];
      const uuid: string = self.crypto.randomUUID();
      const storageRef = ref(storage, `groupLogos/${uuid}.${file.name}`);
      if (!storageRef) {
        setSelectedFile(undefined);
        throw new P6ClientError("UIElementError", "Error with file selection.");
      }
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(
        ref(storage, `groupLogos/${uuid}.${file.name}`),
      );
      if (url) {
        setSelectedFile(file);
        setProfileImageUrl(url);
      } else {
        setSelectedFile(undefined);
        throw new P6ClientError(
          "UIElementError",
          "Your profile images was not uploaded.",
        );
      }
    } catch (error) {
      setSelectedCover(undefined);
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  const onSelectCover = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        setSelectedCover(undefined);
        throw new P6ClientError("UIElementError", "No file was selected.");
      }

      const file = e.target.files[0];
      const uuid: string = self.crypto.randomUUID();
      const storageRef = ref(storage, `groupCovers/${uuid}.${file.name}`);
      if (!storageRef) {
        setSelectedCover(undefined);
        throw new P6ClientError("UIElementError", "Error with file selection.");
      }
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(
        ref(storage, `groupCovers/${uuid}.${file.name}`),
      );
      if (url) {
        setSelectedCover(file);
        setCoverImageUrl(url);
      } else {
        setSelectedCover(undefined);
        throw new P6ClientError(
          "UIElementError",
          "Your profile images was not uploaded.",
        );
      }
    } catch (error) {
      setSelectedCover(undefined);
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  useEffect(() => {
    reset(branding);
  }, [branding, reset]);

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    const formData = data as P6GroupBranding;
    formData.logo = profileImageUrl;
    formData.cover = coverImageUrl;
    clevertap.event.push("P6 - Group Step 1");
    setBranding(formData);
    setStep(2);
  };

  return (
    <div className="pt-0">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-5">
          <div className="text-center text-[34px] font-light text-white">
            Group Info
          </div>
          <div className="text-center text-[25px] font-light">Branding</div>
          <div className="mt-2.5 flex justify-center">
            <div className="relative">
              <input
                type="file"
                data-cy="banner-image"
                id="profile-select"
                onChange={onSelectFile}
                hidden
              />
              <div className="relative h-[76px] w-[76px] flex-none overflow-hidden rounded-full">
                <img
                  src={preview}
                  alt="Event"
                  className="cover h-auto w-auto"
                />
              </div>
              <label htmlFor="profile-select">
                <img
                  src="/icons/camera_icon.svg"
                  alt="Edit"
                  className="absolute bottom-[0px] right-0"
                  width={20}
                  height={18}
                />
              </label>
            </div>
          </div>
          <div className="mb-8 mt-4 flex justify-center">
            <div className="relative w-full">
              <input
                type="file"
                data-cy="cover-image"
                id="cover-select"
                onChange={onSelectCover}
                hidden
              />
              <div className="relative h-[200px] w-full flex-none overflow-hidden">
                <img
                  sizes="(max-width: 768px) 100vw,
                  (max-width: 1200px) 50vw,
                  33vw"
                  src={previewCover}
                  alt="Event"
                  className="cover h-auto w-auto"
                />
              </div>
              <label htmlFor="cover-select">
                <img
                  src="/icons/camera_icon.svg"
                  alt="Edit Pic"
                  className="absolute bottom-[10px] right-[10px]"
                  width={20}
                  height={18}
                />
              </label>
            </div>
          </div>
          <div className="relative mb-2.5">
            <input
              type="text"
              id="name"
              className="peer block w-full appearance-none rounded-full border-[1px] border-grey bg-component px-2.5 pb-1.5 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
              placeholder=" "
              {...register("name", {
                required: true,
              })}
            />
            <label
              htmlFor="name"
              className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
            >
              Group Name
            </label>
          </div>
          {errors.groupName && (
            <p className="mt-[-10px] ml-10 mb-2.5 text-red">
              Group Name is Required
            </p>
          )}
          <div className="relative mb-3">
            <input
              type="text"
              id="category"
              className="peer block w-full appearance-none rounded-full border-[1px] border-grey bg-component px-2.5 pb-1.5 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
              placeholder=" "
              {...register("category", {
                required: true,
              })}
            />
            <label
              htmlFor="category"
              className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
            >
              Category
            </label>
          </div>
          {errors.category && (
            <p className="mt-[-10px] ml-10 mb-2.5 text-red">
              Category is Required
            </p>
          )}
          <div className="flex">
            <P6Button type="submit" loading={loading} text="Next" dataCy="next" />
          </div>
        </div>
      </form>
    </div>
  );
}
