import type { Hit as AlgoliaHit } from 'instantsearch.js/es/types';
import { Link } from 'react-router-dom';
import { imageUrl } from '../../../utils/cdnUrl';

import { Snippet } from 'react-instantsearch';

type HitProps = {
  hit: AlgoliaHit<{
    ProductDescription: string;
    Imagename: string;
    FullManufacturerName: string;
    Model: string[];
    status: string;
    RSRPricing: number;
    RetailPrice: number;
  }>;
};

export function Hit({ hit }: HitProps) {

  return (
    <Link to={`/distribution/rsritem/${hit.objectID}`} className={`text-white w-full flex mb-2 ${hit.status === "active"? "bg-green" : "bg-component" }`}>
      <div className="w-12">
        {hit.Imagename &&(
          <img src={imageUrl(hit.Imagename)} className="aspect-square h-14" alt={hit.objectID} />
        )}
      </div>
      <div className="flext-auto ml-3">
        <div className='text-sm'>
          <Snippet hit={hit} attribute="ProductDescription" />
          {hit.ProductDescription}
        </div>
        <div className="space-x-2">
          <span className="bg-grey px-2 rounded-full text-black">{hit.RSRPricing}</span>
          <span className="bg-grey px-2 rounded-full text-black">{hit.RetailPrice}</span>
        </div>
        <div className='text-xs font-thin'>
          By <span className=" font-light">{hit.FullManufacturerName}</span> in {" "}
          <span className=" font-light">{hit.Model}</span>
        </div>
      </div>
    </Link>
  );
}
