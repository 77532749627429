import { initializeApp, getApp, getApps } from "firebase/app";

const clientConfig = {
  apiKey: (import.meta.env.VITE_FB_API_KEY as string) || "",
  authDomain: (import.meta.env.VITE_FB_AUTH_DOMAIN as string) || "",
  projectId: (import.meta.env.VITE_FB_PROJECT_ID as string) || "",
  storageBucket: (import.meta.env.VITE_FB_STORAGE_BUCKET as string) || "",
  messagingSenderId:
    (import.meta.env.VITE_FB_MESSAGING_SENDER_ID as string) || "",
  appId: (import.meta.env.VITE_FB_APP_ID as string) || "",
  measurementId: (import.meta.env.VITE_FB_MEASUREMENT_ID as string) || "",
};

export const app = getApps().length ? getApp() : initializeApp(clientConfig);
