import { Dispatch, SetStateAction } from "react";
import EventCard from "../event_card/event_card";
import { P6Event } from "../../../../src/types/event";
import P6Button from "../button";

export default function Step1({
  eventsWithMini,
  events,
  setEventsWithMini,
  nextStep,
  nextError,
}: {
  eventsWithMini: P6Event[];
  events: P6Event[];
  setEventsWithMini: Dispatch<SetStateAction<P6Event[]>>;
  nextStep: () => void;
  nextError: boolean;
}) {
  return (
    <div className="flex h-full flex-col overflow-y-scroll bg-component shadow-xl">
      <div className="">
        <div className="flex flex-col items-center">
          <div className="text-white text-[28px] text-center leading-5 mt-4">
            Create MINI
          </div>
          <div className="text-grey text-sm text-center w-3/5 leading-4 font-light pt-2">
            Add event to create a mini
          </div>
        </div>
        {eventsWithMini.map((event) => (
          <div key={event.id}>
            <EventCard
              data={event}
              setEventsWithMini={setEventsWithMini}
              minis={eventsWithMini}
              createMiniSection={true}
            />
          </div>
        ))}
      </div>
      <div className="">
        <div className="w-full mt-5 h-px border border-dark-grey"></div>
      </div>
      <div className="relative mt-2.5 flex-1">
        <div className="">
          {events.map((event, index) => (
            <div key={event.id}>
              <EventCard
                data={event}
                setEventsWithMini={setEventsWithMini}
                minis={eventsWithMini}
                index={index + 1}
              />
            </div>
          ))}
        </div>

        <div className="flex p-2.5 mt-4">
          <P6Button
            sm
            div={true}
            onClick={() => nextStep()}
            text="Next"
            color="bg-green"
            textColor="text-white"
          />
        </div>

        {nextError && (
          <p className="form-error text-center mt-5">
            You need to add at least one event to create a mini
          </p>
        )}
      </div>
    </div>
  );
}
