import { useEffect, useState } from "react";
import { P6Transaction } from "../../../../../src/types/transaction";
import { longDate } from "../../../utils/dateFormat";
import { db } from "../../../firebase/clientFirebaseApps";
import { doc, onSnapshot } from "firebase/firestore";
import { P6Event } from "../../../../../src/types/event";
import { P6Seat } from "../../../../../src/types/event";
import { Link } from "react-router-dom";

export default function HistoryItem({
  transaction,
  userId,
}: {
  transaction: P6Transaction;
  userId: string;
}) {
  const [event, setEvent] = useState<P6Event | undefined>();
  const [seat, setSeat] = useState<P6Seat | undefined>();
  const [icon, setIcon] = useState<string | undefined>();
  const [currencyIcon, setCurrencyIcon] = useState<string | undefined>();
  const [winner, setWinner] = useState<boolean>(false);

  // Get event if availalbe
  useEffect(() => {
    if (transaction.eventId) {
      const unsub = onSnapshot(
        doc(db, "events", transaction.eventId),
        (doc) => {
          setEvent({ id: doc.id, ...doc.data() } as P6Event);
        },
      );
      return () => unsub();
    }
  }, [transaction.eventId]);

  // Gets seat if available
  useEffect(() => {
    if (transaction.eventId && transaction.seatId) {
      const unsub = onSnapshot(
        doc(db, "events", transaction.eventId + "/seats/" + transaction.seatId),
        (doc) => {
          setSeat(doc.data() as P6Seat);
        },
      );
      return () => unsub();
    }
  }, [transaction.seatId, transaction.eventId]);

  useEffect(() => {
    switch (transaction.type) {
      case "purchase":
        setIcon("purchase_icon.svg");
        break;
      case "payment":
        setIcon("payment_icon.svg");
        break;
      case "payout":
        setIcon("payout_icon.svg");
        break;
      case "withdraw":
        setIcon("withdraw_icon.svg");
        break;
      case "trade":
        setIcon("trade_icon.svg");
        break;
      case "refund":
        setIcon("refund_icon.svg");
        break;
      case "add_card":
        setIcon("add_card_icon.svg");
        break;
      case "group_credit_add":
        setIcon("group_credits_add_icon.svg");
        break;
      case "group_credit_remove":
        setIcon("group_credits_remove_icon.svg");
        break;
      default:
    }
  }, [transaction.type]);

  useEffect(() => {
    switch (transaction.type) {
      case "withdraw":
        setCurrencyIcon("money_icon.svg");
        break;
      case "payment":
        setCurrencyIcon("money_icon.svg");
        break;
      default:
        setCurrencyIcon("credit_icon.svg");
    }
  }, [transaction.type]);

  useEffect(() => {
    if (
      event &&
      event.postEvent &&
      event.postEvent.winnerId === userId &&
      seat &&
      event.random &&
      seat.number === event.random.random.data[0]
    ) {
      setWinner(true);
    } else {
      setWinner(false);
    }
  }, [event, seat, userId]);

  return (
    <div
      className={`flex text-white bg-component ${
        winner ? "border border-green" : "bg-component"
      } w-full mb-2.5 p-2.5 items-center rounded-lg mx-2.5`}
    >
      <div className="mr-2.5 w-[45px] p-1">
        <img
          src={`/icons/${icon as string}`}
          className="h-[35px] "
          alt={transaction.type}
        />
      </div>
      <div className="flex-auto">
        <div className="flex text-sm text-dark-grey">
          <div className="flex-auto">{longDate(transaction.createdAt)}</div>
          <div className="pr-3">
            {winner ? (
              <span className="bg-green rounded-full text-white px-2">
                {" "}
                Winner
              </span>
            ) : (
              <div>
                {event && event.state === "active" && (
                  <span className="bg-green rounded-full text-white px-2">
                    {" "}
                    Active
                  </span>
                )}
                {event && event.state === "closed" && (
                  <span className="bg-red rounded-full text-white px-2">
                    {" "}
                    Closed
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex-auto line-clamp-1 text-sm">
          {transaction.type === "purchase" && seat && seat.number && (
            <Link to={`/event/${(event && event.id) as string}`}>
              <span className="text-grey">Seat # </span>
              {seat.number}
              <span className="text-grey"> on </span>
              <span className="text-p6blue hover:text-grey">
                {event && event.title}
              </span>
            </Link>
          )}
          {transaction.type === "payment" && (
            <div className="text-grey">You added funds</div>
          )}
          {transaction.type === "payout" && (
            <Link to={`/event/${(event && event.id) as string}`}>
              <div className="text-grey">
                Payout for{" "}
                <span className="text-p6blue hover:text-grey">
                  {event && event.title}
                </span>
              </div>
            </Link>
          )}
          {transaction.type === "withdraw" && <div>Withdraw</div>}
          {transaction.type === "refund" && (
            <div className="text-grey">
              Refund for event {event && event.title}
            </div>
          )}
          {transaction.type === "add_card" && (
            <div className="text-grey">New credit card added.</div>
          )}
          {transaction.type === "trade" && (
            <Link to={`/event/${(event && event.id) as string}`}>
              <div className="text-grey">
                Trade for credits on{" "}
                <span className="text-p6blue hover:text-grey">
                  {event && event.title}
                </span>
              </div>
            </Link>
          )}
          {transaction.type === "group_credit_add" && (
            <Link to={`/event/${(event && event.id) as string}`}>
              <div className="text-grey">
                Credits added to your group account.
              </div>
            </Link>
          )}
          {transaction.type === "group_credit_remove" && (
            <Link to={`/event/${(event && event.id) as string}`}>
              <div className="text-grey">
                Credits removed from group account.
              </div>
            </Link>
          )}
        </div>
      </div>
      <div className="w-[50px]">
        {transaction.type !== "add_card" && (
          <div className="flex items-center">
            <div
              className={`${
                transaction.amount < 0 ? "text-red" : "text-green"
              }`}
            >
              {transaction.amount}
            </div>
            <div className="ml-1 w-[20px]">
              <img
                src={`/icons/${currencyIcon as string}`}
                className="h-[18px] w-[28px]"
                alt={transaction.type}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
