import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import {
  initializeFirestore,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { getPerformance, FirebasePerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";
import { app } from "./app";

// Authorize.net credentials for payments
export const authData = {
  apiLoginID: (import.meta.env.VITE_AN_API_LOGIN_ID as string) || "",
  clientKey: (import.meta.env.VITE_AN_CLIENT_KEY as string) || "",
};

// Client Side configurations for AppCheck and Firebase XSRF protections
// https://firebase.google.com/docs/app-check/web/recaptcha-provider

// used for local emulator to pass app check
// DO NOT USE IN PRODUCTION
declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}
declare global {
  interface Window {
    dataLayer: unknown[];
  }
}
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// Set it for all non-production builds

if (import.meta.env.MODE === "development") {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = import.meta.env
    .VITE_APPCHECK_DEBUG_TOKEN as string;
}

// reCAPTCHAKey is the reCAPTCHA Enterprise site key for AppCheck
const reCAPTCHAKey =
  (import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY as string) || "";

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(reCAPTCHAKey),
  isTokenAutoRefreshEnabled: true,
});

// Initialize performance monitoring only in prod
let perf: FirebasePerformance;
if (import.meta.env.MODE === "production") {
  perf = getPerformance(app);
}
export { perf as clientPerf };

export const clientAuth = getAuth(app);
export const clientStorage = getStorage(app);
export const db = initializeFirestore(app, {
  experimentalForceLongPolling:
    import.meta.env.MODE === "production" ? false : true,
});
export const functions = getFunctions(app);
export const analytics = getAnalytics(app);

// Initialize Firebase emulators in development
if (import.meta.env.MODE !== "production") {
  console.warn("NOTE: Firebase Emulators initialized.");
  connectAuthEmulator(clientAuth, "http://localhost:9099", {
    disableWarnings: true,
  });
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectStorageEmulator(clientStorage, "localhost", 9199);
}
