import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { clientStorage } from "../../../../firebase/clientFirebaseApps";
import { useUser } from "../../../../layouts/finish_profile_layout/layout";
import Progress from "../../../../components/progress/progress";
import type { SubmitHandler } from "react-hook-form";
import { type P6UserProfile } from "../../../../../../src/types/profile";
import P6Button from "../../../../components/button";
import { P6ClientError } from "../../../../utils/exeptions";
import clevertap from "clevertap-web-sdk";

export default function Step1() {
  const { user, setUser, socialPic } = useUser();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line no-undef
  const [selectedFile, setSelectedFile] = useState<
    Blob | MediaSource | undefined
  >();
  const [preview, setPreview] = useState(
    socialPic ? socialPic : "/images/blank_profile.svg",
  );
  const navigate = useNavigate();
  const storage = clientStorage;

  useEffect(() => {
    if (user) {
      reset({
        displayName: user.displayName,
        fullName: user.fullName as string,
        profileImageUrl: user.profileImageUrl,
      } as FieldValues);
    }
  }, [reset, user]);

  useEffect(() => {
    if (user) {
      if (!selectedFile) {
        if (user?.profileImageUrl) {
          setPreview(user.profileImageUrl);
        } else {
          setPreview("/images/blank_profile.svg");
          setUser({
            ...user,
            profileImageUrl: "/images/blank_profile.svg",
          } as P6UserProfile);
        }
        return;
      }

      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile, user, setUser]);

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined);
        throw new P6ClientError("UIElementError", "No file was selected.");
      }

      const file = e.target.files[0];
      const uuid: string = self.crypto.randomUUID();
      const storageRef = ref(storage, `profileImages/${uuid}.${file.name}`);
      if (!storageRef) {
        setSelectedFile(undefined);
        throw new P6ClientError("UIElementError", "Error with file selection.");
      }
      await uploadBytes(storageRef, file);
      const result = await getDownloadURL(
        ref(storage, `profileImages/${uuid}.${file.name}`),
      );
      if (result) {
        setUser({ ...user, profileImageUrl: result } as P6UserProfile);
        setSelectedFile(file);
      } else {
        setSelectedFile(undefined);
        throw new P6ClientError(
          "UIElementError",
          "Your profile images was not uploaded.",
        );
      }
    } catch (error) {
      new P6ClientError("UnknownError", error as string);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setUser({ ...user, ...data } as P6UserProfile);
    setLoading(true);
    clevertap.profile.push({
      Site: {
        Name: data.fullName as string,
      },
    });
    clevertap.event.push("P6 - Profile Step 1");
    navigate("/finishprofile/step2");
  };

  return (
    <Fragment>
      <Progress step={1} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-5">
          <div className="text-center text-[34px] font-light text-white">
            Finish your profile
          </div>
          <div className="text-center text-[25px] font-light">
            Personal information
          </div>
          <div className="my-8 flex justify-center">
            <div className="relative">
              <input
                data-cy="hidden-input"
                type="file"
                id="profile-select"
                onChange={onSelectFile}
                hidden
              />
              <div className="relative h-[76px] w-[76px] flex-none overflow-hidden rounded-full">
                <img src={preview} alt="Event" className="cover" />
              </div>
              <label htmlFor="profile-select">
                <img
                  src="/icons/camera_icon.svg"
                  alt="Edit"
                  className="absolute bottom-[0px] right-0"
                  width={20}
                  height={18}
                />
              </label>
            </div>
          </div>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="displayName"
                autoComplete="username"
                className="text-field peer"
                placeholder=" "
                {...register("displayName", {
                  required: true,
                })}
              />
              <label htmlFor="displayName" className="form-label">
                Display Name
              </label>
            </div>
            {errors.displayName && (
              <p className="form-error">Display Name is Required</p>
            )}
          </div>
          <div className="mb-5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="fullName"
                autoComplete="name"
                className="text-field peer"
                placeholder=" "
                {...register("fullName", {
                  required: true,
                })}
              />
              <label htmlFor="fullName" className="form-label">
                Full Name
              </label>
            </div>
            {errors.fullName && (
              <p className="form-error">Full Name is Required</p>
            )}
          </div>
          <div className="mt-5 grid">
            <P6Button
              type="submit"
              text="Next"
              loading={loading}
              color="bg-green"
              dataCy="next1-button"
            />
          </div>
        </div>
      </form>
    </Fragment>
  );
}
