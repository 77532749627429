export function currency(value: number) {

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const price = value;
const roundedPrice = parseFloat(price.toFixed(2));

  return (
    <span>
      {USDollar.format(roundedPrice)}
    </span>
  );
}