import { Dispatch, SetStateAction } from "react";
import { P6Event } from "../../../../src/types/event";
import NewMiniFrom from "./form";

export default function Step2({
  eventsWithMini,
  groupId,
  setEventsWithMini,
  setStep,
  setOpen,
}: {
  eventsWithMini: P6Event[];
  groupId: string;
  setEventsWithMini: Dispatch<SetStateAction<P6Event[]>>;
  setStep: Dispatch<SetStateAction<number>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="flex h-full flex-col overflow-y-scroll bg-component shadow-xl">
      <div className="px-4 sm:px-6">
        <div className="flex flex-col items-center">
          <div className="text-white text-[28px] text-center leading-5 mt-4">
            Create MINI
          </div>
          <div className="text-grey text-sm text-center w-3/5 leading-4 pb-4 font-light pt-2">
            Edit MINI options
          </div>
        </div>
        <NewMiniFrom
          groupId={groupId}
          setStep={setStep}
          eventsWithMini={eventsWithMini}
          setOpen={setOpen}
          setEventsWithMini={setEventsWithMini}
        />
      </div>
    </div>
  );
}
