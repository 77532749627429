import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import P6Button from "../button";
import { memberRefer } from "../../functions";
import { P6ApiResponse } from "../../../../src/types";
import { P6ClientError } from "../../utils/exeptions";

export default function ReferModal({
  groupName,
  fullName,
  groupId,
}: {
  groupName: string;
  fullName: string;
  groupId: string;
}) {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
    setLoading(true);
    try {
      const request = {
        fullName: fullName,
        email: data.referEmail as string,
        groupName: groupName,
        groupId: groupId,
      };
      const result = await memberRefer(request);
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        setLoading(false);
        setOpen(false);
      } else {
        throw new P6ClientError("FunctionError", "Unable to create group.");
      }
    } catch (error) {
      setLoading(false);
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  return (
    <div>
      <div
        className="cursor-pointer"
        data-cy="refer"
        onClick={() => {
          setOpen(true);
        }}
      >
        <img
          src="/icons/refer_icon.svg"
          alt="Refer"
          className="m-auto pt-2"
          width={40}
          height={30}
        />
        <div className="mt-1">Refer User</div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-2.5 text-center sm:items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative max-w-screen-xs transform overflow-hidden rounded-lg bg-component text-left shadow-xl transition-all ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="bg-component px-2.5">
                      <div className="flex justify-end pt-2.5">
                        <img
                          src="/icons/X_icon.svg"
                          alt="Close"
                          className="h-auto w-auto cursor-pointer"
                          width={17.93}
                          height={17.93}
                          onClick={() => setOpen(false)}
                        />
                      </div>
                      <div className="relative flex flex-col items-center">
                        <div className="mt-2.5 flex">
                          <div className="flex flex-grow items-start justify-center text-sm text-white">
                            <img
                              src="/icons/refer_icon.svg"
                              alt="Refer"
                              className=""
                              width={80.09}
                              height={81.52}
                              onClick={() => setOpen(false)}
                            />
                          </div>
                        </div>
                        <div className="my-2.5 text-center text-white">
                          <div className="text-sm">Invite a friend to:</div>
                          <div>{groupName}</div>
                        </div>
                        <div className="mt-3 w-full text-center sm:mt-0 ">
                          <div className="text-white">
                            <div className="mb-2.5">
                              <div className="relative mb-2.5">
                                <input
                                  type="text"
                                  id="referEmail"
                                  autoComplete="off"
                                  className="text-field peer"
                                  placeholder=" "
                                  {...register("referEmail", {
                                    required: true,
                                  })}
                                />
                                <label
                                  htmlFor="referEmail"
                                  className="form-label"
                                >
                                  Email
                                </label>
                              </div>
                              {errors.referEmail && (
                                <p className="form-error">Email is Required</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full mt-3">
                          <P6Button
                            type="submit"
                            sm
                            text="Refer User"
                            color="bg-green"
                            textColor="text-white"
                            loading={loading}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="py-flex flex-row-reverse justify-center bg-component px-6">
                      <div className="text=sm font-thin text-grey mt-8 mb-3 px-2 leading-4 text-center">
                        The admin of {groupName} will have to approve your
                        invited user before they are added to the group
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
