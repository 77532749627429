import { useUser } from "../../layouts/page_layout/layout";
import BasicInfo from "./basic_info";
import Details from "./details";
import Connect from "./connect";
// import RemoveAccount from "./remove_account";
import Notes from "./notes";

export default function Profile() {
  const { user } = useUser();

  return (
    <div className="flex-auto">
      <div className="text-white">
        <BasicInfo user={user} />
        <Details user={user} />
        <div className="p-2.5 relative">
          <div className="text-xs text-grey font-thin pb-1 pr-2.5 text-right">
            Notes can be viewd and edited by admins
          </div>
          <Notes note={user.notes as string} />
        </div>
        <Connect />
      </div>
      {/* <RemoveAccount /> */}
    </div>
  );
}
