import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { FieldValues, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { clientStorage } from "../../firebase/clientFirebaseApps";
import { createEvent } from "../../functions";
import { type P6ApiResponse } from "../../../../src/types";
import { P6ClientError } from "../../utils/exeptions";
import P6Button from "../button";
import { Switch } from "@headlessui/react";
import { P6CreateEventRequest, P6Event } from "../../../../src/types/event";
import { P6DistributionItem } from "../../../../src/types/distribution";

type formReturn = {
  rounding: string;
  title: string;
  description: string;
  seats: number;
  eventValue: number;
  creditsToReturn: number;
  autoroll: boolean;
  featured: boolean;
  limited: boolean;
  // creditsToReturn: string;
};

interface PreviewFile extends File {
  preview: string;
  downloadUrl: string;
}

export default function NewEventFrom({
  groupId,
  setOpen,
  templateData,
  distributionData,
}: {
  groupId: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  templateData?: P6Event;
  distributionData?: P6DistributionItem;
}) {
  const [files, setFiles] = useState<PreviewFile[]>([]);
  const [imageError, setImageError] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noGroupCredits, setNoGroupCredits] = useState(false);
  // const [roundDown, setRoundDown] = useState(false);
  const [autoRoll, setAutoRoll] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [limited, setLimited] = useState(false);
  const [freeEvent, setFreeEvent] = useState(
    (templateData && templateData.options && templateData.options.freeEvent) ||
      false
  );
  const [saveTemplate, setSaveTemplate] = useState(false);
  const downloadUrls: Array<string> = files?.map(
    (item: { downloadUrl: string }) => {
      return item.downloadUrl;
    }
  );

  useEffect(() => {
    if (templateData && templateData.images && templateData.images.length > 0) {
      const importImages = templateData.images.map((image) => {
        return {
          preview: image,
          downloadUrl: image,
        } as PreviewFile;
      });
      setFiles(importImages);
    }
  }, [templateData]);

  useEffect(() => {
    if (
      distributionData &&
      distributionData.images &&
      distributionData.images.length > 0
    ) {
      const importImages = distributionData.images.map((image) => {
        return {
          preview: image,
          downloadUrl: image,
        } as PreviewFile;
      });
      setFiles(importImages);
    }
  }, [distributionData]);

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      rounding: "roundUp",
      title:
        (templateData && templateData.title) ||
        (distributionData && distributionData.title) ||
        "",
      description: (templateData && templateData.description) || "",
      seats:
        (templateData && templateData.options && templateData.options.seats) ||
        null,
      eventValue: freeEvent
        ? 0
        : (templateData &&
            templateData.options &&
            templateData.options.eventValue) ||
          Math.ceil(
            (distributionData &&
              distributionData.cost &&
              distributionData.cost * 1.1 * 1.0101) as number
          ) ||
          null,
      creditsToReturn:
        (templateData &&
          templateData.options &&
          templateData.options.creditsToReturn) ||
        (distributionData && Math.ceil(distributionData.cost * 0.9)) ||
        null,
      autoroll: false,
      featured: false,
      limited: false,
      isTemplate: false,
      noGroupCredits: false,
    },
  });

  const seats = useWatch({ control, name: "seats" }) as number;
  const eventValue = useWatch({ control, name: "eventValue" }) as number;

  const storage = clientStorage;
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles: File[]) => {
      setImageLoading(true);
      acceptedFiles.map(async (file: File) => {
        const uuid: string = self.crypto.randomUUID();
        setImageError(false);
        const storageRef = ref(storage, `eventImages/${uuid}.${file.name}`);

        await uploadBytes(storageRef, file).then(() => {
          getDownloadURL(ref(storage, `eventImages/${uuid}.${file.name}`))
            .then((url) => {
              if (!url) {
                throw new P6ClientError(
                  "UIElementError",
                  "Unable to get download url for image"
                );
              }
              const preview = {
                preview: URL.createObjectURL(file),
                downloadUrl: url,
              } as PreviewFile;
              setFiles((files) => [...files, preview]);
              setImageLoading(false);
            })
            .catch((error) => {
              setImageLoading(false);
              throw new P6ClientError("UnknownError", error as string);
            });
        });
      });
    },
  });
  // Create a root reference

  const handleRemoveItem = (file: PreviewFile) => {
    setFiles(
      files.filter(
        (files: PreviewFile) => files.downloadUrl !== file.downloadUrl
      )
    );
  };

  const thumbs = files.map((file: PreviewFile) => (
    <div
      className=" mb-[8px] mr-[8px] inline-flex h-[55px] w-[55px] overflow-hidden rounded-lg "
      key={file.downloadUrl}
    >
      <div className="relative flex flex-auto">
        <span
          className="absolute top-[0px] right-[0px] z-50 flex h-[16px] w-[16px] cursor-pointer items-center justify-center rounded-full bg-red text-white"
          //name={file.name}
          onClick={() => handleRemoveItem(file)}
        >
          <img
            src="/icons/delete_icon.svg"
            alt="Delete"
            className=""
            width={7}
            height={7}
          />
        </span>

        <img src={file.preview} alt="Group" className="cover" />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: PreviewFile) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (files.length === 0) {
      setImageError(true);
      reset(data);
    } else {
      setLoading(true);
      const formData = data as formReturn;
      const request: P6CreateEventRequest = {
        title: formData.title,
        description: formData.description,
        groupId: groupId,
        available: formData.seats,
        images: downloadUrls,
        options: {
          rounding: "roundUp",
          seats: formData.seats,
          eventValue: freeEvent ? 0.0 : formData.eventValue,
          creditsToReturn: formData.creditsToReturn || 0.0,
          freeEvent: freeEvent,
          autoRoll: autoRoll,
          featured: featured,
          limited: limited,
          isTemplate: saveTemplate,
          noGroupCredits: noGroupCredits,
          seatPrice: eventValue / seats,
          type: "event",
        },
        state: "active",
        type: "event",
      };

      if (distributionData) {
        request.distributionItem = distributionData.id;
        request.distribution = distributionData.adminId;
        request.distroCost = distributionData.cost;
      }

      createEvent(request)
        .then((result) => {
          const resultData = result.data as P6ApiResponse;
          // Read result of the Cloud Function.
          if (resultData.success) {
            setLoading(false);
            setOpen(false);
            reset();
            window.dataLayer.push({
              event: "create_event",
              eventProps: {
                event_id: resultData.data,
                ...request
              },
            } as unknown);
          } else {
            throw new P6ClientError(
              "FunctionError",
              "Unable to create new event."
            );
          }
        })
        .catch((error: { code: string; message: string; details: string }) => {
          setLoading(false);
          return Promise.reject(
            new P6ClientError("UnknownError", error.message)
          );
        });
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="text-white text-[28px] text-center leading-5 mt-4">
          Create Event
        </div>
        <div className="text-grey text-sm text-center w-3/5 leading-4 font-light pb-3 pt-2">
          Build a custom event tailored to your audience
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="title"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("title", {
                required: true,
              })}
            />
            <label htmlFor="title" className="form-label">
              Title
            </label>
          </div>
          {errors.title && <p className="form-error">Title is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <textarea
              id="description"
              rows={2}
              className="peer block w-full appearance-none rounded-lg border-[1px] border-grey bg-component px-2.5 pb-1 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
              placeholder=" "
              {...register("description", {
                required: true,
              })}
            ></textarea>
            <label
              htmlFor="description"
              className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-5 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
            >
              Description
            </label>
          </div>
          {errors.description && (
            <p className="form-error">Description is Required</p>
          )}
        </div>
        <div className="grid grid-cols-2 gap-2.5">
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="number"
                id="seats"
                autoComplete="off"
                className="text-field peer"
                placeholder=" "
                {...register("seats", {
                  required: true,
                  valueAsNumber: true,
                })}
              />
              <label htmlFor="seats" className="form-label">
                Seats
              </label>
            </div>
            {errors.seats && <p className="form-error">Seats is Required</p>}
          </div>

          <div className="flex text-white items-center mb-2.5 justify-center pb-2 pl-2.5">
            <Switch
              data-cy="free-event"
              checked={freeEvent}
              onChange={setFreeEvent}
              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
            >
              <span
                aria-hidden="true"
                className="pointer-events-none absolute h-full w-full rounded-md"
              />
              <span
                aria-hidden="true"
                className={`${freeEvent ? "bg-green" : "bg-grey"} 
                pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
              />
              <span
                aria-hidden="true"
                className={`${freeEvent ? "translate-x-5" : "translate-x-0"} 
                pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
              />
            </Switch>
            <div className="ml-2 flex flex-auto">
              <div className="text-sm">Free Event</div>
              <div className="">
                <div className="group relative w-max">
                  <img
                    src="/icons/info_icon.svg"
                    className="ml-1.5 h-3 w-3"
                    alt="tt"
                  />
                  <div className="w-[120px] pointer-events-none absolute -top-[70px] right-6 opacity-0 bg-shaddow text-sm p-2 rounded-lg z-40 transition-opacity group-hover:opacity-100 text-white">
                    Set this event seats all free to buy.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2.5 -mt-2.5 relative">
          <div className="">
            <div className="relative">
              <input
                type="number"
                id="creditsToReturn"
                autoComplete="off"
                className="text-field peer pl-9"
                placeholder=" "
                {...register("creditsToReturn", {
                  valueAsNumber: true,
                })}
              />
              <div className="absolute top-0 flex h-[35px] w-[28px] justify-end">
                <img
                  src="/icons/credit_icon.svg"
                  alt="Credit Icon"
                  className="top-[7px] left-[10px] z-10"
                  width={17.49}
                  height={17.49}
                />
              </div>
              <label htmlFor="creditsToReturn" className="form-label ml-7">
                Trade Value
              </label>
            </div>
            {errors.creditsToReturn && (
              <p className="form-error mt-[1px]">Trade Value is Required</p>
            )}
          </div>
          {!freeEvent && (
            <div className="">
              <div className="relative">
                <input
                  type="number"
                  id="eventValue"
                  autoComplete="off"
                  className="text-field peer pl-9"
                  placeholder=" "
                  {...register("eventValue", {
                    required: true,
                    valueAsNumber: true,
                    // min: (distributionData && distributionData.msrp) || 0
                    min:
                      distributionData && distributionData.cost
                        ? distributionData.cost * 1.1 * 1.0101
                        : 0,
                  })}
                />
                <div className="absolute top-0 flex h-[35px] w-[28px] justify-end">
                  <img
                    src="/icons/credit_icon.svg"
                    alt="Credit Icon"
                    className="top-[7px] left-[10px] z-10"
                    width={17.49}
                    height={17.49}
                  />
                </div>
                <label htmlFor="eventValue" className="form-label ml-7">
                  Event Value
                </label>
              </div>
              {errors.eventValue && (
                <p className="form-error mt-[1px]">
                  {(errors.eventValue.type === "required" &&
                    "Event Value is Required") ||
                    (errors.eventValue.type === "min" &&
                      `Event Value must be greater than ${Math.ceil(
                        (distributionData &&
                          distributionData.cost * 1.1 * 1.0101) ||
                          0
                      )}`)}
                </p>
              )}
            </div>
          )}
        </div>
        {distributionData && (
          <div className="text-white border p-2 mt-4 border-dashed flex items-center">
            <div className="w-[150px]">
              <div>MSRP</div>
              <div>Your Cost</div>
              <div>Profit</div>
            </div>
            <div className="ml-4">
              <div>
                <span className="bg-green rounded-full px-2 ml-2">
                  {distributionData.msrp}
                </span>
              </div>
              <div>
                <span className="bg-green rounded-full px-2 ml-2">
                  {distributionData.cost}
                </span>
              </div>
              <div>
                <span className="bg-green rounded-full px-2 ml-2">
                  {Math.ceil(
                    eventValue - distributionData.cost - eventValue * 0.10101
                  )}
                </span>
              </div>
            </div>
            <div className="text-xs text-grey p-4">
              Cost comes out of credits recived at event closing.
            </div>
          </div>
        )}
        <div className="flex justify-end w-full h-5 mt-2">
          {eventValue > 0 && seats > 0 && freeEvent === false && (
            <div className="flex items-center text-white">
              <span className="text-sm">Seat Price</span>
              <div
                data-cy="seat-price"
                className="bg-green rounded-full px-2.5 ml-2 text-sm"
              >
                {eventValue && seats && Math.ceil(eventValue / seats)} ₢
              </div>
            </div>
          )}
        </div>

        {/* Options */}
        <div className="flex text-white items-center mb-2.5">
          <Switch
            data-cy="auto-roll-event"
            checked={autoRoll}
            onChange={setAutoRoll}
            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
          >
            <span
              aria-hidden="true"
              className="pointer-events-none absolute h-full w-full rounded-md"
            />
            <span
              aria-hidden="true"
              className={`${autoRoll ? "bg-green" : "bg-grey"} 
                pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
            />
            <span
              aria-hidden="true"
              className={`${autoRoll ? "translate-x-5" : "translate-x-0"} 
                pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
            />
          </Switch>
          <div className="ml-2 flex flex-auto">
            <div className="text-sm">Auto Roll</div>
            <div className="">
              <div className="group relative w-max">
                <img
                  src="/icons/info_icon.svg"
                  className="ml-1.5 h-3 w-3"
                  alt="tt"
                />
                <div className="w-[120px] pointer-events-none absolute -top-7 left-8 opacity-0 bg-shaddow text-sm p-2 rounded-lg z-40 transition-opacity group-hover:opacity-100 text-white">
                  Wheel will automatically roll when all seats are filled.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex text-white items-center mb-2.5">
          <Switch
            data-cy="feature-event"
            checked={featured}
            onChange={setFeatured}
            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
          >
            <span
              aria-hidden="true"
              className="pointer-events-none absolute h-full w-full rounded-md"
            />
            <span
              aria-hidden="true"
              className={`${featured ? "bg-green" : "bg-grey"} 
                pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
            />
            <span
              aria-hidden="true"
              className={`${featured ? "translate-x-5" : "translate-x-0"} 
                pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
            />
          </Switch>
          <div className="ml-2 flex flex-auto">
            <div className="text-sm">Featured</div>
            <div className="">
              <div className="group relative w-max">
                <img
                  src="/icons/info_icon.svg"
                  className="ml-1.5 h-3 w-3"
                  alt="tt"
                />
                <div className="w-[120px] pointer-events-none absolute -top-7 left-8 opacity-0 bg-shaddow text-sm p-2 rounded-lg z-40 transition-opacity group-hover:opacity-100 text-white">
                  Put this event on the top of the list.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex text-white items-center mb-2.5">
          <Switch
            data-cy="limited-event"
            checked={limited}
            onChange={setLimited}
            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
          >
            <span
              aria-hidden="true"
              className="pointer-events-none absolute h-full w-full rounded-md"
            />
            <span
              aria-hidden="true"
              className={`${limited ? "bg-green" : "bg-grey"} 
                pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
            />
            <span
              aria-hidden="true"
              className={`${limited ? "translate-x-5" : "translate-x-0"} 
                pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
            />
          </Switch>
          <div className="ml-2 flex flex-auto">
            <div className="text-sm">Limited</div>
            <div className="">
              <div className="group relative w-max">
                <img
                  src="/icons/info_icon.svg"
                  className="ml-1.5 h-3 w-3"
                  alt="tt"
                />
                <div className="w-[120px] pointer-events-none absolute -top-7 left-8 opacity-0 bg-shaddow text-sm p-2 rounded-lg z-40 transition-opacity group-hover:opacity-100 text-white">
                  Only allow users to buy one seat in this event.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex text-white items-center mb-2.5">
          <Switch
            data-cy="group-credits-event"
            checked={noGroupCredits}
            onChange={setNoGroupCredits}
            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
          >
            <span
              aria-hidden="true"
              className="pointer-events-none absolute h-full w-full rounded-md"
            />
            <span
              aria-hidden="true"
              className={`${noGroupCredits ? "bg-green" : "bg-grey"} 
                pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
            />
            <span
              aria-hidden="true"
              className={`${noGroupCredits ? "translate-x-5" : "translate-x-0"} 
                pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
            />
          </Switch>
          <div className="ml-2 flex flex-auto">
            <div className="text-sm">No Group Credits</div>
            <div className="">
              <div className="group relative w-max">
                <img
                  src="/icons/info_icon.svg"
                  className="ml-1.5 h-3 w-3"
                  alt="tt"
                />
                <div className="w-[120px] pointer-events-none absolute -top-7 left-8 opacity-0 bg-shaddow text-sm p-2 rounded-lg z-40 transition-opacity group-hover:opacity-100 text-white">
                  Do not allow group credits on this event.
                </div>
              </div>
            </div>
          </div>
        </div>
        {!freeEvent && (
          <div className="flex text-white items-center mb-2.5 mt-2.5">
            {/* <Switch
              checked={roundDown}
              onChange={setRoundDown}
              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
            >
              <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md" />
              <span
                aria-hidden="true"
                className={`${roundDown ? "bg-green" : "bg-grey"} 
                  pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
              />
              <span
                aria-hidden="true"
                className={`${roundDown ? "translate-x-5" : "translate-x-0"} 
                  pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
              />
            </Switch> */}
            {/* <div className="ml-2 flex flex-auto">
              <div className="text-sm">Round Down</div>
              <div className="">
                <div className="group relative w-max">
                  <img src="/icons/info_icon.svg" className="ml-1.5 h-3 w-3" alt="tt" />
                  <div
                    className="w-[120px] pointer-events-none absolute -top-7 left-8 opacity-0 bg-shaddow text-sm p-2 rounded-lg z-40 transition-opacity group-hover:opacity-100 text-white"
                  >
                    Round down allows you to set the value of each seat to be rounded down to the nearest whole number.
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        )}

        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps({ id: "event-drop-zone" })} />
          <p className="mt-5 border-[1px] border-dashed p-5 text-center text-white cursor-pointer">
            Drag files here, or click to select files
          </p>
        </div>
        {imageError && (
          <p className="mt-[0px] ml-10 mb-2.5 text-red">1 Image is Required</p>
        )}

        <aside className="mt-[16px] flex flex-row flex-wrap">{thumbs}</aside>

        <div className="flex text-white">
          <P6Button
            sm
            type="submit"
            text="Add Event"
            loading={loading || imageLoading}
            color="bg-green"
          />
        </div>
        {templateData === undefined && (
          <div className="flex text-white items-center mb-2.5 mt-2.5">
            <Switch
              checked={saveTemplate}
              onChange={setSaveTemplate}
              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
            >
              <span
                aria-hidden="true"
                className="pointer-events-none absolute h-full w-full rounded-md"
              />
              <span
                aria-hidden="true"
                className={`${saveTemplate ? "bg-green" : "bg-grey"} 
                  pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
              />
              <span
                aria-hidden="true"
                className={`${saveTemplate ? "translate-x-5" : "translate-x-0"} 
                  pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
              />
            </Switch>
            <div className="ml-2 flex flex-auto">
              <div className="text-sm">Save to Templates</div>
              <div className="">
                <div className="group relative w-max">
                  <img
                    src="/icons/info_icon.svg"
                    className="ml-1.5 h-3 w-3"
                    alt="tt"
                  />
                  <div className="w-[120px] pointer-events-none absolute -top-7 left-8 opacity-0 bg-shaddow text-sm p-2 rounded-lg z-40 transition-opacity group-hover:opacity-100 text-white">
                    Allows you to save this event as a template to be reused.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
