import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FieldValues } from "react-hook-form";
import { useUser } from "../../../../layouts/finish_profile_layout/layout";
import Progress from "../../../../components/progress/progress";
import SelectState from "../../../../components/form_fields/select_state";
import type { SubmitHandler } from "react-hook-form";
import { type P6UserProfile } from "../../../../../../src/types/profile";
import P6Button from "../../../../components/button";
import clevertap from "clevertap-web-sdk";

export default function Step2() {
  const { user, setUser } = useUser();
  const {
    register,
    setError,
    setValue,
    reset,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<FieldValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    // reset form with user data
    reset(user?.address as FieldValues);
  }, [user, reset]);

  const onSubmit: SubmitHandler<FieldValues> = (params) => {
    if (!params.state) {
      setError("state", { type: "custom", message: "custom message" });
    } else {
      setLoading(true);
      setUser({ ...user, address: params } as P6UserProfile);
      clevertap.event.push("P6 - Profile Step 2");
      navigate("/finishprofile/step3");
    }
  };

  const onError = () => {
    if (!getValues("state")) {
      setError("state", { type: "custom", message: "custom message" });
    }
  };

  return (
    <div className="w-full">
      <Progress step={2} />

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="p-5">
          <div className="text-center text-[34px] font-light text-white">
            Finish your profile
          </div>
          <div className="mb-8 text-center text-[25px] font-light">Address</div>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="address"
                autoComplete="address-line1"
                className="text-field peer"
                placeholder=" "
                {...register("address", {
                  required: true,
                })}
              />
              <label htmlFor="address" className="form-label">
                Address
              </label>
            </div>
            {errors.address && (
              <p className="form-error">Address is Required</p>
            )}
          </div>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="address2"
                autoComplete="address-line2"
                className="text-field peer"
                placeholder=" "
                {...register("address2", {
                  required: false,
                })}
              />
              <label htmlFor="address2" className="form-label">
                Address 2
              </label>
            </div>
            {errors.address2 && (
              <p className="form-error">Address 2 is Required</p>
            )}
          </div>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="city"
                autoComplete="address-level2"
                className="text-field peer"
                placeholder=" "
                {...register("city", {
                  required: true,
                })}
              />
              <label htmlFor="city" className="form-label">
                City
              </label>
            </div>
            {errors.city && <p className="form-error">City is Required</p>}
          </div>
          <SelectState
            errors={errors}
            setValue={setValue}
            clearErrors={clearErrors}
            defaultValue={getValues("state") as string}
          />
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="zip"
                autoComplete="postal-code"
                className="text-field peer"
                placeholder=" "
                {...register("zip", {
                  required: true,
                })}
              />
              <label htmlFor="zip" className="form-label">
                Zip Code
              </label>
            </div>
            {errors.zip && <p className="form-error">Zip Code is Required</p>}
          </div>
          <div className="mt-5 grid grid-cols-2 gap-2.5">
            <P6Button dataCy="back-button" text="Back" link="/finishprofile/step1" />
            <P6Button
              type="submit"
              text="Next"
              loading={loading}
              color="bg-green"
              dataCy="next2-button"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
