import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import TermsText from "../terms/text";

export default function TermsModal() {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  return (
    <>
      <span
        className="cursor-pointer"
        data-cy="terms-modal"
        onClick={() => {
          setOpen(true);
        }}
      >
        Terms and Services
      </span>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-2.5 text-center sm:items-center sm:p-0">
              <div className="fixed top-0 z-50 flex w-[350px] justify-end pt-2.5 xs:w-[400px] sm:w-[443px]">
                <img
                  src="icons/X_icon.svg"
                  className="h-auto w-auto cursor-pointer p-2.5"
                  width={17.93}
                  height={17.93}
                  alt="close"
                  data-cy="close-terms-modal"
                />
              </div>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative max-w-screen-xs transform overflow-hidden rounded-lg bg-component text-left shadow-xl transition-all sm:my-2.5">
                  <div className="bg-component px-2.5 sm:pb-4">
                    <TermsText />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
