import { Dispatch, SetStateAction, useState } from "react";
import OptionButtons from "./option_buttons";
import PickupOption from "./pickup_option";
import ShipOption from "./ship_option";
import { P6Fulfillment } from "../../../../../src/types/fulfillment";
import { P6Timestamp } from "../../../../../src/types/helpers";

export default function Layout({
  setOptionsOpen,
  setReceiveOpen,
  fulfillment,
  eventId,
  createdAt,
}: {
  setOptionsOpen: Dispatch<SetStateAction<boolean>>;
  setReceiveOpen: Dispatch<SetStateAction<boolean>>;
  fulfillment: P6Fulfillment;
  eventId: string;
  createdAt: P6Timestamp;
}) {
  const [shipOption, setShipOption] = useState(false);
  const [pickupOption, setPickupOption] = useState(false);

  return (
    <div className="relative text-white">
      <div className="flex w-full mt-2">
        <div className="flex flex-grow items-center justify-center"></div>
      </div>
      {!shipOption && !pickupOption && (
        <OptionButtons
          fulfillment={fulfillment}
          setShipOption={setShipOption}
          setPickupOption={setPickupOption}
          setReceiveOpen={setReceiveOpen}
          setOptionsOpen={setOptionsOpen}
          eventId={eventId}
          createdAt={createdAt}
        />
      )}
      {shipOption && (
        <ShipOption
          setOptionsOpen={setOptionsOpen}
          fulfillment={fulfillment}
          eventId={eventId}
        />
      )}
      {pickupOption && (
        <PickupOption
          setOptionsOpen={setOptionsOpen}
          fulfillmentId={fulfillment.id}
          eventId={eventId}
        />
      )}
    </div>
  );
}
