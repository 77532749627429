import EventCard from "../../../components/event_card/event_card";
import { useEffect, useState } from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase/clientFirebaseApps";
import { P6Event } from "../../../../../src/types/event";
import { eventsRollUp } from "../../../utils/eventsRollUp";
import EventRollUpComponent from "../../shared/eventRollUpComponent";

const Active = ({ groupId }: { groupId: string }) => {
  const [events, setEvents] = useState<P6Event[]>([]);

  useEffect(() => {
    const eventsRef = collection(db, "events");
    const eventsQuery = query(
      eventsRef,
      where("groupId", "==", groupId),
      where("state", "==", "active"),
      orderBy("options.featured", "desc"),
      orderBy("createdAt", "desc"),
    );
    const unsubscribe = onSnapshot(eventsQuery, (eventsSnapshot) => {
      if (eventsSnapshot.size > 0) {
        const eventsData: P6Event[] = [];
        eventsSnapshot.forEach((doc) => {
          const eventData = doc.data();
          const eventId = doc.id;
          eventData.id = eventId;
          eventsData.push(eventData as P6Event);
        });
        // Get the nested structure of the event minis, micros and nano
        const updatedEvents = eventsRollUp(eventsData);
        setEvents(updatedEvents);
      } else {
        setEvents([]);
      }
    });

    return () => unsubscribe();
  }, [groupId]);

  return (
    <>
      {events.map((event, index) => (
        <div key={event.id} data-cy={`event-${index + 1}-in-active-group`}>
          <EventCard data={event} />
          {/* mini details */}
          {event?.events && <EventRollUpComponent events={event.events} />}
        </div>
      ))}
      {!events.length && (
        <div className="flex justify-center items-center mt-4">
          <div className="text-center">
            <div className="text-2xl font-bold">No Active Events</div>
            <div className="text-sm text-gray-500">Check back soon!</div>
          </div>
        </div>
      )}
    </>
  );
};
export default Active;
