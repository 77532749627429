import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { P6Event } from "../../../../src/types/event";
import { clientAuth, db } from "../../firebase/clientFirebaseApps";
import { doc, onSnapshot } from "firebase/firestore";
import { P6UserProfile } from "../../../../src/types/profile";
import { signInWithCustomToken } from "firebase/auth";
import { P6ClientError } from "../../utils/exeptions";


export default function ClosedEventIos({}) {
  const { token, eventId } = useParams();
  const [event, setEvent] = useState<P6Event>();
  const [winnerName, setWinnerName] = useState<string>("");
  const auth = clientAuth;
  
  // Get the event data
  useEffect(() => {
    if (eventId) {
      const unsubscribe = onSnapshot(doc(db, "events", eventId), (doc) => {
        const event = doc.data() as P6Event;
        if (event) {
          event.id = doc.id;
          setEvent(event);
        }
      });
      return () => {
        unsubscribe();
      };
    }
  }, [eventId]);

  useEffect(() => {
    if (event && event.postEvent && event.postEvent.winnerId) {
      const unsubscribe = onSnapshot(
        doc(db, "profiles", event.postEvent.winnerId),
        (doc) => {
          if (doc.data()) {
            const profile = doc.data() as P6UserProfile;
            setWinnerName(profile.displayName);
          }
        },
      );
      return () => unsubscribe();
    }
  }, [event]);


  useEffect(() => {
    (async () => {
      try {
        if (token) {
          const encodedToken = atob(token);
          await signInWithCustomToken(auth, encodedToken);
        }
      } catch (error) {
        console.error('Error signing in with ID token:', error);
      }
    })().catch((error) => {
      throw new P6ClientError("UnknownError", error as string);
    });
  }, [token, auth]);

  return (
    <div className= "text-white font-bold flex items-center text-2xl pt-10">
      <div className="flex items-center m-auto pt-10">
        {event?.random?.random?.data.map((number: number) => (
          <div
            key={number}
            className="flex rounded-lg bg-black mr-2.5 px-2 text-2xl "
          >
            #{number}
          </div>
        ))}
        {event?.random?.random?.data.length === 1 && (
          <div className="flex">{winnerName}</div>
        )}
      </div>
    </div>
  );
}
