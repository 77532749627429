import { useState, Dispatch, SetStateAction } from "react";
import { type P6PayoutMethod } from "../../../../../src/types/payment/payout_method";
import { FieldValues, useForm, SubmitHandler } from "react-hook-form";
import P6Button from "../../button/";
import { createPayoutTransfer } from "../../../functions";
import { type P6ApiResponse } from "../../../../../src/types";

export default function PayoutAmount({
  setAccount,
  account,
  groupId,
}: {
  setAccount: Dispatch<SetStateAction<P6PayoutMethod | undefined>>;
  account: P6PayoutMethod;
  groupId: string | undefined;
}) {
  const [requetError, setRequetError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (groupId) {
      setLoading(true);
      const request = {
        amount: data.amount as number,
        recipientId: account.id,
        groupId: groupId,
      };
      const result = await createPayoutTransfer(request);
      const resultData = result.data as P6ApiResponse;
      if (resultData.success === true) {
        setAccount(undefined);
        setLoading(false);
        reset();
      } else {
        setLoading(false);
        setRequetError(resultData.message);
      }
    }
  };

  return (
    <div className="text-white">
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-2.5">
            <div className="mb-2.5 flex-auto">
              <div className="relative mb-2.5">
                <input
                  type="number"
                  id="amount"
                  autoComplete="off"
                  className="text-field peer pl-9"
                  placeholder=" "
                  {...register("amount", {
                    required: true,
                  })}
                />
                <div className="absolute top-0 flex h-[35px] w-[28px] justify-end">
                  <img
                    src="/icons/money_icon.svg"
                    alt="Money Icon"
                    className="top-[9px] left-[10px] z-10"
                    width={12}
                    height={12}
                  />
                </div>
                <label htmlFor="amount" className="form-label ml-7">
                  Ammount
                </label>
              </div>
              {errors.amount && <p className="form-error"> is Required</p>}
            </div>
            <div className="min-w-[170px] h-[35px] flex">
              <P6Button type="submit" text="Withdraw" loading={loading} />
            </div>
          </div>
          <span
            onClick={() => setAccount(undefined)}
            className="cursor-pointer ml-2.5 float-right mb-3 mr-2.5"
          >
            clear
          </span>
        </form>
        {requetError && <p className="form-error">{requetError}</p>}
      </div>
    </div>
  );
}
