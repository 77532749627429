import { useState, useEffect } from "react";
import { P6Event } from "../../../../src/types/event";
import { percent, isFull } from "../../utils/functions";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";
import { P6UserProfile } from "../../../../src/types/profile";

export default function Closed({ data }: { data: P6Event }) {
  const [winnerName, setWinnerName] = useState<string>("");

  useEffect(() => {
    if (data && data.postEvent && data.postEvent.winnerId) {
      const unsubscribe = onSnapshot(
        doc(db, "profiles", data.postEvent.winnerId),
        (doc) => {
          if (doc.data()) {
            const profile = doc.data() as P6UserProfile;
            setWinnerName(profile.displayName);
          }
        },
      );
      return () => unsubscribe();
    }
  }, [data]);

  return (
    <div>
      <div className="mt-1 flex items-center ">
        {data?.random?.random?.data.map((number: number) => (
          <div
            key={number}
            className="flex rounded-lg bg-black mr-2.5 px-2 text-[20px]"
          >
            #{number}
          </div>
        ))}
        {data?.random?.random?.data.length === 1 && (
          <div className="flex">{winnerName}</div>
        )}
      </div>
      <div className="relative mt-1.5 h-[16px] w-full rounded-full bg-black">
        <div className="absolute m-auto flex h-[14px] w-full items-center justify-center text-[14px]">
          {data.options.seats - data.available}/{data.options.seats}
        </div>
        <div
          className={`${isFull(
            data.available,
          )} flex h-[16px] items-center justify-center rounded-full `}
          style={{ width: `${percent(data.options.seats, data.available)}%` }}
        ></div>
      </div>
    </div>
  );
}
