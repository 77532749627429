import { useEffect, useState } from "react";
import { db } from "../../../firebase/clientFirebaseApps";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import ListItem from "./list_item";
import { type P6DistributionItem } from "../../../../../src/types/distribution";

export default function ActiveList() {
  const [activeList, setActiveList] = useState<P6DistributionItem[]>([]);

  useEffect(() => {
    const q = query(
      collection(db, "distribution"),
      where("status", "==", "active"),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const activeItems: P6DistributionItem[] = [];
      querySnapshot.forEach((doc) => {
        activeItems.push({ id: doc.id, ...doc.data() } as P6DistributionItem);
      });

      setActiveList(activeItems);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <div className="text-white pt-3">
      {activeList.map((item) => (
        <ListItem key={item.id} item={item} />
      ))}
    </div>
  );
}
