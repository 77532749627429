import { Dispatch, SetStateAction } from "react";
import P6Button from "../../button";
import { P6Fulfillment } from "../../../../../src/types/fulfillment";
import { P6Timestamp } from "../../../../../src/types/helpers";

export default function OptionButtons({
  setShipOption,
  setPickupOption,
  setReceiveOpen,
  setOptionsOpen,
  fulfillment,
  createdAt,
}: {
  setShipOption: Dispatch<SetStateAction<boolean>>;
  setPickupOption: Dispatch<SetStateAction<boolean>>;
  setReceiveOpen: Dispatch<SetStateAction<boolean>>;
  setOptionsOpen: Dispatch<SetStateAction<boolean>>;
  fulfillment: P6Fulfillment;
  eventId: string;
  createdAt: P6Timestamp;
}) {
  const trade = () => {
    setReceiveOpen(true);
    setOptionsOpen(false);
  };

  return (
    <>
      <div className="grid grid-cols-2 mt-4">
        <div className="text-center p-2 flex flex-col">
          <div className="text-xl font-bold">Fulfillment Options</div>
          <div className="text-sm text-opacity-50 text-white leading-4 pb-2">
            Shipped, pick it up in-store, or trade for Platform Sixx credits.
          </div>
        </div>
        <div className="flex flex-col items-center space-y-3 mb-6 pr-2">
          <div className="flex w-full" data-cy="ship-option">
            <P6Button
              sm
              text="Ship"
              iconLeft
              icon="ship_white_icon"
              onClick={() => setShipOption(true)}
            />
          </div>
          {fulfillment && !fulfillment.distribution && (
            <div className="flex w-full" data-cy="pickup-option">
              <P6Button
                sm
                text="Pickup"
                iconLeft
                icon="pickup_white_icon"
                onClick={() => setPickupOption(true)}
              />
            </div>
          )}
          <div className="flex w-full" data-cy="trade-option">
           { new Date("2023-08-06").getTime() < createdAt.seconds * 1000 && <P6Button
              sm
              text="Trade for Credits"
              iconLeft
              icon="trade_white_icon"
              onClick={() => trade()}
            />}
          </div>
        </div>
      </div>
    </>
  );
}
