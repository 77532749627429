export default function FilledActions({
  autoRoll,
  spinWheel,
  counter,
}: {
  autoRoll: boolean;
  spinWheel: boolean;
  counter: number;
}) {
  return (
    <div>
      {/* autoroll */}
      {autoRoll && (
        <div className="">
          <div className="relative my-2.5 flex bg-component p-2.5 text-white h-[100px]">
            <div className="absolute top-6 left-10">
              <img
                src="/icons/autoroll_icon.svg"
                alt="Auto Roll"
                className=""
                width={44.16}
                height={46}
              />
            </div>
            <div className="flex w-full flex-col items-center">
              {
                <div>
                  {counter && counter < 0
                    ? "Get Ready!"
                    : " Roll will start in:"}
                </div>
              }
              <div className={`text-[34px] leading-[54px]`}>
                {counter < 10 && (
                  <div className="text-[35px] leading-[40px]">
                    Roll in Progress
                  </div>
                )}
                {counter > 10 && (
                  // counter herec
                  <div className="text-[55px]">{counter - 10}</div>
                )}
                {/* {(counter && counter < 0) ? "Roll in Progress" : counter} */}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* manual roll */}
      {!autoRoll && (
        <div className="">
          <div className="relative my-2.5 flex bg-component p-2.5 text-white">
            <div className="flex w-full flex-col items-center">
              <div className="text-[35px] leading-[40px]">Manual Roll</div>
              {spinWheel ? (
                <div>Roll in Progress</div>
              ) : (
                <div>Admin will manually start the roll, check back.</div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
