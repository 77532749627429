import { P6Fulfillment } from "../../../../src/types/fulfillment";
import { P6Timestamp } from "../../../../src/types/helpers";
import { shortDate } from "../../utils/dateFormat";

export default function WonIcons({
  fulfillment,
  closedAt,
}: {
  fulfillment: P6Fulfillment;
  closedAt: P6Timestamp;
}) {
  const iconType = (fulfillment: P6Fulfillment): JSX.Element => {
    let shipIcon: JSX.Element;
    switch (fulfillment.fulfillmentType) {
      case "pickup":
        return !fulfillment.receivedDate && fulfillment?.pickedUpBy ? (
          <img
            src="/icons/pickup_icon_green.svg"
            alt="Pickup"
            className="mx-[2px] mb-[10px]"
            width={20}
            height={20}
          />
        ) : (
          <img
            src="/icons/pickup_icon_on.svg"
            alt="Pickup"
            className="mx-[2px]"
            width={20}
            height={20}
          />
        );
      case "ship":
        shipIcon =
          fulfillment.shipDate && !fulfillment?.receivedDate ? (
            <img
              src="/icons/ship_icon_green.svg"
              alt="ship"
              className="mx-[2px] mb-[1px]"
              width={20}
              height={20}
            />
          ) : (
            <img
              src="/icons/ship_icon_on.svg"
              alt="ship"
              className="mx-[2px] mb-[1px]"
              width={20}
              height={20}
            />
          );
        return fulfillment.shipDate ? (
          shipIcon
        ) : (
          <img
            src="/icons/ship_icon.svg"
            alt="ship"
            className="mx-[2px] mb-[10px]"
            width={20}
            height={20}
          />
        );
      case "trade":
        return (
          <img
            src="/icons/trade_icon_on.svg"
            alt="Pickup"
            className="mx-[2px]"
            width={15}
            height={20}
          />
        );
      default:
        return (
          <img
            src="/icons/question_icon.svg"
            alt="Pickup"
            className="mx-[2px] mb-[10px]"
            width={20}
            height={20}
          />
        );
    }
  };

  return (
    <div className="col-span-2 flex  items-center px-3">
      <div>
        <img
          src="/icons/bag_icon_on.svg"
          alt="Items Won"
          className="mx-[2px]"
          width={16}
          height={20}
        />
        {
          <div className="flex h-3 w-[20px] justify-center text-[10px] text-p6blue">
            {closedAt && shortDate(closedAt)}
          </div>
        }
      </div>
      <div className="mb-3 w-[30px] border-b-[1px]" />
      <div>
        <img
          src={
            fulfillment?.optionSelectedDate
              ? "/icons/action_icon_on.svg"
              : "/icons/action_icon_green.svg"
          }
          alt="Action"
          className={`mx-[2px] ${
            fulfillment?.optionSelectedDate ? "" : "mb-[10px]"
          } width={20} height={20}`}
        />
        {fulfillment?.optionSelectedDate && (
          <div className="flex h-3 w-[20px] justify-center text-[10px] text-p6blue">
            {shortDate(fulfillment.optionSelectedDate)}
          </div>
        )}
      </div>
      <div className="mb-3 w-[30px] border-b-[1px]" />
      <div>
        {fulfillment && iconType(fulfillment)}
        {fulfillment?.shipDate && !fulfillment?.receivedDate && (
          <div
            className={`flex h-3 w-[20px] justify-center text-[10px] mt-[5px] text-green`}
          >
            {shortDate(fulfillment?.shipDate)}
          </div>
        )}
        {fulfillment?.receivedDate && (
          <div
            className={`flex h-3 w-[20px] justify-center text-[10px] mt-[${
              fulfillment.fulfillmentType === "trade" ? 0 : "5px"
            }] text-p6blue`}
          >
            {shortDate(fulfillment?.receivedDate)}
          </div>
        )}
      </div>
      <div className="mb-3 w-[30px] border-b-[1px]" />
      <div>
        <img
          src={
            fulfillment?.receivedDate
              ? "/icons/check_icon_green.svg"
              : "/icons/check_icon.svg"
          }
          alt="Check"
          className={`mx-[2px] ${fulfillment?.receivedDate ? "" : "mb-[10px]"}`}
          width={20}
          height={20}
        />
        {fulfillment?.receivedDate && (
          <div
            className={`flex h-3 w-[20px] justify-center text-[10px] text-green`}
          >
            {shortDate(fulfillment?.receivedDate)}
          </div>
        )}
      </div>
    </div>
  );
}
