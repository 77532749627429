import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import NewEventForm from "./form";
import P6Button from "../button";
import Templates from "./templates";
import { P6Event } from "../../../../src/types/event";
import Distribution from "./distribution";
import { P6DistributionItem } from "../../../../src/types/distribution";

export default function NewEventSlidout({
  groupId,
  template,
}: {
  groupId: string;
  template?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [templateData, setTemplateData] = useState<P6Event | undefined>();
  const [distributionData, setDistributionData] = useState<
    P6DistributionItem | undefined
  >();
  const [customEvent, setCustomEvent] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    setTemplateData(undefined);
  };

  const clearData = () => {
    setTemplateData(undefined);
    setDistributionData(undefined);
    setCustomEvent(false);
  };

  return (
    <div>
      {template ? (
        <div className="flex px-2">
          <P6Button
            div
            text="Saved"
            icon="add_icon"
            sm
            iconTop="6"
            color="bg-p6blue"
            onClick={() => {
              setOpen(!open);
              setTemplateData(undefined);
            }}
          />
        </div>
      ) : (
        <div className="flex px-2">
          <P6Button
            div
            text="Event"
            icon="add_icon"
            sm
            iconTop="6"
            color="bg-green"
            onClick={() => {
              setOpen(!open);
              setTemplateData(undefined);
            }}
          />
        </div>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative z-50 w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4"></div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-component shadow-xl">
                      <div className="relative flex-1 px-4">
                        {/* Replace with your content */}
                        {(templateData || distributionData || customEvent) && (
                          <div
                            className="text-white flex items-center cursor-pointer mt-2"
                            onClick={() => clearData()}
                          >
                            <img
                              src="/icons/back_icon.svg"
                              alt="Back"
                              className="inline-block mr-2 w-3 h-3"
                            />
                            <div className="text-p6blue text-sm">Back</div>
                          </div>
                        )}
                        {template && !templateData && !distributionData ? (
                          <Templates
                            groupId={groupId}
                            setTemplateData={setTemplateData}
                          />
                        ) : (
                          <div>
                            {customEvent || distributionData || templateData ? (
                              <NewEventForm
                                groupId={groupId}
                                setOpen={setOpen}
                                templateData={templateData}
                                distributionData={distributionData}
                              />
                            ) : (
                              <div>
                                <div
                                  className="flex items-center mt-3 cursor-pointer"
                                  onClick={() => setCustomEvent(true)}
                                >
                                  <img
                                    src="/icons/plus_icon.svg"
                                    className="w-[35px] h-[35px] object-cover"
                                    alt="title"
                                  />
                                  <div className="flex-auto ml-2 py-3">
                                    <div className="text-lg text-white line-clamp-1">
                                      Create a custom event
                                    </div>
                                  </div>
                                </div>
                                <div className="text-white mt-2 font-bold">
                                  Distribution Network:
                                </div>
                                <div className="text-grey text-sm">
                                  Say goodbye to the hassles of inventory
                                  management, order fulfillment, and shipping,
                                  while enjoying the added benefit of reduced
                                  dealer costs. Experience the convenience of
                                  streamlined operations, increased efficiency,
                                  and higher profitability. With our platform,
                                  you can focus on what you do best, while we
                                  take care of the rest. Don&#x27;t let
                                  inventory challenges hold you back, thrive in
                                  the world of hassle-free distribution.
                                </div>
                                <Distribution
                                  setCustomEvent={setCustomEvent}
                                  setDistributionData={setDistributionData}
                                />
                              </div>
                            )}
                          </div>
                        )}

                        {/* /End replace */}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
