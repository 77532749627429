import EventCard from "../../../components/event_card/event_card";
import { useEffect, useState } from "react";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  doc,
  DocumentData,
  Query,
  startAfter,
  limit,
} from "firebase/firestore";
import { db } from "../../../firebase/clientFirebaseApps";
import { P6Event } from "../../../../../src/types/event";
import { P6Fulfillment } from "../../../../../src/types/fulfillment";
import { useUser } from "../../../layouts/page_layout/layout";
import { P6GroupMembership } from "../../../../../src/types/groupMembership";
import { P6Timestamp } from "../../../../../src/types/helpers";

const History = ({ groupId }: { groupId: string }) => {
  const [events, setEvents] = useState<P6Event[]>([]);
  const [fulfillments, setFulfillments] = useState<P6Fulfillment[]>([]);
  const { user, userId } = useUser();
  const [admin, setAdmin] = useState<boolean>(false);
  const [firstTime, setFirstTime] = useState(true);
  const [lastDate, setLastDate] = useState<P6Timestamp>({
    nanoseconds: 0,
    seconds: -62135596800,
  }); // latest possible date in firestore

  useEffect(() => {
    if (user && groupId) {
      const unsub = onSnapshot(
        doc(db, "groupMemberships", groupId + (user.userId as string)),
        (doc) => {
          const gMember = doc.data() as P6GroupMembership;
          if (gMember.level === "admin") {
            setAdmin(true);
          } else {
            setAdmin(false);
          }
        },
      );

      return () => unsub();
    }
  }, [user, groupId]);

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight - 200;
      if (bottom && groupId) {
        const eventsRef = collection(db, "events");
        const eventsQuery = query(
          eventsRef,
          where("groupId", "==", groupId),
          where("state", "==", "closed"),
          orderBy("closedAt", "desc"),
          startAfter(lastDate),
          limit(30),
        );
        const unsubscribe = onSnapshot(eventsQuery, (eventsSnapshot) => {
          if (eventsSnapshot.size > 0) {
            const eventsData: P6Event[] = [];
            eventsSnapshot.forEach((doc) => {
              const eventData = doc.data();
              const eventId = doc.id;
              eventData.id = eventId;
              eventsData.push(eventData as P6Event);
            });
            const lDate = eventsData.map((x) => x.closedAt)[
              eventsData.length - 1
            ];
            if (lDate) {
              setLastDate(lDate);
            }
            setEvents(events.concat(eventsData));
          }
        });

        return () => unsubscribe();
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    if (firstTime) {
      setFirstTime(false);
      handleScroll();
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [groupId, firstTime, lastDate, events]);

  useEffect(() => {
    const fulfillmentsRef = collection(db, "fulfillments");
    let fulfillmentsQuery: Query<DocumentData>;
    if (userId && events.length > 0 && groupId) {
      if (admin) {
        fulfillmentsQuery = query(
          fulfillmentsRef,
          where("groupId", "==", groupId),
          where("adminId", "==", userId),
        );
      } else {
        fulfillmentsQuery = query(
          fulfillmentsRef,
          where("groupId", "==", groupId),
          where("winnerId", "==", userId),
        );
      }

      const unsubscribe = onSnapshot(
        fulfillmentsQuery,
        (fulfillmentsSnapshot) => {
          if (fulfillmentsSnapshot.size > 0) {
            const fulfillmentsData: P6Fulfillment[] = [];
            fulfillmentsSnapshot.forEach((doc) => {
              const fulfillmentData = {
                id: doc.id,
                ...doc.data(),
              } as P6Fulfillment;
              fulfillmentsData.push(fulfillmentData);
            });
            setFulfillments(fulfillmentsData);
          } else {
            setFulfillments([]);
          }
        },
      );
      return () => unsubscribe();
    } else {
      setFulfillments([]);
    }
  }, [events, userId, admin, groupId]);

  return (
    <div>
      <div className="h-[55px] w-full bg-component px-2.5 pt-2.5">
        <div className="relative">
          <input
            type="text"
            id="email"
            className="peer block w-full appearance-none rounded-full border-[1px] border-grey bg-component px-2.5 pb-1.5 pt-2 pl-10 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
            placeholder=" "
          />
          <img
            src="/icons/magnify_glass.svg"
            alt="Email Icon"
            className="absolute top-[11px] left-[10px] z-10"
            width={17.49}
            height={17.49}
          />
          <label
            htmlFor="email"
            className="z-9 absolute top-0 left-7 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
          >
            Search History
          </label>
        </div>
      </div>
      {events.map((event) => (
        <div key={event.id}>
          <EventCard
            data={event}
            user={user}
            admin={admin}
            fulfillment={fulfillments.find((x) => x.eventId === event.id)}
          />
        </div>
      ))}
      {!events.length && (
        <div className="flex justify-center items-center mt-4">
          <div className="text-center">
            <div className="text-2xl font-bold">No Events in History</div>
            <div className="text-sm text-gray-500">Check back soon!</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default History;
