import { Link } from "react-router-dom";

export default function Loading() {
  return (
    <div className="w-full">
      <div className="mt-2.5 flex items-center p-2.5">
        <Link to="/groups/create" className="flex flex-auto justify-end">
          <div className=" text-[24px] text-white">Create Group</div>
          <div className="flex pl-2.5">
            <img
              src="/icons/plus_icon.svg"
              alt="Create Group"
              className="m-auto"
              width={24}
              height={24}
            />
          </div>
        </Link>
      </div>
      {[...Array<undefined>(3)].map((x: undefined, i: number) => (
        <div key={i} className="w-full p-2.5">
          <div className="relative h-[205px] w-full overflow-hidden rounded-lg bg-component">
            <div className="flex h-full  animate-pulse">
              <div className="bg-shadow m-2.5 h-[57px] w-[57px] rounded-full"></div>
              <div className="absolute bottom-0 h-[55px] w-full bg-background">
                <div className="absolute bottom-2.5 right-2.5 h-[30px] w-[90px] rounded-full bg-p6blue px-4 text-[22px] text-white"></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
