import { useEffect, useState, Dispatch, type SetStateAction } from "react";
import { clientAuth } from "../../firebase/clientFirebaseApps";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase/clientFirebaseApps";
import { collection, onSnapshot, query, doc, where } from "firebase/firestore";
import { useOutletContext } from "react-router-dom";
import { Outlet } from "react-router-dom";
import LayoutHeader from "./layout_header";
import LayoutSubHeader from "./layout_subheader";
import EventList from "../../components/event_list/event_list";
import { type P6UserProfile } from "../../../../src/types/profile";
import { type P6GroupMembership } from "../../../../src/types/groupMembership";
import * as Sentry from "@sentry/react";
import clevertap from "clevertap-web-sdk";
import { P6ClientError } from "../../utils/exeptions";
import SiteAlert from "../../components/site_alert";

type ContextType = {
  user: P6UserProfile;
  setUser: Dispatch<SetStateAction<P6UserProfile>>;
  userId: string;
  activeGroupIds: string[];
  setActiveGroupIds: Dispatch<SetStateAction<string[]>>;
  pendingGroupIds: string[];
  suspendedGroupIds: string[];
  setPaymentOpen: Dispatch<SetStateAction<boolean>>;
  setCreditAmount: Dispatch<SetStateAction<number>>;
  setGroupCreditAmount: Dispatch<SetStateAction<number>>;
};

export default function PageLayout() {
  const [userId, setUserId] = useState<string>();
  const [groupMemberships, setGroupMemberships] = useState<P6GroupMembership[]>([]);
  const [activeGroupIds, setActiveGroupIds] = useState<string[]>([]);
  const [pendingGroupIds, setPendingGroupIds] = useState<string[]>([]);
  const [suspendedGroupIds, setSuspendedGroupIds] = useState<string[]>([]);
  const [user, setUser] = useState<P6UserProfile | null | undefined>();
  const [paymentOpen, setPaymentOpen] = useState<boolean>(false);
  const [creditAmount, setCreditAmount] = useState<number>(0);
  const [site, setSiteMessage] = useState<string | null>(null);
  const auth = clientAuth;
  const navigate = useNavigate();

  // set Sentry to identify the user
  Sentry.setUser({ email: user?.email, id: user?.userId });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUser(null);
        navigate("/login");
      }
    });
  }, [auth, navigate]);

  // maintinance check
  useEffect(() => {
    const unsub = onSnapshot(doc(db, "settings", "publicSettings"), (doc) => {
      if (doc.data()) {
        const settings = doc.data();
        setSiteMessage(settings && settings.siteMessage as string || null)
        if (settings && settings.maintenance === true) {
          navigate("/maintenance");
        }
      }
    });
    return () => unsub();
  }, [navigate]);

  useEffect(() => {
    if (userId) {
      const unsub = onSnapshot(doc(db, "profiles", userId), (doc) => {
        if (doc.data()) {
          const userData = doc.data();
          setUser({ ...(userData as P6UserProfile), userId: doc.id });
          if (userData && userData.completeProfile === false) {
            navigate("/finishprofile/step1");
          }
        } else {
          navigate("/finishprofile/step1");
        }
      });

      return () => unsub();
    }
  }, [userId, navigate]);

  useEffect(() => {
    if (userId) {
      const groupsRef = collection(db, "groupMemberships");
      const groupsQuery = query(groupsRef, where("userId", "==", userId));
      const activeGroupIdsAr: string[] = [];
      const pendingGroupIdsAr: string[] = [];
      const suspendedGroupIdsAr: string[] = [];
      const groupMembershipsAr: P6GroupMembership[] = [];
      const unsubscribe = onSnapshot(groupsQuery, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const groupM = doc.data() as P6GroupMembership;
          groupMembershipsAr.push(groupM);
          if (groupM.status === "active") {
            activeGroupIdsAr.push(groupM.groupId);
          } else if (groupM.status === "pending") {
            pendingGroupIdsAr.push(groupM.groupId);
          } else if (groupM.status === "suspended") {
            suspendedGroupIdsAr.push(groupM.groupId);
          }
        });
        setActiveGroupIds(activeGroupIdsAr);
        setPendingGroupIds(pendingGroupIdsAr);
        setSuspendedGroupIds(suspendedGroupIdsAr);
        setGroupMemberships(groupMembershipsAr);
      });
      return () => unsubscribe();
    } else {
      setActiveGroupIds([]);
      setPendingGroupIds([]);
      setSuspendedGroupIds([]);
    }
  }, [userId]);

  useEffect(() => {
    if (user) {
      try {
        clevertap.onUserLogin.push({
          Site: {
            Name: user.displayName,
            Identity: user.userId,
            Email: user.notificationEmail,
            Phone: "+" + (user.phoneNumber as string),
            DOB: new Date(),
            groups: activeGroupIds,
          },
        });

        window.dataLayer.push({
          event: "user_data",
          eventProps: {
            user_id: user.userId,
            email: user.email,
            display_name: user.displayName,
            phone_number: user.phoneNumber,
            notifications_email: user.notificationEmail,
            address_city: user.address?.city,
            address_state: user.address?.state,
            credits: user.credits,
            groupMemberships: groupMemberships,
          },
        });
      } catch (error) {
        throw new P6ClientError("UnknownError", error as string);
      }
    }
  }, [user, activeGroupIds, groupMemberships]);

  if (!user) {
    return null;
  }

  return (
    <div className="bg-background">
      <LayoutHeader />
      <LayoutSubHeader
        paymentOpen={paymentOpen}
        setPaymentOpen={setPaymentOpen}
        creditAmount={creditAmount}
        displayName={user.displayName}
        profileImageUrl={user.profileImageUrl}
        user={user}
      />
      <SiteAlert siteMessage={site} />
      <div className="md:container md:mx-auto md:flex">
        <div className="w-full relative">
          <Outlet
            context={{
              user,
              setUser,
              userId,
              activeGroupIds,
              setActiveGroupIds,
              pendingGroupIds,
              suspendedGroupIds,
              setPaymentOpen,
              setCreditAmount,
            }}
          />
        </div>
        <div
          className="ml-2.5 hidden w-[443px] text-white sm:hidden md:sticky md:top-[110px] md:mb-[-110px] md:h-full md:max-h-screen md:max-w-screen-xs  md:shrink-0 md:flex-grow md:flex-col md:overflow-y-auto md:pb-[110px] lg:flex"
          id="inner-scroll"
        >
          {userId && <EventList user={user} activeGroupIds={activeGroupIds} />}
        </div>
      </div>
    </div>
  );
}

export function useUser() {
  return useOutletContext<ContextType>();
}
