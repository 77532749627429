import { Dispatch, SetStateAction } from "react";

export default function PayoutAddMethod({
  setFormOpen,
}: {
  setFormOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="text-white">
      <div
        onClick={() => setFormOpen(true)}
        className="flex justify-center items-center border-[1px] rounded-lg px-5 py-1.5 cursor-pointer hover:bg-green"
      >
        <img
          src="/icons/add_icon.svg"
          alt="Add Payment"
          className="mr-4 right-[10px] cursor-pointer"
          width={18}
          height={18}
        />
        Add Payout Method
      </div>
    </div>
  );
}
