import NewEventSlidout from "../../components/new_event_slideout/slideout";
import NewMiniSlidout from "../../components/new_mini_slideout/slideout";
// import SavedEventSlidout from "../../components/saved_event_slideout/slideout";

export default function AdminActions({
  groupId,
  page,
}: {
  groupId: string;
  page: string;
}) {
  return (
    <>
      {page === "active" && (
        <div className="grid grid-cols-3 bg-component pt-3 pb-2.5">
          <NewEventSlidout groupId={groupId} />
          <NewMiniSlidout groupId={groupId} />
          <NewEventSlidout groupId={groupId} template={true} />
          {/* <SavedEventSlidout /> */}
        </div>
      )}
    </>
  );
}
