import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { clientStorage } from "../../../firebase/clientFirebaseApps";
import { createDistributionItem } from "../../../functions";
import { type P6ApiResponse } from "../../../../../src/types";
import { P6ClientError } from "../../../utils/exeptions";
import P6Button from "../../../components/button";

type formReturn = {
  rounding: string;
  title: string;
  description: string;
  seats: number;
  eventValue: number;
  creditsToReturn: number;
  autoroll: boolean;
  featured: boolean;
  limited: boolean;
};

interface PreviewFile extends File {
  preview: string;
  downloadUrl: string;
}

export default function NewEventFrom({
  setOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [files, setFiles] = useState<PreviewFile[]>([]);
  const [imageError, setImageError] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const downloadUrls: Array<string> = files?.map(
    (item: { downloadUrl: string }) => {
      return item.downloadUrl;
    },
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {},
  });

  const storage = clientStorage;
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles: File[]) => {
      setImageLoading(true);
      acceptedFiles.map(async (file: File) => {
        const uuid: string = self.crypto.randomUUID();
        setImageError(false);
        const storageRef = ref(storage, `eventImages/${uuid}.${file.name}`);

        await uploadBytes(storageRef, file).then(() => {
          getDownloadURL(ref(storage, `eventImages/${uuid}.${file.name}`))
            .then((url) => {
              if (!url) {
                throw new P6ClientError(
                  "UIElementError",
                  "Unable to get download url for image",
                );
              }
              const preview = {
                preview: URL.createObjectURL(file),
                downloadUrl: url,
              } as PreviewFile;
              setFiles((files) => [...files, preview]);
              setImageLoading(false);
            })
            .catch((error) => {
              setImageLoading(false);
              throw new P6ClientError("UnknownError", error as string);
            });
        });
      });
    },
  });
  // Create a root reference

  const handleRemoveItem = (file: PreviewFile) => {
    setFiles(
      files.filter(
        (files: PreviewFile) => files.downloadUrl !== file.downloadUrl,
      ),
    );
  };

  const thumbs = files.map((file: PreviewFile) => (
    <div
      className=" mb-[8px] mr-[8px] inline-flex h-[55px] w-[55px] overflow-hidden rounded-lg "
      key={file.downloadUrl}
    >
      <div className="relative flex flex-auto">
        <span
          className="absolute top-[0px] right-[0px] z-50 flex h-[16px] w-[16px] cursor-pointer items-center justify-center rounded-full bg-red text-white"
          //name={file.name}
          onClick={() => handleRemoveItem(file)}
        >
          <img
            src="/icons/delete_icon.svg"
            alt="Delete"
            className=""
            width={7}
            height={7}
          />
        </span>

        <img src={file.preview} alt="Group" className="cover" />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: PreviewFile) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (files.length === 0) {
      setImageError(true);
      reset(data);
    } else {
      setLoading(true);
      const formData = data as formReturn;
      const request = {
        ...formData,
        images: downloadUrls,
      };

      createDistributionItem(request)
        .then((result) => {
          const resultData = result.data as P6ApiResponse;
          // Read result of the Cloud Function.
          if (resultData.success) {
            setLoading(false);
            setOpen(false);
            reset();
          } else {
            throw new P6ClientError(
              "FunctionError",
              "Unable to create new event.",
            );
          }
        })
        .catch((error: { code: string; message: string; details: string }) => {
          setLoading(false);
          return Promise.reject(
            new P6ClientError("UnknownError", error.message),
          );
        });
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="text-white text-[28px] text-center mt-4">
          Create Item
        </div>
        {/* <div className="text-grey text-sm text-center w-3/5 leading-4 font-light pb-3 pt-2">
          Build a custom event tailored to your audience
        </div> */}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="title"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("title", {
                required: true,
              })}
            />
            <label htmlFor="title" className="form-label">
              Title
            </label>
          </div>
          {errors.title && <p className="form-error">Title is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="number"
              id="msrp"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("msrp", {
                required: true,
                valueAsNumber: true,
              })}
            />
            <label htmlFor="msrp" className="form-label">
              MSRP
            </label>
          </div>
          {errors.msrp && <p className="form-error">MSRP is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="number"
              id="cost"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("cost", {
                required: true,
                valueAsNumber: true,
              })}
            />
            <label htmlFor="cost" className="form-label">
              Admin Cost
            </label>
          </div>
          {errors.cost && <p className="form-error">Admin Cost is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="number"
              id="available"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("available", {
                required: true,
                valueAsNumber: true,
              })}
            />
            <label htmlFor="available" className="form-label">
              Quantity
            </label>
          </div>
          {errors.available && (
            <p className="form-error">Quantity is Required</p>
          )}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="manufacturer"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("manufacturer", {
                required: true,
              })}
            />
            <label htmlFor="manufacturer" className="form-label">
              Manufacturer
            </label>
          </div>
          {errors.title && (
            <p className="form-error">Manufacturer is Required</p>
          )}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="upc"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("upc", {
                required: true,
              })}
            />
            <label htmlFor="upc" className="form-label">
              UPC
            </label>
          </div>
          {errors.upc && <p className="form-error">UPC is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="mfgModel"
              id="mfgModel"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("mfgModel", {
                required: false,
              })}
            />
            <label htmlFor="mfgModel" className="form-label">
              MFG Model
            </label>
          </div>
          {errors.mfgModel && (
            <p className="form-error">MFG Model is Required</p>
          )}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="family"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("family", {
                required: false,
              })}
            />
            <label htmlFor="family" className="form-label">
              Family
            </label>
          </div>
          {errors.family && <p className="form-error">Family is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="model"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("model", {
                required: true,
              })}
            />
            <label htmlFor="model" className="form-label">
              Model
            </label>
          </div>
          {errors.model && <p className="form-error">Model is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="info"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("info", {
                required: true,
              })}
            />
            <label htmlFor="info" className="form-label">
              Info
            </label>
          </div>
          {errors.info && <p className="form-error">Info is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="type"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("type", {
                required: false,
              })}
            />
            <label htmlFor="type" className="form-label">
              Type
            </label>
          </div>
          {errors.type && <p className="form-error">Type is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="action"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("action", {
                required: false,
              })}
            />
            <label htmlFor="action" className="form-label">
              Action
            </label>
          </div>
          {errors.action && <p className="form-error">Action is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="caliber"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("caliber", {
                required: false,
              })}
            />
            <label htmlFor="caliber" className="form-label">
              Caliber
            </label>
          </div>
          {errors.caliber && <p className="form-error">Caliber is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="finish"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("finish", {
                required: false,
              })}
            />
            <label htmlFor="finish" className="form-label">
              Finish
            </label>
          </div>
          {errors.finish && <p className="form-error">Finish is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="finishType"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("finishType", {
                required: false,
              })}
            />
            <label htmlFor="finishType" className="form-label">
              Finish Type
            </label>
          </div>
          {errors.finishType && (
            <p className="form-error">Finish Type is Required</p>
          )}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="framePolymer"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("framePolymer", {
                required: false,
              })}
            />
            <label htmlFor="framePolymer" className="form-label">
              Frame
            </label>
          </div>
          {errors.framePolymer && (
            <p className="form-error">Frame Polymer is Required</p>
          )}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="stockGrips"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("stockGrips", {
                required: false,
              })}
            />
            <label htmlFor="stockGrips" className="form-label">
              Stock Grips
            </label>
          </div>
          {errors.stockGrips && (
            <p className="form-error">Stock Grips is Required</p>
          )}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="barrel"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("barrel", {
                required: false,
              })}
            />
            <label htmlFor="barrel" className="form-label">
              Barrel
            </label>
          </div>
          {errors.barrel && <p className="form-error">Barrel is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="overallLength"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("overallLength", {
                required: false,
              })}
            />
            <label htmlFor="overallLength" className="form-label">
              Overall Length
            </label>
          </div>
          {errors.overallLength && (
            <p className="form-error">Overall Length is Required</p>
          )}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="capacity"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("capacity", {
                required: false,
              })}
            />
            <label htmlFor="capacity" className="form-label">
              Capacity
            </label>
          </div>
          {errors.capacity && (
            <p className="form-error">Capacity is Required</p>
          )}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="magazines"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("magazines", {
                required: false,
              })}
            />
            <label htmlFor="magazines" className="form-label">
              Magazines
            </label>
          </div>
          {errors.magazines && (
            <p className="form-error">Magazines is Required</p>
          )}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="magDescription"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("magDescription", {
                required: false,
              })}
            />
            <label htmlFor="magDescription" className="form-label">
              Mag Description
            </label>
          </div>
          {errors.magDescription && (
            <p className="form-error">Mag Description is Required</p>
          )}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="sights"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("sights", {
                required: false,
              })}
            />
            <label htmlFor="sights" className="form-label">
              Sights
            </label>
          </div>
          {errors.sights && <p className="form-error">Sights is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="sightType"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("sightType", {
                required: false,
              })}
            />
            <label htmlFor="sightType" className="form-label">
              Sight Type
            </label>
          </div>
          {errors.sightType && (
            <p className="form-error">Sight Type is Required</p>
          )}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="threadPattern"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("threadPattern", {
                required: false,
              })}
            />
            <label htmlFor="threadPattern" className="form-label">
              Thread Pattern
            </label>
          </div>
          {errors.threadPattern && (
            <p className="form-error">Thread Pattern is Required</p>
          )}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="specialFeature"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("specialFeature", {
                required: false,
              })}
            />
            <label htmlFor="specialFeature" className="form-label">
              Special Feature
            </label>
          </div>
          {errors.specialFeature && (
            <p className="form-error">Special Feature is Required</p>
          )}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="weight"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("weight", {
                required: false,
              })}
            />
            <label htmlFor="weight" className="form-label">
              Weight
            </label>
          </div>
          {errors.weight && <p className="form-error">Weight is Required</p>}
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="shippingWeight"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("shippingWeight", {
                required: true,
              })}
            />
            <label htmlFor="shippingWeight" className="form-label">
              Shipping Weight
            </label>
          </div>
          {errors.shippingWeight && (
            <p className="form-error">Shipping Weight is Required</p>
          )}
        </div>

        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps({ id: "inventory-drop-zone" })} />
          <p className="mt-5 border-[1px] border-dashed p-5 text-center text-white cursor-pointer">
            Drag files here, or click to select files
          </p>
        </div>
        {imageError && (
          <p className="mt-[0px] ml-10 mb-2.5 text-red">1 Image is Required</p>
        )}

        <aside className="mt-[16px] flex flex-row flex-wrap">{thumbs}</aside>

        <div className="flex text-white">
          <P6Button
            sm
            type="submit"
            text="Add Event"
            loading={loading || imageLoading}
            color="bg-green"
            dataCy="addInventory"
          />
        </div>
      </form>
    </div>
  );
}
