import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db, analytics } from "../../firebase/clientFirebaseApps";
import { logEvent } from "firebase/analytics";
import {
  doc,
  onSnapshot,
  query,
  collection,
  where,
  limit,
} from "firebase/firestore";
import { createGroupMembership } from "../../functions";
import { useNavigate } from "react-router-dom";
import P6Button from "../../components/button";
import { type P6Group } from "../../../../src/types/group";
import { type P6ApiResponse } from "../../../../src/types";
import { P6ClientError } from "../../utils/exeptions";
import * as Sentry from "@sentry/react";
import { FieldValues, useForm, SubmitHandler } from "react-hook-form";
import LoadingSpinner from "../../components/loading_spinner";

export default function Join() {
  const [group, setGroup] = useState<P6Group>();
  const [loading, setLoading] = useState<boolean>(false);
  const { groupId } = useParams();
  const [formInput, setFormInput] = useState<string>("");
  const [groupLoading, setGroupLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setFormInput(data.groupCode as string);
    // navigate(`/join/${data.groupCode as string}`)
  };

  useEffect(() => {
    if (formInput) {
      const q = query(
        collection(db, "publicGroups"),
        where("groupCode", "==", formInput),
        limit(1)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          navigate(`/join/${querySnapshot.docs[0].id}`);
        } else {
          navigate(`/join/${formInput}`);
        }
      });
      return () => unsubscribe();
    }
  }, [formInput, navigate]);

  // Add group name to Sentry
  Sentry.setTag("group", group?.name);

  useEffect(() => {
    if (groupId) {
      const unsub = onSnapshot(doc(db, "publicGroups", groupId), (doc) => {
        if (!doc.exists()) {
          const q = query(
            collection(db, "publicGroups"),
            where("groupCode", "==", groupId),
            limit(1)
          );
          const unsub = onSnapshot(q, (querySnapshot) => {
            if (querySnapshot.docs.length === 0) {
              setGroupLoading(false);
            }
            const groupData = querySnapshot.docs[0].data() as P6Group;
            groupData.id = querySnapshot.docs[0].id;
            if (groupData.name && groupData.cover) {
              setGroup(groupData);
              setGroupLoading(false);
            }
          });
          return () => {
            unsub();
          };
        } else {
          const groupData = doc.data() as P6Group;
          if (groupData.name && groupData.cover) {
            setGroup({ id: doc.id, ...doc.data() } as P6Group);
            setGroupLoading(false);
          }
        }
      });
      return () => {
        unsub();
      };
    }
  }, [groupId]);

  const joinGroup = async () => {
    setLoading(true);
    try {
      const request = {
        groupId: group && group.id,
        level: "user",
        status: "pending",
        inviteId: "",
      };
      const result = await createGroupMembership(request);
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        logEvent(analytics, "login");
        window.dataLayer.push({
          event: "event_data",
          eventProps: {
            groupId: group && group.id,
            user_role: "user"
          },
        } as unknown);
        navigate(`/groups`);
      } else {
        if (
          resultData.message === "Group membership already exists for this user"
        ) {
          navigate(`/groups`);
        }
        throw new P6ClientError("FunctionError", "Unable to create group.");
      }
    } catch (error) {
      setLoading(false);
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  return (
    <div className="p-2.5">
      {groupId && group && (
        <div className="relative h-[205px]  overflow-hidden rounded-lg bg-component">
          {group && (
            <img src={group?.cover} className={`cover over`} alt="Group" />
          )}
          <div className={`absolute bottom-0 flex h-[55px] w-full bg-black/80`}>
            <div className="flex flex-auto flex-col">
              <div className="ml-2.5 text-[22px] text-white line-clamp-1">
                {group?.name}
              </div>
              {/* <div className="ml-2.5 text-xs leading-none text-grey line-clamp-1">{group?.businessInfo.description}</div> */}
            </div>
            <div className="flex w-[80px] items-center mr-2.5">
              <P6Button
                sm
                onClick={() => joinGroup()}
                text="Join"
                loading={loading}
                div
              />
            </div>
          </div>
        </div>
      )}
      {!groupId && (
        <div className="mt-8 px-6">
          <div className="text-center text-[28px]">Join Group</div>
          <div className="text-center text-sm mb-3">
            Please enter your group code to join a group.
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2.5">
              <div className="relative mb-2.5">
                <input
                  type="text"
                  id="groupCode"
                  autoComplete="off"
                  className="text-field peer"
                  placeholder=" "
                  {...register("groupCode", {
                    required: true,
                  })}
                />
                <label htmlFor="groupCode" className="form-label">
                  Group Code
                </label>
              </div>
              {errors.groupCode && (
                <p className="form-error">GroupCode is Required</p>
              )}
            </div>
            <div className="flex mt-3 gap-2">
              <P6Button
                sm
                text="Cancel"
                color="bg-dark-grey"
                onClick={() => navigate("/groups")}
              />
              <P6Button sm type="submit" text="Join Group" />
            </div>
          </form>
        </div>
      )}
      {groupLoading && (
        <LoadingSpinner />
      )}
      {!groupLoading && groupId && !group && (
        <div>
          <div className=" bg-yellow-400 rounded-lg p-2.5 text-dark-grey mt-4">
            The group you are looking for does not exist.
          </div>
          <div className="flex mt-4">
            <P6Button sm text="Go Back" onClick={() => navigate("/groups")} />
          </div>
        </div>
      )}
    </div>
  );
}
