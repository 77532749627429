import { useState } from "react";
import Progress from "../../../../components/progress/progress";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Confirm from "./confirm";
import {
  P6GroupBranding,
  P6GroupBusinessInfo,
  P6GroupPersonalInfo,
} from "../../../../../../src/types/group";

export default function GroupCreateForm() {
  const [step, setStep] = useState(1);
  const [branding, setBranding] = useState<P6GroupBranding>();
  const [businessInfo, setBusinessInfo] = useState<P6GroupBusinessInfo>();
  const [personalInfo, setPersonalInfo] = useState<P6GroupPersonalInfo>();

  return (
    <div className="w-full pt-10 text-white">
      <Progress step={step} />
      {step === 1 && (
        <Step1
          setStep={setStep}
          branding={branding as P6GroupBranding}
          setBranding={setBranding}
        />
      )}
      {step === 2 && (
        <Step2
          setStep={setStep}
          businessInfo={businessInfo as P6GroupBusinessInfo}
          setBusinessInfo={setBusinessInfo}
        />
      )}
      {step === 3 && (
        <Step3
          setStep={setStep}
          personalInfo={personalInfo as P6GroupPersonalInfo}
          setPersonalInfo={setPersonalInfo}
        />
      )}

      {step === 4 && (
        <Confirm
          setStep={setStep}
          branding={branding as P6GroupBranding}
          businessInfo={businessInfo as P6GroupBusinessInfo}
          personalInfo={personalInfo as P6GroupPersonalInfo}
        />
      )}
    </div>
  );
}
