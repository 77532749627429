import { getTransactions } from "../../../../functions";
import { P6ApiResponse } from "../../../../../../src/types";
import { useState } from "react";
import { P6TransactionTotals } from "../../../../../../src/types/transaction";
import { Datepicker } from "flowbite-react";
import FinancialStatistics from "./components/financialStatistics";
import P6Button from "../../../../components/button";
import moment from "moment";

export default function Financial() {
  const [payout, setPayout] = useState(0);
  const [purchase, setPurchase] = useState(0);
  const [refund, setRefund] = useState(0);
  const [payment, setPayment] = useState(0);
  const [trade, setTrade] = useState(0);
  const [withdraw, setWithdraw] = useState(0);
  const [groupCreditAdd, setGroupCreditAdd] = useState(0);
  const [groupRemoveAdd, setGroupRemoveAdd] = useState(0);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).startOf("day").toDate(),
  );
  const [toDate, setToDate] = useState<Date>(
    moment(new Date()).endOf("day").toDate(),
  );

  const handleFromDateChange = (date: Date) => {
    const formatDate = moment(date).startOf("day").toDate();
    setFromDate(formatDate);
  };

  const handleToDateChange = (date: Date) => {
    const formatDate = moment(date).endOf("day").toDate();
    setToDate(formatDate);
  };

  const handleSearch = async () => {
    const data = { fromDate: fromDate.getTime(), toDate: toDate.getTime() };
    const response = await getTransactions(data);
    const result = response.data as P6ApiResponse;
    if (result.success) {
      const transactionTotals = result.data as P6TransactionTotals;
      setPurchase(transactionTotals.totalPurchase);
      setPayout(transactionTotals.totalPayout);
      setRefund(transactionTotals.totalRefund);
      setPayment(transactionTotals.totalPayment);
      setTrade(transactionTotals.totalTrade);
      setWithdraw(transactionTotals.totalWithdraw);
      setGroupCreditAdd(transactionTotals.totalGroupCreditAdd);
      setGroupRemoveAdd(transactionTotals.totalGroupRemoveAdd);
    }
  };

  return (
    <div className="flex flex-col text-white mx-auto">
      <div className="flex flex-row space-x-4">
        <label>From Date:</label>
        <Datepicker
          data-cy="from-date"
          onSelectedDateChanged={(date) => handleFromDateChange(date)}
          maxDate={new Date()}
        />
        <label>To Date:</label>
        <Datepicker
          data-cy="to-date"
          onSelectedDateChanged={(date) => handleToDateChange(date)}
          minDate={fromDate}
        />
        <div data-cy="search-button" className="w-[270px] p-1">
          <P6Button text="Search" onClick={() => handleSearch()} />
        </div>
      </div>

      <div className="mt-4">
        <FinancialStatistics
          payout={payout}
          purchase={purchase}
          refund={refund}
          payment={payment}
          trade={trade}
          withdraw={withdraw}
          groupCreditAdd={groupCreditAdd}
          groupRemoveAdd={groupRemoveAdd}
        />
      </div>
    </div>
  );
}
