import { Link } from "react-router-dom";

export default function ErrorPage({error}: {error?: string}) {

  return (
    <div className="flex min-h-screen flex-col items-center justify-center text-white">
      <img src="/images/logo.png" width="200" alt="logo" />
      <div className="ustify-center flex flex-col items-center rounded-lg bg-component p-4">
        <h1 className="text-[34px]">Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        {error && (<p>{error}</p>)}
        <Link to="/feed" className="mt-4 rounded-full bg-green px-4 py-1">
          Go Home
        </Link>
      </div>
    </div>
  );
}
