import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "@firebase/auth";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { clientAuth } from "../../../firebase/clientFirebaseApps";
import { signIn } from "../../../firebase/clientUserSessionUtils";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import TermsModal from "../terms_modal";
import { FirebaseError } from "@firebase/util";
import { authErrors } from "../../../utils/authErrors";
import type { User } from "firebase/auth";
import clevertap from "clevertap-web-sdk";

export default function SignUp() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const appleProvider = new OAuthProvider("apple.com");
  const [authError, setAuthError] = useState<string>();
  const [searchParams] = useSearchParams();
  const join = searchParams.get("join");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();

  const signInUser = async (user: User) => {
    await signIn(user);
    clevertap.onUserLogin.push({
      Site: {
        Identity: user.uid,
        Email: user.email as string,
      },
    });
    clevertap.event.push("P6 - Account Created", {
      userId: user.uid,
      email: user.email as string,
    });
    if (join) {
      navigate(`/join/${join}`);
    } else {
      navigate(`/feed`);
    }
  };

  // google login
  async function googleSignin() {
    try {
      const { user } = await signInWithPopup(clientAuth, googleProvider);
      await signInUser(user);
      clevertap.event.push("Signup with Google");
      // TODO: fix come back and fix
    } catch (error) {
      if (error instanceof FirebaseError) {
        setAuthError(authErrors[error.code as keyof typeof authErrors]);
      }
      setLoading(false);
    }
  }

  // facebook login

  async function facebookSignin() {
    try {
      const { user } = await signInWithPopup(clientAuth, facebookProvider);
      await signInUser(user);
      clevertap.event.push("Signup with Facebook");
      // fix come back and fix
    } catch (error) {
      if (error instanceof FirebaseError) {
        setAuthError(authErrors[error.code as keyof typeof authErrors]);
      }
      setLoading(false);
    }
  }

  // apple login

  async function appleSignin() {
    try {
      const { user } = await signInWithPopup(clientAuth, appleProvider);
      await signInUser(user);
      clevertap.event.push("Signup with Apple");
      // fix come back and fix
    } catch (error) {
      if (error instanceof FirebaseError) {
        setAuthError(authErrors[error.code as keyof typeof authErrors]);
      }
      setLoading(false);
    }
  }

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const formData = data as { email: string; password: string };
    setLoading(true);
    try {
      const { user } = await createUserWithEmailAndPassword(
        clientAuth,
        formData.email,
        formData.password,
      );
      await signInUser(user);
      window.dataLayer.push({
        event: "sign_up",
      } as unknown);
      clevertap.event.push("Signup with Email");
    } catch (error) {
      if (error instanceof FirebaseError) {
        setAuthError(authErrors[error.code as keyof typeof authErrors]);
      }
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full flex-col items-center">
      <div className="mt-108 mb-5 text-[44px] font-thin">Sign Up</div>
      <div
        onClick={() => void googleSignin()}
        className="mb-[14px] flex w-[290px] cursor-pointer items-center rounded-full bg-white text-black hover:bg-dark-grey hover:text-white "
      >
        <div className="overflow-hidden rounded-full bg-white">
          <img
            src="/icons/google_icon.svg"
            className="m-[2px]"
            width={43}
            height={43}
            alt="google"
          />
        </div>
        <div className="ml-2.5 text-[21px]">Signup with Google</div>
      </div>
      <div
        onClick={() => void facebookSignin()}
        className="mb-[14px] flex w-[290px] cursor-pointer items-center rounded-full bg-white text-black hover:bg-dark-grey hover:text-white"
      >
        <div className="overflow-hidden rounded-full bg-white">
          <img
            src="/icons/facebook_icon.svg"
            alt="facebook"
            className="m-[4px]"
            width={41}
            height={41}
          />
        </div>
        <div className="ml-2.5 text-[21px]">Signup with Facebook</div>
      </div>
      <div
        onClick={() => void appleSignin()}
        className="mb-2.5 flex w-[290px] cursor-pointer items-center rounded-full bg-white text-black hover:bg-dark-grey hover:text-white"
      >
        <div className="overflow-hidden rounded-full bg-white">
          <img
            src="/icons/apple_icon.svg"
            alt="apple"
            className="my-[3px] ml-[6px] mr-[8px]"
            width={33}
            height={41}
          />
        </div>
        <div className="ml-2.5 text-[21px]">Signup with Apple</div>
      </div>
      <div className="flex w-full items-center px-14">
        <div className="mt-1 flex w-full flex-auto border-b-[1px] border-dark-grey" />
        <div className="mx-2.5 flex text-[29px] font-light">or</div>
        <div className="mt-1 flex w-full flex-auto border-b-[1px] border-dark-grey" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className={`w-[290px] ${open ? "" : "hidden"}`}>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="email"
                id="email"
                autoComplete="email"
                className="text-field peer pl-9"
                placeholder=" "
                {...register("email", {
                  required: true,
                })}
              />
              <div className="absolute top-[0px] flex h-[35px] w-[28px] items-center justify-end">
                <img
                  src="/icons/email_icon.svg"
                  alt="email icon"
                  className="top-[7px] left-[10px] z-10"
                  width={17.49}
                  height={17.49}
                />
              </div>
              <label htmlFor="email" className="form-label ml-7">
                Email
              </label>
            </div>
            {errors.email && <p className="form-error">Email is Required</p>}
          </div>
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="password"
                id="password"
                autoComplete="password"
                className="text-field peer pl-9"
                placeholder=" "
                {...register("password", {
                  required: true,
                })}
              />
              <div className="absolute top-[0px] flex h-[35px] w-[28px] items-center justify-end">
                <img
                  src="/icons/key_icon.svg"
                  alt="password icon"
                  className="top-[7px] left-[10px] z-10"
                  width={17.49}
                  height={17.49}
                />
              </div>
              <label htmlFor="password" className="form-label ml-7">
                Password
              </label>
            </div>
            {errors.password && (
              <p className="form-error">Password is Required</p>
            )}
            {authError && <p className="form-error">{authError}</p>}
          </div>
        </div>
        <div
          data-cy="sign-up-with-email"
          onClick={() => setOpen(!open)}
          className={`mb-[14px] mt-5 flex w-[290px] cursor-pointer items-center justify-center rounded-full bg-p6blue py-[7px] text-[21px] hover:bg-dark-grey ${
            open ? "hidden" : ""
          }`}
        >
          Sign up with email
        </div>
        <button
          type="submit"
          data-cy="sign-up-with-email-button"
          className={`relative mb-[14px] mt-5 flex w-[290px] items-center justify-center rounded-full bg-p6blue py-[7px] text-[21px] hover:bg-dark-grey ${
            open ? "" : "hidden"
          }`}
          disabled={loading}
        >
          {loading && (
            <img
              width={40}
              height={40}
              src="/icons/spinner_icon.svg"
              alt="Event"
              className="absolute left-[4px] top-[4px] animate-spin"
            />
          )}
          Sign up with email
        </button>
      </form>
      <div
        className={`w-[290px] text-[15px] font-light ${open ? "hidden" : ""}`}
      >
        <p>By signing in, you agree to the </p>
        <div>
          <span className="text-p6blue">
            <TermsModal />{" "}
          </span>{" "}
          of Platform Sixx
        </div>
      </div>

      <div className="mt-10 text-[21px] font-light">
        Already have an Account?
      </div>
      <Link to={join ? `/login?join=${join}` : "/login"}>
        <div className="mb-5 mt-2 flex w-[290px] items-center justify-center rounded-full bg-white py-[7px] text-[21px] text-black hover:bg-dark-grey hover:text-white">
          Login
        </div>
      </Link>
    </div>
  );
}
