import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "@firebase/auth";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { clientAuth } from "../../../firebase/clientFirebaseApps";
import { authErrors } from "../../../utils/authErrors";
import { FirebaseError } from "@firebase/util";
import P6Button from "../../../components/button";
import clevertap from "clevertap-web-sdk";

export default function ResetPassword() {
  const [loading, setLoading] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string>();
  const auth = clientAuth;
  const navigate = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();

  const triggerResetEmail = async (email: string) => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      reset();
      clevertap.event.push("Password Reset");
      navigate("/login");
      setLoading(false);
    } catch (error) {
      if (error instanceof FirebaseError) {
        setAuthError(authErrors[error.code as keyof typeof authErrors]);
      }
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) =>
    triggerResetEmail(data.email as string);

  return (
    <div className="p-5 text-white">
      <div className="text-center text-[34px] font-light text-white">
        Reset your Password?
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="my-5">
          <div className="mb-2.5">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="email"
                autoComplete="email"
                className="text-field peer pl-9"
                placeholder=" "
                {...register("email", {
                  required: true,
                })}
              />
              <div className="absolute top-0 flex h-[35px] w-[28px] justify-end">
                <img
                  src="/icons/email_icon.svg"
                  alt="email icon"
                  className="top-[7px] left-[10px] z-10"
                  width={17.49}
                  height={17.49}
                />
              </div>
              <label htmlFor="email" className="form-label ml-7">
                Email
              </label>
            </div>
            {errors.email && <p className="form-error">Email is Required</p>}
            {authError && <p className="form-error">{authError}</p>}
          </div>
        </div>
        <div className="mb-5 flex">
          <P6Button
            type="submit"
            text="Reset Password"
            loading={loading}
            color="bg-green"
          />
        </div>
      </form>
      <div>
        <P6Button link="/login" text="Login" />
      </div>
    </div>
  );
}
