import { useEffect, useState } from "react";
import { collection, query, where, orderBy, startAfter, limit, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";
import { useUser } from "../../layouts/page_layout/layout";
import PostList from "./post/post_list";
import EventWidget from "../../components/event_widget/list";
import Loading from "./loading";
// import { eventsRollUp } from "../../utils/eventsRollUp";
import { P6Timestamp } from "../../../../src/types/helpers";
import { P6Post } from "../../../../src/types/post";
import { postsRollUp } from "../../utils/postsRollUp";
import PostModal from "./post_modal/post_modal";
import { P6ClientError } from "../../utils/exeptions";
// import { P6ClientError } from "../../utils/exeptions";
export default function Page() {
  const [admin, setAdmin] = useState(true);
  const [loading, setLoading] = useState(true);
  const [firstTime, setFirstTime] = useState(true);
  const { activeGroupIds, userId } = useUser();
  const [postOpen, setPostOpen] = useState(false);
  const [posts, setPosts] = useState<P6Post[]>([]);
  const [lastDate, setLastDate] = useState<P6Timestamp>({
    nanoseconds: 0,
    seconds: -62135596800,
  }); // latest possible date in firestore

  useEffect(() => {
    setLoading(true);
    const handleScroll = () => {
      const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - 200;
      if ((bottom || firstTime) && activeGroupIds.length > 0) {
        if (firstTime) {
          setFirstTime(false)
        }
        const postsRef = collection(db, "posts");
        const postsQuery = query(
          postsRef,
          where('groupId', 'in', activeGroupIds),
          orderBy('createdAt', 'desc'),
          startAfter(lastDate),
          limit(10)
        );

        const unsubscribe = onSnapshot(postsQuery, (snapshot) => {
          const newPosts = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          } as P6Post));
          setPosts(posts.concat(newPosts));

          // Update lastDate for the next query
          if (snapshot.docs.length > 0) {
            const lastPostDoc = snapshot.docs[snapshot.docs.length - 1];
            const post = lastPostDoc.data() as P6Post
            setLastDate(post.createdAt);
          }
        }, (error) => {
          console.error('Error getting events:', error);
        });
        return () => {
          unsubscribe();
        };
      } else {
        setLoading(false);
      }
    };
    setLoading(false);
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });
    if (firstTime) {
      handleScroll();
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeGroupIds, lastDate, firstTime, posts]);

  useEffect(() => {
    if (userId) {
      const postsRef = collection(db, "groups");
      const postsQuery = query(
        postsRef,
        where('adminId', '==', userId)
      );

      const unsubscribe = onSnapshot(postsQuery, (snapshot) => {
        const isAdmin = snapshot.size > 0;
        setAdmin(isAdmin);
      }, (error) => {
        throw new P6ClientError("FunctionError", "Unable to fetch groups.", error);
      });
      return () => {
        unsubscribe();
      };
    }
  }, [userId])

  if (loading && posts.length === 0) {
    return <Loading />;
  }

  return (
    <div className="w-full text-white relative">
      {admin && <button
        data-cy="create-post-button"
        onClick={() => setPostOpen(true)}
        className="border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-300 my-5 " 
      >
        Create a Post
      </button>}
      <div className="lg:hidden">
        <EventWidget activeGroupIds={activeGroupIds} />
      </div>
      {posts.length > 0 ? (
        <>
          <PostList events={postsRollUp(posts)} />
        </>
      ) : (
        <div className="mt-2.5 flex h-[87px] w-full flex-col justify-center rounded-lg bg-component text-center">
          <p className="text-[25px]">No Available Events</p>
          <p className="">Check back later.</p>
        </div>
      )}
      <PostModal
        optionsOpen={postOpen}
        setOptionsOpen={setPostOpen}
      />
    </div>
  );
}
