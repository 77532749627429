import { useState, useEffect, useRef } from "react";
import { db } from "../../firebase/clientFirebaseApps";
import {
  collection,
  doc,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import { useLocation, useParams } from "react-router-dom";
import { useUser } from "../../layouts/page_layout/layout";
import { updateEvent } from "../../functions";
import { eventTime } from "../../utils/eventTimer";
import EventWidget from "../../components/event_widget/list";
import EventTitle from "./event_title";
import EventImages from "./event_images";
import EventsInMini from "./events_in_mini";
import FilledActions from "./filled_actions";
import ClosedActions from "./closed_actions";
import Progress from "./progress";
import SeatList from "./seat_list";
import Loading from "../feed/loading";
import Wheel from "../../components/wheel";
import P6Button from "../../components/button";
import AdminActions from "./admin_actions";
import MiniWinners from "./miniWinners";
import { type P6Fulfillment } from "../../../../src/types/fulfillment";
import { type P6Event } from "../../../../src/types/event";
import { type P6Group } from "../../../../src/types/group";
import { type P6GroupMembership } from "../../../../src/types/groupMembership";
import * as Sentry from "@sentry/react";
import { P6ApiResponse } from "../../../../src/types";
import { P6ClientError } from "../../utils/exeptions";
import { P6Slice } from "../../../../src/types/event";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import clevertap from "clevertap-web-sdk";
import RedirectModal from "../../components/event_card/redirect_modal/redirect_modal";

export default function Event() {
  const [event, setEvent] = useState<P6Event | null>(null); // cleaned
  const [seatList, setSeatList] = useState<P6Slice[]>();
  const [wheelSpun, setWheelSpun] = useState<boolean>(false);
  const [fulfillment, setFulfillment] = useState<P6Fulfillment>();
  const [group, setGroup] = useState<P6Group>();
  const [groupId, setGroupId] = useState<string>();
  const [admin, setAdmin] = useState<boolean>(false);
  const { user, activeGroupIds } = useUser();
  const [spinWheel, setSpinWheel] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(1);
  const [eventState, setEventState] = useState<string>("");
  const [winnerLoading, setWinnerLoading] = useState<boolean>(false);
  const [seatsFlashed, setSeatsFlashed] = useState<boolean>(false);
  const [hideSpinButton, setHideSpinButton] = useState<boolean>(false);
  const countdownRef = useRef<
    string | number | undefined | ReturnType<typeof setTimeout>
  >();
  const [winners, setWinners] = useState<number[]>([]);
  const { eventId } = useParams();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (navigator.userAgent.match(/(iPhone)/)) {
      setOptionsOpen(true);
    }
  }, [location.search, eventId]);

  // Add group name to Sentry
  Sentry.setTag("group", group?.name);

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      const indexPlus: number = index + 1;
      const indexString = indexPlus.toString();
      return '<span class="' + className + '">' + indexString + "</span>";
    },
  };

  // Get the event data
  useEffect(() => {
    if (eventId) {
      const unsubscribe = onSnapshot(doc(db, "events", eventId), (doc) => {
        const event = doc.data() as P6Event;
        if (event) {
          event.id = doc.id;
          setEvent(event);
          setGroupId(event.groupId);
          clevertap.event.push("Event Viewed", {
            Identity: event.id,
            Title: event.title,
            Type: event.type,
            GroupId: event.groupId,
            Rounding: event.options.rounding,
            Winners: event.winners,
            EventValue: event.options.eventValue,
            AutoRoll: event.options.autoRoll,
            Featured: event.options.featured,
            FreeEvent: event.options.freeEvent,
            limited: event.options.limited,
            Seats: event.options.seats,
            State: event.state,
            Available: event.available,
            DOB: new Date(),
          });

          window.dataLayer.push({
            event: "event_data",
            eventProps: {
              ...event
            },
          } as unknown);

          if (
            event &&
            event.random &&
            event.random.random &&
            event.random.random.data
          ) {
            const winningNumbers = event.random.random.data;
            setWinners(winningNumbers);
          }
        }
      });
      return () => {
        setCounter(1);
        setWheelSpun(false);
        setSpinWheel(false);
        setSeatsFlashed(false);
        setEventState("");
        setEvent(null);
        setSeatList([]);
        setFulfillment(undefined);
        unsubscribe();
      };
    }
  }, [eventId]);

  // Set the distance in time from filled datw
  useEffect(() => {
    if (event && event.filledDate && event.options.autoRoll) {
      const { distance } = eventTime(
        event.filledDate,
        event.state,
        0,
        event.options.autoRoll,
      );
      setCounter(distance + 30);
      if (distance + 30 < 0) {
        setWheelSpun(true);
      }
    }
    if (event && !event.options.autoRoll && event.closedAt) {
      setWheelSpun(true);
    }
  }, [event]);

  // Handle event states
  useEffect(() => {
    if (event && event.options.autoRoll) {
      if (counter && event && event.filledDate) {
        if (counter > 0) setEventState("filled");
        if (counter > 2 && counter < 11) setSpinWheel(true);
        countdownRef.current = setInterval(() => {
          setCounter(counter - 1);
        }, 1000);
        if (counter <= 1) {
          setEventState("closed");
          clearInterval(countdownRef.current);
          return;
        }

        return () => {
          clearInterval(countdownRef.current);
        };
      } else {
        setEventState("active");
      }
    } else {
      if (event && event.filledDate && !event.closedAt) {
        setEventState("filled");
      }
      if (event && event.spin && !event.closedAt) {
        setSpinWheel(true);
        setTimeout(() => {
          setSeatsFlashed(true);
        }, 1000);
      }
      if (event && event.closedAt) {
        setEventState("closed");
      }
      // setEventState("active");
    }
  }, [counter, event]);

  // Get the seat list for the wheel once filled or closed
  useEffect(() => {
    if (eventId) {
      const q = query(
        collection(db, `events/${eventId}/seats`),
        orderBy("number"),
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const seats: P6Slice[] = [];
        querySnapshot.forEach((doc) => {
          const seat = doc.data();
          const color =
            seat.number % 2 === 0
              ? {
                  value: 1,
                  label: "lose",
                  color: "#BD442D",
                  textColor: "#ffffff",
                }
              : {
                  value: 0.8,
                  label: "win",
                  color: "#ffffff",
                  textColor: "#000000",
                };
          seats.push({
            number: seat.number as number,
            displayName: seat.displayName as string,
            color: color.color,
            textColor: color.textColor,
          });
        });
        setSeatList(seats);
      });
      return () => {
        unsubscribe();
      };
    }
  }, [eventId]);

  // Set fulfillment data
  useEffect(() => {
    if (!admin && event) {
      const fulfillmentsRef = collection(db, "fulfillments");
      const fulfillmentsQuery = query(
        fulfillmentsRef,
        where("eventId", "==", event.id),
        where("winnerId", "==", user.userId),
      );
      const unsubscribe = onSnapshot(
        fulfillmentsQuery,
        (fulfillmentsSnapshot) => {
          fulfillmentsSnapshot.forEach((doc) => {
            const fulfillmentData = doc.data();
            const fulfillmentId = doc.id;
            fulfillmentData.id = fulfillmentId;
            setFulfillment(fulfillmentData as P6Fulfillment);
          });
        },
      );
      return () => unsubscribe();
    }
    if (admin && event) {
      const fulfillmentsRef = collection(db, "fulfillments");
      const fulfillmentsQuery = query(
        fulfillmentsRef,
        where("eventId", "==", event.id),
        where("adminId", "==", user.userId),
        where("groupId", "==", event.groupId),
      );
      const unsubscribe = onSnapshot(
        fulfillmentsQuery,
        (fulfillmentsSnapshot) => {
          fulfillmentsSnapshot.forEach((doc) => {
            const fulfillmentData = doc.data();
            const fulfillmentId = doc.id;
            fulfillmentData.id = fulfillmentId;
            setFulfillment(fulfillmentData as P6Fulfillment);
          });
        },
      );
      return () => unsubscribe();
    }
  }, [admin, event, eventId, user.userId]);

  // Set group data
  useEffect(() => {
    if (groupId) {
      const unsubscribe = onSnapshot(doc(db, "groups", groupId), (doc) => {
        setGroup(doc.data() as P6Group);
      });
      return () => unsubscribe();
    }
  }, [groupId]);

  // Takes the group level from group member ship and not from the group admin
  // this will make is easier when we add modirators.
  useEffect(() => {
    if (user && groupId) {
      const unsubscribe = onSnapshot(
        doc(db, "groupMemberships", groupId + (user.userId as string)),
        (doc) => {
          try {
            if (!doc.data()) {
              throw new P6ClientError(
                "InternalError",
                "Group membership data is missing.",
              );
            }
            const gMember = doc.data() as P6GroupMembership;
            if (gMember.level === "admin") {
              setAdmin(true);
            } else {
              setAdmin(false);
            }
          } catch (error) {
            throw new P6ClientError("UnknownError", error as string);
          }
        },
      );

      return () => unsubscribe();
    }
  }, [user, groupId]);

  // Manualy run wheel
  const updateWheelRun = async (event: P6Event) => {
    setWinnerLoading(true);
    try {
      if (!event.id)
        throw new P6ClientError("InvalidArgument", "Event id is missing");
      const updateData = {
        id: event.id,
        spin: true,
        wheelRun: true,
      };
      const reault = await updateEvent(updateData);
      const resultData = reault.data as P6ApiResponse;
      if (resultData.success) {
        setHideSpinButton(true);
        setWinnerLoading(false);
        setSpinWheel(true);
      } else {
        throw new P6ClientError(
          "FunctionError",
          "Unable to spin the wheel manually.",
        );
      }
    } catch (error) {
      setWinnerLoading(false);
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  if (!event || !group || !groupId) {
    return <Loading />;
  }

  return (
    <div className="flex-auto">
      <RedirectModal setOptionsOpen={setOptionsOpen} optionsOpen={optionsOpen} />
      <div className="lg:hidden">
        <EventWidget activeGroupIds={activeGroupIds} />
      </div>
      {admin === true && (
        <AdminActions
          eventId={event.id}
          groupId={groupId}
          groupCreditsUsed={event.groupCreditsUsed}
          eventValue={event.options.eventValue}
          eventState={event.state}
          distroCost={event.distroCost}
        />
      )}

      <div className="mt-2.5 bg-component py-2.5 ">
        <EventTitle
          title={event.title}
          date={event.createdAt as Timestamp}
          logo={group.logo}
          groupId={groupId}
          autoRoll={event.options.autoRoll}
        />
        {event.type !== "mini" && (
          <EventImages images={event.images as string[]} />
        )}
        {event.type === "mini" && event.mainEventIds && (
          <EventsInMini mainEventIds={event.mainEventIds} />
        )}
        <div className="mt-2.5 px-2.5 text-[14px] text-white">
          {event.description}
        </div>
      </div>
      <div>
        <Progress seats={event.options.seats} available={event.available} />
      </div>
      {fulfillment && eventState === "closed" && (
        <div className="text-white">
          <ClosedActions
            key={eventId}
            admin={admin}
            fulfillment={fulfillment}
            event={event}
            userId={user.userId}
          />
        </div>
      )}
      {event.type === "event" && !fulfillment && eventState === "closed" && (
        <MiniWinners
          key={eventId}
          winners={winners}
          seatList={seatList}
          event={event}
        />
      )}
      {event.type === "mini" && eventState === "closed" && (
        <MiniWinners
          key={eventId}
          winners={winners}
          seatList={seatList}
          event={event}
        />
      )}
      {eventState === "filled" && (
        <FilledActions
          key={eventId}
          autoRoll={event?.options.autoRoll}
          spinWheel={spinWheel}
          counter={counter || 0}
        />
      )}

      {!event.options.autoRoll &&
        !hideSpinButton &&
        !wheelSpun &&
        admin &&
        event.filledDate && (
          <div>
            <div className="flex justify-center top-[110px] z-40 flex-auto sticky">
              <P6Button
                loading={winnerLoading}
                text="Spin the Wheel"
                textColor="text-white"
                onClick={() => updateWheelRun(event)}
              />
            </div>
          </div>
        )}

      {/* Placeholder wheel for manual */}
      {event.filledDate &&
        event.options.seats <= 50 &&
        !event.options.autoRoll &&
        !winners.length && (
          <Wheel
            key={eventId && eventId + "holder"}
            seatList={seatList}
            autoRoll={false}
            random={0}
            wheelSpun={false}
            spinIt={false}
          />
        )}

      {event.filledDate &&
        event.options.seats <= 50 &&
        seatList?.length === event.options.seats && (
          <div className="relative max-w-full lg:max-w-xl">
            <Swiper
              pagination={pagination}
              modules={[Pagination]}
              className={`${
                winners && winners.length > 1
                  ? "padding-bottom"
                  : "no-padding-bottom"
              }`}
            >
              {winners &&
                winners.length &&
                winners.map((value, index) => (
                  <SwiperSlide key={index}>
                    <Wheel
                      key={eventId && eventId}
                      seatList={seatList}
                      autoRoll={event.options.autoRoll}
                      random={value}
                      wheelSpun={wheelSpun}
                      spinIt={spinWheel}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        )}

      <div className="mt-2.5">
        <SeatList
          key={eventId}
          groupId={groupId}
          cost={event.options.seatPrice}
          eventId={eventId as string}
          totalSeats={event.options.seats}
          available={event.available}
          limited={event.options.limited}
          random={winners}
          flashSeats={spinWheel}
          wheelSpun={wheelSpun}
          seatsFlashed={seatsFlashed}
          admin={admin}
          distribution={event.distribution ? true : false}
          noGroupCredits={event.options.noGroupCredits as boolean}
        />
      </div>
    </div>
  );
}
