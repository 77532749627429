import { Link } from 'react-router-dom';

export default function DistributorsList() {
  return (
    <div className="text-white grid grid-cols-3 mt-4">
      <div className="flex bg-grey aspect-square items-center justify-center rounded-lg">
        <Link to="/distribution/distributors/rsr">
        <img src="https://firebasestorage.googleapis.com/v0/b/platformsixx-next.appspot.com/o/distributors%2Flogo-rsr.png?alt=media&token=f60cddd4-7e35-4b87-ac9a-ade712d13a7a" alt="RSR" />
        </Link>
      </div>
    </div>
  );
}
