import { db } from "../../../../firebase/clientFirebaseApps";
import { doc, onSnapshot } from "firebase/firestore";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { type P6UserProfile } from "../../../../../../src/types/profile";
import { updateGroupMembership } from "../../../../functions";
import { type P6ApiResponse } from "../../../../../../src/types";
import { P6ClientError } from "../../../../utils/exeptions";

export default function User({
  userId,
  groupId,
  list,
  setSelectedUser,
  userIds,
  setUserIds,
}: {
  userId: string;
  groupId: string;
  list: string;
  setSelectedUser: Dispatch<SetStateAction<string | undefined>>;
  userIds?: string[];
  setUserIds?: Dispatch<SetStateAction<string[]>>;
}) {
  const [user, setUser] = useState<P6UserProfile>();
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);

  const handleAlert = (state: boolean) => {
    setDeleteAlert(state);
  };
  const updateGroupUser = async (remove: boolean) => {
    try {
      if (!user || !groupId || !userId) {
        throw new P6ClientError(
          "InvalidArgument",
          "User and Group ID are required.",
        );
      }
      const request = {
        groupId: groupId,
        level: "user",
        status: "active",
        userId: userId,
        delete: remove,
      };

      const result = await updateGroupMembership(request);
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        // TODO: test put a notification in here for the user to let them know it has been removed
        handleAlert(false);
        if (setUserIds && userIds && userIds.length > 0) {
          setUserIds(userIds?.filter((x) => x !== userId));
        }
      } else {
        throw new P6ClientError("FunctionError", "Unable to remove event.");
      }
    } catch (error) {
      handleAlert(false);
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  useEffect(() => {
    if (userId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const unsub = onSnapshot(doc(db, "profiles", userId), (doc) => {
        setUser(doc?.data() as P6UserProfile);
      });

      return () => unsub();
    }
  }, [userId]);

  if (!user) {
    return null;
  }

  return (
    <div className="flex text-white bg-component mt-2.5 items-center px-2.5 py-1">
      <div
        className="flex flex-auto items-center cursor-pointer"
        onClick={() => setSelectedUser(userId)}
      >
        <div className="mr-2.5 h-[38px] w-[38px]">
          <img
            src={user.profileImageUrl || `/icons/user_icon.svg`}
            alt="author"
            className="rounded-full h-[38px] w-[38px]"
            width={38}
            height={38}
          />
        </div>
        <div className="flex-auto">
          <div className="text-lg leading-5">{user.displayName}</div>
          <div className="text-grey">{user.email}</div>
        </div>
      </div>
      <div data-cy="remove-user-icon" className="px-1 text-lg mr-2">
        <svg
          onClick={() => handleAlert(true)}
          className="fill-white hover:fill-red"
          id="Component_22_1"
          data-name="Component 22 – 1"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="18"
          viewBox="0 0 14 18"
        >
          <title>remove</title>
          <path
            id="Path_24"
            data-name="Path 24"
            d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V7H6ZM19,4H15.5l-1-1h-5l-1,1H5V6H19Z"
            transform="translate(-5 -3)"
          />
        </svg>
      </div>
      {list === "pending" && (
        <div data-cy="add-user" className="px-1 text-lg">
          <svg
            onClick={() => updateGroupUser(false)}
            className="fill-white hover:fill-green"
            id="Component_9_1"
            data-name="Component 9 – 1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <path
              id="Path_22"
              data-name="Path 22"
              d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5,11H13v4H11V13H7V11h4V7h2v4h4Z"
              transform="translate(-2 -2)"
            />
          </svg>
        </div>
      )}

      {deleteAlert && (
        <div
          id="alert-additional-content-1"
          className="fixed mr-2.5 max-w-[540px] bottom-2.5 max-md: z-50 p-4 mb-4 text-white border border-red rounded-lg bg-[#422c27] dark:text-white dark:border-red"
          role="alert"
        >
          <div className="flex items-center ">
            <svg
              className="flex-shrink-0 w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <h3 className="text-lg font-medium ">Remove Pending User?</h3>
          </div>
          <div className="mt-2 mb-4 text-sm text-grey">
            This action can not be undone, please make sure you wanted to remove
            the pending user or click cancel to go back.
          </div>
          <div className="flex">
            <button
              type="button"
              className="text-white bg-dark-grey hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-dark-grey dark:hover:bg-grey"
              onClick={() => handleAlert(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="text-white bg-transparent border border-red hover:bg-highlight hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red dark:border-red dark:text-white dark:hover:text-white dark:focus:ring-red"
              data-dismiss-target="#alert-additional-content-1"
              aria-label="Close"
              data-cy="remove-user"
              onClick={() => updateGroupUser(true)}
            >
              Remove Pending User
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
