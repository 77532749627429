import { Link } from "react-router-dom";
import { useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";
import { percent, isFull } from "../../utils/functions";
import { type P6Event } from "../../../../src/types/event";
import { P6ClientError } from "../../utils/exeptions";

export default function WidgitItem({ data }: { data: P6Event }) {
  const [groupLogo, setGroupLogo] = useState("/images/blank_profile.svg");

  if (data.groupId) {
    (async () => {
      const docRef = doc(db, "groups", data.groupId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setGroupLogo(docSnap.data().logo as string);
      } else {
      }
    })().catch((error) => {
      throw new P6ClientError("UnknownError", error as string);
    });
  }

  return (
    <div className="relative">
      {/* small group image and link */}
      <Link to={`/group/${data.groupId}/active`} className="">
        <div className="absolute left-[7px] top-[5px] z-10 overflow-hidden rounded-full border border-component bg-red">
          <img
            src={groupLogo}
            alt="Group Logo"
            className="rounded-full w-[27px] h-[27px] object-cover"
          />
        </div>
      </Link>
      <Link to={`/event/${data.id}`}>
        <div className="ml-2.5 mt-2 h-[116px] w-[116px] flex-none overflow-hidden rounded-lg bg-white">
          <div className={`z-5 relative h-2.5 w-full bg-black`}>
            <div
              className={`h-2.5 ${isFull(data.available)}`}
              style={{
                width: `${percent(data.options.seats, data.available)}%`,
              }}
            ></div>
          </div>
          <div className="relative h-full w-full flex-none">
            <img
              src={
                data.type === "mini"
                  ? `/images/mini.svg`
                  : data.images && data.images[0]
              }
              alt="Event"
              className="cover h-auto w-auto"
            />
          </div>
          <div className="absolute bottom-[4px] left-[14px] rounded-full bg-green pl-2 pr-2 text-base text-white">
            {data.options.seatPrice} ₢
          </div>
          <div className={`${data.type === "mini" ? "" : "hidden"}`}>
            <div className="absolute bottom-[4px] right-[14px] rounded-full bg-background pl-1 pr-1 text-base text-white">
              M
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
