import Layout from "./layout";
import { Fragment, useRef, Dispatch, SetStateAction } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function PaymentModal({
  credits,
  paymentOpen,
  setPaymentOpen,
  creditAmount,
  groupCreditAmount,
}: {
  credits: number;
  paymentOpen?: boolean;
  setPaymentOpen: Dispatch<SetStateAction<boolean>>;
  creditAmount?: number;
  groupCreditAmount?: number;
}) {
  const cancelButtonRef = useRef(null);

  return (
    <div>
      <div
        className="relative flex h-[30px] w-auto cursor-pointer items-center justify-center rounded-3xl bg-green"
        onClick={() => {
          setPaymentOpen(true);
        }}
      >
        {groupCreditAmount && groupCreditAmount > 0 ? (
          <span className="absolute flex items-center -top-2.5 bg-p6blue rounded-full px-2 text-xs leading-3 py-[1px] text-white">
            {groupCreditAmount}
            <span className="ml-1">G₢</span>
          </span>
        ) : (
          ""
        )}

        <p data-cy="credits" className="ml-3 whitespace-nowrap text-white">
          {credits ? credits : 0} ₢
        </p>
        <img
          src="/icons/add_icon.svg"
          alt="Add Credits"
          className="mr-1 ml-2"
          width={20}
          height={20}
        />
      </div>
      <Transition.Root show={paymentOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setPaymentOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-2.5 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-screen-xs transform rounded-lg bg-component text-left shadow-xl transition-all sm:my-2.5">
                  <Layout
                    setPaymentOpen={setPaymentOpen}
                    creditAmount={creditAmount}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
