import { P6Event } from "../../../../src/types/event";
import { P6Fulfillment } from "../../../../src/types/fulfillment";
import Won from "../../components/event_card/won";
import { shortDate } from "../../utils/dateFormat";
import VerifyRandom from "../../components/verify_random/layout";
import { RandomOrgResult } from "../../../../src/types/random";

export default function ClosedActions({
  fulfillment,
  event,
  userId,
  admin,
}: {
  fulfillment: P6Fulfillment;
  event: P6Event;
  userId: string | undefined;
  admin?: boolean;
}) {

  return (
    <div>
      {/* winner */}
      <div
        className={`${event.postEvent?.winnerId === userId ? "" : "hidden"}`}
      >
        <div className="mt-2.5 grid grid-cols-5 bg-green p-2.5 text-white">
          <div className="">
            <div className="mt-4 rounded-lg bg-black px-1.5 text-center text-[22px]">
              #{event?.random?.random?.data[0]}
            </div>
          </div>
          <div className="col-span-3">
            <div className="text-center">
              <div className="mt-2.5 text-[35px] leading-[40px]">You Won!</div>
            </div>
          </div>
          <div>
            <div className="mt-4">
              <VerifyRandom random={event.random as RandomOrgResult} />
            </div>
            <div className="text-center">
              {event.closedAt ? shortDate(event.closedAt) : ""}
            </div>
          </div>
        </div>
        <div className="bg-component py-4 px-[63px] text-white">
          <Won data={event} fulfillment={fulfillment} />
        </div>
      </div>
      {/* looser */}
      <div
        className={`${event.postEvent?.winnerId === userId ? "hidden" : ""}`}
      >
        <div className="my-2.5 grid grid-cols-5 bg-green p-2.5 text-white">
          <div className="">
            <div className="mt-4 rounded-lg bg-black px-1.5 text-center text-[22px]">
              #{event.random?.random.data[0]}
            </div>
          </div>
          <div className="col-span-3">
            <div className="text-center">
              <div className="text-[35px] leading-[40px]">Winner</div>
              <div>{event.winnerName}</div>
            </div>
          </div>
          <div>
            <div className="mt-4">
              <VerifyRandom random={event.random as RandomOrgResult} />
            </div>
            <div className="text-center">#{event?.random?.random?.data[0]}</div>
          </div>
        </div>
      </div>
      {admin && event.postEvent?.winnerId !== userId && (
        <div className="bg-component py-4 px-[63px] text-white">
          <Won data={event} fulfillment={fulfillment} />
        </div>
      )}
    </div>
  );
}
