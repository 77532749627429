function isNumeric(n: string) {
  const num = parseFloat(n)
  return !isNaN(num) && isFinite(num);
}

export function imageUrl(name: string) {
  let imagePath = "rsr_number/";
  let cdn = "https://cdn.pf6.app/";
  if (import.meta.env.MODE !== "production") {
    cdn = "https://cdn.p6next.com/"
    imagePath = "rsr_number/";
  }

  const imageBaseUrl =`${cdn}${imagePath}`;
  const imageFistChar = name.charAt(0);
  const numberCheck = isNumeric(imageFistChar) as boolean;
  const folder = numberCheck ? "%23/" : imageFistChar.toLocaleLowerCase() + "/";
  const imageName = name.replace(/(\.[\w\d_-]+)$/i, '_HR$1');
  return imageBaseUrl + folder + imageName;

}