import { Dispatch, SetStateAction, useState } from "react";
import { percent } from "../../utils/functions";
import { P6Event } from "../../../../src/types/event";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import P6Button from "../button";

export default function MiniEventCreate({
  event,
  minis,
  setEventsWithMini,
  createMiniSection,
  index
}: {
  event: P6Event;
  minis: P6Event[];
  setEventsWithMini: Dispatch<SetStateAction<P6Event[]>>;
  createMiniSection?: boolean;
  index?: number;
}) {
  const [seats, setSeats] = useState<number>(0);
  const { register, handleSubmit } = useForm<FieldValues>();
  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (
      isNaN(data.seats as number) ||
      data.seats > event.available ||
      data.seats < 0
    ) {
      return;
    }
    event.options.miniSeats = data.seats as number;
    const eventWithMini = [...minis, event];
    setEventsWithMini(eventWithMini);
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(ev.target.value);
    if (!isNaN(val) && val <= event.available && val >= 0) {
      setSeats(val);
    }
  };

  const removeEvent = () => {
    const filteredMinis = minis.filter((x) => x.id !== event.id);
    setEventsWithMini(filteredMinis);
  };

  const calculateMiniBar = () => {
    const totalSeats = createMiniSection
      ? (event.options.miniSeats as number)
      : seats;
    if (!totalSeats || totalSeats === 0) {
      return 0;
    }
    const taken = event.options.seats - event.available;
    const seatPercent = ((taken + totalSeats) / event.options.seats) * 100;

    return seatPercent - percent(event.options.seats, event.available);
    // return createMiniSection ? percent((event.options.miniSeats as number), event.available) : percent((seats as number), event.available)
  };

  const calculateMainBar = () => {
    return (
      100 - (percent(event.options.seats, event.available) + calculateMiniBar())
    );
  };
  return (
    <div>
      <div className="flex">
        <div className="mt-0 mr-1 flex flex-auto h-6 text-[12px] md:text-[14px] leading-8 text-grey line-clamp-2">
          {createMiniSection ? "Remove?" : "How many?"}
        </div>
        {createMiniSection ? (
          <div className="mt-2 flex flex-shrink-0 items-center">
            {/* <div className="">
              <div
                className="flex h-[20px] w-auto cursor-pointer items-center justify-center rounded-full bg-red" onClick={removeEvent}
              >
                <p className="ml-3 whitespace-nowrap text-white text-sm md:text-base"> Remove</p>
              </div>
            </div> */}
            <P6Button
              sm
              div
              onClick={() => removeEvent()}
              text="Remove"
              color="bg-red"
              textColor="text-white"
            />
          </div>
        ) : (
          <div className="mt-1 flex-auto">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="mt-1 flex items-center gap-2"
            >
              <input
                data-cy={event.type}
                max={event.available}
                type="number"
                className="peer block w-10 ml-4 h-6 appearance-none rounded-full border-[1px] border-grey bg-component px-2 pb-2 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
                id="seats"
                autoComplete="off"
                placeholder=" "
                {...register("seats", {
                  required: true,
                  valueAsNumber: true,
                })}
                onChange={(event) => handleChange(event)}
              />
              <div className="flex flex-auto" data-type={event.type} data-cy={`event-${index}-add-button`}>
                {/* <button
                  className="flex h-[26px] w-auto cursor-pointer items-center justify-center rounded-3xl bg-green"
                >
                  <p className="ml-3 whitespace-nowrap text-white">Add</p>
                  <img src="/icons/add_icon.svg" alt="Add Credits" className="mr-1.5 ml-1" width={20} height={20} />
                </button> */}
                <P6Button type="submit" sm text="Add" textColor="text-white" />
              </div>
            </form>
          </div>
        )}
      </div>
      <div className="relative mt-1.5 h-[16px] w-full rounded-full bg-black flex">
        <div
          className={`bg-p6blue flex h-[16px] items-center justify-center rounded-full`}
          style={{ width: `${percent(event.options.seats, event.available)}%` }}
        >
          <div className="absolute m-auto flex h-[16px] w-full items-center justify-center text-[14px] text-white">
            {event.available !== event.options.seats
              ? event.options.seats - event.available
              : ""}
          </div>
        </div>
        <div
          className={`bg-green flex h-[16px] items-center justify-center rounded-full`}
          style={{ width: `${calculateMiniBar()}%` }}
        >
          <div className="absolute m-auto flex h-[16px] w-full items-center justify-center text-[14px] text-white">
            {createMiniSection && event.options.miniSeats
              ? event.options.miniSeats
              : seats > 0
              ? seats
              : ""}
          </div>
        </div>
        <div
          className={`bg-black flex h-[16px] items-center justify-center rounded-full`}
          style={{ width: `${calculateMainBar()}%` }}
        >
          <div className="absolute m-auto flex h-[16px] w-full items-center justify-center text-[14px] text-white">
            {calculateMainBar() === 0
              ? ""
              : event.options.seats -
                (event.options.miniSeats ? event.options.miniSeats : seats) -
                (event.options.seats - event.available)}
          </div>
        </div>
      </div>
    </div>
  );
}
