import { type Dispatch, SetStateAction } from "react";
import { useForm, FieldValues, SubmitHandler } from "react-hook-form";
import { stateList } from "../../../utils/state_array";
import Select from "react-select";
import { type authnetAddress } from "../../../../../src/types/payment/provider/authnet";
import P6Button from "../../button";

export default function AddBillingAddress({
  setAddress,
  setAddressOpen,
}: {
  setAddress: Dispatch<SetStateAction<authnetAddress | undefined>>;
  setAddressOpen: Dispatch<SetStateAction<boolean>>;
}) {
  //const [loading, setLoading] = useState(false);
  const {
    register,
    setError,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (!data.state) {
      setError("state", { type: "custom", message: "custom message" });
    } else {
      setAddress(data as authnetAddress);
      setAddressOpen(false);
    }
  };

  const onError = () => {
    if (!getValues("state")) {
      setError("state", { type: "custom", message: "custom message" });
    }
  };

  const setStateValue = (value: string) => {
    setValue("state", value);
    clearErrors("state");
  };

  return (
    <div className="mt-4 p-2.5 text-white">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="grid grid-cols-2 gap-2.5">
          <div className="">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="firstName"
                autoComplete="given-name"
                className="text-field peer"
                placeholder=" "
                {...register("firstName", {
                  required: true,
                })}
              />
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
            </div>
            {errors.firstName && (
              <p className="form-error">First Name is Required</p>
            )}
          </div>

          <div className="">
            <div className="relative mb-2.5">
              <input
                type="text"
                id="lastName"
                autoComplete="family-name"
                className="text-field peer"
                placeholder=" "
                {...register("lastName", {
                  required: true,
                })}
              />
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
            </div>
            {errors.lastName && (
              <p className="form-error">Last Name is Required</p>
            )}
          </div>
        </div>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="address"
              autoComplete="address-line1"
              className="text-field peer"
              placeholder=" "
              {...register("address", {
                required: true,
              })}
            />
            <label htmlFor="address" className="form-label">
              Address
            </label>
          </div>
          {errors.address && <p className="form-error">Address is Required</p>}
        </div>
        <div className="mb- grid grid-cols-5">
          <div className="col-span-2 mb-2.5">
            <div className="mb-2.5">
              <div className="relative mb-2.5">
                <input
                  type="text"
                  id="city"
                  autoComplete="address-level2"
                  className="text-field peer"
                  placeholder=" "
                  {...register("city", {
                    required: true,
                  })}
                />
                <label htmlFor="city" className="form-label">
                  City
                </label>
              </div>
              {errors.city && <p className="form-error">City is Required</p>}
            </div>
          </div>
          <div className="col-span-3 flex">
            <div className="relative ml-2.5 mr-2.5 w-40">
              <Select
                unstyled
                instanceId="state"
                classNames={{
                  control: (state) =>
                    state.isFocused
                      ? "flex block px-2.5 w-30 text-sm text-grey bg-component rounded-full border-[1px] appearance-none text-white border-green outline-none ring-0 border-green peer pl-4"
                      : "flex block px-2.5 w-30 text-sm text-grey bg-component rounded-full border-[1px] border-grey appearance-none dark:text-white dark:border-grey dark:focus:border-green focus:outline-none focus:ring-0 focus:border-green peer pl-4",
                  menu: (state) =>
                    state.options
                      ? "bg-component rounded-lg border-[1px] border-grey mt-1.5 absolute w-[150px]"
                      : "",
                  option: (state) =>
                    state.data
                      ? "hover:bg-green px-2.5 first:mt-2 last:mb-2"
                      : "",
                }}
                isSearchable={true}
                name="state"
                options={stateList}
                onChange={(value) => setStateValue(value?.value as string)}
              />
              <label
                htmlFor="state"
                className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
              >
                State
              </label>
              {errors.state && (
                <p className="mt-[0px] mb-2.5 pl-2.5 text-red">Required</p>
              )}
            </div>
            <div className="flex-auto">
              <div className="mb-2.5">
                <div className="relative mb-2.5">
                  <input
                    type="text"
                    id="zip"
                    autoComplete="postal-code"
                    className="text-field peer"
                    placeholder=" "
                    {...register("zip", {
                      required: true,
                    })}
                  />
                  <label htmlFor="zip" className="form-label">
                    Zip Code
                  </label>
                </div>
                {errors.zip && (
                  <p className="form-error">Zip Code is Required</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex space-x-2.5">
          <P6Button
            text="Cancel"
            color="bg-grey"
            div
            onClick={() => setAddressOpen(false)}
          />
          <P6Button type="submit" text="Add Paymnet Address" color="bg-green" />
        </div>
      </form>
    </div>
  );
}
