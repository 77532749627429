import { ChangeEvent, useEffect, useState } from "react";
import { useUser } from "../../layouts/page_layout/layout";
import ItemGroup from "./item_group";
import ItemWebinar from "./item_webinar";
import algoliasearch from "algoliasearch/lite";
import { EventIndex, GroupIndex } from "../../../../src/types/algolia/indexes";
import { P6Group } from "../../../../src/types/group";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";
import { P6ClientError } from "../../utils/exeptions";

const appId = import.meta.env.VITE_ANGOLIA_APP_ID as string;
const apiKey = import.meta.env.VITE_ANGOLIA_API_KEY as string;
const searchClient = algoliasearch(appId, apiKey);
let groupIndexName = "Groups";
let eventIndexName = "Events";

if (process.env.NODE_ENV !== "production") {
  groupIndexName = "dev_" + groupIndexName;
  eventIndexName = "dev_" + eventIndexName;
}
const groupIndex = searchClient.initIndex(groupIndexName);
const eventIndex = searchClient.initIndex(eventIndexName);

export default function Search() {
  const { activeGroupIds } = useUser();
  const [searchQuery, setSearchQuery] = useState("");
  const [groupResults, setGroupResults] = useState<GroupIndex[] | null>();
  const [eventResults, setEventResults] = useState<EventIndex[] | null>();
  const [activeGroups, setActiveGroups] = useState<P6Group[]>([]);

  useEffect(() => {
    const unsubscribeActive: (() => void)[] = [];

    if (activeGroupIds.length > 0) {
      const groupsRef = collection(db, "groups");
      activeGroupIds.forEach((groupId) => {
        const q = query(groupsRef, where("groupId", "==", groupId));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const activeAr: P6Group[] = [];
          querySnapshot.forEach((doc) => {
            activeAr.push({ id: doc.id, ...doc.data() } as P6Group);
          });

          setActiveGroups((prevActiveGroups) => {
            const updatedGroups = prevActiveGroups.filter(
              (group) => group.id !== groupId,
            );
            return [...updatedGroups, ...activeAr];
          });
        });

        unsubscribeActive.push(unsubscribe);
      });
    }

    return () => {
      unsubscribeActive.forEach((unsubscribe) => unsubscribe());
    };
  }, [activeGroupIds]);

  useEffect(() => {
    if (searchQuery.trim() !== "" && searchQuery.length > 2) {
      const groupIdsFilter = activeGroupIds
        .map((groupId) => `groupId:${groupId}`)
        .join(" OR ");
      groupIndex
        .search(searchQuery, {
          filters: groupIdsFilter,
        })
        .then(({ hits }) => {
          setGroupResults(hits as GroupIndex[]);
        })
        .catch((error) => {
          throw new P6ClientError("UnknownError", error as string);
        });

      eventIndex
        .search(searchQuery, {
          filters: groupIdsFilter,
        })
        .then(({ hits }) => {
          setEventResults(hits as EventIndex[]);
        })
        .catch((error) => {
          throw new P6ClientError("UnknownError", error as string);
        });
    } else {
      setGroupResults(null);
      setEventResults(null);
    }
  }, [searchQuery, activeGroupIds]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className=" md:container md:mx-auto">
      <div className="mt-2.5 bg-component p-2.5">
        <div className="mb-2.5">
          <div className="relative mb-2.5 mt-2">
            <input
              type="text"
              id="search"
              autoComplete="true"
              className="peer block w-full appearance-none rounded-full border-[1px] border-grey bg-component px-2.5 pb-1.5 pt-2 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green pl-9"
              placeholder=" "
              onChange={handleInputChange}
              value={searchQuery}
            />
            <div className="absolute top-0 flex h-[35px] w-[28px] justify-end">
              <img
                src="/icons/magnify_glass.svg"
                alt="Credit Icon"
                className="top-[7px] left-[10px] z-10"
                width={17.49}
                height={17.49}
              />
            </div>

            <label
              htmlFor="search"
              className="z-9 absolute top-0 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red left-7"
            >
              Search
            </label>
          </div>
        </div>
      </div>
      {groupResults &&
        eventResults &&
        groupResults.length === 0 &&
        eventResults.length === 0 && (
          <div className="mt-2.5 flex h-[87px] w-full flex-col justify-center rounded-lg bg-component text-center">
            <p className="text-[25px] text-white">
              No Available Events Or Groups
            </p>
            <p className="text-white">Check back later.</p>
          </div>
        )}
      {!groupResults && !eventResults && (
        <div className="mt-2.5 flex h-[87px] w-full flex-col justify-center rounded-lg bg-component text-center">
          <p className="text-[25px] text-white">
            Search For Available Events Or Groups
          </p>
        </div>
      )}
      <div className="mt-2.5 bg-component text-white">
        {groupResults &&
          groupResults.map((group, index) => (
            <ItemGroup
              key={group.objectID}
              group={group}
              noBorder={index === 0}
            />
          ))}
        {activeGroups &&
          activeGroups.length > 0 &&
          eventResults &&
          eventResults.map((event, index) => (
            <ItemWebinar
              key={event.objectID}
              event={event}
              noBorder={
                groupResults && groupResults.length > 0 ? false : index === 0
              }
              group={
                activeGroups.find((x) => x.groupId === event.groupId) as P6Group
              }
            />
          ))}
      </div>
    </div>
  );
}
