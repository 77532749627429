import Layout from "./layout";
import { Fragment, useRef, Dispatch, SetStateAction } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { P6Fulfillment } from "../../../../../src/types/fulfillment";

export default function ReceiveModal({
  optionsOpen,
  setOptionsOpen,
  fulfillment,
  creditsToReturn,
}: {
  optionsOpen?: boolean;
  setOptionsOpen: Dispatch<SetStateAction<boolean>>;
  fulfillment: P6Fulfillment;
  creditsToReturn: number;
}) {
  const cancelButtonRef = useRef(null);

  return (
    <div>
      <Transition.Root show={optionsOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOptionsOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-2.5 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-screen-xs transform rounded-lg bg-component text-left shadow-xl transition-all sm:my-2.5">
                  <Layout
                    setOptionsOpen={setOptionsOpen}
                    fulfillment={fulfillment}
                    creditsToReturn={creditsToReturn}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
