import { useState } from "react";
import PayoutAddMethod from "./payout_add_method";
import PayoutMethodForm from "./payout_method_form";
import PayoutMethodsList from "./payout_methods_list";
import PayoutAmount from "./payout_amount";
import { type P6PayoutMethod } from "../../../../../src/types/payment/payout_method";

export default function Payout({
  userId,
  groupId,
}: {
  userId: string;
  groupId: string | undefined;
}) {
  const [formOpen, setFormOpen] = useState(false);
  const [account, setAccount] = useState<P6PayoutMethod | undefined>();

  return (
    <div className="text-white p-2.5 pt-4">
      {account && (
        <PayoutAmount
          setAccount={setAccount}
          account={account}
          groupId={groupId}
        />
      )}
      {!account && !formOpen && <PayoutAddMethod setFormOpen={setFormOpen} />}
      {!account && formOpen && (
        <PayoutMethodForm setFormOpen={setFormOpen} groupId={groupId} />
      )}
      {!account && !formOpen && (
        <PayoutMethodsList
          userId={userId}
          groupId={groupId}
          setAccount={setAccount}
        />
      )}
    </div>
  );
}
