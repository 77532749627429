import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { clientAuth } from "../../firebase/clientFirebaseApps";
import Tabs from "./components/tabs";
import { onAuthStateChanged } from "firebase/auth";
import AddCredits from "./components/add";
import Payouts from "./components/payout";
import { useParams } from "react-router-dom";
import { db } from "../../firebase/clientFirebaseApps";
import { doc, onSnapshot } from "firebase/firestore";
import { P6GroupMembership } from "../../../../src/types/groupMembership";

export default function Layout({
  setPaymentOpen,
  creditAmount,
}: {
  setPaymentOpen: Dispatch<SetStateAction<boolean>>;
  creditAmount?: number;
}) {
  const [tab, setTab] = useState<string>("Add Credits");
  const [admin, setAdmin] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>();
  const auth = clientAuth;
  const { groupId } = useParams();

  useEffect(() => {
    if (userId && groupId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const unsub = onSnapshot(
        doc(db, "groupMemberships", groupId + userId),
        (doc) => {
          const gMember = doc.data() as P6GroupMembership;
          if (gMember.level === "admin") {
            setAdmin(true);
          } else {
            setAdmin(false);
          }
        },
      );
      return () => unsub();
    }
  }, [userId, groupId]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
  }, [auth]);

  useEffect(() => {
    if (!admin) {
      setTab("Add Credits");
    }
  }, [admin]);

  return (
    <div className="relative text-white">
      <Tabs admin={admin} setTab={setTab} />
      {tab === "Add Credits" && (
        <AddCredits
          userId={userId}
          creditAmount={creditAmount}
          setPaymentOpen={setPaymentOpen}
        />
      )}
      {tab === "Payout" && userId && admin && (
        <Payouts userId={userId} groupId={groupId} />
      )}
    </div>
  );
}
