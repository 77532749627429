import { useState } from "react";
import renderHTML from "html-react-parser";

export default function SiteAlert({
  siteMessage,
}: {
  siteMessage: string | null;
}) {
  const [show, setShow] = useState<boolean>(true);
  if (!siteMessage || !show) {
    return null;
  }

  return (
    <div
      id="alert-additional-content-1"
      className="container relative mx-auto p-4 mt-3 mb-1 text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800"
      role="alert"
    >
      <div className="mt-2 mb-4 text-sm html-message">
        {renderHTML(siteMessage)}
      </div>
      <button
        type="button"
        onClick={() => setShow(false)}
        className="ms-auto absolute top-2 right-2 -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-blue-400 dark:hover:bg-gray-700"
        data-dismiss-target="#alert-1"
        aria-label="Close"
      >
        <span className="sr-only">Close</span>
        <svg
          className="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>
  );
}
