import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase/clientFirebaseApps";
import { doc, onSnapshot } from "firebase/firestore";
import { type P6DistributionItem } from "../../../../src/types/distribution";
import ItemImages from "./components/itemImages";
import { type P6ApiResponse } from "../../../../src/types";
import { updateDistributionStatus } from "../../functions";
import { P6ClientError } from "../../utils/exeptions";
// import P6Button from "../../components/button";
import { Switch } from "@headlessui/react";

export default function DistributionItemDetails() {
  const [item, setItem] = useState<P6DistributionItem>();
  const [active, setActive] = useState<boolean>();
  const { itemId } = useParams();

  useEffect(() => {
    setActive(item && item.status === "active" ? true : false);
  }, [item]);

  const changeStatus = async () => {
    try {
      const request = {
        inStock: item && item.status === "active" ? false : true,
        itemId: itemId,
      };
      setActive(!active);

      const result = await updateDistributionStatus(request);
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
      } else {
        throw new P6ClientError("FunctionError", "Unable to remove event.");
      }
    } catch (error) {
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  // Get the event data
  useEffect(() => {
    if (itemId) {
      const unsubscribe = onSnapshot(doc(db, "distribution", itemId), (doc) => {
        const currentItem = doc.data() as P6DistributionItem;
        if (currentItem) {
          setItem(currentItem);
        }
      });
      return () => {
        unsubscribe();
      };
    }
  }, [itemId]);
  return (
    <div className="text-white pt-3">
      <div className="flex items-center mb-3">
        <Switch
          checked={active}
          onChange={changeStatus}
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
        >
          <span
            aria-hidden="true"
            className="pointer-events-none absolute h-full w-full rounded-md"
          />
          <span
            aria-hidden="true"
            className={`${active ? "bg-green" : "bg-grey"} 
            pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
          />
          <span
            aria-hidden="true"
            className={`${active ? "translate-x-5" : "translate-x-0"} 
            pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out`}
          />
        </Switch>
        <span className="ml-2 capitalize">
          {item && item.status === "active" ? "Active" : "Disabled"}
        </span>
      </div>

      <div className="flex text-sm">
        <div className="flex-auto text-grey">upc: {item?.upc}</div>
        <div className="bg-green px-2 rounded-full">{item?.available}</div>
      </div>
      <div className="text-xl mb-2">{item?.title}</div>
      {/* <div> status: {item?.status}</div> */}
      <div className="[&_span]:text-grey">
        <div>
          <span>Manufacturer:</span> {item?.manufacturer}
        </div>
        <div>
          <span>MSRP:</span> {item?.msrp}
        </div>
        <div>
          <span>Cost:</span> {item?.cost}
        </div>
        {item?.mfgModel && (
          <div>
            <span>MFG Model:</span> {item?.mfgModel}
          </div>
        )}
        {item?.family && (
          <div>
            <span>Family:</span> {item?.family}
          </div>
        )}
        <div>
          <span>Model:</span> {item?.model}
        </div>
        <div>
          <span>Info:</span> {item?.info}
        </div>
      </div>
      <div>{item && item.images && <ItemImages images={item.images} />}</div>
      <div>
        <div>Details</div>
        <div className="bg-component p-2 space-y-1 text-sm rounded-md [&_span]:text-grey [&>div]:border-b">
          {item?.type && (
            <div>
              <span>Type:</span> {item?.type}
            </div>
          )}
          {item?.action && (
            <div>
              <span>Action:</span> {item?.action}
            </div>
          )}
          {item?.caliber && (
            <div>
              <span>Caliber:</span> {item?.caliber}
            </div>
          )}
          {item?.finish && (
            <div>
              <span>Finish:</span> {item?.finish}
            </div>
          )}
          {item?.finishType && (
            <div>
              <span>Finish Type:</span> {item?.finishType}
            </div>
          )}
          {item?.framePolymer && (
            <div>
              <span>Frame Polymer:</span> {item?.framePolymer}
            </div>
          )}
          {item?.stockGrips && (
            <div>
              <span>Stock Grips:</span> {item?.stockGrips}
            </div>
          )}
          {item?.barrel && (
            <div>
              <span>Barrel:</span> {item?.barrel}
            </div>
          )}
          {item?.overallLength && (
            <div>
              <span>Overall Length:</span> {item?.overallLength}
            </div>
          )}
          {item?.capacity && (
            <div>
              <span>Capacity:</span> {item?.capacity}
            </div>
          )}
          {item?.magazines && (
            <div>
              <span>Magazines:</span> {item?.magazines}
            </div>
          )}
          {item?.magDescription && (
            <div>
              <span>Mag Description:</span> {item?.magDescription}
            </div>
          )}
          {item?.sights && (
            <div>
              <span>Sights:</span> {item?.sights}
            </div>
          )}
          {item?.sightType && (
            <div>
              <span>Sight Type:</span> {item?.sightType}
            </div>
          )}
          {item?.threadPattern && (
            <div>
              <span>Thread Pattern:</span> {item?.threadPattern}
            </div>
          )}
          {item?.specialFeature && (
            <div>
              <span>Special Feature:</span> {item?.specialFeature}
            </div>
          )}
          {item?.weight && (
            <div>
              <span>Weight:</span> {item?.weight}
            </div>
          )}
          {item?.shippingWeight && (
            <div>
              <span>Shipping Weight:</span> {item?.shippingWeight}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
