import { Link } from "react-router-dom";

export default function CreateGroup() {
  const smallLogo = "/icons/p6_small_logo.svg";
  const year = new Date().getFullYear();

  return (
    <div className="auth-bg flex w-full justify-center">
      <div className="flex flex-auto text-white md:container md:mx-auto">
        <div className="flex h-screen flex-auto flex-col items-center text-white">
          <div className="w-full flex-auto px-10">
            <div className="p-2.5">
              <div className="">
                <p className="text-center text-[32px]">Create Group</p>
                <p>
                  Easily share and monitize your content. We give you the tools
                  to build and manage customizable events. Creting a group
                  allows your to build and manage your userbase
                </p>
                <Link to="/groups/create/start" >
                  <div data-cy="startCreateGroup" className="relative mt-8 w-full cursor-pointer rounded-full bg-green text-center text-[32px] font-light hover:bg-dark-grey">
                    Create Group
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-5">
              <img
                src={smallLogo}
                alt="Platform Sixx Small Logo"
                className=""
              />
            </div>
            <div>© {year} Platform Sixx</div>
          </div>
        </div>
      </div>
    </div>
  );
}
