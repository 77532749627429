import { ErrorBoundary } from "react-error-boundary";

// Layouts
import AdminLayout from "./layouts/admin_layout/layout";
import AuthLayout from "./layouts/auth_layout/layout";
import PageLayout from "./layouts/page_layout/layout";
import FinishProfileLayout from "./layouts/finish_profile_layout/layout";
import JoinLayout from "./layouts/join_layout/layout";

// Maintenance
import Maintenance from "./views/maintenance";

// Pages
import ErrorPage from "./views/error";
import Login from "./views/auth/login";
import SignUp from "./views/auth/signup";
import Settings from "./views/settings";
import ResetPassword from "./views/auth/reset_password";
import Step1 from "./views/auth/finishprofile/step1";
import Step2 from "./views/auth/finishprofile/step2";
import Step3 from "./views/auth/finishprofile/step3";
import Home from "./views/home";
import Feed from "./views/feed";
import Groups from "./views/groups";
import Group from "./views/group";
import History from "./views/history";
import Notifications from "./views/notifications";
import Profile from "./views/profile";
import Search from "./views/search";
import Event from "./views/event";
import CreateGroup from "./views/groups/create";
import GroupCreateForm from "./views/groups/create/start";
import Join from "./views/join";
import FacebookLanding from "./views/facebook_landing";

// Admin
import Dashboard from "./views/admin/views/dashboard";
import Financial from "./views/admin/views/financial";
import Users from "./views/admin/views/users";
import SettingsAdmin from "./views/admin/views/settings";
import MaintenanceAdmin from "./views/admin/views/maintenance";
// distribution
import Distribution from "./layouts/distro_layout/layout";
import DistributionHom from "./views/distribution";
import DistributionItemDetails from "./views/distribution/item_details";
import DistributionRSRItemDetails from "./views/distribution/rsr_item_details";
import DistributionOrders from "./views/distribution/views/orders";
import DistributionInventory from "./views/distribution/views/inventory";
import DistributorsList from "./views/distribution/views/distributors";
import FinancialByUser from "./views/admin/views/financialByUser";
import DistributionOrder from "./views/distribution/views/order";
import DistributorsInventory from "./views/distribution/distributors_inventory";

// IOS Views
import IOSPayment from "./views/iOSViews/payment";
import EventWinner from "./views/iOSViews/eventWinner";

// Error Boundary
function Fallback({ error }: { error: Error}) {
  return <ErrorPage error={error.message} />
}

const routesConfig = [
  {
    path: "/iospaymentform/:token",
    element: (
      <ErrorBoundary fallbackRender={Fallback}>
        <IOSPayment />
      </ErrorBoundary>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/event-winner/:token/:eventId",
    element: (
      <ErrorBoundary fallbackRender={Fallback}>
        <EventWinner />
      </ErrorBoundary>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/maintenance",
    element: (
      <ErrorBoundary fallbackRender={Fallback}>
        <Maintenance />
      </ErrorBoundary>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/facebook",
    element: (
      <ErrorBoundary fallbackRender={Fallback}>
        <FacebookLanding />
      </ErrorBoundary>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/distribution",
    element: (
      <ErrorBoundary fallbackRender={Fallback}>
        <Distribution />
      </ErrorBoundary>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/distribution",
        element: <DistributionHom />,
      },
      {
        path: "/distribution/inventory",
        element: <DistributionInventory />,
      },
      {
        path: "/distribution/distributors",
        element: <DistributorsList />,
      },
      {
        path: "/distribution/distributors/:distributorId",
        element: <DistributorsInventory />,
      },
      {
        path: "/distribution/item/:itemId",
        element: <DistributionItemDetails />,
      },
      {
        path: "/distribution/rsritem/:itemId",
        element: <DistributionRSRItemDetails />,
      },
      {
        path: "/distribution/orders",
        element: <DistributionOrders />,
      },
      {
        path: "/distribution/order/:orderId",
        element: <DistributionOrder />,
      },
    ],
  },
  {
    path: "/p6admin",
    element: (
      <ErrorBoundary fallbackRender={Fallback}>
        <AdminLayout />
      </ErrorBoundary>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/p6admin/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/p6admin/financial",
        element: <Financial />,
      },
      {
        path: "/p6admin/financial-by-user",
        element: <FinancialByUser />,
      },
      {
        path: "/p6admin/users",
        element: <Users />,
      },
      {
        path: "/p6admin/settings",
        element: <SettingsAdmin />,
      },
      {
        path: "/p6admin/maintenance",
        element: <MaintenanceAdmin />,
      },
    ],
  },
  {
    path: "/finishprofile",
    element: (
      <ErrorBoundary fallbackRender={Fallback}>
        <FinishProfileLayout />
      </ErrorBoundary>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "step1",
        element: <Step1 />,
      },
      {
        path: "/finishprofile/step2",
        element: <Step2 />,
      },
      {
        path: "/finishprofile/step3",
        element: <Step3 />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <ErrorBoundary fallbackRender={Fallback}>
        <PageLayout />
      </ErrorBoundary>
    ), 
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/feed",
        element: <Feed />,
      },
      {
        path: "/group/:groupId/:action",
        element: <Group />,
      },
      {
        path: "/groups",
        element: <Groups />,
      },
      {
        path: "/groups/create/start",
        element: <GroupCreateForm />,
      },
      {
        path: "/groups/create",
        element: <CreateGroup />,
      },

      {
        path: "/history",
        element: <History />,
      },
      {
        path: "/notifications",
        element: <Notifications />,
      },
      {
        path: "/event/:eventId",
        element: <Event />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/search",
        element: <Search />,
      },
    ],
  },
  {
    element: (
      <ErrorBoundary fallbackRender={Fallback}>
        <AuthLayout />
      </ErrorBoundary>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
      {
        path: "/resetpassword",
        element: <ResetPassword />,
      },
    ],
  },
  {
    element: (
      <ErrorBoundary fallbackRender={Fallback}>
        <JoinLayout />
      </ErrorBoundary>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/join",
        element: <Join />,
      },
      {
        path: "/join/:groupId",
        element: <Join />,
      },
    ],
  },
];

export default routesConfig;
