import { fromNow } from "../../utils/dateFormat";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Active from "./active";
import Closed from "./closed";
import Won from "./won";
import { P6Event } from "../../../../src/types/event";
import { P6UserProfile } from "../../../../src/types/profile";
import { P6Fulfillment } from "../../../../src/types/fulfillment";
import { P6Timestamp } from "../../../../src/types/helpers";
import MiniEventCreate from "./mini_event_create";
import { Dispatch, SetStateAction } from "react";

export default function EventCard({
  data,
  user,
  fulfillment,
  admin,
  createMiniSection,
  minis,
  setEventsWithMini,
  index,
}: {
  data: P6Event;
  user?: P6UserProfile;
  fulfillment?: P6Fulfillment;
  admin?: boolean;
  setEventsWithMini?: Dispatch<SetStateAction<P6Event[]>>;
  createMiniSection?: boolean;
  minis?: P6Event[];
  index?: number
}) {
  const { eventId } = useParams();
  const linkId = data.id;
  const displayType = (state: string) => {
    if (setEventsWithMini && minis) {
      return (
        <MiniEventCreate
          event={data}
          setEventsWithMini={setEventsWithMini}
          createMiniSection={createMiniSection}
          minis={minis}
          index={index}
        />
      );
    }

    if (state === "active" || state === "filled") {
      return (
        <Active
          description={data.description}
          autoRoll={data.options.autoRoll}
          seats={data.options.seats}
          available={data.available}
        />
      );
    }
    if (
      state === "closed" &&
      fulfillment &&
      user &&
      (user.userId === data.postEvent?.winnerId || admin)
    ) {
      return (
        <Won data={data} fulfillment={fulfillment} admin={admin as boolean} />
      );
    }
    if (state === "closed") {
      return <Closed data={data} />;
    }
  };

  return (
    <div className="px-2.5 pt-2.5 md:px-0">
      <div
        className={`flex h-[87px] w-full overflow-hidden rounded-lg bg-component ${
          eventId === linkId ? "border-[1px] border-green" : ""
        }`}
      >
        <Link to={`/event/${linkId}`}>
          <div data-cy={data.options.autoRoll ? `auto-roll${data.options.limited ? "-limited" : ""}` : `manual-roll${data.options.limited ? "-limited" : ""}`} className="relative h-[87px] w-[87px] flex-none">
            {data.options.featured && (
              <img
                src="/icons/featured_icon.svg"
                alt="Featured"
                className="absolute -top-4 -left-4 w-[44px] h-[44px]"
              />
            )}
            <img
              //sizes="(width: 87px)"
              src={(data.images && data.images[0]) || `/images/mini.svg`}
              alt="Event cover"
              className="h-[87px] w-[87px]"
            />
            <div
              data-cy={`event-${data.options.freeEvent ? "free" : `${data.options.seatPrice}`}${data.options.autoRoll ? "-auto-roll" : "-manual"}`}
              className={`text[14px] text-white absolute bottom-[4px] right-[10px] flex h-[16px] items-center justify-center rounded-full bg-green px-1 ${
                data.options.freeEvent ? "px-2" : ""
              }`}
            >
              {data.options.freeEvent ? "Free" : ` ${data.options.seatPrice} ₢`}
            </div>
          </div>
        </Link>
        <div
          className={`w-full px-2.5 py-[5px] ${
            setEventsWithMini ? "bg-shaddow" : "bg-component"
          } relative`}
        >
          <div className="">
            {setEventsWithMini ? (
              <div></div>
            ) : (
              <div className="absolute top-0 right-2 text-[10px] text-grey">
                {fromNow(data.createdAt as P6Timestamp)}
              </div>
            )}
            <Link to={`/event/${linkId}`}>
              <div className="text-white line-clamp-1 text-sm mt-[7px] -mb-[6px]">
                {data.title}
              </div>
            </Link>
          </div>
          <div className="">{displayType(data.state)}</div>
        </div>
      </div>
    </div>
  );
}
