import { db } from "../../firebase/clientFirebaseApps";
import {
  collection,
  orderBy,
  getDocs,
  query,
  limit,
  startAfter,
} from "firebase/firestore";
import { type P6Seat } from "../../../../src/types/event";
import { P6ClientError } from "../../utils/exeptions";

export const seatLoad = {
  // this function will be fired when the app is first time run,
  seatsFirstBatch: async function (eventId: string) {
    try {
      let lastKey = 0;
      const q = query(
        collection(db, `events/${eventId}/seats`),
        orderBy("number"),
        limit(20),
      );
      const querySnapshot = await getDocs(q);
      const seats: P6Seat[] = [];
      querySnapshot.forEach((doc) => {
        seats.push({ id: doc.id, ...doc.data() } as P6Seat);
        lastKey = doc.data().number as number;
      });
      return { seats, lastKey };
    } catch (error) {
      new P6ClientError("UnknownError", error as string);
    }
  },

  // this function will be fired each time we load more
  seatsNextBatch: async (key: number, eventId: string) => {
    try {
      let lastKey = 0;
      const q = query(
        collection(db, `events/${eventId}/seats`),
        orderBy("number"),
        startAfter(key),
        limit(20),
      );
      const querySnapshot = await getDocs(q);

      const seats: P6Seat[] = [];
      querySnapshot.forEach((doc) => {
        seats.push({ id: doc.id, ...doc.data() } as P6Seat);
        lastKey = doc.data().number as number;
      });
      return { seats, lastKey };
    } catch (error) {
      new P6ClientError("UnknownError", error as string);
    }
  },
};
