import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite.css";
import {
  HierarchicalMenu,
  Hits,
  InstantSearch,
  SearchBox,
  Pagination,
  DynamicWidgets,
  RefinementList,
} from "react-instantsearch";
import { Hit } from "./components/hits";

const appId = import.meta.env.VITE_ANGOLIA_APP_ID as string;

export default function DistroSearch() {
  const searchClient = algoliasearch(appId, "92477191355cfdb1b3b8b4b94190f2f1");

  return (
    <div className="relative">
      <InstantSearch
        searchClient={searchClient}
        indexName="dev_distribution"
        routing
      >
        <div className="mt-3">
          <SearchBox classNames={{
            form: "!w-full !bg-background !rounding-full !over",
            input: "!text-field !bg-component !text-white !border-0 !focus:outline-none",

          }} placeholder="Search for products" />
        </div>
        <div className="relative overflow-hidden md:flex mt-3">
          <div className="sidebar w-40 py-0 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out">
            <div className="text-sm text-white">
              <div className="underline text-lg mt-2 text-p6blue">
                Manufactures
              </div>
              <DynamicWidgets>
                <HierarchicalMenu
                  attributes={["FullManufacturerName"]}
                  showMore={true}
                />
              </DynamicWidgets>

              <div>
                <div className="underline text-lg mt-2 text-p6blue">Cost</div>
                <RefinementList attribute="RSRPricing" showMore={true} />
              </div>
              <div>
                <div className="underline text-lg mt-2 text-p6blue">MSRP</div>
                <RefinementList attribute="RetailPrice" showMore={true} />
              </div>
            </div>

            {/* <RangeInput attribute="RSRPricing" /> */}
          </div>
          <div className="flex-1 text-2xl bg-background sticky font-bold overflow-y-auto no-scrollbar">
            <Hits
              hitComponent={Hit}
              classNames={{
                root: "!bg-background",
                list: "!flex-auto",
                item: "!bg-background !w-full !p-0 !flex-auto",
              }}
            />
            <div className="w-full ">
              <Pagination
              />
            </div>
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}
