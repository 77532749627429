import { useState, useEffect, ChangeEvent } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { clientStorage } from "../../firebase/clientFirebaseApps";
import LeaveGroup from "./leave_group";
import { updateGroup } from "../../functions";
import { P6ClientError } from "../../utils/exeptions";
import { P6ApiResponse } from "../../../../src/types";

export default function GroupImage({
  groupId,
  cover,
  logo,
  name,
  admin,
  userId,
}: {
  groupId: string;
  cover: string;
  logo: string;
  name: string;
  admin: boolean;
  userId: string;
}) {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedCover, setSelectedCover] = useState<File>();
  const [, setPreview] = useState<string>(logo);
  const [, setPreviewCover] = useState(cover);

  const storage = clientStorage;

  useEffect(() => {
    if (!selectedFile) {
      setPreview(logo);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, logo]);

  useEffect(() => {
    if (!selectedCover) {
      setPreviewCover(cover);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedCover);
    setPreviewCover(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedCover, cover]);

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined);
        throw new P6ClientError("UIElementError", "No file was selected.");
      }

      const file = e.target.files[0];
      const uuid: string = self.crypto.randomUUID();
      const storageRef = ref(storage, `groupLogos/${uuid}.${file.name}`);
      if (!storageRef) {
        setSelectedFile(undefined);
        throw new P6ClientError("UIElementError", "Error with file selection.");
      }
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(
        ref(storage, `groupLogos/${uuid}.${file.name}`),
      );
      if (url) {
        const result = await updateGroup({ groupId: groupId, logo: url });
        const resultData = result.data as P6ApiResponse;
        if (!resultData.success) {
          throw new P6ClientError(
            "UIElementError",
            "Unable to update group logo.",
          );
        } else {
          setSelectedFile(file);
        }
      } else {
        setSelectedFile(undefined);
        throw new P6ClientError(
          "UIElementError",
          "Your profile images was not uploaded.",
        );
      }
    } catch (error) {
      throw new P6ClientError("UnknownError", error as string);
    }
  };
  // cover image
  const onSelectCover = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        setSelectedCover(undefined);
        throw new P6ClientError("UIElementError", "No file was selected.");
      }

      const file = e.target.files[0];
      const uuid: string = self.crypto.randomUUID();
      const storageRef = ref(storage, `groupCovers/${uuid}.${file.name}`);
      if (!storageRef) {
        setSelectedCover(undefined);
        throw new P6ClientError("UIElementError", "Error with file selection.");
      }
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(
        ref(storage, `groupCovers/${uuid}.${file.name}`),
      );
      if (url) {
        const result = await updateGroup({ groupId: groupId, cover: url });
        const resultData = result.data as P6ApiResponse;
        if (!resultData.success) {
          throw new P6ClientError(
            "UIElementError",
            "Unable to update group cover photo.",
          );
        } else {
          setSelectedCover(file);
        }
      } else {
        setSelectedCover(undefined);
        throw new P6ClientError(
          "UIElementError",
          "Your profile images was not uploaded.",
        );
      }
    } catch (error) {
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  return (
    <div className="relative mt-2.5 w-full bg-component">
      <div className="relative h-[175px] overflow-hidden">
        {cover && <img src={cover} alt="Group" className="cover" />}
        {logo && (
          <div className="absolute h-[70px] w-[70px]">
            <div className="absolute top-2.5 left-2.5 h-[60px] w-[60px] overflow-hidden rounded-full">
              <img className="cover absolute" src={logo} alt="logo" />
            </div>
            <input
              type="file"
              id="profile-select"
              data-cy="profile-select"
              onChange={onSelectFile}
              hidden
            />
            {admin && (
              <label htmlFor="profile-select">
                <img
                  src="/icons/camera_icon.svg"
                  alt="Edit"
                  className="absolute bottom-[0px] right-0"
                  width={20}
                  height={18}
                />
              </label>
            )}
          </div>
        )}
        {!admin && (
          <LeaveGroup admin={admin} userId={userId} groupId={groupId} />
        )}
        {admin && (
          <div className="relative">
            <input
              type="file"
              id="cover-select"
              data-cy="cover-select"
              onChange={onSelectCover}
              hidden
            />
            <div className="absolute right-2.5 top-2.5 bg-black p-2 rounded-full">
              <label htmlFor="cover-select">
                <img
                  src="/icons/camera_icon.svg"
                  alt="Edit"
                  className=""
                  width={20}
                  height={18}
                />
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="absolute bottom-0 flex h-[46px] w-full bg-black/80">
        <div className="ml-2.5 flex items-center truncate text-[22px] text-white">
          {name}
        </div>
      </div>
    </div>
  );
}
