import {
  linkWithPopup,
  unlink,
  GoogleAuthProvider,
  OAuthProvider,
  FacebookAuthProvider,
  User,
} from "firebase/auth";
import { clientAuth } from "../../firebase/clientFirebaseApps";
import { useState, useEffect } from "react";
import { Provider } from "../../../../src/types/provider";
import { P6ClientError } from "../../utils/exeptions";

export default function Cennect() {
  const [googleLink, setGoogleLink] = useState(false);
  const [facebookLink, setFacebookLink] = useState(false);
  const [appleLink, setAppleLink] = useState(false);
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const appleProvider = new OAuthProvider("apple.com");
  const auth = clientAuth;
  const links = auth?.currentUser?.providerData;

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    links?.map((provider: Provider) => {
      if (provider.providerId === "google.com") setGoogleLink(true);
      if (provider.providerId === "facebook.com") setFacebookLink(true);
      if (provider.providerId === "apple.com") setAppleLink(true);
    });
  }, [links]);

  // link google
  function linkGoogle() {
    if (googleLink) {
      unlink(auth.currentUser as User, "google.com")
        .then(() => {
          setGoogleLink(false);
        })
        .catch((error) => {
          throw new P6ClientError("UnknownError", error as string);
        });
    } else {
      if (auth && auth.currentUser) {
        linkWithPopup(auth.currentUser, googleProvider)
          .then(() => {
            setGoogleLink(true);
          })
          .catch((error) => {
            throw new P6ClientError("UnknownError", error as string);
          });
      }
    }
  }
  // link facebook
  function linkFacebook() {
    if (facebookLink) {
      unlink(auth.currentUser as User, "facebook.com")
        .then(() => {
          setFacebookLink(false);
        })
        .catch((error) => {
          throw new P6ClientError("UnknownError", error as string);
        });
    } else {
      linkWithPopup(auth.currentUser as User, facebookProvider)
        .then(() => {
          setFacebookLink(true);
        })
        .catch((error) => {
          throw new P6ClientError("UnknownError", error as string);
        });
    }
  }
  // link apple
  function linkApple() {
    if (appleLink) {
      unlink(auth.currentUser as User, "apple.com")
        .then(() => {
          setAppleLink(false);
        })
        .catch((error) => {
          throw new P6ClientError("UnknownError", error as string);
        });
    } else {
      linkWithPopup(auth.currentUser as User, appleProvider)
        .then(() => {
          setAppleLink(true);
        })
        .catch((error) => {
          throw new P6ClientError("UnknownError", error as string);
        });
    }
  }

  return (
    <div className="mt-14 flex flex-col items-center text-black">
      <div
        onClick={() => void linkGoogle()}
        className={`${
          googleLink ? "bg-green text-white" : "bg-white"
        } mb-[14px] flex w-[290px] cursor-pointer items-center rounded-full hover:bg-dark-grey hover:text-white`}
      >
        <div className="overflow-hidden rounded-full bg-white">
          <img
            src="/icons/google_icon.svg"
            alt="Google"
            className="m-[2px]"
            width={43}
            height={43}
          />
        </div>
        <div className="ml-2.5 text-[21px]">
          {googleLink ? "Unlink from Google" : "Connect with Google"}
        </div>
      </div>

      <div
        onClick={() => void linkFacebook()}
        className={`${
          facebookLink ? "bg-green text-white" : "bg-white"
        } mb-[14px] flex w-[290px] cursor-pointer items-center rounded-full hover:bg-dark-grey hover:text-white`}
      >
        <div className="overflow-hidden rounded-full bg-white">
          <img
            src="/icons/facebook_icon.svg"
            alt="Facebook"
            className="m-[4px]"
            width={41}
            height={41}
          />
        </div>
        <div className="ml-2.5 text-[21px]">
          {facebookLink ? "Unlink from Facebook" : "Connect with Facebook"}
        </div>
      </div>

      <div
        onClick={() => void linkApple()}
        className={`${
          appleLink ? "bg-green text-white" : "bg-white"
        } mb-[14px] flex w-[290px] cursor-pointer items-center rounded-full hover:bg-dark-grey hover:text-white`}
      >
        <div className="overflow-hidden rounded-full bg-white">
          <img
            src="/icons/apple_icon.svg"
            alt="Apple"
            className="my-[3px] ml-[6px] mr-[8px]"
            width={33}
            height={41}
          />
        </div>
        <div className="ml-2.5 text-[21px]">
          {appleLink ? "Unlink from Apple" : "Connect with Apple"}
        </div>
      </div>
    </div>
  );
}
