import { P6UserProfile } from "../../../../src/types/profile";
import DetailsEdit from "./details_edit";

export default function Details({ user }: { user?: P6UserProfile | null }) {
  const address = user?.address;

  return (
    <div className="relative mt-2.5 w-full bg-component text-white">
      <DetailsEdit />
      <div className="flex p-2.5">
        <div className="w-1/2 border-r-[1px] border-grey px-2.5 pt-6 pb-2.5">
          <p>{address?.address}</p>
          <p className={`${address?.address2 ? "" : "hidden"}`}>
            {address?.address2}
          </p>
          <p>
            {address?.city}, {address?.state} {address?.zip}
          </p>
        </div>
        <div className="w-1/2 px-2.5 pt-6 pb-2.5 text-right">
          <p>{user?.phoneNumber}</p>
          <p>{user?.notificationEmail}</p>
        </div>
      </div>
    </div>
  );
}
