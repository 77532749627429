import { useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import P6Button from "../../components/button";
import { P6ClientError } from "../../utils/exeptions";
import { updateUserProfile } from "../../functions";
import { P6ApiResponse } from "../../../../src/types";

export default function Notes({ note }: { note: string }) {
  const [changed, setChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      notes: note,
    },
  });

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
    setLoading(true);
    try {
      const result = await updateUserProfile(data);
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        setChanged(false);
        setLoading(false);
      } else {
        throw new P6ClientError("FunctionError", "Unable to update profile.");
      }
    } catch (error) {
      setLoading(false);
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  return (
    <div className="text-white relative">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <div className="relative ">
            <textarea
              id="notes"
              rows={4}
              className="peer block w-full appearance-none rounded-lg border-[1px] border-grey bg-component px-2.5 pb-1 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
              placeholder=" "
              {...register("notes", {
                onChange: () => {
                  setChanged(true);
                },
              })}
            ></textarea>
            <label
              htmlFor="notes"
              className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-5 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
            >
              Notes
            </label>
          </div>
          {errors.notes && (
            <p className="mt-[-10px] mb-2.5 pl-2.5 text-red">
              Description is Required
            </p>
          )}
        </div>
        {changed && (
          <div className="flex mt-2.5 justify-end">
            <div className="w-24 flex">
              <P6Button type="submit" sm text="Update" loading={loading} />
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
