import { Link, useLocation } from "react-router-dom";

export default function LayoutHeader() {
  const { pathname } = useLocation();

  return (
    <div className="sticky top-0 z-50 h-[55px] w-auto border-b border-border bg-component">
      <div className="flex items-center">
        <div className="flex h-14 w-14 items-center justify-center">
          <Link to="/profile">
            <img
              src={
                pathname === "/profile"
                  ? "/icons/profile_on.svg"
                  : "/icons/profile.svg"
              }
              alt="Profile"
              className="m-auto"
              width={32}
              height={32}
            />
          </Link>
        </div>
        <div className="flex grow items-center justify-center">
          <div className="flex h-8 w-full items-center justify-center border-b-2">
            <Link to="/search" className="flex">
              <img
                src="/icons/magnify_glass.svg"
                alt="Search"
                className="mr-2 flex"
                width={17.49}
                height={17.49}
              />
              <p className="text-white">Search</p>
            </Link>
          </div>
        </div>
        <div className="flex h-14 w-14 items-center justify-center">
          <img
            src="/icons/p6_small_logo.svg"
            alt="Platform Sixx"
            className="m-auto"
            width={34}
            height={44}
          />
        </div>
      </div>
    </div>
  );
}
