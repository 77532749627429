import { useEffect, useState } from "react";
import { clientAuth } from "../../firebase/clientFirebaseApps";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import * as Sentry from "@sentry/react";
import MenuTop from "./menuTop";
import MenuSide from "./menuSide";
import {
  onSnapshot,
  collection,
  query,
  limit,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";

export default function AdminLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [siteAdmin, setSiteAdmin] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [adminUser, setAdminUser] = useState<
    { email: string | null; photoURL: string | null } | undefined
  >();
  const auth = clientAuth;
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        setAdminUser(user);
        Sentry.setUser({ id: user?.uid });
      } else {
        navigate("/login");
      }
    });
  }, [auth, navigate]);

  useEffect(() => {
    if (userId) {
      const siteAdminsRef = collection(db, "siteAdmins");
      const q = query(siteAdminsRef, where("userId", "==", userId), limit(1));
      const unsubscribeAdmin = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.size === 0) {
          navigate("/feed");
        }
        querySnapshot.docs.forEach((doc) => {
          if (!doc) {
            navigate("/feed");
          }
          if (doc.data().userId !== userId) {
            navigate("/feed");
          } else {
            setSiteAdmin(true);
          }
        });
        return () => unsubscribeAdmin();
      });
    }
  }, [navigate, userId]);

  if (!siteAdmin || !adminUser) {
    return null;
  }

  return (
    <>
      <div>
        <MenuSide sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="lg:pl-72">
          <MenuTop setSidebarOpen={setSidebarOpen} user={adminUser} />
          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
