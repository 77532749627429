import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { P6Event } from "../../../../src/types/event";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/clientFirebaseApps";
import { P6Timestamp } from "../../../../src/types/helpers";

export default function Templates({
  groupId,
  setTemplateData,
}: {
  groupId: string;
  setTemplateData: Dispatch<SetStateAction<P6Event | undefined>>;
}) {
  const [list, setList] = useState<P6Event[]>([]);
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [lastDate, setLastDate] = useState<P6Timestamp>({
    nanoseconds: 0,
    seconds: -62135596800,
  });

  useEffect(() => {
    const handleScroll = () => {
      // const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - 300;
      if (groupId) {
        const q = query(
          collection(db, "events"),
          where("groupId", "==", groupId),
          where("options.isTemplate", "==", true),
          orderBy("createdAt", "desc"),
          startAfter(lastDate),
          limit(30),
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const ar: Array<P6Event> = [];
          querySnapshot.docs.forEach((doc) => {
            ar.push({ id: doc.id, ...doc.data() } as P6Event);
          });
          if (ar.length > 0) {
            setLastDate(ar[ar.length - 1].createdAt);
            setList(list.concat(ar));
          }
        });
        setFirstTime(false);
        return () => unsubscribe();
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    if (firstTime) {
      handleScroll();
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [groupId, list, lastDate, firstTime]);

  return (
    <div className="text-white h-full">
      <div className="text-xl text-center">Templates</div>
      <div className="text-center text-sm text-white text-opacity-50">
        Select template and create event.
      </div>
      {list.map((doc: P6Event) => (
        <div key={doc.id} className="mt-4">
          <div
            className="bg-shaddow rounded-lg mb-2.5 overflow-hidden flex"
            onClick={() => setTemplateData(doc)}
          >
            <div className=" flex-shrink-0 mr-2.5">
              <img
                src={doc && doc.images && doc.images[0]}
                alt=""
                className="w-[60px] h-[60px] object-cover"
              />
            </div>
            <div className="flex-auto">
              <div>{doc.title}</div>
              <div className="text-xs line-clamp-2 text-white text-opacity-50">
                {doc.description}
              </div>
            </div>
          </div>
        </div>
      ))}
      {list.length === 0 && (
        <div className="text-center text-white text-opacity-50 mt-5 px-5">
          <div className="border rounded-lg py-2.5">
            <div className="text-lg underline">No templates yet</div>
            <div className="px-8 text-sm">
              Start by creating an event and selecting the Save as Template
              option.
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
