import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

export default function Users() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) =>
    console.log(data);
  return (
    <div className="text-white">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2.5">
          <div className="relative mb-2.5">
            <input
              type="text"
              id="userId"
              autoComplete="off"
              className="text-field peer"
              placeholder=" "
              {...register("userId", {
                required: true,
              })}
            />
            <label htmlFor="userId" className="form-label">
              User ID
            </label>
          </div>
          {errors.userId && <p className="form-error"> is Required</p>}
        </div>
      </form>
    </div>
  );
}
