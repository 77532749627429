import { Fragment } from "react";
import { Link } from "react-router-dom";

type params = {
  loading?: boolean;
  color?: string;
  text: string;
  textColor?: string;
  link?: string;
  div?: boolean;
  onClick?: (() => void) | (() => Promise<void>) | undefined;
  justify?: string;
  iconTop?: string;
  sm?: boolean;
  icon?: string;
  className?: string;
  square?: boolean;
  inlineIcon?: string;
  disabled?: boolean;
  iconLeft?: boolean;
  dataCy?: string;
  type?: "button" | "submit" | "reset";
};

export default function P6Button(params: params) {
  const {
    loading,
    color,
    text,
    textColor,
    link,
    div,
    onClick,
    justify,
    sm,
    icon,
    iconTop,
    className,
    square,
    inlineIcon,
    type,
    iconLeft,
  } = params;

  return (
    <Fragment>
      {!link && !div && (
        <button
          onClick={onClick ? onClick : undefined}
          type={type || "button"}
          className={`relative flex flex-auto justify-${
            justify ? justify : "center"
          } cursor-pointer rounded-full items-center ${
            color ? color : "bg-p6blue"
          } text-center text-[${
            sm ? "16px" : "22px"
          }] font-light hover:bg-dark-grey px-3  ${className as string}`}
          disabled={loading}
          data-cy={params.dataCy}
        >
          {loading && (
            <img
              width={sm ? 20 : 20}
              height={sm ? 20 : 20}
              src="/icons/spinner_icon.svg"
              alt="Event"
              className={`absolute left-[4px] animate-spin`}
            />
          )}
          {icon && (
            <img
              // width={iconSize? iconSize : 30}
              width={sm ? 12 : 30}
              height={sm ? 12 : 30}
              src={`/icons/${icon}.svg`}
              alt="Event"
              className={`absolute ${
                iconLeft ? "left-[10px]" : "right-[10px]"
              }`}
            />
          )}
          <span className={textColor}>{text}</span>
        </button>
      )}
      {link && !div && (
        <Link to={link}>
          <div
            data-cy={params.dataCy}
            className={`relative flex flex-auto justify-${
              justify ? justify : "center"
            } cursor-pointer rounded-full ${
              color ? color : "bg-p6blue"
            } text-center text-[${
              sm ? "16px" : "22px"
            }] font-light hover:bg-dark-grey  ${className as string}`}
          >
            <span className={textColor}>{text}</span>
          </div>
        </Link>
      )}
      {div && (
        <div
          data-cy={params.dataCy}
          onClick={onClick ? onClick : undefined}
          className={`flex flex-auto justify-${
            justify ? justify : "center"
          } relative cursor-pointer ${square ? "" : "rounded-full"} ${
            color ? color : "bg-p6blue"
          } text-center text-[${
            sm ? "16px" : "22px"
          }] font-light hover:bg-dark-grey px-4 py-[1px] items-center ${
            className as string
          }`}
        >
          {loading && (
            <img
              // width={iconSize? iconSize : 30}
              width={sm ? 16 : 30}
              height={sm ? 16 : 30}
              src="/icons/spinner_icon.svg"
              alt="Event"
              className={`absolute left-[4px] animate-spin`}
            />
          )}
          {icon && (
            <img
              // width={iconSize? iconSize : 30}
              width={sm ? 12 : 30}
              height={sm ? 12 : 30}
              src={`/icons/${icon}.svg`}
              alt="Event"
              className={`absolute right-[10px] top-[${iconTop || "0"}px]`}
            />
          )}
          <span className={`${textColor as string} flex whitespace-nowrap`}>
            {inlineIcon && (
              <img
                // width={iconSize? iconSize : 30}
                width={sm ? 20 : 30}
                height={sm ? 20 : 30}
                src={`/icons/${inlineIcon}.svg`}
                alt="Event"
                className={`mr-2 ${
                  inlineIcon === "spinner_icon" ? "animate-spin" : ""
                }`}
              />
            )}
            {text}
          </span>
        </div>
      )}
    </Fragment>
  );
}
