import { Link } from "react-router-dom";
import { EventIndex } from "../../../../src/types/algolia/indexes";
import { P6Group } from "../../../../src/types/group";
import { shortDate } from "../../utils/dateFormat";

export default function ItemWebinar({
  noBorder,
  event,
  group,
}: {
  noBorder?: boolean;
  event: EventIndex;
  group: P6Group;
}) {
  const profilePic = "/images/test/profile.jpg";
  const postImage = "/images/test/post_image.jpg";

  return (
    <div className={`text-white ${noBorder ? "" : "border-t-[1px]"}`}>
      <div className="flex items-center px-2.5 py-3">
        <Link to={`/group/${group.groupId}/active`}>
          <div>
            <img
              src={group.logo ? group.logo : profilePic}
              alt="group"
              className="rounded-full"
              width={35}
              height={35}
            />
          </div>
        </Link>
        <div className="flex flex-auto flex-col px-2.5">
          <Link to={`/event/${event.objectID}`}>
            <div className="text-xs text-grey">
              Event - {event.createdAt && shortDate(event.createdAt)}
            </div>
            <div className="text-[15px] leading-4">{event.title}</div>
          </Link>
        </div>
        <div className="h-[35px] w-[35px]">
          <Link to={`/event/${event.objectID}`}>
            <img
              src={
                event.images && event.images.length > 0 && event.images[0]
                  ? event.images[0]
                  : postImage
              }
              alt="event"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
