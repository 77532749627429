import { Fragment, useRef, useState, useEffect, ChangeEvent } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FieldValues, useForm, SubmitHandler } from "react-hook-form";
import { clientStorage } from "../../firebase/clientFirebaseApps";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useUser } from "../../layouts/page_layout/layout";
import { type P6UserProfile } from "../../../../src/types/profile";
import { P6ClientError } from "../../utils/exeptions";
import { updateUserProfile } from "../../functions";
import { P6ApiResponse } from "../../../../src/types";

export default function BasicInfoEdit() {
  const { user, setUser } = useUser();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const cancelButtonRef = useRef(null);
  const [preview, setPreview] = useState("/images/blank_profile.svg");
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const storage = clientStorage;
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();

  // TODO: update user profile is not working for the user

  useEffect(() => {
    // reset form with user data
    reset({
      displayName: user?.displayName,
      fullName: user?.fullName,
      profileImageUrl: user?.profileImageUrl,
    });
  }, [reset, user]);

  useEffect(() => {
    if (user) {
      if (!selectedFile) {
        if (user?.profileImageUrl) {
          setPreview(user.profileImageUrl);
        } else {
          setPreview("/images/blank_profile.svg");
          setUser({ ...user, profileImageUrl: "/images/blank_profile.svg" });
        }
        return;
      }

      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile, user, setUser]);

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        throw new P6ClientError("UIElementError", "No file was selected.");
      }

      const file = e.target.files[0];
      const uuid: string = self.crypto.randomUUID();
      const storageRef = ref(storage, `profileImages/${uuid}.${file.name}`);
      if (!storageRef) {
        throw new P6ClientError("UIElementError", "Error with file selection.");
      }
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(
        ref(storage, `profileImages/${uuid}.${file.name}`),
      );
      if (url) {
        setPreview(url);
      } else {
        throw new P6ClientError(
          "UIElementError",
          "Your profile images was not uploaded.",
        );
      }
    } catch (error) {
      setSelectedFile(undefined);
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setLoading(true);
    try {
      data.profileImageUrl = preview;
      setUser({ ...user, ...data } as P6UserProfile);
      const result = await updateUserProfile(data);
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        setLoading(false);
        setOpen(false);
      } else {
        throw new P6ClientError("FunctionError", "Unable to update profile.");
      }
    } catch (error) {
      setLoading(false);
      throw new P6ClientError("UnknownError", error as string);
    }
  };

  return (
    <div>
      <div
        onClick={() => setOpen(true)}
        className="absolute top-1 right-2.5 cursor-pointer text-p6blue"
      >
        edit
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-2.5 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative max-w-screen-xs transform overflow-hidden rounded-lg bg-component text-left shadow-xl transition-all sm:my-2.5">
                  <div className="min-w-[300px] text-white">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="p-5">
                        <div className="text-center text-[30px] font-light text-white">
                          Edit Your Profile
                        </div>
                        <div className="font-ligh text-center text-[22px] text-grey">
                          Personal information
                        </div>
                        <div className="my-8 flex justify-center">
                          <div className="relative">
                            <input
                              data-cy="hidden-input"
                              type="file"
                              id="profile-select"
                              onChange={onSelectFile}
                              hidden
                            />
                            <div className="relative h-[76px] w-[76px] flex-none overflow-hidden rounded-full">
                              <img
                                src={preview}
                                alt="Event"
                                className="cover"
                              />
                            </div>
                            <label htmlFor="profile-select">
                              <img
                                src="/icons/camera_icon.svg"
                                alt="Edit"
                                className="absolute bottom-[0px] right-0"
                                width={20}
                                height={18}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="mb-2.5">
                          <div className="relative mb-2.5">
                            <input
                              type="text"
                              id="displayName"
                              autoComplete="username"
                              className="text-field peer"
                              placeholder=" "
                              {...register("displayName", {
                                required: true,
                              })}
                            />
                            <label htmlFor="displayName" className="form-label">
                              Display Name
                            </label>
                          </div>
                          {errors.displayName && (
                            <p className="form-error">
                              Display Name is Required
                            </p>
                          )}
                        </div>
                        <div className="mb-2.5">
                          <div className="relative mb-2.5">
                            <input
                              type="text"
                              id="fullName"
                              autoComplete="name"
                              className="text-field peer"
                              placeholder=" "
                              {...register("fullName", {
                                required: true,
                              })}
                            />
                            <label htmlFor="fullName" className="form-label">
                              Full Name
                            </label>
                          </div>
                          {errors.fullName && (
                            <p className="form-error">Full Name is Required</p>
                          )}
                        </div>
                        <button
                          className="relative w-full cursor-pointer rounded-full bg-green text-center text-[25px] font-light hover:bg-dark-grey"
                          disabled={loading}
                        >
                          {loading && (
                            <img
                              width={28}
                              height={28}
                              src="/icons/spinner_icon.svg"
                              alt="Event"
                              className="absolute left-[4px] top-[4px] animate-spin"
                            />
                          )}
                          Update
                        </button>
                        <div
                          onClick={() => setOpen(false)}
                          className="relative mt-3 flex-auto rounded-full bg-white pl-1.5 text-center text-[25px] font-light text-black hover:bg-dark-grey"
                        >
                          Cancel
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
