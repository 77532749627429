import AddMembers from "./add_members_modal";

export default function MemberTools({
  groupId,
  count,
  groupCode,
}: {
  groupId: string;
  count: number;
  groupCode: string;
}) {
  return (
    <div className="flex items-center">
      <div className="flex-auto">
        <AddMembers groupId={groupId} groupCode={groupCode} />
      </div>
      <div className="bg-p6blue rounded-full px-2.5 self-end mr-2.5">
        {count}
      </div>
    </div>
  );
}
