import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useState, Dispatch, SetStateAction, useEffect } from "react";
import { createPost } from "../../../functions";
import { P6ClientError } from "../../../utils/exeptions";
import P6Button from "../../../components/button";
import { useDropzone } from "react-dropzone";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { clientStorage, db } from "../../../firebase/clientFirebaseApps";
import { useUser } from "../../../layouts/page_layout/layout";
import { P6Group } from "../../../../../src/types/group";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import GroupSelect from "../../../components/form_fields/select_group";
import { P6CreatePostRequest } from "../../../../../src/types/post";

interface PreviewFile extends File {
  preview: string;
  downloadUrl: string;
}

export default function PostForm({
  setOptionsOpen,
}: {
  setOptionsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { userId } = useUser();
  const [groups, setGroups] = useState<P6Group[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<PreviewFile[]>([]);
  const [imageError, setImageError] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState(false);
  const {
    register,
    setError,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<FieldValues>();
  const downloadUrls: Array<string> = files?.map(
    (item: { downloadUrl: string }) => {
      return item.downloadUrl;
    }
  );
  const storage = clientStorage;
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles: File[]) => {
      setImageLoading(true);
      acceptedFiles.map(async (file: File) => {
        const uuid: string = self.crypto.randomUUID();
        setImageError(false);
        const storageRef = ref(storage, `eventImages/${uuid}.${file.name}`);

        await uploadBytes(storageRef, file).then(() => {
          getDownloadURL(ref(storage, `eventImages/${uuid}.${file.name}`))
            .then((url) => {
              if (!url) {
                throw new P6ClientError(
                  "UIElementError",
                  "Unable to get download url for image"
                );
              }
              const preview = {
                preview: URL.createObjectURL(file),
                downloadUrl: url,
              } as PreviewFile;
              setFiles((files) => [...files, preview]);
              setImageLoading(false);
            })
            .catch((error) => {
              setImageLoading(false);
              throw new P6ClientError("UnknownError", error as string);
            });
        });
      });
    },
  });

  useEffect(() => {
    if (userId) {
      const postsRef = collection(db, "groups");
      const postsQuery = query(
        postsRef,
        where('adminId', '==', userId)
      );

      const unsubscribe = onSnapshot(postsQuery, (snapshot) => {
        const newGroups = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        } as P6Group));
        setGroups(newGroups);
      }, (error) => {
        throw new P6ClientError("FunctionError", "Unable to fetch groups.", error);
      });
      return () => {
        unsubscribe();
      };
    }
  }, [userId])

  const handleRemoveItem = (file: PreviewFile) => {
    setFiles(
      files.filter(
        (files: PreviewFile) => files.downloadUrl !== file.downloadUrl
      )
    );
  };

  const thumbs = files.map((file: PreviewFile) => (
    <div
      className=" mb-[8px] mr-[8px] inline-flex h-[55px] w-[55px] overflow-hidden rounded-lg "
      key={file.downloadUrl}
    >
      <div className="relative flex flex-auto">
        <span
          className="absolute top-[0px] right-[0px] z-50 flex h-[16px] w-[16px] cursor-pointer items-center justify-center rounded-full bg-red text-white"
          //name={file.name}
          onClick={() => handleRemoveItem(file)}
        >
          <img
            src="/icons/delete_icon.svg"
            alt="Delete"
            className=""
            width={7}
            height={7}
          />
        </span>

        <img src={file.preview} alt="Group" className="cover" />
      </div>
    </div>
  ));

  const onSubmit: SubmitHandler<FieldValues> = async (params) => {
    if (!params.group) {
      setError("group", { type: "custom", message: "custom message" });
    } else {
      try {
        const group = groups.find(x => x.id === params.group) as P6Group
        setLoading(true);
        const request: P6CreatePostRequest = {
          groupId: params.group as string,
          description: params.description as string,
          title: params.title as string,
          images: downloadUrls,
          state: "active",
          groupLogo: group.logo,
          groupName: group.name,
        };

        await createPost(request);
        setLoading(false);
        setOptionsOpen(false);
      } catch (error) {
        throw new P6ClientError("UnknownError", error as string);
      }
    }
  };

  const onError = () => {
    if (!getValues("group")) {
      setError("group", { type: "custom", message: "custom message" });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="p-2">
          <div className="text-center text-xl mb-2">Post Details</div>
          <div className="mb-8 flex">
            {/* <div>
              <div className="text-lg font-bold">{user && user.fullName} What is this!</div>
            </div> */}
          </div>
          <div className="flex flex-col">
            <div className="mb-2.5">
              <div className="relative mb-2.5">
                <input
                  type="text"
                  id="title"
                  autoComplete="off"
                  className="text-field peer"
                  placeholder=" "
                  {...register("title", {
                    required: true,
                  })}
                />
                <label htmlFor="title" className="form-label">
                  Title
                </label>
              </div>
              {errors.title && <p className="form-error">Title is Required</p>}
            </div>
            <div className="mb-2.5">
              <div className="relative mb-2.5">
                <textarea
                  id="description"
                  rows={4}
                  className="peer block w-full appearance-none rounded-lg border-[1px] border-grey bg-component px-2.5 pb-1 pt-2 pl-4 text-sm text-grey focus:border-green focus:outline-none focus:ring-0 dark:border-grey dark:text-white dark:focus:border-green"
                  placeholder=" "
                  {...register("description", {
                    required: true,
                  })}
                ></textarea>
                <label
                  htmlFor="description"
                  className="z-9 absolute top-0 left-2 origin-[50] -translate-y-3 scale-75 transform rounded-full bg-component px-2 text-base text-gray-500 duration-300 peer-placeholder-shown:top-5 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-green peer-aria-expanded:bg-red"
                >
                  Description
                </label>
              </div>
              {errors.description && (
                <p className="form-error">Description is Required</p>
              )}
            </div>
            <div className="relative z-10" data-cy="group-select">
              <GroupSelect
                groups={groups.map((x) => ({ label: x.name, value: x.groupId }))}
                errors={errors}
                setValue={setValue}
                clearErrors={clearErrors}
                defaultValue={getValues("group") as string}
              />
            </div>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps({ id: "event-drop-zone" })} />
              <p className="mt-5 border-[1px] border-dashed p-5 text-center text-white cursor-pointer">
                Drag files here, or click to select files
              </p>
            </div>
            {imageError && (
              <p className="mt-[0px] ml-10 mb-2.5 text-red">1 Image is Required</p>
            )}

            <aside className="mt-[16px] flex flex-row flex-wrap">{thumbs}</aside>
          </div>
          <div className="flex flex-grow items-center justify-center text-center p-2.5">
            <div className="flex w-full gap-x-3">
              <P6Button
                sm
                text="Cancel"
                type="button"
                color={"bg-dark-grey"}
                onClick={() => setOptionsOpen(false)}
              />
              <P6Button
                sm
                text="Create"
                type="submit"
                color={"bg-p6blue"}
                loading={loading || imageLoading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
