import { useState, useEffect, useRef } from "react";
import Seat from "./seat";
import { seatLoad } from "./seatService";
import { type P6Seat } from "../../../../src/types/event";
import P6Button from "../../components/button";
import { purchaseSeat } from "../../functions";
import { type P6ApiResponse } from "../../../../src/types";
import { useUser } from "../../layouts/page_layout/layout";
import { P6ClientError } from "../../utils/exeptions";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { generateRandomNumbers } from "../../utils/randomNumberArray";

export default function SeatList({
  cost,
  eventId,
  totalSeats,
  available,
  limited,
  random,
  flashSeats,
  wheelSpun,
  groupId,
  seatsFlashed,
  admin,
  distribution,
  noGroupCredits,
}: {
  cost: number;
  eventId: string;
  totalSeats: number;
  available: number;
  limited: boolean;
  random: number[];
  flashSeats: boolean;
  wheelSpun: boolean;
  groupId: string;
  seatsFlashed: boolean | undefined;
  admin: boolean;
  distribution: boolean;
  noGroupCredits: boolean;
}) {
  const [seats, setSeats] = useState<P6Seat[]>([]);
  const [flash, setFlash] = useState<number[]>([]);
  const [lastKey, setLastKey] = useState<number>(0);
  const [buyBlock, setBuyBlock] = useState<boolean>(false);
  const [nextSeatsLoading, setNextSeatsLoading] = useState(false);
  const { user, setPaymentOpen } = useUser();
  const [alert, setAlert] = useState<boolean>(false);
  const [alreadyPurchasedSeat, setAlreadyPurchasedSeat] =
    useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(0);
  const [loadingFor, setLoadingFor] = useState<string>("");
  const [limitAlert, setLimitAlert] = useState<boolean>(false);
  const winnerTimeout = useRef<
    string | number | undefined | ReturnType<typeof setTimeout>
  >();
  const flashTimeout = useRef<
    string | number | undefined | ReturnType<typeof setTimeout>
  >();

  // seat flasher
  let time = 0;
  const diff = 50;
  const minTime = 0;
  const maxTime = 10000;

  function easeInOutExpo(t: number, b: number, c: number, d: number) {
    t /= d;
    return c * t * t * t + b;
  }

  // This function will run the flashing seats while wheel is spinning
  // TODO: figure out a better way of doing seat flashes.
  useEffect(() => {
    function flash() {
      for (let i = 1, len = diff; i <= len; i++) {
        const currentTime = time; // Capture the current value of time
        const flashFunc = () => {
          flashTimeout.current = setTimeout(() => {
            setFlash(generateRandomNumbers(1, totalSeats, totalSeats / 3));
          }, currentTime);
        };
        flashFunc();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        time = easeInOutExpo(i, minTime, maxTime, diff);
      }
      winnerTimeout.current = setTimeout(() => {
        setFlash(random);
        if (totalSeats > 50) {
          // setWheelSpun(true);
        }
      }, 10100);
    }
    if (flashSeats === true && !seatsFlashed && random.length) {
      flash();
    }

    if (wheelSpun) {
      setFlash(random);
    }
    return () => {
      setFlash([]);
      clearTimeout(winnerTimeout.current);
      clearTimeout(flashTimeout.current);
    };
  }, [flashSeats, wheelSpun, random]);

  const purchaseAlert = (quantity: number) => {
    setAlert(true);
    setQuantity(quantity);
    setTimeout(() => {
      setAlert(false);
    }, 3000);
    setTimeout(() => {
      setQuantity(0);
    }, 4000);
  };

  // Auto buy section
  // TODO - need to move this to its own file
  const autoBuy = async (quantity: number, type: string) => {
    setLoadingFor(type);
    try {
      let groupCredits: number =
        user.groupCredits && user.groupCredits[groupId]
          ? user.groupCredits[groupId]
          : 0;
      const globalCredits = user.credits && user.credits ? user.credits : 0;
      if (distribution || noGroupCredits) {
        groupCredits = 0;
      }
      if (globalCredits + groupCredits < cost * quantity) {
        setPaymentOpen(true);
        throw new P6ClientError(
          "PaymentError",
          "You do not have enugh credits to make this purchase.",
        );
      }
      if (available < quantity) {
        throw new P6ClientError(
          "InvalidArgument",
          "Not enough seats available.",
        );
      }
      if (quantity > 100) {
        throw new P6ClientError(
          "InvalidArgument",
          "You can only buy 100 seats at a time.",
        );
      }
      if (limited && quantity > 1) {
        throw new P6ClientError(
          "InvalidArgument",
          "You can only purchase 1 seat.",
        );
      }
      if (!eventId || !quantity) {
        throw new P6ClientError(
          "InvalidArgument",
          "Event id, seat id, and quantity are required to purchase seat.",
        );
      }
      const purchaseInfo: {
        eventId: string;
        seatId: string;
        quantity: number;
        distribution: boolean;
        noGroupCredits: boolean;
      } = {
        eventId: eventId,
        seatId: "random",
        quantity: quantity,
        distribution: distribution,
        noGroupCredits: noGroupCredits,
      };

      const result = await purchaseSeat(purchaseInfo);
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        setLoadingFor("");
        purchaseAlert(quantity);
      } else {
        throw new P6ClientError("FunctionError", "Unable to purchase seat.");
      }
    } catch (error) {
      setLoadingFor("");
      const result = new P6ClientError("UnknownError", error as string);
      return result;
    }
  };

  // Set up the lazy load for the seats
  useEffect(() => {
    // first 5 posts
    seatLoad
      .seatsFirstBatch(eventId)
      .then((res) => {
        if (res) {
          setSeats(res.seats);
          setLastKey(res.lastKey);
        }
      })
      .catch((error) => {
        throw new P6ClientError("UnknownError", error as string);
      });
  }, [eventId]);

  useEffect(() => {
    if (limitAlert) {
      setTimeout(() => {
        setLimitAlert(false);
      }, 5000);
    }
  }, [limitAlert]);

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight - 200;
      if (bottom) {
        if (!nextSeatsLoading) {
          if (lastKey > 0) {
            setNextSeatsLoading(true);
            seatLoad
              .seatsNextBatch(lastKey, eventId)
              .then((res) => {
                if (res) {
                  setLastKey(res.lastKey);
                  setSeats(seats.concat(res.seats));
                  setNextSeatsLoading(false);
                }
              })
              .catch((error) => {
                setNextSeatsLoading(false);
                throw new P6ClientError("UnknownError", error as string);
              });
          }
        }
      }
    };
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [eventId, lastKey, nextSeatsLoading, seats]);

  useEffect(() => {
    if (seats.length > 0 && limited && user.userId) {
      seats
        .filter((x) => x.boughtBy)
        .map((x) => {
          if (x.boughtBy === user.userId) {
            setAlreadyPurchasedSeat(true);
          }
          return true;
        });
    }
  }, [seats, limited, user]);

  let buyBlockOptions: number[] = [];
  if (totalSeats <= 10) {
    buyBlockOptions = [2, 4, 6, 8];
  } else if (totalSeats <= 25) {
    buyBlockOptions = [5, 10, 15, 20];
  } else if (totalSeats <= 100) {
    buyBlockOptions = [5, 10, 20, 50];
  } else if (totalSeats <= 500) {
    buyBlockOptions = [10, 20, 50, 100];
  } else if (totalSeats > 500) {
    buyBlockOptions = [20, 50, 75, 100];
  }

  function buyBlockButton(position: number) {
    const amount = buyBlockOptions[position];
    const over = limited || available < amount;

    return (
      <button
        data-cy={`buy-block-${position + 1}`}
        disabled={over}
        key={position}
        onClick={() => autoBuy(amount, "")}
        className={`m-auto w-[75px] text-black text-center text-sm rounded-lg overflow-hidden shadow-md transition-all cursor-pointer ${
          over ? "bg-grey" : "hover:scale-125 bg-white"
        }`}
      >
        <p
          className={`${
            over ? "bg-dark-grey text-grey" : "bg-green text-white"
          }`}
        >
          {cost * amount} ₢
        </p>
        <p className={`text-lg ${over ? " text-dark-grey" : ""}`}>{amount}</p>
      </button>
    );
  }

  if (!seats) {
    return null;
  }

  return (
    <div key={eventId}>
      {limitAlert && (
        <div className="rounded-md bg-yellow-200 border-2 border-yellow-600 p-4 fixed right-2.5 left-2.5 inset-x-0 top-[120px] z-50 bg-opacity-80">
          <div className="flex">
            <div className="flex-shrink-0 item">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-800"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-lg font-medium text-yellow-900">
                This event is limited
              </h3>
              <div className="mt-2 text-sm text-yellow-800">
                <p>On limited events you can only purchase 1 seat.</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {available > 0 && (
        <div className="relative col-span-2 text-white mb-5 bg-component mt-2.5 overflow-hidden">
          <div
            className={`flex absolute py-2.5 -top-10 duration-1000 transition ${
              alert ? "translate-y-10" : ""
            } right-2.5 left-2.5 items-center bg-p6blue text-white text-sm font-bold px-4 py-3" role="alert`}
          >
            <img
              // width={iconSize? iconSize : 30}
              width={20}
              height={20}
              src={`/icons/gratz_icon.svg`}
              alt="Event"
              className={`mr-2`}
            />
            <p>You just bought {quantity} seats!</p>
          </div>
          <div className="pt-2.5 text-center">Buying Tools</div>
          <div className="text-xs text-center text-grey">
            Buy 1 random, multiple random, or{" "}
            {available > 100 ? "100 at a time." : "all available seats."}
          </div>
          <div className="flex space-x-2.5 pb-2.5 px-2.5 mt-2.5">
            <P6Button
              inlineIcon={
                loadingFor === "random" ? "spinner_icon" : "buyrandom_icon"
              }
              square
              onClick={
                loadingFor === "random"
                  ? () => null
                  : () => autoBuy(1, "random")
              }
              sm
              div
              text="Random"
            />
            <P6Button
              inlineIcon="buyblock_icon"
              square
              onClick={() => setBuyBlock(!buyBlock)}
              sm
              div
              text="Block"
              iconTop="8"
              className={buyBlock ? "bg-dark-grey" : ""}
            />
            <P6Button
              inlineIcon={
                loadingFor === "allseats" ? "spinner_icon" : "buyall_icon"
              }
              square
              onClick={
                loadingFor === "allseats"
                  ? () => null
                  : () => autoBuy(available > 100 ? 100 : available, "allseats")
              }
              sm
              div
              text={`${available > 100 ? "100" : "All Open"}`}
            />
          </div>

          {buyBlock && (
            <div className="grid grid-cols-4 bg-shaddow p-2.5">
              {Array.from(Array(4), (_, i) => buyBlockButton(i))}
            </div>
          )}
        </div>
      )}
      {distribution && (
        <div className="p-2 bg-component text-white border-yellow-500 border rounded-lg mb-4 text-center">
          Distribution events can not be purchased with Group Credits.
        </div>
      )}
      {noGroupCredits && (
        <div className="p-2 bg-component text-white border-yellow-500 border rounded-lg mb-4 text-center">
          This event does not allow group credits.
        </div>
      )}
      <div key={eventId} className="grid grid-cols-2 gap-2.5 px-2.5 pb-2.5">
        {seats.map((seat) => (
          <Seat
            flash={flash}
            key={seat.id}
            seatId={seat.id}
            cost={cost}
            eventId={eventId}
            limited={limited}
            groupId={groupId}
            alreadyPurchasedSeat={alreadyPurchasedSeat}
            setLimitAlert={setLimitAlert}
            admin={admin}
            distribution={distribution}
            noGroupCredits={noGroupCredits}
          />
        ))}
        {nextSeatsLoading && (
          <div className="col-span-2 mx-auto">
            <img
              width={40}
              height={40}
              src="/icons/spinner_icon.svg"
              alt="Event"
              className={`animate-spin`}
            />
          </div>
        )}
      </div>
    </div>
  );
}
