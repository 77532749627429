import { Link, useLocation, useParams } from "react-router-dom";
import MenuSlideout from "../../components/menu_slideout/menu_slideout";
import { useEffect, useState, Dispatch, type SetStateAction } from "react";
import { db } from "../../firebase/clientFirebaseApps";
import { doc, onSnapshot } from "firebase/firestore";
import { P6UserProfile } from "../../../../src/types/profile";

export default function LayoutSubHeader({
  creditAmount,
  user,
}: {
  paymentOpen: boolean;
  setPaymentOpen: Dispatch<SetStateAction<boolean>>;
  creditAmount?: number;
  displayName: string;
  profileImageUrl: string;
  user: P6UserProfile;
}) {
  const { pathname } = useLocation();
  const { eventId, groupId } = useParams();
  const [, setCredits] = useState<number>(0);
  const [group, setGroup] = useState<string>("");
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    let groupCredits = 0;
    if (group) {
      if (user.groupCredits) {
        groupCredits = user.groupCredits[group];
      }
      setCredits(user.credits + (groupCredits ? groupCredits : 0));
    } else {
      setCredits(user.credits);
    }
  }, [groupId, group, user.credits, pathname, user.groupCredits]);

  useEffect(() => {
    if (eventId) {
      const unsub = onSnapshot(doc(db, "events", eventId), (doc) => {
        setGroup(doc?.data()?.groupId as string);
      });
      return () => unsub();
    }
    if (groupId) {
      setGroup(groupId);
    }
    if (!eventId && !groupId) {
      setGroup("");
    }
  }, [eventId, groupId]);

  const active = (path: string) => {
    if (pathname === path) {
      return "head-active";
    }
  };

  return (
    <div className="sticky top-[55px] z-50 h-[55px] w-full border-b border-border bg-component">
      <div className="flex items-center">
        <div
          className={`flex h-14 w-16 items-center justify-center ${
            active("/distribution/orders") as string
          }`}
        >
          <Link to="/distribution/orders">
            <img
              src={
                pathname === "/distribution/orders"
                  ? "/icons/orders_icon_on.svg"
                  : "/icons/orders_icon.svg"
              }
              alt="Orders"
              className="m-auto"
              width={30}
              height={30}
              data-cy="orders"
            />
          </Link>
        </div>
        <div
          className={`flex h-14 w-16 items-center justify-center ${
            active("/distribution/inventory") as string
          }`}
        >
          <Link to="/distribution/inventory">
            <img
              src={
                pathname === "/distribution/inventory"
                  ? "/icons/inventory_icon_on.svg"
                  : "/icons/inventory_icon.svg"
              }
              alt="Inventory"
              className="m-auto"
              width={30}
              height={30}
              data-cy="inventory"
            />
          </Link>
        </div>

        <div
          className={`flex h-14 w-16 items-center justify-center ${
            active("/distribution/distributors") as string
          }`}
        >
          <Link to="/distribution/distributors">
            <img
              src={
                pathname === "/distribution/distributors"
                  ? "/icons/distributors_icon_on.svg"
                  : "/icons/distributors_icon.svg"
              }
              alt="Distributors"
              className="m-auto"
              width={30}
              height={30}
              data-cy="distributors"
            />
          </Link>
        </div>
        {/* <div className={`flex h-14 w-16 items-center justify-center ${active("/notifications") as string}`}>
          <Link to="/notifications">
            <img
              src={pathname === "/notifications" ? "/icons/notification_icon_on.svg" : "/icons/notification_icon.svg"}
              alt="Notifications"
              className="m-auto"
              width={25}
              height={30}
            />
          </Link>
        </div> */}
        <div className="flex grow items-center justify-end">
          <div className="bg-green rounded-full px-2 text-white">
            {creditAmount}
          </div>
        </div>
        <div className="flex h-14 w-16 items-center justify-center">
          <img
            src="/icons/menu_icon.svg"
            alt="Menu"
            className="m-auto cursor-pointer"
            width={36.14}
            height={24.09}
            onClick={() => {
              setMenuOpen(true);
            }}
          />
          <MenuSlideout
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            user={user}
          />
        </div>
      </div>
    </div>
  );
}
