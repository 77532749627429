import { P6UserProfile } from "../../../../src/types/profile";
import BasicInfoEdit from "./basic_info_edit";

export default function BasicInfo({ user }: { user?: P6UserProfile | null }) {
  return (
    <div className="mt-5 w-full bg-component">
      <div className="relative flex items-center">
        <div className="relative ml-[20px] flex py-[10px]">
          <div>
            <div className="relative h-[81px] w-[81px] flex-none overflow-hidden rounded-full">
              <img src={user?.profileImageUrl} alt="Event" className="cover" />
            </div>
          </div>
        </div>
        <div className="ml-[20px] flex flex-auto flex-col text-white">
          <div className="text-[22px] leading-5">{user?.fullName}</div>
          <div className="text-[18px] text-grey">{user?.displayName}</div>
        </div>
        <BasicInfoEdit />
      </div>
    </div>
  );
}
