export default function Home() {
  return (
    <div className="w-full text-white relative auth-bg p-2.5">
      <img
        src="/images/p6_logo.svg"
        alt="logo"
        className="w-full h-[80px] mt-3"
      />

      <div className="w-full p-2.5 rounded-lg mb-2.5">
        <div className="text-[30px] text-center mb-4 mt-4">
          Easy as 1, 2, 3!
        </div>
        <div className="text-sm text-center">
          Platform Sixx takes the hassle out of creating and entering your
          favorite events. We handle everything so you can focus on the fun.
        </div>
        <div className="flex items-center mt-6">
          <div className="flex w-16 h-16 rounded-full bg-p6blue items-center justify-center text-white text-[50px] animate-pulse">
            1
          </div>
          <div className="ml-4 text-[20px] sm:text-[30px]">
            Create or join a group.
          </div>
        </div>
        <div className="flex items-center mt-6">
          <div className="flex w-16 h-16 rounded-full bg-p6blue items-center justify-center text-white text-[50px] animate-pulse">
            2
          </div>
          <div className="ml-4 text-[20px] sm:text-[30px]">
            Purchase your credits.
          </div>
        </div>
        <div className="flex items-center mt-6">
          <div className="flex w-16 h-16 rounded-full bg-p6blue items-center justify-center text-white text-[50px] animate-pulse">
            3
          </div>
          <div className="ml-4 text-[20px] sm:text-[30px]">
            Buy a seat and enjoy the show.
          </div>
        </div>
        <div className="mt-8">
          <div className="px-2.5 text-[24px] w-full border-b">Features</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-2">
            <ul className="justify-center mt-2.5 space-y-2.5">
              <li className="mr-2">
                <div className="flex items-center bg-component p-2 rounded-xl">
                  <img
                    src="/icons/shield_icon.svg"
                    alt="credit card"
                    className="w-6 h-6 mr-2"
                  />
                  <div className="flex flex-col">
                    <div className=" font-bold underline">Secure Payments</div>
                    <div className="text-xs pr-4">
                      All payments are processed through Authorize.net.
                    </div>
                  </div>
                </div>
              </li>
              <li className="mr-2">
                <div className="flex items-center bg-component p-2 rounded-xl">
                  <img
                    src="/icons/build_icon.svg"
                    alt="credit card"
                    className="w-6 h-6 mr-2"
                  />
                  <div className="flex flex-col">
                    <div className=" font-bold underline">
                      Easy event building
                    </div>
                    <div className="text-xs pr-4">
                      Simple useful tools for creating a custom event.
                    </div>
                  </div>
                </div>
              </li>
              <li className="mr-2">
                <div className="flex items-center bg-component p-2 rounded-xl">
                  <img
                    src="/icons/event_icon.svg"
                    alt="credit card"
                    className="w-6 h-6 mr-2"
                  />
                  <div className="flex flex-col">
                    <div className=" font-bold underline">Event management</div>
                    <div className="text-xs pr-4">
                      Close, start or remove events on your schedule.
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="justify-center mt-2.5 space-y-2.5">
              <li className="mr-2">
                <div className="flex items-center bg-component p-2 rounded-xl">
                  <img
                    src="/icons/groups_feature_icon.svg"
                    alt="credit card"
                    className="w-6 h-6 mr-2"
                  />
                  <div className="flex flex-col">
                    <div className=" font-bold underline">Group management</div>
                    <div className="text-xs pr-4">
                      Customize your group with your images and logo.
                    </div>
                  </div>
                </div>
              </li>
              <li className="mr-2">
                <div className="flex items-center bg-component p-2 rounded-xl">
                  <img
                    src="/icons/user_feature_icon.svg"
                    alt="credit card"
                    className="w-6 h-6 mr-2"
                  />
                  <div className="flex flex-col">
                    <div className=" font-bold underline">User management</div>
                    <div className="text-xs pr-4">
                      Full control over your users. Add, remove, promote.
                    </div>
                  </div>
                </div>
              </li>
              <li className="mr-2">
                <div className="flex items-center bg-component p-2 rounded-xl">
                  <img
                    src="/icons/user_feature_icon.svg"
                    alt="credit card"
                    className="w-6 h-6 mr-2"
                  />
                  <div className="flex flex-col">
                    <div className=" font-bold underline">
                      User transaction log
                    </div>
                    <div className="text-xs pr-4">
                      See all your transactions in one easy to access place.
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center mt-8">
          Create your next group with us & enjoy the time saving benefits of
          Platform Sixx.
          <img
            src="/icons/p6_small_logo.svg"
            alt="logo"
            className="w-full h-[80px] mt-3"
          />
          <div className="flex flex-col justify-end text-[10px] text-grey">
            <div>&copy; {new Date().getFullYear()} Platform Sixx Inc.</div>
            <div>All rights reserved.</div>
          </div>
        </div>
      </div>
    </div>
  );
}
